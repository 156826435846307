import React, { useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
	ERROR_MESSAGE_CLASSES,
	ERROR_MESSAGES,
	NavRoutes,
} from '../../../utils/constants'
import { actions } from '../model.slice'
import { useDispatch, useSelector } from 'react-redux'
import {
	useQuery,
	isObjectEmpty,
	validateMobileNumber,
	formatResponseObject,
	getNavigationRoute,
} from '../../../utils/methods'
import { createAgent, createCustomer } from './customerDetails.asyncActions'
import { LINKEDIN_CALLBACK_URL_INTRO } from '../../../utils/getEnvVars'
import { selectAgentInfo } from './customerDetails.selectors'
import { NumericFormat } from 'react-number-format'
import { NameRegularExpression } from '../../../utils/constants.regex'
import { selectAffiliateSlug } from '../../Affiliates/affiliates.selectors'

const CustomerDetailsForm = ({ modalsVisibility, setModalsVisibility }) => {
	const dispatch = useDispatch()
	const queryParams = useQuery()
	const agentsResponseInfo = useSelector(selectAgentInfo)
	const affiliateSlug = useSelector(selectAffiliateSlug)

	const { id: agentReferralID } = agentsResponseInfo
	const agentDetails = JSON.parse(
		window.sessionStorage.getItem('agent_details')
	)
	const { shouldActivateIntroScreen } = actions
	const initialValues = {
		firstName: '',
		lastName: '',
		email: '',
		phoneNumber: '',
	}
	const validationSchema = Yup.object().shape({
		firstName: Yup.string()
			.min(3, ERROR_MESSAGES.MIN_LENGTH_3)
			.max(255, ERROR_MESSAGES.MAX_LENGTH_255)
			.test('firstName', ERROR_MESSAGES.FIRST_NAME_VALIDATE_REGREX, (val) =>
				NameRegularExpression.test(val)
			)
			.required(ERROR_MESSAGES.FIRST_NAME_REQUIRED),
		lastName: Yup.string()
			.min(3, ERROR_MESSAGES.MIN_LENGTH_3)
			.max(255, ERROR_MESSAGES.MAX_LENGTH_255)
			.test('lastName', ERROR_MESSAGES.LAST_NAME_VALIDATE_REGREX, (val) =>
				NameRegularExpression.test(val)
			)
			.required(ERROR_MESSAGES.LAST_NAME_REQUIRED),
		email: Yup.string()
			.email(ERROR_MESSAGES.EMAIL_VALIDATE_SPACE)
			.required(ERROR_MESSAGES.EMAIL_REQUIRED),
		phoneNumber: Yup.string().test(
			'phoneNumber',
			ERROR_MESSAGES.MOBILE_NUMBER,
			(val) => validateMobileNumber(val)
		),
	})
	const onFormSubmit = (values) => {
		values = formatResponseObject(values)
		const url = getNavigationRoute(affiliateSlug, NavRoutes.MODEL)
		window.history.replaceState({}, document.title, url)
		setModalsVisibility({
			...modalsVisibility,
			shouldShowCustomerDetailsModal: false,
		})
		dispatch(shouldActivateIntroScreen(true))
		if (agentReferralID) {
			dispatch(
				createCustomer({
					...values,
					referralId: agentReferralID,
				})
			)
		}
	}
	const allowedMobileLength = (values) => {
		const MAX_TEN_DIGIT = 9999999999
		const { value } = values
		return value <= MAX_TEN_DIGIT
	}
	useEffect(() => {
		const authCode = queryParams.get('code')
		if (isObjectEmpty(agentsResponseInfo)) {
			dispatch(
				createAgent({
					agent: { ...agentDetails },
					authCode: authCode || null,
					redirectURL: authCode ? LINKEDIN_CALLBACK_URL_INTRO : null,
				})
			)
		}
	}, [dispatch, agentDetails, agentsResponseInfo, queryParams])
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => onFormSubmit(values)}
		>
			{({
				values,
				handleBlur,
				handleChange,
				touched,
				errors,
				handleSubmit,
			}) => (
				<form>
					<div className='form-group flex-col mb-6'>
						<div className='form-group mb-3'>
							<label
								className='mb-1 text-sm font-medium left-5 inline-block'
								htmlFor='fullName'
							>
								Customer First Name
							</label>
							<input
								type='text'
								name='firstName'
								className='form-control'
								placeholder='Customer First Name'
								onBlur={handleBlur}
								value={values.firstName}
								onChange={handleChange}
							/>
							{touched.firstName && errors.firstName && (
								<p className={ERROR_MESSAGE_CLASSES}>{errors.firstName}</p>
							)}
						</div>
						<div className='form-group mb-3'>
							<label
								className='mb-1 text-sm font-medium left-5 inline-block'
								htmlFor='fullName'
							>
								Customer Last Name
							</label>
							<input
								type='text'
								name='lastName'
								className='form-control'
								placeholder='Customer Last Name'
								onBlur={handleBlur}
								value={values.lastName}
								onChange={handleChange}
							/>
							{touched.lastName && errors.lastName && (
								<p className={ERROR_MESSAGE_CLASSES}>{errors.lastName}</p>
							)}
						</div>
						<div className='form-group mb-3'>
							<label
								className='mb-1 text-sm font-medium left-5 inline-block'
								htmlFor='email'
							>
								Customer Email
							</label>
							<input
								type='text'
								name='email'
								className='form-control'
								placeholder='Customer Email'
								onBlur={handleBlur}
								value={values.email}
								onChange={handleChange}
							/>
							{touched.email && errors.email && (
								<p className={ERROR_MESSAGE_CLASSES}>{errors.email}</p>
							)}
						</div>
						<div className='form-group mb-3'>
							<label
								className='mb-1 text-sm font-medium left-5 inline-block'
								htmlFor='streetAddress'
							>
								Customer Phone Number
							</label>
							<NumericFormat
								type='text'
								name='phoneNumber'
								className='form-control'
								placeholder='Customer Phone Number'
								allowNegative={false}
								decimalScale={0}
								isAllowed={(values) => allowedMobileLength(values)}
								onBlur={handleBlur}
								value={values.phoneNumber}
								onChange={handleChange}
							/>
							{touched.phoneNumber && errors.phoneNumber && (
								<p className={ERROR_MESSAGE_CLASSES}>{errors.phoneNumber}</p>
							)}
						</div>
					</div>
					<div className='flex mb-4 space-x-2'>
						<button
							type='button'
							className={`btn btn-light flex-1`}
							onClick={() => {
								setModalsVisibility({
									...modalsVisibility,
									shouldShowCustomerDetailsModal: false,
									shouldShowAgentDetailsModal: true,
								})
							}}
						>
							Back
						</button>
						<button
							type='submit'
							className={`btn btn-primary flex-1`}
							onClick={(e) => {
								handleSubmit(e)
							}}
						>
							Submit
						</button>
					</div>
				</form>
			)}
		</Formik>
	)
}

export default CustomerDetailsForm
