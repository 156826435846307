import React from 'react'
import { closeIcon, linkedInIcon } from '../../assets/icons'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { shouldShowNavbar } from '../NavBar'
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import { NavRoutes, PLENTI } from '../../utils/constants'
import { selectAffiliateSlug } from '../../features/Affiliates/affiliates.selectors'

const Modal = ({
	shouldShowModal,
	setShouldShowModal,
	result,
	title,
	subtitle,
	interactBtn = false,
	prevBtn,
	nextBtn = {},
	linkedInBtn,
}) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const isAffiliateSite = affiliateSlug !== PLENTI

	const { MEETING_SCHEDULER } = NavRoutes
	const navigateToMeetingScheduler = () => {
		dispatch(shouldShowNavbar(false))
		isAffiliateSite
			? navigate(`/${affiliateSlug}${MEETING_SCHEDULER}`, {
					state: location.pathname.split('/')[2],
			  })
			: navigate(MEETING_SCHEDULER, { state: location.pathname })
	}
	const {
		classes: nextBtnClasses,
		title: nextButtonTitle,
		isDisabled: nextButtonIsDisabled,
		click: nextButtonNavigate,
	} = nextBtn
	return (
		<>
			{shouldShowModal && (
				<div
					className='modal fixed inset-0 bg-slate-900/70 backdrop-blur-md z-[999]  overflow-auto px-4 flex items-center'
					onClick={() => setShouldShowModal(false)}
				>
					<div
						className='modal-dialog bg-white rounded-xl sm:w-[400px] my-6 mx-auto'
						onClick={(e) => e.stopPropagation()}
					>
						<div className='modal-header flex items-center p-6'>
							<div
								className={`icon w-10 h-10 grid place-items-center rounded-full ${result.iconBackground} `}
							>
								<img src={result.icon} width='24' alt='target-icon' />
							</div>
							<button
								className='btn ml-auto rounded-md hover:bg-slate-100'
								onClick={() => setShouldShowModal(false)}
							>
								<img src={closeIcon} width='24' alt='close-icon' />
							</button>
						</div>
						<div className='modal-body text-left px-6'>
							<h1 className='text-lg font-medium leading-7 mb-2 text-gray-900'>
								{title}
							</h1>
							<p className='text-sm leading-5 text-gray-500 mb-3 whitespace-pre-line'>
								{subtitle}
							</p>
							{interactBtn && (
								<button
									className='btn btn-primary-extalight w-full shadow-sm'
									onClick={navigateToMeetingScheduler}
								>
									Talk to an Attorney-Trained Mentor
								</button>
							)}
						</div>
						<div className='modal-footer p-6 flex gap-4'>
							{prevBtn && (
								<button
									className={`btn flex-1 ${prevBtn.classes}`}
									onClick={() => setShouldShowModal(false)}
								>
									{prevBtn.title}
								</button>
							)}
							{!isEmpty(nextBtn) && (
								<button
									type='button'
									className={classnames(
										'btn btn-primary text-white flex-1 disabled:opacity-50',
										{
											[`${nextBtnClasses}`]: Boolean(nextBtnClasses),
											'cursor-not-allowed': nextButtonIsDisabled,
										}
									)}
									onClick={() => nextButtonNavigate()}
									disabled={Boolean(nextButtonIsDisabled)}
								>
									{nextButtonTitle}
								</button>
							)}
							{linkedInBtn && (
								<button
									className={`btn ${linkedInBtn.classes} flex-1`}
									onClick={() => linkedInBtn.click()}
								>
									<span className='inline-flex items-baseline'>
										<img
											src={linkedInIcon}
											alt='linkedin-icon'
											className='inline mr-2'
										/>
										{linkedInBtn.title}
									</span>
								</button>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default Modal
