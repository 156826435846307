import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { actions } from '../model.slice'
import { ERROR_MESSAGE_CLASSES, SessionKeys } from '../../../utils/constants'
import { getUserAgreementPDF } from '../../../utils/methods'
import { warnNotification } from '../../../widgets/ToastNotification/ToastNotification'

const IntroQuestion = ({ modalsVisibility, setModalsVisibility }) => {
	const dispatch = useDispatch()
	const { shouldActivateIntroScreen } = actions
	const [isUserAgreementAccepted, setIsUserAgreementAccepted] = useState(false)
	const initialValues = {
		isBroker: true,
	}
	const validationSchema = Yup.object().shape({
		isBroker: Yup.bool().oneOf([true, false], 'Please select a value'),
	})
	const redirectToUserAgreementPDF = () => {
		const URL = getUserAgreementPDF()
		window.open(URL, '_blank', 'noopener,noreferrer')
	}
	const toggleUserAgreementAcceptance = () =>
		setIsUserAgreementAccepted((preValue) => !preValue)
	const renderWarningToAcceptUserAgreement = (event) => {
		event.preventDefault()
		warnNotification('Please Accept User Agreement to continue.')
	}
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				window.sessionStorage.removeItem(SessionKeys.AGENT_DETAILS)
				if (values.isBroker === 'false') {
					setModalsVisibility({
						...modalsVisibility,
						shouldShowIntroQuestionModal: false,
					})
					dispatch(shouldActivateIntroScreen(true))
				} else {
					setModalsVisibility({
						...modalsVisibility,
						shouldShowAgentDetailsModal: true,
						shouldShowIntroQuestionModal: false,
					})
				}
			}}
		>
			{({
				values,
				touched,
				errors,
				handleBlur,
				handleChange,
				handleSubmit,
			}) => (
				<form>
					<div className='form-group mb-5 pr-5'>
						<div className='flex-col items-center gap-4'>
							<div className='checkbox mb-3'>
								<div className='flex'>
									<div className='flex-col'>
										<input
											id='broker'
											className='text-primary-700 mr-2  focus:ring-primary-200/75'
											type='radio'
											name='isBroker'
											onChange={handleChange}
											onBlur={handleBlur}
											value={true}
											defaultChecked
										/>
									</div>
									<div className='flex-col'>
										<label htmlFor='broker' className='text-gray-800'>
											I am a real estate agent, broker, accountant or consultant
											completing this for someone else.
										</label>
									</div>
								</div>
							</div>
							<div className='checkbox'>
								<div className='flex'>
									<div className='flex-col'>
										<input
											id='customer'
											className='text-primary-700 mr-2  focus:ring-primary-200/75'
											type='radio'
											name='isBroker'
											onChange={handleChange}
											onBlur={handleBlur}
											value={false}
										/>
									</div>
									<div className='flex-col'>
										<label htmlFor='customer' className='text-gray-800'>
											I am completing this myself to learn how I might save on
											taxes via a 1031 exchange.
										</label>
									</div>
								</div>
							</div>
						</div>
						{touched.isBroker && errors.isBroker && (
							<p className={ERROR_MESSAGE_CLASSES}>{errors.isBroker}</p>
						)}
						<label className='inline-flex relative mt-3 items-center cursor-pointer mr-auto md:mr-0 ml-auto'>
							<input
								type='checkbox'
								checked={isUserAgreementAccepted}
								className='sr-only peer'
								onClick={() => toggleUserAgreementAcceptance()}
								readOnly
							/>
							<div className="w-11 h-6 bg-gray-300 peer-focus:outline-none rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-primary-700"></div>
							<span className='ml-3 text-sm font-medium text-gray-900'>
								I agree to the&nbsp;
								<span
									className='text-primary-700 underline'
									onClick={redirectToUserAgreementPDF}
								>
									user terms
								</span>
							</span>
						</label>
					</div>
					<div className='flex mb-4'>
						<button
							type='submit'
							className={`btn btn-primary text-white flex-1`}
							onClick={(e) => {
								if (isUserAgreementAccepted) handleSubmit(e)
								else renderWarningToAcceptUserAgreement(e)
							}}
						>
							Submit
						</button>
					</div>
				</form>
			)}
		</Formik>
	)
}

export default IntroQuestion
