import { createAsyncThunk } from '@reduxjs/toolkit'
import doAsync from '../../infrastructure/doAsync'

export const updateCurrentInvestmentSellAndPurchasePlan = createAsyncThunk(
	'moreDetails/updatedSellAndPurchasePlan',
	async (
		{ userId, sellPlan, purchasePlan },
		thunkArgs,
		{ useCaching, noBusySpinner } = {}
	) => {
		return await doAsync({
			url: `users/${userId}/current-investment`,
			useCaching,
			noBusySpinner,
			httpMethod: 'put',
			httpConfig: {
				body: JSON.stringify({
					sellPlan: sellPlan,
					purchasePlan: purchasePlan,
				}),
			},
			errorMessage:
				'Unable to updated sell & purchase plan, Please try again later.',
			...thunkArgs,
		})
	}
)
export const getCurrentInvestmentDetails = createAsyncThunk(
	'moreDetails/getcurrentInvestmentDetail',
	async ({ userId }, thunkArgs, { useCaching, noBusySpinner } = {}) => {
		return await doAsync({
			url: `users/${userId}/current-investment`,
			useCaching,
			noBusySpinner,
			errorMessage:
				'Unable to fetch current investment details, Please try again later.',
			...thunkArgs,
		})
	}
)
export const getNewInvestmentsDetail = createAsyncThunk(
	'moreDetails/getNewInvestmentsDetails',
	async ({ userId }, thunkArgs, { useCaching, noBusySpinner } = {}) => {
		return await doAsync({
			url: `users/${userId}/new-investments`,
			useCaching,
			noBusySpinner,
			errorMessage:
				'Unable to fetch new investment details, Please try again later.',
			...thunkArgs,
		})
	}
)
