import slice from './affiliates.slice'

export const selectSlice = (state) => state[slice.name]
export const selectIsSlugValidated = (state) => selectSlice(state).isSlugValid
export const selectIsCompanyNameValidated = (state) =>
	selectSlice(state).isCompanyNameValid
export const selectIsSlugFieldActive = (state) =>
	selectSlice(state).isSlugFieldActive
export const selectUploadedImage = (state) => selectSlice(state).uploadedImage
export const selectFormData = (state) => selectSlice(state).formData
export const selectAffiliateDetails = (state) =>
	selectSlice(state).affiliateDetails
export const selectAffiliateSlug = (state) => selectSlice(state).affiliateSlug
export const selectImageData = (state) => selectSlice(state).imageData
