import ChatWindow from './ChatWindow'
import * as chatWindowStatesSelector from './chatWindowStates.selectors'
import slice from './chatWindowStates.slice'

export const {
	name,
	actions: { toggleActive, hideChatWindow },
	reducer,
} = slice

export const { selectChatWindowState } = chatWindowStatesSelector

export default ChatWindow
