import slice from './navbarStates.slice'

export const selectSlice = (state) => state[slice.name]

export const selectIntroState = (state) => selectSlice(state).intro
export const selectCurrentInvestmentState = (state) =>
	selectSlice(state).currentInvestment
export const selectFindInvestmentState = (state) =>
	selectSlice(state).findInvestment
export const selectMoreDetailsState = (state) => selectSlice(state).moreDetails
export const selectSummaryState = (state) => selectSlice(state).summary
export const selectNavbarState = (state) => selectSlice(state).navbar
export const selectAuthState = (state) => selectSlice(state).auth
export const selectReferralState = (state) => selectSlice(state).referral
export const selectAffiliateIntro = (state) =>
	selectSlice(state).affiliateIntroduction
export const selectAffiliateStartApplicationState = (state) =>
	selectSlice(state).affiliateStartApplication
export const selectRequestApprovalState = (state) =>
	selectSlice(state).affiliateRequestApproval
export const selectTermsState = (state) => selectSlice(state).affiliateTerms
