import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import classnames from 'classnames'
import { Header } from './Header'
import Footer from './Footer'
import { openLink, chevronDown, phone } from '../../assets/icons'
import {
	commaSeparatedToNumber,
	getNavigationRoute,
	numberCommasSeparator,
	openURLInNewTabDefault,
} from '../../utils/methods'
import { errorNotification } from '../../widgets/ToastNotification'
import { NavRoutes, PLENTI, SliderConstants } from '../../utils/constants'
import { stateCodeAndNames } from '../../utils/stateCodes'
import { useDispatch, useSelector } from 'react-redux'
import {
	fetchContactDetails,
	saveContactOnHubspot,
} from './ReinvestmentAffiliate.asynAction'
import {
	selectAffiliateDetails,
	selectAffiliateSlug,
} from '../Affiliates/affiliates.selectors'
import { ceoProfilePicture, taxReportAffialiate } from '../../assets/images'
import SeeHowProfitTaxed from './SeeHowProfitTaxed'
import useBodyScrollDisable from '../../utils/useBodyScrollDisable'
import Loading from '../../widgets/Loading/Loading'
import { EmailRegularExpression } from '../../utils/constants.regex'

const plentiExchangeGuideSteps = [
	{
		serialNumber: 1,
		title: 'calculate your total tax picture',
		description:
			'Find out what your tax bite will be on a standard sale. Complete the form on the left.',
	},
	{
		serialNumber: 2,
		title: 'review reinvestment options',
		description:
			'Saving on your tax bill empowers you to reinvest in something that offers more than just wealth-building. Review reinvestments. A Plenti Financial 1031 Specialist can discuss your options in detail when you are ready.',
	},
	{
		serialNumber: 3,
		title: 'model your exchange',
		description:
			'The Plenti App can help give you a tailored look at what your 1031 Exchange could look like. Ask questions along the way.',
	},
]

const PROFIT_DETAILS_MAPPING = [
	{ name: 'equity', label: 'Equity', text: 'text-neutral-500' },
	{ name: 'profit', label: 'Profit', text: 'text-emerald-600' },
	{ name: 'taxes', label: 'Taxes', text: 'text-red-800' },
]

const { MIN_VALUE, MAX_VALUE } = SliderConstants
const MAX_SELLING_PRICE = 5 * MAX_VALUE
const { HOME, REINVESTMENT_CHOICES, MODEL } = NavRoutes
const RESIDENCE_STATE = 'residenceState'
const PROPERTY_LOCATION = 'propertyLocation'
const federalTaxThreshold400k = 400000
const federalTaxThreshold250k = 250000
const federalNetInvIncTaxRateMultiplier = 0.038
const federalDepreciationRecaptureTaxRateMultiplier = 0.25
const federalCapitalGainsTaxRateGreaterProfit = 0.2
const federalCapitalGainsTaxRateLesserProfit = 0.15
const RENT_DURATION_DIVISOR = 27.5

const TAX_REPORT_VALUES = {
	propertyPrice: {
		maxValue: MAX_VALUE,
		errorMessage: `Property price cannot exceed $${MAX_VALUE.toLocaleString()}`,
	},
	sellingPrice: {
		maxValue: MAX_SELLING_PRICE,
		errorMessage: `Selling price cannot exceed $${MAX_SELLING_PRICE.toLocaleString()}`,
	},
	loanBalance: {
		maxValue: MAX_SELLING_PRICE,
		errorMessage: `Loan balance cannot exceed $${MAX_SELLING_PRICE.toLocaleString()}`,
	},
	rentalDuration: {
		maxValue: 30,
		errorMessage: 'Rental duration cannot exceed 30 years',
	},
}

const getPositiveRoundValue = (value) => Math.round(Math.max(0, value))

const getQueryParams = (url) => {
	const queryString = url && url.startsWith('?') ? url.slice(1) : url
	if (!queryString) return

	const pairs = queryString.split('&')
	const queryParams = pairs?.reduce((queryParams, pair) => {
		const [key, value] = pair.split('=')
		if (key && value) queryParams[key] = value
		return queryParams
	}, {})
	return queryParams
}

const TaxReportHeader = () => (
	<div className='relative'>
		<div className='absolute inset-0 bg-gradient-to-b from-black/20 to-black/90 z-10' />
		<div
			className='absolute inset-0 bg-cover bg-center z-0'
			style={{ backgroundImage: `url(${taxReportAffialiate})` }}
		/>
		<div className='relative p-6 sm:p-12 z-20 font-work-sans-medium text-white flex flex-col sm:flex-row sm:items-end gap-8'>
			<div className='flex-1'>
				<div className='text-lg sm:text-xl'>
					Learn your tax bite, review options and build wealth
				</div>
				<div className='text-xs sm:text-sm mt-2'>
					Plenti Financial, headquartered in San Diego, CA, operates nationwide
					as an IRS-required IRC Section 1031 accommodator. With 50 years of
					experience, we&apos;ve never had a loss claim. Our proprietary tech
					platform ensures top-notch client education, security, and seamless
					exchange execution.
				</div>
			</div>
			<a
				href='tel:8669441031'
				className='bg-white rounded-full py-2 px-3 flex justify-center items-center gap-2 text-gray-800 font-work-sans-semibold text-xs'
			>
				<img src={phone} className='w-4 h-4' /> (866) 944 - 1031
			</a>
		</div>
	</div>
)

const TaxReport = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const affiliateDetails = useSelector(selectAffiliateDetails)
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const [profitDetails, setProfitDetails] = useState({})
	let emailFromParams = getQueryParams(window.location.search)?.email

	emailFromParams =
		emailFromParams && EmailRegularExpression.test(emailFromParams)
			? emailFromParams
			: ''

	const {
		currentPrice = '',
		originalPrice = '',
		email = emailFromParams,
	} = location.state?.values || {}

	const [isOpen, setIsOpen] = useState(false)
	const [searchTerm, setSearchTerm] = useState('')
	const dropdownRef = useRef(null)
	const locatedPropertyDropdownRef = useRef(null)
	const [selectedState, setSelectedState] = useState(null)
	const [propertyLocation, setPropertyLocation] = useState(null)
	const [shouldShowSeeHowProfitTaxed, setShouldShowSeeHowProfitTaxed] =
		useState(null)
	const [taxReportValues, setTaxReportValues] = useState({
		propertyPrice: numberCommasSeparator(originalPrice),
		sellingPrice: numberCommasSeparator(currentPrice),
		loanBalance: '',
		rentalDuration: '',
	})
	const [isFetching, setIsFetching] = useState(emailFromParams)

	useBodyScrollDisable(shouldShowSeeHowProfitTaxed)

	const isAffiliateSite = affiliateSlug && affiliateSlug !== PLENTI

	useEffect(() => {
		const handleClickOutside = (event) => {
			const shouldCloseDropdown =
				(isOpen === PROPERTY_LOCATION &&
					locatedPropertyDropdownRef.current &&
					!locatedPropertyDropdownRef.current.contains(event.target)) ||
				(isOpen === RESIDENCE_STATE &&
					dropdownRef.current &&
					!dropdownRef.current.contains(event.target))

			shouldCloseDropdown && setIsOpen()
		}

		isOpen && document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [isOpen])

	useEffect(() => {
		!email && navigate(isAffiliateSite ? `/${affiliateSlug}` : HOME)
		window.scrollTo(0, 0)
	}, [email, navigate, isAffiliateSite, affiliateSlug])

	const handleDropdownToggle = (selectedStateLocationInUSA) => {
		setIsOpen(
			isOpen !== selectedStateLocationInUSA && selectedStateLocationInUSA
		)
		setSearchTerm('')
	}

	const handleSearchInputChange = (event) => {
		setSearchTerm(event.target.value.toLowerCase())
	}

	const handleStateSelect = (state, property) => {
		if (property === RESIDENCE_STATE) {
			setSelectedState(state)
		} else if (property === PROPERTY_LOCATION) {
			setPropertyLocation(state)
		}
		setIsOpen(false)
	}

	const filteredStates = stateCodeAndNames.filter(
		(state) =>
			state.state_name.toLowerCase().includes(searchTerm) ||
			state.state_code.toLowerCase().includes(searchTerm)
	)

	useEffect(() => {
		emailFromParams &&
			dispatch(fetchContactDetails({ email: emailFromParams })).then((res) => {
				setIsFetching(false)
				if (!res.payload) return
				const { originalPrice, currentSalesPrice } = res.payload
				originalPrice &&
					currentSalesPrice &&
					setTaxReportValues((values) => ({
						...values,
						propertyPrice: numberCommasSeparator(originalPrice),
						sellingPrice: numberCommasSeparator(currentSalesPrice),
					}))
			})
	}, [dispatch, emailFromParams])

	const isCalculateButtonDisabled = useMemo(
		() =>
			!Object.values(taxReportValues).every((value) => value) ||
			!selectedState ||
			!propertyLocation,
		[taxReportValues, selectedState, propertyLocation]
	)

	const handleInputChange = (e) => {
		const name = e.target.name
		const inputValue = e.target.value
		const numericValue = inputValue.replace(/[^\d]/g, '')
		const formattedValue = numberCommasSeparator(numericValue)
		if (numericValue <= TAX_REPORT_VALUES[name].maxValue)
			setTaxReportValues((values) => ({ ...values, [name]: formattedValue }))
		else errorNotification(TAX_REPORT_VALUES[name].errorMessage)
	}

	const calculateProfit = () => {
		const sellingPrice = commaSeparatedToNumber(taxReportValues.sellingPrice)
		const originalPrice = commaSeparatedToNumber(taxReportValues.propertyPrice)
		const rentDuration = commaSeparatedToNumber(taxReportValues.rentalDuration)
		const loanBalance = commaSeparatedToNumber(taxReportValues.loanBalance)

		const profit =
			sellingPrice -
			(originalPrice - originalPrice * (rentDuration / RENT_DURATION_DIVISOR))

		const federalNetInvIncTax = getPositiveRoundValue(
			(profit - federalTaxThreshold250k) * federalNetInvIncTaxRateMultiplier
		)
		const federalDepreciationRecaptureTax = getPositiveRoundValue(
			originalPrice *
				(rentDuration / RENT_DURATION_DIVISOR) *
				federalDepreciationRecaptureTaxRateMultiplier
		)

		const federalCapitalGainsTax = getPositiveRoundValue(
			(sellingPrice - originalPrice) *
				(profit > federalTaxThreshold400k
					? federalCapitalGainsTaxRateGreaterProfit
					: federalCapitalGainsTaxRateLesserProfit)
		)

		const stateGainsTaxPropertyLocation = profit * propertyLocation.state_value

		const stateGainsTaxResidence = profit * selectedState.state_value

		const stateCapitalGainsTax = getPositiveRoundValue(
			Math.max(stateGainsTaxPropertyLocation, stateGainsTaxResidence)
		)
		const calculatedFinalTax =
			federalCapitalGainsTax +
			federalDepreciationRecaptureTax +
			federalNetInvIncTax +
			stateCapitalGainsTax

		const stateWithHigherTax =
			selectedState.state_value > propertyLocation.state_value
				? selectedState
				: propertyLocation

		setProfitDetails({
			equity: Math.round(Math.max(0, sellingPrice - loanBalance)),
			profit: Math.round(Math.max(0, profit)),
			taxes: Math.round(Math.max(0, calculatedFinalTax)),
			federalNetInvIncTax,
			federalDepreciationRecaptureTax,
			federalCapitalGainsTax,
			stateCapitalGainsTax,
			stateWithHigherTax,
		})
		const request = {
			Email: email,
			OriginalPrice: originalPrice,
			CurrentSalesPrice: sellingPrice,
			CurrentLoanBalance: loanBalance,
			YourStateOfResidence: selectedState?.state_name,
			StateOfProperty: propertyLocation?.state_name,
			NumberOfYearsUsedAsRental: rentDuration,
			Affiliate: affiliateDetails?.companySlug || '',
		}
		email && dispatch(saveContactOnHubspot(request))
	}

	const toggleSeeHowProfitTaxed = () => {
		setShouldShowSeeHowProfitTaxed((prevValue) => !prevValue)
		var iframe = document.getElementById('hubspot-conversations-iframe')
		iframe &&
			(iframe.style.visibility = shouldShowSeeHowProfitTaxed
				? 'visible'
				: 'hidden')
	}

	return (
		<div>
			<Header />
			<div className='flex flex-col sm:flex-row w-full'>
				{isAffiliateSite && (
					<div className='sm:hidden'>
						<TaxReportHeader />
					</div>
				)}
				<div className='flex sm:w-[40%] p-6 sm:px-8 md:p-12 min-h-[200px] sm:min-h-0 '>
					<Loading loading={isFetching}>
						<div className='flex flex-col gap-6 !justify-start'>
							<p className='font-work-sans-semibold text-black sm:text-xl'>
								Your Personalized Tax Report
							</p>
							<p className='text-gray-500 font-work-sans-medium text-sm sm:text-lg'>
								Complete the highlighted fields below to calculate the total
								estimated taxes due on a standard sale
							</p>
							<div className='flex flex-col gap-6'>
								<div className='flex w-full justify-between items-center gap-2'>
									<p className='text-xs sm:text-sm text-gray-700 font-work-sans-medium'>
										How much did you pay for your current property?
									</p>
									<div className='relative w-36 flex items-center'>
										<span className='absolute text-sm text-gray-500 left-0 h-4 pl-3 flex items-center font-work-sans-regular'>
											$
										</span>
										<input
											name='propertyPrice'
											placeholder={MAX_VALUE.toLocaleString()}
											type='text'
											value={taxReportValues.propertyPrice}
											onChange={handleInputChange}
											className={classnames(
												'remove-arrow w-36 text-end inline-flex justify-center pl-8 pr-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-gray-100 focus:ring-blue-200',
												{
													'ring-4 border-orange-400 focus:border-gray-300 ring-orange-100':
														!taxReportValues.propertyPrice,
												}
											)}
										/>
									</div>
								</div>
								<div className='flex justify-between items-center gap-2'>
									<p className='text-xs sm:text-sm text-gray-700 font-work-sans-medium'>
										Current property&apos;s approximate selling price?
									</p>
									<div className='relative w-36 flex items-center'>
										<span className='absolute text-sm text-gray-500 left-0 h-4 pl-3 flex items-center font-work-sans-regular'>
											$
										</span>
										<input
											name='sellingPrice'
											placeholder={MAX_VALUE.toLocaleString()}
											type='text'
											value={taxReportValues.sellingPrice}
											onChange={handleInputChange}
											className={classnames(
												'remove-arrow w-36 inline-flex text-end justify-center pl-8 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-gray-100 focus:ring-blue-200',
												{
													'ring-4 border-orange-400 focus:border-gray-300 ring-orange-100':
														!taxReportValues.sellingPrice,
												}
											)}
										/>
									</div>
								</div>
								<div className='flex justify-between items-center gap-2'>
									<p className='text-xs sm:text-sm text-gray-700 font-work-sans-medium'>
										What is your approximate loan balance?
									</p>
									<div className='relative w-36 flex items-center'>
										<span className='absolute text-sm text-gray-500 left-0 h-4 pl-3 flex items-center font-work-sans-regular'>
											$
										</span>
										<input
											name='loanBalance'
											type='text'
											placeholder={MIN_VALUE}
											value={taxReportValues.loanBalance}
											onChange={handleInputChange}
											className={classnames(
												'remove-arrow w-36 inline-flex justify-center text-end pl-8 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-gray-100 focus:ring-blue-200',
												{
													'ring-4 border-orange-400 focus:border-gray-300 ring-orange-100':
														!taxReportValues.loanBalance,
												}
											)}
										/>
									</div>
								</div>
								<div className='flex justify-between items-center gap-2'>
									<p className='text-xs sm:text-sm text-gray-700 font-work-sans-medium'>
										How many years was your property a rental?
									</p>
									<div className='relative w-36'>
										<input
											name='rentalDuration'
											type='text'
											placeholder={MIN_VALUE}
											value={taxReportValues.rentalDuration}
											onChange={handleInputChange}
											className={classnames(
												'remove-arrow w-36 text-end inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-gray-100 focus:ring-blue-200',
												{
													'ring-4 border-orange-400 focus:border-gray-300 ring-orange-100':
														!taxReportValues.rentalDuration,
												}
											)}
										/>
									</div>
								</div>
								<div className='flex justify-between items-center gap-2'>
									<p className='text-xs sm:text-sm text-gray-700 font-work-sans-medium'>
										State where the property is located?
									</p>
									<div
										className='relative group'
										ref={locatedPropertyDropdownRef}
									>
										<button
											onClick={() => handleDropdownToggle(PROPERTY_LOCATION)}
											id='dropdown-button'
											className={classnames(
												'flex justify-between items-center w-36 px-4 py-2 text-sm font-work-sans-regular text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-gray-100 focus:ring-blue-200',
												{
													'ring-4 border-orange-400 focus:border-gray-300 ring-orange-100':
														!propertyLocation,
												}
											)}
										>
											<span className='text-gray-800 text-base font-work-sans-normal truncate'>
												{propertyLocation
													? `${propertyLocation.state_name} (${propertyLocation.state_code})`
													: 'Select State'}
											</span>
											<img src={chevronDown} />
										</button>
										<div
											id='dropdown-menu'
											className={`custom-select absolute font-work-sans-regular right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 z-50 w-max ${
												isOpen === PROPERTY_LOCATION
													? 'opacity-100 visible transition duration-500'
													: 'opacity-0 invisible'
											}`}
											style={{
												maxHeight:
													isOpen === PROPERTY_LOCATION ? '275px' : '0px',
												overflowY: 'auto',
											}}
										>
											<input
												id='search-input'
												className='block w-full px-4 py-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none font-work-sans-regular'
												type='text'
												placeholder='Search state'
												autoComplete='off'
												onChange={handleSearchInputChange}
												value={searchTerm}
											/>

											{filteredStates.map((state, index) => (
												<button
													key={state.state_code}
													onClick={() =>
														handleStateSelect(state, PROPERTY_LOCATION)
													}
													className='block w-full text-start px-4 py-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md font-work-sans-regular'
												>
													{state.state_name} ({state.state_code})
												</button>
											))}
										</div>
									</div>
								</div>
								<div className='flex justify-between items-center gap-2'>
									<p className='text-xs sm:text-sm text-gray-700 font-work-sans-medium'>
										Your state of residence?
									</p>
									<div className='relative group' ref={dropdownRef}>
										<button
											onClick={() => handleDropdownToggle(RESIDENCE_STATE)}
											id='dropdown-button'
											className={classnames(
												'flex justify-between items-center w-36 px-4 py-2 text-sm font-work-sans-regular text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-gray-100 focus:ring-blue-200',
												{
													'ring-4 border-orange-400 focus:border-gray-300 ring-orange-100':
														!selectedState,
												}
											)}
										>
											<span className='text-gray-800 text-base font-work-sans-normal truncate'>
												{selectedState
													? `${selectedState.state_name} (${selectedState.state_code})`
													: 'Select State'}
											</span>
											<img src={chevronDown} />
										</button>
										<div
											id='dropdown-menu'
											className={`custom-select absolute font-work-sans-regular right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 z-50 w-max ${
												isOpen === RESIDENCE_STATE
													? 'opacity-100 visible transition duration-500'
													: 'opacity-0 invisible'
											}`}
											style={{
												maxHeight: isOpen === RESIDENCE_STATE ? '275px' : '0px',
												overflowY: 'auto',
											}}
										>
											<input
												id='search-input'
												className='block w-full px-4 py-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none font-work-sans-regular'
												type='text'
												placeholder='Search state'
												autoComplete='off'
												onChange={handleSearchInputChange}
												value={searchTerm}
											/>

											{filteredStates.map((state, index) => (
												<a
													key={index}
													onClick={() =>
														handleStateSelect(state, RESIDENCE_STATE)
													}
													className='block px-4 py-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md font-work-sans-regular'
												>
													{state.state_name} ({state.state_code})
												</a>
											))}
										</div>
									</div>
								</div>
							</div>
							<div className='relative flex justify-center items-center mt-7'>
								<div className='w-[60%] sm:w-[50%] bg-white'>
									<button
										className='text-center text-white font-work-sans-semibold w-full px-[18px] py-[13px] sm:px-5 sm:py-4 rounded-full bg-primary-200 hover:bg-primary-100 border flex justify-center items-center cursor-pointer transition duration-500 ease-in-out disabled:hover:bg-primary-200 disabled:cursor-not-allowed z-40 sm:text-lg'
										disabled={isCalculateButtonDisabled}
										onClick={calculateProfit}
									>
										Calculate
									</button>
								</div>
								<p className='border border-b-1 border-gray-300 absolute w-full -z-10'></p>
							</div>
							<div className='bg-skyGray border border-gray-300 p-3 sm:p-6 flex flex-col gap-6'>
								<div className='flex justify-between flex-wrap gap-1 sm:gap-2'>
									{PROFIT_DETAILS_MAPPING.map(({ name, label, text }) => (
										<div
											key={name}
											className='flex min-w-[5rem] flex-col gap-2'
										>
											<p className='font-work-sans font-bold text-zinc-700'>
												{label}
											</p>
											<p
												className={`font-work-sans-semibold text-sm sm:text-2xl duration-300 ${
													profitDetails[name] ? text : 'text-gray-300'
												}`}
											>
												{profitDetails[name]
													? `$${numberCommasSeparator(profitDetails[name])}`
													: '$ 0'}
											</p>
										</div>
									))}
								</div>
								<div className='flex justify-center'>
									<button
										className={classnames(
											'text-center text-black font-work-sans-semibold w-full py-3 rounded-[30px] bg-white border border-gray-400 flex justify-center items-center cursor-pointer transition duration-500 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed text-sm sm:text-base',
											{
												'text-gray-400': !profitDetails.profit,
												'text-black hover:bg-[#eceef1]': profitDetails.profit,
											}
										)}
										disabled={!profitDetails.profit && !profitDetails.taxes}
										onClick={toggleSeeHowProfitTaxed}
									>
										See How Your Profit is Taxed
									</button>
								</div>
							</div>
						</div>
					</Loading>
				</div>
				<div className='sm:w-[60%]'>
					{isAffiliateSite && (
						<div className='hidden sm:block'>
							<TaxReportHeader />
						</div>
					)}
					<div className='flex flex-col bg-blueGray gap-10 w-full p-6 sm:p-12 md:px-24 md:py-20'>
						<h1 className='text-[#5C9DA5] text-4xl sm:text-43 font-work-sans-light'>
							Your Plenti Exchange 3-Step Tax Guide
						</h1>
						<div className='flex flex-col gap-10'>
							{plentiExchangeGuideSteps.map((step) => (
								<div key={step.serialNumber} className='flex gap-5'>
									<div>
										<h2 className='text-white border-b-2 w-fit px-4 text-lg sm:text-2xl font-work-sans-regular'>
											{step.serialNumber}
										</h2>
									</div>
									<div className='flex flex-col gap-4'>
										<h2 className='text-white text-sm sm:text-xl font-work-sans-semibold'>
											{step.title.toUpperCase()}
										</h2>
										<p className='text-white text-sm sm:text-lg font-work-sans-medium'>
											{step.description}
										</p>
									</div>
								</div>
							))}
						</div>
						<div className='flex flex-col-reverse sm:flex-row justify-end font-work-sans-regular mt-6 gap-4'>
							<button
								className='text-white bg-[#171A1D] border group border-white rounded-full py-3 px-4 flex gap-2 items-center justify-center transition-colors duration-500 hover:text-black hover:bg-gray-100'
								onClick={() =>
									openURLInNewTabDefault(
										getNavigationRoute(affiliateSlug, REINVESTMENT_CHOICES)
									)
								}
							>
								View Reinvestments
								<img
									src={openLink}
									className='w-4 h-4 group-hover:filter group-hover:invert'
								/>
							</button>

							<button
								className='text-white bg-[#171A1D] group border border-white rounded-full py-3 px-4 flex gap-2 items-center justify-center transition-colors duration-300 hover:text-black hover:bg-gray-100'
								onClick={() =>
									openURLInNewTabDefault(
										getNavigationRoute(affiliateSlug, MODEL)
									)
								}
							>
								Model My Exchange
								<img
									src={openLink}
									className='w-4 h-4 group-hover:filter group-hover:invert'
								/>
							</button>
						</div>
					</div>
				</div>
				{isAffiliateSite && (
					<div className='sm:hidden p-6 flex flex-col gap-8 items-center'>
						<img src={ceoProfilePicture} className='h-36 w-36' />
						<div className='font-work-sans-medium'>
							<div className='text-lg text-blueGray'>
								Meet the CEO of Plenti Financial
							</div>
							<div className='text-xs text-gray-700 mt-2'>
								Hi I&apos;m David Greenberger, Esq. I went to law school a long
								time ago for two reasons: I wanted to help people, and my mother
								told me too. But it wasn&apos;t until many years later, that I
								was able to make an impact on people&apos;s financial lives by
								showing them how to sell highly appreciated real estate tax
								free. I started on a journey to tell as many people as I could,
								and to create a platform that would make selling tax free with
								an exchange, easy to do!
							</div>
						</div>
					</div>
				)}
				{shouldShowSeeHowProfitTaxed && (
					<SeeHowProfitTaxed
						profitDetails={profitDetails}
						toggleSeeHowProfitTaxed={toggleSeeHowProfitTaxed}
					/>
				)}
			</div>
			<Footer />
		</div>
	)
}

export default TaxReport
