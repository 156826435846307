import React from 'react'
import { roadmapHouseImage } from '../../../assets/images'
import RoadmapCta from '../RoadmapCta'

const Introduction = ({ setShouldShowMeetingSchedular }) => (
	<div>
		<div className='w-full h-auto p-8 sm:p-12 md:p-24 flex flex-col gap-12'>
			<div className='font-work-sans-light text-6xl text-primary-600'>
				<div>Plenti Financial</div>
				<div> 1031 Exchange Roadmap </div>
			</div>
			<div className='flex flex-col sm:flex-row gap-10'>
				<div className='text-gray-500 font-work-sans-regular text-lg flex flex-col gap-8 flex-1'>
					<div className='font-work-sans-light text-43'>Introduction</div>
					<div>
						Unlock your path to a successful 1031 real estate exchange with our
						Roadmap. It simplifies options and kickstarts your journey,
						empowering answer: what&apos;s next after selling? At Plenti
						Financial, we offer expert guidance on 1031 exchanges for free.
					</div>
					<div>
						In this inaugural volume, discover 10 diverse investment prospects
						ready to unlock new opportunities
					</div>
					<ul className='list-disc pl-6'>
						<li>Residential Properties</li>
						<li>Home Office</li>
						<li>Multi Family</li>
						<li>Triple Net Leases (NNN)</li>
						<li>Delaware Statutory Trust (DST)</li>
						<li>Vacation Properties & Condo Hotels</li>
						<li>Raw Land Cash Out Options</li>
						<li>Foreclosure</li>
						<li>Other Investments</li>
					</ul>
				</div>
				<div className='w-full sm:w-5/12 max-h-96 sm:max-h-max h-auto'>
					<img
						src={roadmapHouseImage}
						className='w-full h-full max-h-96 sm:max-h-max object-cover border border-black'
					/>
				</div>
			</div>
		</div>
		<RoadmapCta>
			<div className='flex flex-col sm:flex-row justify-between items-center gap-4 sm:gap-6'>
				<div className='text-white flex-1 font-work-sans-regular text-lg sm:text-2xl text-center'>
					Elevate your real estate strategy with us!
				</div>
				<button
					className='btn btn-light sm:w-auto cursor-pointer rounded-full font-work-sans-semibold sm:text-lg text-primary-700 px-3 py-2 sm:px-5 sm:py-4'
					onClick={() => setShouldShowMeetingSchedular((value) => !value)}
				>
					Schedule a Consultation
				</button>
			</div>
		</RoadmapCta>
	</div>
)

export default Introduction
