import Summary from './Summary'
import * as selectors from './summary.selectors'
import slice from './summary.slice'

export * from './summary.asyncActions'
export const {
	name,
	actions: { resetToInititalState },
	reducer,
} = slice
export const { selectUsersResponseData, selectTaxReportInBase64 } = selectors

export default Summary
