import React from 'react'

export default function useWindowSize() {
	// SSR: server side rendering support,
	// Handling case were window object return 'undefined'
	const isSSR = typeof window !== 'undefined'
	const [windowSize, setWindowSize] = React.useState({
		width: isSSR ? window.innerWidth : 1200,
		height: isSSR ? window.innerHeight : 800,
	})

	function changeWindowSize() {
		setWindowSize({ width: window.innerWidth, height: window.innerHeight })
	}

	React.useEffect(() => {
		window.addEventListener('resize', changeWindowSize)

		return () => {
			window.removeEventListener('resize', changeWindowSize)
		}
	}, [])

	return windowSize
}
