import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import { whiteTick } from '../../assets/icons'
import QualifySteps from './QualifySteps'
import { PROPERTY_QUALIFY_STEPS } from '../../utils/constants'
import { Header } from '../../features/Reinvestment/Header'

const generateValidationSchema = (steps) => {
	const schema = {}
	steps.forEach((step) => {
		if (step.subSteps) {
			if (Array.isArray(step.subSteps))
				schema[step.name] = Yup.string().required(step.error)
			else {
				const { fields } = step.subSteps
				if (fields) {
					schema[step.name] = Yup.object().test('', '', (value, prop) => {
						const propertyTypeValue = prop.parent.propertyType
						let schema
						if (propertyTypeValue && fields[propertyTypeValue]) {
							const schemaObject = {}
							fields[propertyTypeValue].forEach(({ name, error }) => {
								schemaObject[name] = error
									? Yup.string().required()
									: Yup.string()
							})
							schema = Yup.object().shape(schemaObject)
						}
						const isValid =
							!schema || schema?.isValidSync(value[propertyTypeValue])

						if (!isValid) {
							return prop.createError({
								path: step.name,
								message: {
									heading: fields[propertyTypeValue].find(
										({ name }) => !value[propertyTypeValue][name]
									)?.error,
									description: 'Please complete the missing fields to continue',
								},
							})
						}
						return isValid
					})
				}
			}
		}
	})
	return Yup.object().shape(schema)
}

const generateInitialValues = (steps) =>
	steps.reduce((initialValues, step) => {
		if (step.subSteps) {
			if (Array.isArray(step.subSteps)) initialValues[step.name] = ''
			else {
				const { fields } = step.subSteps
				const subStepInitialValues = {}
				Object.entries(fields).forEach(([key, values]) => {
					subStepInitialValues[key] = {}
					values.forEach((value) => {
						subStepInitialValues[key][value.name] = ''
					})
				})

				initialValues[step.name] = subStepInitialValues
			}
		}
		return initialValues
	}, {})

const Qualify = ({ isModal }) => {
	const [currentStep, setCurrentStep] = useState(0)
	const [currentStepDetails, setCurrentStepDetails] = useState(
		PROPERTY_QUALIFY_STEPS[currentStep]
	)
	const [isQualified, setIsQualified] = useState(false)

	const validationSchema = generateValidationSchema(PROPERTY_QUALIFY_STEPS)
	const initialValues = generateInitialValues(PROPERTY_QUALIFY_STEPS)

	useEffect(() => {
		setCurrentStepDetails(PROPERTY_QUALIFY_STEPS[currentStep])
		const diqModal = document.getElementById('diq-modal')
		if (diqModal) diqModal.scrollIntoView()
	}, [currentStep])

	return (
		<div>
			{!isModal && <Header />}
			<div
				id='diq-modal'
				className={classNames('w-full custom-select flex flex-col', {
					'gap-3 p-4 sm:p-6': isModal,
					'p-6 sm:px-22 md:py-12 md:px-36': !isModal,
				})}
			>
				<div
					className={classNames('w-full flex flex-col', {
						'gap-3': isModal,
						'gap-6': !isModal,
					})}
				>
					<h1
						className={classNames(
							'font-work-sans-light text-zinc-900 leading-normal font-light',
							{
								'text-2xl sm:text-4xl': isModal,
								'text-3xl sm:text-43': !isModal,
							}
						)}
					>
						Does Your Property Qualify?
					</h1>
					{currentStep < PROPERTY_QUALIFY_STEPS.length - 1 ? (
						<>
							<div className='text-zinc-900 font-work-sans-semibold font-bold text-base sm:text-xl mb-2'>
								Take the Quiz, Save Thousands!*
							</div>
							<div className='font-work-sans-medium text-xs sm:text-lg text-gray-500'>
								Even if your property cannot be sold tax free, we will let you
								know about any tax breaks that you may be able to take advantage
								of.
							</div>
						</>
					) : isQualified ? (
						<>
							<div className='text-zinc-900 font-work-sans-semibold font-bold text-base sm:text-xl'>
								Congratulations!
							</div>
							<div className='font-work-sans-medium text-xs sm:text-lg text-gray-500'>
								Based on the answers you provided, your property may qualify to
								be sold tax free, but you&apos;ll need to contact the 1031
								specialists at Plenti to go over a few more details and get your
								paperwork ready before you close on your sale.
							</div>
						</>
					) : (
						<>
							<div className='text-zinc-900 font-work-sans-semibold font-bold text-base sm:text-xl'>
								You <b> May Not </b> Qualify for a 1031 Exchange
							</div>
							<div className='font-work-sans-medium text-xs sm:text-lg text-gray-500'>
								Based on the answers you provided, your property may not qualify
								to be sold tax free with a 1031 Exchange.
							</div>
						</>
					)}
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						validateOnMount
					>
						<div
							className={classNames(
								'w-full border border-zinc-200 bg-neutral-100 flex flex-col gap-4',
								{
									'px-4 md:px-10 py-10': isModal,
									'p-6 sm:p-12': !isModal,
								}
							)}
						>
							<div className='w-full px-8 sm:px-20 md:px-32 lg:px-34'>
								<div className='flex w-full gap-5 justify-between relative pb-10'>
									<div className='absolute bg-gray-200 h-1 w-full top-3 sm:top-5'>
										<div
											className='bg-primary-600 h-1 duration-300'
											style={{
												width: `${
													(currentStep / (PROPERTY_QUALIFY_STEPS.length - 1)) *
													100
												}%`,
											}}
										/>
									</div>
									{PROPERTY_QUALIFY_STEPS.map(({ label }, index) => (
										<div
											key={label}
											className={classNames(
												'flex flex-col items-center gap-4 font-work-sans-medium relative rounded-full bg-white h-fit',
												{ 'text-primary-600': index <= currentStep }
											)}
										>
											<div
												className={classNames(
													'relative flex justify-center items-center rounded-full w-6 h-6 sm:w-10 sm:h-10 border-2 box-content',
													{
														'bg-primary-600 hover:bg-primary-700 border-4 border-blueChalk':
															index <= currentStep,
														'bg-white hover:bg-gray-100 border-slate-300':
															index > currentStep,
													}
												)}
											>
												{index < currentStep ||
												currentStep === PROPERTY_QUALIFY_STEPS.length - 1 ? (
													<img
														src={whiteTick}
														alt='green check mark'
														className='!stroke-white !fill-white h-2 w-2 sm:h-5 sm:w-5'
													/>
												) : (
													<div
														className={classNames(
															'rounded-full p-1 sm:p-1.5 w-fit h-fit',
															{
																'bg-white': index <= currentStep,
																'bg-slate-300': index > currentStep,
															}
														)}
													/>
												)}
												<div className='h-6 text-xs sm:text-base absolute bottom-0 font-work-sans-medium top-6 sm:top-12 text-nowrap-sm'>
													{label}
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
							<QualifySteps
								currentStepDetails={currentStepDetails}
								currentStep={currentStep}
								setCurrentStep={setCurrentStep}
								setIsQualified={setIsQualified}
								isQualified={isQualified}
								isModal={isModal}
							/>
						</div>
					</Formik>
					<div className='font-work-sans-medium text-xs sm:text-base text-gray-500 text-justify'>
						Plenti Financial™ is a trade name of 1031 Exchange Advantage®. 1031
						Exchange Advantage™ is a trade name of 1031 EA, LLC and is not a
						licensed broker. 1031 EA, LLC is a division of Plenti Financial and
						is the accommodator for a 1031 Exchange as required by the IRS. 1031
						EA, LLC does not provide, brokerage, legal or accounting services
						and is simply a Qualified Intermediary under the IRS code. It
						therefore is not providing any services other than accommodation
						services and clients and prospects are advised to confirm all tax,
						legal, accounting and real estate investment decisions with their
						professionals such as their accountant or attorney.
					</div>
				</div>
			</div>
		</div>
	)
}

export default Qualify
