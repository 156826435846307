import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import {
	ERROR_MESSAGES,
	ERROR_MESSAGE_CLASSES,
	FORM_FIELDS,
	getRequiredErrorMessage,
	supportedFormats,
	DEFAULT_VALUES,
	COLOR_FIELDS,
	UNIQUE_FIELDS,
	NavRoutes,
	BUSY_INDICATORS,
} from '../../utils/constants'
import { NameRegularExpression } from '../../utils/constants.regex'
import { validateMobileNumber } from '../../utils/methods'
import { alertCircleIcon, greenCheckIcon } from '../../assets/icons'
import ColorPicker from '../../widgets/ColorPicker/ColorPicker'
import Logo from './Logo'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from './affiliates.slice'
import {
	selectIsSlugValidated,
	selectIsSlugFieldActive,
	selectIsCompanyNameValidated,
	selectUploadedImage,
	selectFormData,
} from './affiliates.selectors'
import { validateAffiliateKeywords } from './affiliates.asyncActions'
import { spinner } from '../../assets/gifs'
import { getNamedBusyIndicator } from '../../widgets/busyIndicator'
import { useNavigate } from 'react-router-dom'
import { NavLinkStates } from '../../utils/constant.enum'
import { shouldActivate } from '../../widgets/NavBar'
import classnames from 'classnames'

const {
	setFormDataState,
	setIsSlugFieldActive,
	setIsSlugValidated,
	setIsCompanyNameValidated,
} = actions
const { EMPTY_STRING, BOOLEAN } = DEFAULT_VALUES
const { TRUE, FALSE } = BOOLEAN
const { VALIDATE_UNIQUE_FIELDS } = BUSY_INDICATORS
const { SLUG, COMPANY_NAME, COMPANY_SLUG } = UNIQUE_FIELDS
const { AFFILIATE_REQUEST_APPROVAL, AFFILIATE_INTRODUCTION } = NavRoutes
const {
	MAX_LENGTH_255,
	MIN_LENGTH_3,
	MIN_LENGTH_2,
	FIRST_NAME_VALIDATE_REGREX,
	LAST_NAME_VALIDATE_REGREX,
	MOBILE_NUMBER,
	EMAIL_VALIDATE_SPACE,
	URL_ALREADY_IN_USE,
	INVALID_FORMAT,
	LOGO_TOO_LARGE,
	INVALID_FILE_DIMENSIONS,
	COMPANY_NAME_ALREADY_IN_USE,
} = ERROR_MESSAGES

const InputField = ({
	id,
	type,
	label,
	placeholder,
	values,
	handleBlur,
	handleChange,
	errorMessage,
	className,
}) => (
	<div className={`sm:mt-0 mt-3 ${className || EMPTY_STRING}`}>
		<label
			className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'
			htmlFor={id}
		>
			{label}
		</label>
		<input
			id={id}
			type={type}
			name={id}
			placeholder={placeholder}
			className={`form-control ${errorMessage && 'border-red-400'}`}
			onBlur={handleBlur}
			value={values[id]}
			onChange={handleChange}
		/>
		{errorMessage && <p className={ERROR_MESSAGE_CLASSES}>{errorMessage}</p>}
	</div>
)

const StartApplication = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const formData = useSelector(selectFormData)
	const [slug, setSlug] = useState(EMPTY_STRING)
	const [companyName, setCompanyName] = useState(EMPTY_STRING)
	const isSlugValid = useSelector(selectIsSlugValidated)
	const isCompanyNameValid = useSelector(selectIsCompanyNameValidated)
	const isSlugFieldActive = useSelector(selectIsSlugFieldActive)
	const isValidateApiActive = useSelector(
		getNamedBusyIndicator(VALIDATE_UNIQUE_FIELDS)
	)
	const uploadedImage = useSelector(selectUploadedImage)

	useEffect(() => {
		if (companyName && !isSlugFieldActive) {
			const validatedSlug = setTimeout(
				() =>
					dispatch(
						validateAffiliateKeywords({ key: COMPANY_NAME, value: companyName })
					),
				800
			)
			return () => clearTimeout(validatedSlug)
		}
	}, [companyName, dispatch, isSlugFieldActive])

	useEffect(() => {
		if (slug && isSlugFieldActive) {
			const validatedSlug = setTimeout(
				() => dispatch(validateAffiliateKeywords({ key: SLUG, value: slug })),
				800
			)
			return () => clearTimeout(validatedSlug)
		}
	}, [slug, dispatch, isSlugFieldActive])

	const initialValues = { ...formData }
	const validationSchema = Yup.object().shape({
		firstName: Yup.string()
			.min(2, MIN_LENGTH_2)
			.max(255, MAX_LENGTH_255)
			.test('firstName', FIRST_NAME_VALIDATE_REGREX, (val) =>
				NameRegularExpression.test(val)
			)
			.required(getRequiredErrorMessage('first name')),
		lastName: Yup.string()
			.min(2, MIN_LENGTH_2)
			.max(255, MAX_LENGTH_255)
			.test('lastName', LAST_NAME_VALIDATE_REGREX, (val) =>
				NameRegularExpression.test(val)
			)
			.required(getRequiredErrorMessage('last name')),
		email: Yup.string()
			.email(EMAIL_VALIDATE_SPACE)
			.required(getRequiredErrorMessage('email')),
		phoneNumber: Yup.string()
			.test('phoneNumber', MOBILE_NUMBER, (val) => validateMobileNumber(val))
			.required(getRequiredErrorMessage('phone number')),
		companySlug: Yup.string().required(getRequiredErrorMessage('unique url')),
		companyName: Yup.string()
			.min(3, MIN_LENGTH_3)
			.max(255, MAX_LENGTH_255)
			.required(getRequiredErrorMessage('company name')),
		logoURL: Yup.mixed()
			.required(getRequiredErrorMessage('logo'))
			.test('fileFormat', INVALID_FORMAT, (value) => {
				if (!value) return false
				return supportedFormats.includes(value.type)
			})
			.test('fileSize', LOGO_TOO_LARGE, (value) => {
				if (!value) return false
				return value.size <= 50 * 1024 * 1024
			})
			.test('fileDimensions', INVALID_FILE_DIMENSIONS, (value) => {
				if (!value || Object.keys(value).length === 3) return TRUE

				return new Promise((resolve) => {
					const image = new Image()
					image.src = URL.createObjectURL(value)
					image.onload = () => {
						const width = image.width
						const height = image.height
						URL.revokeObjectURL(image.src)
						resolve(width <= 800 && height <= 400)
					}
				})
			}),
	})

	const onFormSubmit = (values) => {
		if (isSlugValid && isCompanyNameValid) {
			const { logoURL } = values
			const updatedFormData = {
				...values,
				logoURL: {
					name: logoURL.name,
					size: logoURL.size,
					type: logoURL.type,
				},
			}
			dispatch(setFormDataState(updatedFormData))
			dispatch(shouldActivate(NavLinkStates.REQUEST_APPROVAL))
			navigate(AFFILIATE_REQUEST_APPROVAL)
		}
	}

	const getIcon = (isValid, field) => {
		const isActive =
			field === COMPANY_SLUG ? isSlugFieldActive : !isSlugFieldActive
		const hasValue = field === COMPANY_SLUG ? slug : companyName
		if (isValidateApiActive && isActive) return spinner
		else if (isValid === FALSE) return alertCircleIcon
		else if (isValid && hasValue) return greenCheckIcon
		else return EMPTY_STRING
	}

	const getBorderClasses = (touched, errors, field) => {
		let borderClasses = EMPTY_STRING
		const isValid =
			field === COMPANY_SLUG
				? isSlugValid && slug && touched[field]
				: isCompanyNameValid && companyName && touched[field]
		const hasError = touched[field] && (errors[field] || isValid === false)
		if (isValid) borderClasses = 'border-emerald-300'
		else if (hasError) borderClasses = ' border-red-400'
		return borderClasses
	}

	return (
		<div className='flex flex-col min-h-screen sm:pt-22 pt-12'>
			<h1 className='sm:text-3xl text-2xl font-bold leading-7 text-gray-900 mb-6 sm:px-22 px-12'>
				Request an Affiliate Account
			</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values) => onFormSubmit(values)}
			>
				{({
					values,
					handleBlur,
					handleChange,
					touched,
					errors,
					handleSubmit,
					setFieldValue,
				}) => (
					<form className='flex-grow grid'>
						<div className='form-group sm:px-22 px-12 sm:pb-22 pb-12'>
							<div className='sm:grid sm:grid-cols-2 gap-6 text-gray-700'>
								<div className='col-span-2'>
									<label
										className='mb-2 text-sm font-medium inline-block'
										htmlFor='companyName'
									>
										Your Company Name
									</label>
									<div className='relative'>
										<input
											id='companyName'
											type='text'
											name='companyName'
											placeholder='Your Company Name'
											className={`form-control w-full pr-8 ${getBorderClasses(
												touched,
												errors,
												COMPANY_NAME
											)}`}
											onBlur={handleBlur}
											value={values.companyName}
											onChange={(e) => {
												handleChange(e)
												dispatch(setIsSlugFieldActive(false))
												dispatch(setIsCompanyNameValidated(null))
												setCompanyName(e.target.value)
											}}
										/>
										{touched.companyName && (
											<img
												src={getIcon(isCompanyNameValid, COMPANY_NAME)}
												className='absolute right-2 top-0 translate-y-[40%]'
											/>
										)}
									</div>
									{touched.companyName &&
										(errors.companyName || isCompanyNameValid === FALSE) && (
											<p className={`${ERROR_MESSAGE_CLASSES}`}>
												{errors.companyName
													? errors.companyName
													: isCompanyNameValid === FALSE
													? COMPANY_NAME_ALREADY_IN_USE
													: EMPTY_STRING}
											</p>
										)}
								</div>
								{FORM_FIELDS.map(
									({ id, label, type, placeholder, className }) => (
										<InputField
											key={id}
											id={id}
											type={type}
											label={label}
											placeholder={placeholder}
											className={className}
											errorMessage={
												touched[id] && errors[id] ? errors[id] : EMPTY_STRING
											}
											values={values}
											handleBlur={handleBlur}
											handleChange={handleChange}
										/>
									)
								)}
								<div className='col-span-2 sm:mt-0 mt-3'>
									<label
										className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'
										htmlFor='companySlug'
									>
										Your Unique URL
									</label>
									<div className='flex w-full sm:flex-row flex-col'>
										<p className='text-gray-900 mr-1 mt-2'>
											www.plentiexchange.com/
										</p>
										<div className='w-full'>
											<div className='relative'>
												<input
													id='companySlug'
													type='text'
													name='companySlug'
													placeholder='your-slug'
													className={`w-full form-control pr-8 ${getBorderClasses(
														touched,
														errors,
														COMPANY_SLUG
													)}`}
													onBlur={handleBlur}
													value={values.companySlug}
													onChange={(e) => {
														handleChange(e)
														dispatch(setIsSlugFieldActive(TRUE))
														dispatch(setIsSlugValidated(null))
														setSlug(e.target.value)
													}}
												/>
												{touched.companySlug && (
													<img
														src={getIcon(isSlugValid, COMPANY_SLUG)}
														className='absolute right-2 top-0 translate-y-[40%]'
													/>
												)}
											</div>
											{touched.companySlug &&
												(errors.companySlug || isSlugValid === FALSE) && (
													<p className={`${ERROR_MESSAGE_CLASSES}`}>
														{errors.companySlug
															? errors.companySlug
															: isSlugValid === FALSE
															? URL_ALREADY_IN_USE
															: EMPTY_STRING}
													</p>
												)}
										</div>
									</div>
								</div>
								{COLOR_FIELDS.map(({ label, id }) => (
									<ColorPicker
										key={id}
										label={label}
										id={id}
										value={values[id]}
										setFieldValue={setFieldValue}
										handleChange={handleChange}
									/>
								))}
								<Logo
									errorMessage={
										touched.logoURL && errors.logoURL
											? errors.logoURL
											: EMPTY_STRING
									}
									setFieldValue={setFieldValue}
									value={values.logoURL}
									slug={slug}
									isSlugValid={isSlugValid}
								/>
							</div>
						</div>
						<div
							className={classnames(
								'text-right space-x-2 self-end border-t-2 p-6'
							)}
						>
							<button
								className='btn btn-light'
								onClick={() => navigate(AFFILIATE_INTRODUCTION)}
							>
								Previous
							</button>
							<button
								className={`btn btn-primary ${
									!uploadedImage || isValidateApiActive
										? 'cursor-not-allowed opacity-50'
										: ''
								}`}
								type='submit'
								onClick={handleSubmit}
								disabled={!uploadedImage || isValidateApiActive}
							>
								Continue
							</button>
						</div>
					</form>
				)}
			</Formik>
		</div>
	)
}

export default StartApplication
