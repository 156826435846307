import { createSlice } from '@reduxjs/toolkit'
// import * as asyncAction from './ReinvestmentAffiliate.asynAction'

const initialState = {
	isStepOneCompleted: false,
	isStepTwoCompleted: false,
	stepOneFormData: null,
}

const slice = createSlice({
	name: 'reinvestmentAffiliate',
	initialState,
	reducers: {
		setIsStepOneCompleted: (state, action) => {
			state.isStepOneCompleted = action.payload
		},
		setIsStepTwoCompleted: (state, action) => {
			state.isStepTwoCompleted = action.payload
		},
		setStepOneFormData: (state, action) => {
			state.stepOneFormData = action.payload
		},
	},
	extraReducers: {},
})
export default slice
export const { name, actions, reducer } = slice
