import React, { useState } from 'react'
import {
	chartBreakoutSquareIcon,
	greenTickMark,
	messageChatCircleIcon,
	minusIcon,
	plusIcon,
	rightBottomBG,
	zapBlueIcon,
} from '../../assets/icons'
import {
	affiliateIntroFirstImage,
	landingLeftTopLinePattern,
	landingRightBg,
	affiliateIntroHandShake,
	barGraph,
	lineGraph,
	screen,
} from '../../assets/images'
import { magnifyingGlass, finder, calculator } from '../../assets/gifs'
import {
	FREQUENTLY_ASKED_QUESTIONS,
	NavRoutes,
	DOC_URL,
} from '../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { shouldActivate } from '../../widgets/NavBar'
import { NavLinkStates } from '../../utils/constant.enum'
import { openURLInNewTab } from '../../utils/methods'
import parse from 'html-react-parser'

const { PARTNER_AGREEMENT, TAX_REPORT_URL, LEAD_ACTIVITY_REPORT } =
	DOC_URL.SAMPLE_DOC

const AffiliateIntroduction = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [areCollapsed, setAreCollapsed] = useState(
		Array(FREQUENTLY_ASKED_QUESTIONS.length).fill(false)
	)

	const toggleCollapsibleStatus = (indexToToggleCollapseStatus) => {
		return areCollapsed.map((eachQuestionStatus, index) =>
			index === indexToToggleCollapseStatus
				? !eachQuestionStatus
				: eachQuestionStatus
		)
	}

	return (
		<>
			<div className='lg:p-[84px] lg:pr-44 xs:p-0'>
				<div className='grid grid-cols-12 border border-gray-300 rounded-lg mb-6 overflow-hidden'>
					<div className='col-span-8 xs:col-span-12 sm:col-span-8 lg:col-span-7 2xl:col-span-7 px-16 py-16 flex flex-col justify-center'>
						<h3 className='text-primary-600 text-2xl mb-4 font-bold'>
							The Plenti Affiliate Program
						</h3>
						<h1 className='text-gray-900 text-4xl font-bold mb-4'>
							Improve Your Retention with Turnkey 1031 Exchange Services
						</h1>
						<p className='text-gray-600 text-base mb-8'>
							Empower your clients to buy better real estate investments while
							ensuring you continue to manage, broker or lend on their
							properties with a 1031 Exchange.
						</p>
						<ul className='space-y-7'>
							<li className='flex'>
								<div className='icon w-7 h-7 grid place-content-center mr-3 rounded-full bg-teal-100/75'>
									<img src={greenTickMark} width='14' alt='green check mark' />
								</div>
								<span className='text-gray-700'>
									Give your clients a path to substantial tax savings
								</span>
							</li>
							<li className='flex'>
								<div className='icon w-7 h-7 grid place-content-center mr-3 rounded-full bg-teal-100/75'>
									<img src={greenTickMark} width='14' alt='green check mark' />
								</div>
								<span className='text-gray-700'>
									Improve your client retention rates
								</span>
							</li>
							<li className='flex'>
								<div className='icon w-7 h-7 grid place-content-center mr-3 rounded-full bg-teal-100/75'>
									<img src={greenTickMark} width='14' alt='green check mark' />
								</div>
								<span className='text-gray-700'>
									Stand out in property management, brokerage, mortgage or
									financial planning
								</span>
							</li>
						</ul>
					</div>
					<div className='col-span-4 xs:col-span-12 sm:col-span-4 lg:col-span-5 2xl:col-span-5 flex justify-end items-end relative'>
						<img src={landingRightBg} alt='intro right background' />
						<img
							src={affiliateIntroFirstImage}
							className='absolute m-16 rounded-lg bg-white border-gray-200 border-2'
							alt='affiliate Intro First image'
						/>
					</div>
				</div>

				<div className='grid grid-cols-12 border border-gray-300 mb-6 rounded-lg overflow-hidden'>
					<div className='col-span-4 xs:col-span-12 sm:col-span-4 lg:col-span-5 2xl:col-span-5 flex p-justify-start items-start relative mr-16'>
						<img src={landingLeftTopLinePattern} alt='Intro left background' />
						<img
							src={affiliateIntroHandShake}
							className='absolute m-16 bg-white rounded-full border-gray-200 border'
							alt='Intro Hand Shake Image'
						/>
					</div>
					<div className='col-span-8 xs:col-span-12 sm:col-span-8 lg:col-span-7 2xl:col-span-7 py-16 px-16 flex flex-col justify-center'>
						<h1 className='text-gray-900 text-2xl font-bold mb-4'>
							Partner with Plenti and Begin Offering 1031 Exchanges Right Away
						</h1>
						<p className='text-gray-600 text-base mb-8'>
							Whether you&apos;re a property manager, realtor, mortgage lender
							or financial planner you want to be of value to your clients and
							find a new way to attract clients, but most importantly:{' '}
							<span className='font-semibold'>
								retain the ones you have so they keep coming back and refer to
								you when they have a need!
							</span>{' '}
							In today&apos;s fast paced world, it&apos;s not easy… but
							<span className='font-semibold'> Plenti</span> can help.
						</p>
						<p className='text-gray-600 text-base mb-8'>
							What&apos;s a 1031 Exchange? The IRS rule lets you sell and then
							buy one or more properties OR repair and improve them and{' '}
							<span className='font-semibold'>
								skip all the capital gains taxes up to 47% of the profit in New
								York City,
							</span>{' '}
							if you hire a 1031 accommodator like Plenti.{' '}
							<span className='font-semibold'>The Plenti Exchange™ </span>is a
							better way to exchange, using time tested tools and tying
							everything together in one app.
						</p>
					</div>
				</div>

				<div className='grid grid-cols-12 border border-gray-300 mb-6 rounded-lg overflow-hidden pb-16'>
					<div className='col-span-12 px-16 py-16 flex flex-col justify-center'>
						<h1 className='text-gray-900 text-2xl font-bold'>
							Why Join the Program?
						</h1>
						<span className='text-gray-600 text-lg font-normal leading-7 py-3'>
							The short answer is why not!
						</span>
						<p className='text-gray-600 text-base font-normal leading-6'>
							Plenti offers a marketing machine: free marketing tools, leads and
							tech-enabled sales tools to service & develop your client&apos;s
							interest in selling and buying real estate tax free. The Plenti
							Exchange™ will trigger the need for your services over and over
							again. Each time your client exchanges he will need to buy what
							you sell, and you will know about it if you&apos;re plugged in to
							the Affiliate program with your Hubspot Activity Reports. Plenti
							lets you intro the concept, our platform walks clients through the
							3 step process to motivate them to act. Mention Plenti and walk
							your clients through the tools and coach them along at each step
							using your know-how and services and Plenti.
						</p>
					</div>
					<div className='col-span-4 xl:col-span-4 xs:col-span-12 mb-4 pl-16 pr-8 flex flex-col'>
						<span className='p-3 mb-5'>
							<img src={messageChatCircleIcon} alt='chat Icon' />
						</span>
						<p className='text-xl font-medium leading-[30px]'>
							Unlock New Revenue
						</p>
						<p className='text-gray-500 text-base font-normal leading-6'>
							Plenti has a new method to{' '}
							<span className='font-semibold'>
								find, service and generate new activity from your client base.
							</span>{' '}
							On average, you will earn about $500 per transaction. But really,
							the money is in the new revenue in property management, or
							brokerage or mortgages you&apos;ll sell because you introduced an
							exchange.
						</p>
					</div>
					<div className='col-span-4 xl:col-span-4 xs:col-span-12 mb-4 xs:pl-16 xs:pr-8 px-8 flex flex-col'>
						<span className='p-3 mb-5'>
							<img src={zapBlueIcon} alt='zap Icon' />
						</span>
						<p className='text-xl font-medium leading-[30px]'>
							Whitelabeled Services
						</p>
						<p className='text-gray-500 text-base font-normal leading-6'>
							Your logo matters. Upload it and it will appear on all the Plenti
							Tools and materials. It matters to your brand to be seen in the
							world as someone who provides new ideas and services to get them
							done that will encourage the sale of your services.
						</p>
					</div>
					<div className='col-span-4 xl:col-span-4 xs:col-span-12 xs:pl-16 xs:pr-8 pr-16 flex flex-col'>
						<span className='p-3 mb-5'>
							<img
								src={chartBreakoutSquareIcon}
								alt='chart Breakout Square Icon'
							/>
						</span>
						<p className='text-xl font-medium leading-[30px]'>
							Turnkey Offerings
						</p>
						<p className='text-gray-500 text-base font-normal leading-6'>
							It&apos;s turnkey for you and the exchange has been made turnkey
							for your client! We are always available to Chat and consult on
							your selling needs and your clients&apos; exchange needs, just
							give us a call.
						</p>
					</div>
				</div>

				<div className='grid grid-cols-12 border border-gray-300 rounded-lg mb-6 p-16 overflow-hidden'>
					<div className='col-span-12 mb-16 flex flex-col justify-center'>
						<h1 className='text-gray-900 text-2xl font-bold mb-4'>
							We Give You The Marketing Tools
						</h1>
						<p className='text-gray-600 text-base'>
							Over time there will be a library of outreach materials available
							for you to choose from to promote your services to your base and
							outreach to new.
						</p>
					</div>
					<img
						src={screen}
						className='col-span-12 right-1/2 top-1/2 mx-[auto] mb-16'
						alt='Laptop Screen Image'
					/>
					<div className='hidden lg:col-span-5 2xl:col-span-6 lg:flex relative'>
						<img
							src={magnifyingGlass}
							className='absolute p-16 right-1/2 top-1/2 translate-x-1/2 -translate-y-1/2 w-10/12 2xl:w-7/12 rounded-full bg-white border-gray-200 border-2'
							alt='magnifying glass gif'
						/>
					</div>
					<div className='col-span-12 xs:p-0 lg:col-span-7 2xl:col-span-6  lg:px-16 lg:py-16 flex flex-col mb-16 justify-center'>
						<h1 className='text-gray-900 text-2xl font-bold mb-4'>
							Tax Reports
						</h1>
						<p className='text-gray-600 text-base mb-8'>
							Get clients a specific tax picture and brochure instantly (no
							waiting to see their CPA) of the exchange concepts they&apos;ll
							need to know. Once they do, they&apos;ll be motivated to sell and
							buy real estate tax free! Point out a problem, then a solution{' '}
							<span className='font-semibold'>Plenti™</span> can provide.
						</p>
						<button
							className='btn btn-primary w-full'
							onClick={() => openURLInNewTab(TAX_REPORT_URL)}
						>
							View Sample Tax Report
						</button>
					</div>

					<div className='col-span-12 lg:col-span-7 2xl:col-span-6 xs:p-0 lg:px-16 lg:py-16 flex flex-col mb-16 justify-center'>
						<h1 className='text-gray-900 text-2xl font-bold mb-4'>
							Investment Finder
						</h1>
						<p className='text-gray-600 text-base mb-8'>
							Offer clients what they need to do an exchange to move them to use
							your services: In order to list, buy, mortgage or manage, your
							client&apos;s exchange will trigger the sale of a lot of services.
							We know because people have been asking us for years if we can
							help them with all of the above. That starts with a plan to do a{' '}
							<span className='font-semibold'>Plenti Exchange™.</span>
						</p>
						<p className='text-gray-600 text-base mb-8'>
							<span className='font-semibold'>
								Feature your listings on the Plenti App!
							</span>{' '}
							<br />
							Integration with your listings means your clients can model your
							listings in their exchanges and in turn make it easier to
							facilitate their selling and buying tax free from real time 24/7
							property options. Our network also allows you to feature your
							listings to{' '}
							<span className='font-semibold'>
								ALL of our users nationwide!!!
							</span>
						</p>
					</div>
					<div className='hidden lg:col-span-5 2xl:col-span-6 lg:flex justify-end items-end relative'>
						<img
							src={finder}
							className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-10/12 2xl:w-7/12 rounded-full bg-white border-gray-200 border-2'
							alt='Finder gif'
						/>
					</div>
					<div className='hidden lg:col-span-5 2xl:col-span-6 lg:flex mb-16 relative'>
						<img
							src={calculator}
							className='absolute p-16 right-1/2 top-1/2 translate-x-1/2 -translate-y-1/2 w-10/12 2xl:w-7/12 rounded-full bg-white border-gray-200 border-2'
							alt='calculator gif'
						/>
					</div>
					<div className='col-span-12 xs:p-0 lg:col-span-7 2xl:col-span-6  lg:px-16 lg:py-16 flex flex-col justify-center'>
						<h1 className='text-gray-900 text-2xl font-bold mb-4'>
							The Plenti Exchange App
						</h1>
						<p className='text-gray-600 text-base mb-8'>
							It&apos;s all about convenience, education and moving fast these
							days. And in the past, before our app, most people elected to pay
							taxes as they simply gave up in desperation. Such a shame to send
							money to the IRS if you don&apos;t have to just because no one
							made it simple to exchange until now! Introducing the Plenti
							Exchange™.
						</p>
					</div>
				</div>

				<div className='grid grid-cols-12 grid-rows-2 border border-gray-300 rounded-lg mb-6 overflow-hidden'>
					<div className='col-span-12 row-span-2 lg:col-span-7 2xl:col-span-6 px-16 py-16 flex flex-col justify-center'>
						<h1 className='text-gray-900 text-2xl font-bold mb-4'>
							We&apos;ll Help You Track Your Leads
						</h1>
						<p className='text-gray-600 text-base mb-8'>
							Investors who learn about an exchange have a lot of questions you
							won&apos;t be able to answer easily, but that&apos;s ok,
							that&apos;s where we come in. Your prospects can chat, call or
							schedule a consultation and we&apos;ll answer those questions.
						</p>
						<p className='text-gray-600 text-base mb-8'>
							The <span className='font-semibold'>Plenti Exchange™</span>{' '}
							platform helps them pull all the pieces necessary to complete
							their exchange.
						</p>
						<p className='text-gray-600 text-base mb-8'>
							<span className='font-semibold'>Plenti</span> tracks your leads
							and shows you where your prospects are in the process so you can
							time these credible leads properly to sell your services. Plus
							Plenti is able to feature your services to its growing database of
							users. You will also view your anticipated revenue shares from the
							exchanges originated in your account.
						</p>
						<p className='text-gray-600 text-base mb-8'>
							At the least, you will get something out of every exchangor from
							your database, and at the most you&apos;ll win a lot of new
							business from exchanges created from your database using the{' '}
							<span className='font-semibold'>Plenti</span> Tools. We provide a
							time sensitive heads up to connect with motivated clients with
							funds ready to spend, and that&apos;s half the battle.
						</p>
						<button
							className='btn btn-primary w-full my-1'
							onClick={() => openURLInNewTab(PARTNER_AGREEMENT)}
						>
							See Sample Partner Agreement
						</button>
						<button
							className='btn btn-primary w-full my-1'
							onClick={() => openURLInNewTab(LEAD_ACTIVITY_REPORT)}
						>
							See Sample Lead Activity Report
						</button>
					</div>
					<div className='hidden row-span-1 lg:col-span-5 2xl:col-span-6 lg:flex justify-end items-end relative'>
						<img
							src={lineGraph}
							className='absolute m-10 pl-6 bg-white border-gray-200 border-2'
							alt='Line Graph image'
						/>
					</div>
					<div className='hidden row-span-1 lg:col-span-5 2xl:col-span-6 lg:flex justify-end items-end relative'>
						<img src={rightBottomBG} alt='intro right background' />
						<img
							src={barGraph}
							className='absolute m-10 pl-6 mb-40 bg-white border-gray-200 border-2'
							alt='Bar Graph image'
						/>
					</div>
				</div>

				<div className='grid grid-cols-12 border border-gray-300 rounded-lg mb-5 overflow-hidden'>
					<div className='col-span-12 px-16 py-16 flex flex-col justify-center'>
						<h1 className='text-gray-900 text-2xl font-bold mb-4'>
							Frequently Asked Questions
						</h1>
						{FREQUENTLY_ASKED_QUESTIONS.map(
							({ question, answer, type }, indexOfQuestion) => {
								return (
									<div
										className='border border-[#68B6BF] bg-[#F9FDFF] rounded-lg p-4 mb-2 cursor-pointer'
										key={indexOfQuestion}
										onClick={() =>
											setAreCollapsed(toggleCollapsibleStatus(indexOfQuestion))
										}
									>
										<div className='flex justify-between items-start'>
											<h1 className='text-gray-900 text-lg font-medium mb-1'>
												{question}
											</h1>
											{areCollapsed[indexOfQuestion] ? (
												<img src={minusIcon} width='20' alt='Minus icon' />
											) : (
												<img src={plusIcon} width='20' alt='Plus icon' />
											)}
										</div>
										<p
											className={`text-gray-600 text-base font-normal ${
												areCollapsed[indexOfQuestion] ? 'max-h-40' : 'max-h-0'
											} transition-all delay-100 duration-500 ease overflow-hidden w-full`}
										>
											{type === 'html' ? parse(answer) : answer}
										</p>
									</div>
								)
							}
						)}
					</div>
				</div>
			</div>
			<div className='sticky bottom-0 fixed'>
				<div className='border bg-white border-gray-200 p-6 flex align-center justify-end'>
					<button
						className='btn btn-primary'
						onClick={() => {
							dispatch(shouldActivate(NavLinkStates.START_APPLICATION))
							navigate(NavRoutes.AFFILIATE_START_APPLICATION)
						}}
					>
						Apply for Approval
					</button>
				</div>
			</div>
		</>
	)
}

export default AffiliateIntroduction
