import { createSlice } from '@reduxjs/toolkit'
import * as customerDetails from './customerDetails.asyncActions'

const initialState = {
	agentInfoResponse: {},
	customerInfoResponse: {},
	referredAgentInfo: '',
}

const slice = createSlice({
	name: 'customerDetailsState',
	initialState,
	reducers: {
		resetToInititalState: () => initialState,
	},
	extraReducers: {
		[customerDetails.createAgent.fulfilled]: (state, action) => {
			state.agentInfoResponse = action.payload || initialState.agentInfoResponse
		},
		[customerDetails.createCustomer.fulfilled]: (state, action) => {
			state.customerInfoResponse =
				action.payload || initialState.customerInfoResponse
		},
		[customerDetails.getAgentInfo.fulfilled]: (state, action) => {
			state.referredAgentInfo = action.payload || initialState.referredAgentInfo
		},
	},
})

export default slice
export const { name, actions, reducer } = slice
