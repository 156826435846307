import { createAsyncThunk } from '@reduxjs/toolkit'
import doAsync from '../../../infrastructure/doAsync'

export const createAgent = createAsyncThunk(
	'customerDetailsState/agents',
	async (agentDetails, thunkArgs, { useCaching, noBusySpinner } = {}) =>
		await doAsync({
			url: 'agents',
			useCaching,
			noBusySpinner,
			httpMethod: 'post',
			httpConfig: {
				body: JSON.stringify(agentDetails),
			},
			errorMessage: 'Unable to create agent, Please try again later.',
			...thunkArgs,
		})
)

export const createCustomer = createAsyncThunk(
	'customerDetailsState/users',
	async (customerDetails, thunkArgs, { useCaching, noBusySpinner } = {}) =>
		await doAsync({
			url: 'users',
			useCaching,
			noBusySpinner,
			httpMethod: 'post',
			httpConfig: {
				body: JSON.stringify(customerDetails),
			},
			errorMessage: 'Unable to create customer, Please try again later.',
			...thunkArgs,
		})
)

export const getAgentInfo = createAsyncThunk(
	'customerDetailsState/getAgents',
	async ({ agentID }, thunkArgs, { useCaching, noBusySpinner } = {}) =>
		await doAsync({
			url: `agents/${agentID}`,
			useCaching,
			noBusySpinner,
			errorMessage: 'Unable to fetch agent info, Please try again later.',
			...thunkArgs,
		})
)
