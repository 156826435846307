import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAffiliateSlug } from '../../Affiliates/affiliates.selectors'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { GrowthWithExchangeConstants } from '../../../utils/constants'

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Legend,
	ChartDataLabels
)

const {
	SALE_DATA_PRIMARY,
	SALE_DATA_SECONDARY,
	PURCHASE_DATA_PRIMARY,
	PURCHASE_DATA_SECONDARY,
	ZERO,
	EIGHT,
	TEN,
	TWELVE,
	SIX_HUNDRED,
	DOLLAR,
	TOP,
	END,
} = GrowthWithExchangeConstants

const generateOptions = (minY, maxY, stepY, labelsFont, valueFont) => ({
	maintainAspectRatio: false,
	responsive: true,
	hover: false,
	plugins: {
		tooltip: {
			enabled: false,
		},
		legend: {
			display: false,
		},
		datalabels: {
			color: (context) => {
				const backgroundColor = context.dataset.backgroundColor
				return backgroundColor
			},
			offset: ZERO,
			align: TOP,
			anchor: END,
			font: {
				family: 'work-sans-regular',
				size:
					window.innerWidth <= SIX_HUNDRED
						? labelsFont.mobile
						: labelsFont.desktop,
			},
			formatter: (value) => DOLLAR + value.toLocaleString(),
		},
	},
	scales: {
		y: {
			min: minY,
			max: maxY,
			ticks: {
				stepSize: stepY,
				callback: function (value) {
					return DOLLAR + value.toLocaleString()
				},
				font: {
					size:
						window.innerWidth <= SIX_HUNDRED
							? valueFont.mobile
							: valueFont.desktop,
				},
			},
		},
		x: {
			grid: {
				display: false,
			},
			ticks: {
				font: {
					size:
						window.innerWidth <= SIX_HUNDRED
							? labelsFont.mobile
							: labelsFont.desktop,
				},
			},
		},
	},
})

const labels = ['Sale of #1', 'Sale of #2', 'Sale of #3', 'Sale of #4']
const purchaseLabels = [
	'Purchase of #1',
	'Purchase of #2',
	'Purchase of #3',
	'Purchase of #4',
]

const generateData = (
	labels,
	primaryDataset,
	secondaryDataset,
	primaryColor
) => ({
	labels,
	datasets: [
		{
			data: primaryDataset,
			backgroundColor: `rgb(${primaryColor})`,
			borderRadius: TEN,
		},
		{
			data: secondaryDataset,
			backgroundColor: '#67747F',
			borderRadius: TEN,
		},
	],
})

const data = (primaryColor) =>
	generateData(labels, SALE_DATA_PRIMARY, SALE_DATA_SECONDARY, primaryColor)

const purchaseData = (primaryColor) =>
	generateData(
		purchaseLabels,
		PURCHASE_DATA_PRIMARY,
		PURCHASE_DATA_SECONDARY,
		primaryColor
	)

const options = generateOptions(
	50000,
	300000,
	50000,
	{ mobile: EIGHT, desktop: TWELVE },
	{ mobile: EIGHT, desktop: TWELVE }
)
const purchaseOptions = generateOptions(
	200000,
	1000000,
	200000,
	{ mobile: EIGHT, desktop: TWELVE },
	{ mobile: EIGHT, desktop: TWELVE }
)

const GrowthWithExchange = () => {
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const [primaryColor, setPrimaryColor] = useState('')

	useEffect(() => {
		const root = document.documentElement
		setPrimaryColor(
			getComputedStyle(root).getPropertyValue('--color-primary-200')
		)
	}, [affiliateSlug])

	return (
		<div className='p-8 pt-0 sm:p-12 sm:pt-0 md:p-24 md:pt-0 flex flex-col gap-[50px]'>
			<div className='flex flex-col gap-8'>
				<div className='text-gray-500 text-xl sm:text-43 font-work-sans-light'>
					How Does Your Money Grow with an Exchange?
				</div>
				<div className='text-gray-500 text-sm sm:text-[17px] font-work-sans-regular'>
					If you started with a property worth $300,000 and exchanged four times
					you would earn almost 3 times the profit and have almost 3 times the
					amount of real estate than if you hadn’t exchanged.
				</div>
			</div>
			<div className='bg-gray-100 p-2 sm:p-6 shadow flex flex-col gap-6'>
				<div className='pb-3 border-b border-gray-500 flex-col justify-center items-start gap-4'>
					<div className='text-gray-500 text-sm font-work-sans-semibold'>
						After-Tax Profits with 1031 Exchange
					</div>
				</div>
				<div className='w-full h-[40vh]'>
					<Bar options={options} data={data(primaryColor)} />
				</div>
				<div className='gap-3.5 flex flex-col sm:flex-row justify-center'>
					<div className='justify-center sm:justify-start items-center gap-1 flex'>
						<div className='w-2 h-2 sm:w-3 sm:h-3 bg-primary-200 rounded-full' />
						<div className='text-gray-800 w-max text-xs sm:text-sm font-work-sans-regular'>
							With a 1031 Exchange
						</div>
					</div>
					<div className='justify-center sm:justify-start items-center gap-1 flex'>
						<div className='w-2 h-2 sm:w-3 sm:h-3 bg-gray-500 rounded-full' />
						<div className='text-gray-800 w-max text-xs sm:text-sm font-work-sans-regular'>
							Without a 1031 Exchange
						</div>
					</div>
				</div>
			</div>
			<div className='bg-gray-100 p-2 sm:p-6 shadow flex flex-col gap-6'>
				<div className='pb-3 border-b border-neutral-500 flex-col justify-center items-start gap-4'>
					<div className='self-stretch flex-col justify-center items-start gap-1 flex'>
						<div className='text-gray-500 text-sm font-work-sans-semibold'>
							Growth of Real Estate Purchasing Power with 1031 Exchange
						</div>
						<div className='self-stretch text-gray-500 text-sm font-work-sans-regular'>
							Increase real estate value three times with a 1031 Exchange by not
							paying tax each time and leveraging into more real estate
						</div>
					</div>
				</div>
				<div className='w-full h-[40vh]'>
					<Bar options={purchaseOptions} data={purchaseData(primaryColor)} />
				</div>
				<div className='gap-3.5 flex flex-col sm:flex-row justify-center'>
					<div className='justify-center sm:justify-start items-center gap-1 flex'>
						<div className='w-2 h-2 sm:w-3 sm:h-3 bg-primary-200 rounded-full' />
						<div className='text-gray-800 w-max text-xs sm:text-sm font-work-sans-regular'>
							With a 1031 Exchange
						</div>
					</div>
					<div className='justify-center sm:justify-start items-center gap-1 flex'>
						<div className='w-2 h-2 sm:w-3 sm:h-3 bg-gray-500 rounded-full' />
						<div className='text-gray-800 w-max text-xs sm:text-sm font-work-sans-regular'>
							Without a 1031 Exchange
						</div>
					</div>
				</div>
			</div>
			<div className='text-center text-gray-400 text-sm font-work-sans-regular'>
				*Value of real estate purchased is based on a 5 year hold and
				appreciation of 5 % per year starting with one property worth $300,000.
				Assumes a loan of 80% LTV for each purchase.
			</div>
		</div>
	)
}

export default GrowthWithExchange
