import MoreDetails from './MoreDetails'
import * as selectors from './moreDetails.selectors'
import slice from './moreDetails.slice'

export const { name, actions, reducer } = slice
export const {
	selectMoreDetailsResponse,
	selectCurrentInvestment,
	selectNewInvestments,
	selectMoreDetails,
} = selectors
export default MoreDetails
