import React, { useRef, useState, useEffect, useMemo } from 'react'
import classnames from 'classnames'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Range } from 'react-range'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { get, isString } from 'lodash'
import { Form, Formik } from 'formik'
import { MEETING_SCHEDULER_ID, APP_BASE_URL } from '../../utils/getEnvVars'
import {
	openURLInNewTabDefault,
	getPropertyTax,
	numberCommasSeparator,
	getNavigationRoute,
} from '../../utils/methods'
import {
	ReinvestmentGenericModal,
	ReinvestmentModal,
	TaxSavingStepsGenericModal,
	HowItWorksModal,
} from '../../widgets/Modal'
import { Header } from './Header'
import Footer from './Footer'
import {
	PreviousBtn,
	NextBtn,
	PlayBtn,
	arrowLeftIcon,
	email,
	home,
	location,
	arrowLeft,
	shieldDollar,
	grayCirclePlaySolid,
} from '../../assets/icons'
import { selectUsersResponseData } from '../Summary/summary.selectors'
import {
	userStory,
	reinvestmentIntro,
	waysToGetStarted,
	googleReview,
	taxes,
} from '../../assets/images'
import {
	exchangeInfo,
	userStoryData,
	reviews,
	VIDEOS_URL,
	PLAY_VIDEO,
	ExchangeCostCards,
	PLENTI,
	ERROR_MESSAGES,
	ERROR_MESSAGE_CLASSES,
	SliderConstants,
	DEFAULT_VALUES,
	NavRoutes,
	PLAY,
} from '../../utils/constants'
import {
	selectAffiliateDetails,
	selectAffiliateSlug,
} from '../Affiliates/affiliates.selectors'
import { saveContactOnHubspot } from './ReinvestmentAffiliate.asynAction'
import Loading from '../../widgets/Loading/Loading'
import { QualifyModal } from '../Qualify/QualifyModal'
import useBodyScrollDisable from '../../utils/useBodyScrollDisable'

const {
	MIN_VALUE,
	MAX_VALUE,
	STEP_COUNT,
	ZERO,
	ONE,
	HUNDRED,
	DEFAULT_RANGE_1M,
	DEFAULT_RANGE_400K,
} = SliderConstants
const { EMPTY_STRING } = DEFAULT_VALUES
const { TAXREPORT, MODEL } = NavRoutes
const TAX_PERCENTAGE = 0.3

const ExchangeCards = () => (
	<div className='grid sm:grid-cols-3 xs:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 w-full gap-4'>
		{ExchangeCostCards.map((exchangeCard, index) => (
			<div
				className='relative bg-white flex flex-col justify-between py-10 px-4'
				key={index}
			>
				<div>
					{index === 0 && (
						<div className='absolute top-0 right-0 flex justify-end'>
							<div className='bg-[#5c6974] w-[50px] h-[50px] premium-card' />
						</div>
					)}
					<span className='text-4xl text-[#414141] font-axiforma-thin tracking-tighter'>
						{exchangeCard.price}
					</span>
					<p className='mt-2 text-[#5c6974] text-md font-axiforma-semibold md:text-sm lg:text-2xl'>
						{exchangeCard.exchangeType}
					</p>
					<div className='border-slate-400 border-t-2 mt-5 mb-4'></div>
					<p className='mt-2 text-[#5c6974] text-[13px] leading-tight font-axiforma-regular'>
						{exchangeCard.bundleAndSave}
						<span className='text-[#5c6974] font-bold'> &#42;</span>
					</p>
					<div className='border-slate-400 border-t-2 mt-4 mb-4'></div>
					{exchangeCard.detail.map((info, idx) => (
						<p
							className='mt-4 text-sm text-slate-700 leading-tight font-axiforma-regular'
							key={idx}
						>
							{info}
						</p>
					))}
				</div>
				<p className='mt-4 text-[10px] text-[#5c6974] font-bold leading-tight font-axiforma-bold'>
					&#42; When you use a Plenti preferred provider for a real estate,
					mortgage or replacement property.
				</p>
			</div>
		))}
	</div>
)

const ExpandData = ({
	selectedExchangeInfoSection,
	setShouldShowVideoModal,
	setShouldShowQualifyModal,
}) => {
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const { component, buttonText, targetPage, description, tab, openModal } =
		exchangeInfo[selectedExchangeInfoSection]
	const [isHovered, setIsHovered] = useState(false)

	const handleOnClick = () => {
		if (buttonText === PLAY_VIDEO)
			setShouldShowVideoModal((prevState) => !prevState)
		else if (openModal) setShouldShowQualifyModal(true)
		else {
			if (affiliateSlug === PLENTI)
				return targetPage & window.open(`${APP_BASE_URL}${targetPage}`, tab)
			return (
				targetPage &&
				window.open(`${APP_BASE_URL}/${affiliateSlug}${targetPage}`, tab)
			)
		}
	}
	const handleMouseEnter = () => setIsHovered(true)

	const handleMouseLeave = () => setIsHovered(false)

	return (
		<>
			{component ? (
				<ExchangeCards />
			) : (
				<>
					<div className='text-white text-xl md:text-2xl font-work-sans-regular mb-4 md:mb-20'>
						{description.split('\n').map((description, i) => (
							<p className='mb-8' key={i}>
								{description}
							</p>
						))}
					</div>
					<button
						className={classnames(
							'w-full md:w-max text-white text-center font-work-sans-semibold py-4 px-8 rounded-[30px] border border-white flex justify-center items-center hover:bg-white hover:text-black cursor-pointer transition duration-500 ease-in-out',
							{ hidden: !buttonText }
						)}
						onClick={() => handleOnClick()}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						<p>{buttonText}</p>
						<div
							className={classnames('w-4 h-4 ml-5', {
								hidden: buttonText !== 'Play video',
							})}
						>
							<PlayBtn fill={isHovered ? 'black' : 'white'} />
						</div>
					</button>
				</>
			)}
		</>
	)
}

const ReinvestmentAffiliate = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const sliderRef = useRef(null)
	const aboutRef = useRef(null)
	const waysToGetStartedRef = useRef(null)
	const affiliateDetails = useSelector(selectAffiliateDetails)
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const [selectedExchangeInfoSection, setSelectedExchangeInfoSection] =
		useState(0)
	const [shouldShowVideoModal, setShouldShowVideoModal] = useState(false)
	const [shouldShowMeetingSchedular, setShouldShowMeetingSchedular] =
		useState(false)
	const [shouldShowReaffiliateModal, setShouldShowReaffiliateModal] =
		useState(false)
	const [shouldShowTaxSavingModal, setShouldShowTaxSavingModal] =
		useState(false)
	const [shouldShowQualifyModal, setShouldShowQualifyModal] = useState(false)

	const [isTaxSavingSecondaryScreen, setIsTaxSavingSecondaryScreen] =
		useState(false)
	const [isDataSaving, setIsDataSaving] = useState(false)

	useBodyScrollDisable(shouldShowTaxSavingModal)
	useBodyScrollDisable(shouldShowQualifyModal)
	useBodyScrollDisable(shouldShowReaffiliateModal)

	const toggleTaxSaving = () => {
		setShouldShowTaxSavingModal((prevValue) => !prevValue)
		setIsTaxSavingSecondaryScreen(false)
	}

	const toggleQualify = () =>
		setShouldShowQualifyModal((prevValue) => !prevValue)

	const toggleMeetingScheduler = () =>
		setShouldShowMeetingSchedular((prevValue) => !prevValue)

	const toggleReadOurGuide = () =>
		setShouldShowReaffiliateModal((prevValue) => !prevValue)

	const waysTogetStartedSteps = [
		{
			title: 'LEARN',
			description:
				'Learn about the process, what kinds of properties qualify, the different types of 1031 Exchanges, and more.',
			buttonText: 'Read our guide',
			onClick: () => toggleReadOurGuide(),
		},
		{
			title: 'PLAN',
			description:
				'Use our platform to enter your numbers and start modeling your reinvestment scenarios, get a tax estimate, and explore reinvestment options.',
			buttonText: 'Start modeling',
			onClick: () =>
				openURLInNewTabDefault(getNavigationRoute(affiliateSlug, MODEL)),
		},
		{
			title: 'GET A CONSULT',
			description: 'Ready to move forward? Talk to an advisor.',
			buttonText: 'Book a consult',
			onClick: toggleMeetingScheduler,
		},
	]

	const sliderSettings = {
		dots: true,
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: false,
		infinite: true,
		fade: true,
		adaptiveHeight: true,
		cssEase: 'ease-in-out',
	}

	const handleCloseVideo = () => setShouldShowVideoModal(false)

	const PrevArrow = () => (
		<div className='mr-8' onClick={() => sliderRef.current.slickPrev()}>
			<PreviousBtn className='cursor-pointer slider-button' />
		</div>
	)

	const NextArrow = () => (
		<div onClick={() => sliderRef.current.slickNext()}>
			<NextBtn className='cursor-pointer slider-button' />
		</div>
	)

	const usersResponseData = useSelector(selectUsersResponseData)
	const { user } = usersResponseData
	const [originalPrice, setOriginalPrice] = useState([
		MIN_VALUE,
		DEFAULT_RANGE_400K,
	])
	const [currentSalesPrice, setCurrentSalesPrice] = useState([
		MIN_VALUE,
		DEFAULT_RANGE_1M,
	])
	const [reinvestmentBudget, setReinvestmentBudget] = useState([
		ZERO,
		DEFAULT_RANGE_1M,
	])

	const taxSavingAmount = useMemo(
		() =>
			originalPrice[1] < currentSalesPrice[1]
				? getPropertyTax(currentSalesPrice[1], originalPrice[1])
				: ZERO,
		[originalPrice, currentSalesPrice]
	)

	const taxSaved = useMemo(() => {
		if (taxSavingAmount <= ZERO) return taxSavingAmount
		return Math.min(
			Math.max(
				taxSavingAmount -
					(currentSalesPrice[1] - reinvestmentBudget[1]) * TAX_PERCENTAGE,
				ZERO
			),
			taxSavingAmount
		)
	}, [reinvestmentBudget, currentSalesPrice, taxSavingAmount])

	const setInititalValues = (dataSet, key) => {
		const initValue = get(dataSet, key, EMPTY_STRING)
		return isString(initValue) ? initValue : EMPTY_STRING
	}

	const initialValues = {
		email: setInititalValues(user, 'email'),
	}

	const taxSavingContent = [
		{
			src: home,
			description: "Your Current Property's Sale Price",
			amount: currentSalesPrice[1],
		},
		{
			src: location,
			description: 'Estimated Price of New Property',
			amount: reinvestmentBudget[1],
		},
		{
			src: shieldDollar,
			description: taxSaved
				? `Tax Savings With a${
						taxSaved < taxSavingAmount ? ' Partial' : ''
				  } 1031 Exchange`
				: taxSavingAmount
				? 'Zero tax savings with this partial exchange.'
				: 'No Exchange is needed, because no tax is due.',
			amount: taxSaved,
		},
	]

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.email(ERROR_MESSAGES.EMAIL_VALIDATE_SPACE)
			.required(ERROR_MESSAGES.EMAIL_REQUIRED),
	})

	const calculateSliderWidth = (value, maxValue, minValue) => {
		return (value / (maxValue - minValue)) * 100
	}

	const [firstSliderCalculatedWidth, setFirstSliderCalculatedWidth] = useState(
		calculateSliderWidth(originalPrice[1], MAX_VALUE, MIN_VALUE)
	)
	const [secondSliderCalculatedWidth, setSecondSliderCalculatedWidth] =
		useState(calculateSliderWidth(currentSalesPrice[1], MAX_VALUE, MIN_VALUE))
	const [thirdSliderCalculatedWidth, setThirdSliderCalculatedWidth] = useState(
		calculateSliderWidth(reinvestmentBudget[1], MAX_VALUE, MIN_VALUE)
	)

	const seeTaxSavingResult = () => setIsTaxSavingSecondaryScreen(true)

	const getPersonalizedTaxReport = () => setIsTaxSavingSecondaryScreen(false)

	const handleSliderValuesChange = (
		newValues,
		setValue,
		setRangeSliderWidth
	) => {
		const calculatedPercentageForWidth =
			((newValues[ONE] - MIN_VALUE) / (MAX_VALUE - MIN_VALUE)) * HUNDRED
		setRangeSliderWidth(calculatedPercentageForWidth)
		setValue(newValues)
	}

	const videoRef = useRef(null)
	const [isPlaying, setIsPlaying] = useState(false)
	const [isLoading, setIsLoading] = useState(true)

	const videoPlaying = (event) => setIsPlaying(event.type === PLAY)

	const handlePlay = () => videoRef.current && videoRef.current.play()

	const handleLoadedMetadata = () => setIsLoading(false)

	useEffect(() => {
		const taxSavingModal = document.getElementById('tax-saving-modal')
		if (taxSavingModal) taxSavingModal.scrollIntoView()
	}, [isTaxSavingSecondaryScreen])

	const handleFormSubmission = ({ email }) => {
		const request = {
			Email: email,
			OriginalPrice: originalPrice[1],
			CurrentSalesPrice: currentSalesPrice[1],
			TaxSaving: taxSavingAmount,
			Affiliate: affiliateDetails?.companySlug || '',
			URLReferrerTaxReport: '1',
		}
		setIsDataSaving(true)
		dispatch(saveContactOnHubspot(request)).then(() => {
			setIsDataSaving(false)
			navigate(
				affiliateSlug && affiliateSlug !== PLENTI
					? `/${affiliateSlug}${TAXREPORT}`
					: TAXREPORT,
				{
					state: {
						values: {
							email,
							originalPrice: originalPrice[1],
							currentPrice: currentSalesPrice[1],
							reinvestmentPrice: reinvestmentBudget[1],
						},
					},
				}
			)
		})
	}

	return (
		<div className='w-full'>
			<Header aboutRef={aboutRef} waysToGetStartedRef={waysToGetStartedRef} />
			{shouldShowReaffiliateModal && (
				<ReinvestmentModal handleClose={toggleReadOurGuide} />
			)}
			<div
				className='bg-cover'
				style={{ backgroundImage: `url(${reinvestmentIntro})` }}
			>
				<div
					className='px-8 sm:px-20 md:px-36 py-36'
					style={{
						background:
							'linear-gradient(0deg, rgba(0, 0, 0, 0.65) 25.53%, rgba(0, 0, 0, 0.00) 83.75%)',
					}}
				>
					<div className='text-white flex flex-col gap-52 md:gap-[396px]'>
						<h1 className='font-work-sans-light max-[480px]:text-4xl text-[56px] md:text-[76px] leading-normal font-light'>
							Don&apos;t lose 30% of your real estate profit to unnecessary
							taxes
						</h1>
						<div className='flex justify-end gap-12 xl:gap-72 items-center flex-wrap md:flex-nowrap'>
							<p className=' font-work-sans-regular text-xl leading-8 w-full'>
								A 1031 Exchange helps you keep more of your profit to reinvest
								in something better. We use our 50 years of experience to help
								you execute it all simply.
							</p>
							<div className='w-full flex md:justify-center'>
								<button
									onClick={toggleTaxSaving}
									className='text-white text-center font-work-sans-semibold w-full md:w-2/3 py-4 rounded-[30px] border border-white flex justify-center items-center hover:bg-white hover:text-black cursor-pointer transition duration-500 ease-in-out'
								>
									See how much you can save
								</button>
							</div>
						</div>
						{shouldShowTaxSavingModal && (
							<TaxSavingStepsGenericModal handleClose={toggleTaxSaving}>
								<div id='tax-saving-modal' className='w-full h-auto'>
									{!isTaxSavingSecondaryScreen && (
										<div className='w-full p-4 sm:p-6 flex flex-col justify-between'>
											<p className='font-work-sans-semibold text-zinc-900 text-base sm:text-xl'>
												Discover Your Tax Savings
											</p>
											<p className='text-gray-500 mb-4 sm:mb-6 mt-2 font-work-sans-medium text-sm sm:text-lg'>
												Taxes Suck! Skip the taxes and reinvest into a better
												investment. Adjust the sliders to the right to provide
												information on a property you COULD sell. We’ll estimate
												the amount of profit you can save by NOT paying taxes.
											</p>
											<div className='gap-2 sm:gap-4 flex flex-col'>
												<div className='bg-gray-100 p-4 border border-gray-300'>
													<p className='text-zinc-700 font-work-sans-regular mb-3 text-sm sm:text-base'>
														How much did you originally pay for your property?
													</p>
													<Range
														step={STEP_COUNT}
														min={MIN_VALUE}
														max={MAX_VALUE}
														values={originalPrice}
														onChange={(newValues) =>
															handleSliderValuesChange(
																[0, newValues[0] || newValues[1]],
																setOriginalPrice,
																setFirstSliderCalculatedWidth
															)
														}
														renderTrack={({ props, children }) => (
															<div
																{...props}
																style={{
																	...props.style,
																	height: '8px',
																	width: '98%',
																	position: 'relative',
																	left: '12px',
																}}
																className='bg-mischka'
															>
																<div
																	{...props}
																	style={{
																		...props.style,
																		height: '8px',
																		width: firstSliderCalculatedWidth + '%',
																		position: 'absolute',
																	}}
																	className='bg-cadetBlue'
																/>
																{children}
															</div>
														)}
														renderThumb={({ index, props }) => (
															<div
																{...props}
																style={{
																	...props.style,
																	height: '24px',
																	width: '24px',
																	borderRadius: '50%',
																}}
																className={`bg-${
																	index === 1 ? 'white' : 'cadetBlue'
																} ${
																	index === 1
																		? 'border border-cadetBlue'
																		: 'border-none'
																}`}
																onMouseDown={(event) => {
																	if (index === 0) {
																		event.stopPropagation()
																	}
																}}
															/>
														)}
													/>
													<p className='text-tiber text-center mt-2 font-work-sans-medium text-base'>
														$<span>{originalPrice[1].toLocaleString()}</span>
													</p>
												</div>
												<div className='bg-gray-100 p-4 border border-gray-300'>
													<p className='text-zinc-700 font-work-sans-regular mb-3 text-sm sm:text-base'>
														What is the property&apos;s approximate selling
														price now?
													</p>
													<Range
														step={STEP_COUNT}
														min={MIN_VALUE}
														max={MAX_VALUE}
														values={currentSalesPrice}
														onChange={(newValues) =>
															handleSliderValuesChange(
																[0, newValues[0] || newValues[1]],
																setCurrentSalesPrice,
																setSecondSliderCalculatedWidth
															)
														}
														renderTrack={({ props, children }) => (
															<div
																{...props}
																style={{
																	height: '8px',
																	width: '98%',
																	left: '12px',
																	position: 'relative',
																}}
																className='bg-mischka'
															>
																<div
																	{...props}
																	style={{
																		...props.style,
																		height: '8px',
																		width: secondSliderCalculatedWidth + '%',
																		position: 'absolute',
																	}}
																	className='bg-cadetBlue'
																/>
																{children}
															</div>
														)}
														renderThumb={({ index, props }) => (
															<div
																{...props}
																style={{
																	...props.style,
																	height: '24px',
																	width: '24px',
																	borderRadius: '50%',
																}}
																className={`bg-${
																	index === 1 ? 'white' : 'cadetBlue'
																} ${
																	index === 1
																		? 'border border-cadetBlue'
																		: 'border-none'
																}`}
																onMouseDown={(event) => {
																	if (index === 0) {
																		event.stopPropagation()
																	}
																}}
															/>
														)}
													/>
													<p className='text-tiber text-center mt-2 font-work-sans-medium text-base'>
														$
														<span>{currentSalesPrice[1].toLocaleString()}</span>
													</p>
												</div>
												<div className='bg-gray-100 p-4 border border-gray-300'>
													<p className='text-zinc-700 font-work-sans-regular mb-3 text-sm sm:text-base'>
														How Much Real Estate will you Buy?*
													</p>
													<Range
														step={STEP_COUNT}
														min={MIN_VALUE}
														max={MAX_VALUE}
														values={reinvestmentBudget}
														onChange={(newValues) =>
															handleSliderValuesChange(
																[0, newValues[0] || newValues[1]],
																setReinvestmentBudget,
																setThirdSliderCalculatedWidth
															)
														}
														renderTrack={({ props, children }) => (
															<div
																{...props}
																style={{
																	...props.style,
																	height: '8px',
																	width: '98%',
																	left: '12px',
																	position: 'relative',
																}}
																className='bg-mischka'
															>
																<div
																	{...props}
																	style={{
																		...props.style,
																		height: '8px',
																		width: thirdSliderCalculatedWidth + '%',
																		position: 'absolute',
																	}}
																	className='bg-cadetBlue'
																/>
																{children}
															</div>
														)}
														renderThumb={({ index, props }) => (
															<div
																{...props}
																style={{
																	...props.style,
																	height: '24px',
																	width: '24px',
																	borderRadius: '50%',
																}}
																className={`bg-${
																	index === 1 ? 'white' : 'cadetBlue'
																} ${
																	index === 1
																		? 'border border-cadetBlue'
																		: 'border-none'
																}`}
																onMouseDown={(event) => {
																	if (index === 0) {
																		event.stopPropagation()
																	}
																}}
															/>
														)}
													/>
													<p className='text-tiber text-center mt-2 font-work-sans-medium text-base'>
														$
														<span>
															{reinvestmentBudget[1].toLocaleString()}
														</span>
													</p>
												</div>
											</div>
											<button
												className='text-center font-work-sans-semibold w-full py-4 px-3.5 rounded-[30px] bg-primary-200 hover:bg-primary-100 border flex justify-center items-center cursor-pointer transition duration-500 ease-in-out mt-4 sm:mt-6 text-lg'
												onClick={seeTaxSavingResult}
											>
												See Results
												<img
													src={arrowLeftIcon}
													alt='arrowLeftIcon'
													className='rotate-180 ml-2 w-5 h-5'
													style={{
														filter: 'brightness(0) invert(1)',
													}}
												/>
											</button>
											<p className='text-gray-500 text-center mt-4 sm:mt-6 font-work-sans-medium text-xs sm:text-sm'>
												*You must buy as much as you sell to save all the taxes.
												Sell first, then buy. Or buy first, then sell-
												that&apos;s a reverse exchange! No tax on your gains.
												IRS exempts taxes with an exchange. IRC 1031 builds real
												estate equity. Build wealth with it!
											</p>
										</div>
									)}
									{isTaxSavingSecondaryScreen && (
										<div className='w-full flex flex-col sm:flex-row'>
											<div className='w-full sm:w-[45%] bg-white flex gap-3 sm:gap-6 p-4 sm:p-6 flex-col'>
												<img
													src={arrowLeft}
													className='border border-gray-300 rounded-full cursor-pointer h-9 w-9 transition-transform duration-300 hover:scale-110 hidden sm:block p-2'
													onClick={getPersonalizedTaxReport}
												/>
												<div className='flex flex-col gap-2'>
													<p className='font-work-sans-semibold text-gray-900 text-xl'>
														Your Saved Profits
													</p>
													<p className='text-gray-500 font-work-sans-medium text-sm sm:text-lg'>
														You could save
														<span className='text-primary-600 font-work-sans-bold mx-1'>
															${numberCommasSeparator(taxSavingAmount)}
														</span>
														in unnecessary taxes using a 1031 Exchange!
													</p>
												</div>
												<div className='flex flex-col gap-4'>
													{taxSavingContent.map((item, index) => (
														<div
															key={index}
															className={`flex justify-between border ${
																index === taxSavingContent.length - 1
																	? 'border-emerald-600 bg-feta'
																	: 'border-zinc-200'
															}`}
														>
															<div className='flex gap-5 items-center p-3 sm:px-6 sm:py-4'>
																<div>
																	<img
																		src={item.src}
																		className='h-10 w-[39.18px] sm:w-14 sm:h-14'
																	/>
																</div>
																<div>
																	<p
																		className={`text-base sm:text-lg ${
																			index === taxSavingContent.length - 1
																				? 'text-emerald-600 font-work-sans-bold'
																				: 'text-zinc-900 font-work-sans-medium'
																		}`}
																	>
																		${item.amount.toLocaleString()}
																	</p>
																	<p
																		className={`text-xs sm:text-sm font-work-sans-regular ${
																			index === taxSavingContent.length - 1
																				? 'text-emerald-600'
																				: 'text-zinc-700'
																		}`}
																	>
																		{item.description}
																	</p>
																</div>
															</div>
														</div>
													))}
													{taxSavingAmount !== taxSaved &&
														(taxSaved ? (
															<p className='text-zinc-900 text-xs sm:text-sm font-work-sans-regular'>
																You can save the entire
																<span className='text-primary-600 font-work-sans-bold mx-1'>
																	${numberCommasSeparator(taxSavingAmount)}
																</span>
																in Capital Gains taxes, if you buy at least as
																much property as you are selling.
															</p>
														) : (
															<p className='text-red-500 text-xs sm:text-sm font-work-sans-regular'>
																There is no value in this partial exchange since
																you must buy more than
																<span className='font-work-sans-bold mx-1'>
																	${numberCommasSeparator(originalPrice[1])}
																</span>
															</p>
														))}
												</div>
											</div>
											<div className='w-full sm:w-[55%] bg-solitude flex flex-col'>
												<div className='w-full relative'>
													<video
														ref={videoRef}
														preload='metadata'
														controls
														poster={taxes}
														className='w-full relative'
														onPlay={videoPlaying}
														onPause={videoPlaying}
														onLoadedMetadata={handleLoadedMetadata}
													>
														<source
															src={VIDEOS_URL.TAX_GUIDE_1031_EXCHANGE}
															type='video/mp4'
														/>
													</video>
													{!isPlaying && !isLoading && (
														<img
															src={grayCirclePlaySolid}
															onClick={handlePlay}
															className='absolute top-1/2 left-1/2 opacity-50 hover:opacity-100 h-12 w-12 sm:h-16 sm:w-16 md:w-20 md:h-20 cursor-pointer transition duration-500 transform -translate-x-1/2 -translate-y-1/2'
														/>
													)}
												</div>
												<div className='p-6 flex flex-col gap-4'>
													<p className='font-work-sans-bold text-zinc-700 text-base sm:text-lg'>
														What is an IRC Section 1031 Exchange?
													</p>
													<p className='text-neutral-500 font-work-sans-regular text-xs sm:text-sm'>
														A 1031 Exchange is a tax law - used by savvy real
														estate investors - to save thousands of dollars in
														taxes giving them Plenti of options for reinvesting.
														Plenti makes using this law easy. What properties
														qualify for a 1031 exchange? Whether you decide to
														exchange or not, at least you&apos;ll know all your
														options and how to do it.
													</p>
													<Formik
														validationSchema={validationSchema}
														initialValues={initialValues}
														onSubmit={handleFormSubmission}
													>
														{({
															values,
															handleBlur,
															handleChange,
															touched,
															errors,
															isValid,
														}) => (
															<Form>
																<div>
																	<div className='form-group'>
																		<label
																			htmlFor='customerEmail'
																			className='mb-2 text-sm font-medium left-5 inline-block text-zinc-700 font-work-sans-medium'
																		>
																			Email
																		</label>
																		<div className='relative'>
																			<input
																				id='customerEmail'
																				type='text'
																				name='email'
																				className='form-control pl-10 text-gray-500 font-work-sans-regular text-base'
																				placeholder='Enter Email'
																				onChange={handleChange}
																				onBlur={handleBlur}
																				value={values.email}
																			/>
																			<img
																				src={email}
																				alt='email'
																				className='absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5'
																			/>
																		</div>
																		{touched.email && errors.email && (
																			<p className={ERROR_MESSAGE_CLASSES}>
																				{errors.email}
																			</p>
																		)}
																	</div>
																</div>

																<button
																	className='text-center text-white text-sm sm:text-lg font-work-sans-semibold w-full px-[18px] py-[13px] sm:py-4 sm:px-5 rounded-[30px] bg-primary-200 hover:bg-primary-100 disabled:hover:bg-primary-200 border flex justify-center items-center cursor-pointer transition duration-500 ease-in-out mt-4 disabled:cursor-not-allowed'
																	type='submit'
																	disabled={
																		!isValid || !values.email || isDataSaving
																	}
																>
																	<Loading
																		loading={isDataSaving}
																		setLoading={setIsDataSaving}
																	>
																		<div className='flex justify-center items-center'>
																			Show Me How To Save My Profits
																			<img
																				src={arrowLeftIcon}
																				alt='arrowLeftIcon'
																				className='rotate-180 ml-2 w-5 h-5'
																				style={{
																					filter: 'brightness(0) invert(1)',
																				}}
																			/>
																		</div>
																	</Loading>
																</button>
															</Form>
														)}
													</Formik>
													<p className='text-neutral-500 text-xs sm:text-sm text-center font-work-sans-regular mb-1 sm:mb-20'>
														*The IRS doesn&apos;t tax your sale with an
														exchange! Utilize IRC 1031 to grow real estate
														equity and wealth!
													</p>
												</div>
											</div>
										</div>
									)}
								</div>
							</TaxSavingStepsGenericModal>
						)}
					</div>
				</div>
			</div>
			<div className='w-full py-32 px-8 sm:px-20 md:pl-36 lg:px-36 md:pr-8 bg-black'>
				<h2 className='text-[#68B6BF] font-work-sans-light mb-8 md:mb-40 text-4xl md:leading-[64px] md:text-[57px] max-w-[764px] justify-around'>
					Tax-free real estate investing made simple
				</h2>
				<div className='flex justify-between flex-col md:flex-row gap-16 md:gap-8 lg:gap-32'>
					<div className='flex flex-col justify-between h-auto md:h-[300px]'>
						{exchangeInfo.map((item, index) => (
							<div
								onClick={() => setSelectedExchangeInfoSection(index)}
								key={item.title}
								className={classnames(
									'text-xl cursor-pointer text-[#67747F] mb-4'
								)}
							>
								<p
									className={classnames(
										'uppercase mb-2 font-work-sans-semibold border-b-2 border-black sm:w-max',
										{
											'text-white border-b-2 border-white':
												index === selectedExchangeInfoSection,
										}
									)}
								>
									{item.title}
								</p>
								<div
									className={classnames(
										'text-white font-normal mb-4 md:mb-20 transition-transform duration-300 md:hidden',
										{
											hidden: index !== selectedExchangeInfoSection,
										}
									)}
								>
									<ExpandData
										selectedExchangeInfoSection={selectedExchangeInfoSection}
										setShouldShowVideoModal={setShouldShowVideoModal}
										setShouldShowQualifyModal={setShouldShowQualifyModal}
									/>
								</div>
							</div>
						))}
					</div>
					<div className='hidden md:block lg:w-full md:w-1/2'>
						<ExpandData
							selectedExchangeInfoSection={selectedExchangeInfoSection}
							setShouldShowVideoModal={setShouldShowVideoModal}
							setShouldShowQualifyModal={setShouldShowQualifyModal}
						/>
					</div>
				</div>
			</div>
			<div className='w-full py-24 px-8 sm:px-20 md:px-36 flex flex-col items-center'>
				<img
					src={userStory}
					alt='userStory'
					className='w-full h-full min-h-[240px] object-cover md:object-fill'
				/>
				<div ref={aboutRef}>
					<div className='flex flex-col md:flex-row gap-16 md:gap-32 my-16 md:items-center'>
						<div className='flex flex-col w-full md:w-1/2'>
							<h4 className='text-xl leading-normal font-work-sans-semibold'>
								WHY PLENTI FINANCIAL
							</h4>
							<h3 className='text-2xl md:text-[43px] font-work-sans-light pt-5 pb-8 leading-tight'>
								Your one-stop solution for 1031 Exchange & reinvestment
							</h3>
							<p className='text-xl font-work-sans-regular md:leading-8'>
								We’re a nationwide provider of 1031 exchange accommodation
								services with an impeccable track record and not a single claim
								against us to recover funds. We work with some of the largest
								brokerage companies in the country and carry Errors & Omission
								Insurance and fidelity bond coverages. In addition, our banks
								provide unlimited FDIC insurance per exchange account regardless
								of the balance.
							</p>
						</div>
						<div className='w-full md:w-2/5'>
							{userStoryData.map((item, i) => (
								<p
									className='text-[17px] font-work-sans-regular leading-normal pb-5 pt-1 border-t-[1px] border-blue-500'
									key={i}
								>
									{item.text}
								</p>
							))}
						</div>
					</div>
				</div>
				<div
					className='text-black text-center font-work-sans-semibold w-full md:w-1/5 py-4 rounded-[30px] border border-black flex justify-center items-center hover:bg-black hover:text-white cursor-pointer transition duration-500 ease-in-out'
					onClick={() =>
						openURLInNewTabDefault(getNavigationRoute(affiliateSlug, MODEL))
					}
				>
					Model your exchange
				</div>
			</div>
			<div className='w-full py-8 md:py-24 px-8 sm:px-20 md:px-36 bg-black'>
				<div className='flex flex-col md:flex-row'>
					<div className='w-full pt-24 md:w-1/3 h-auto'>
						<div className='pb-16 text-white text-center text-3xl sm:text-[43px] font-work-sans-light leading-none'>
							Trusted by thousands of clients
						</div>
						<div className='text-white text-xl text-center font-work-sans-regular leading-8'>
							Join over 10,000 happy clients who did successful 1031 Exchanges
							with us.
						</div>
						<div className='mt-16 w-full flex  justify-center'>
							<img src={googleReview} alt='googleReview' />
						</div>
					</div>
					<div className='w-full md:w-2/3 mt-16 md:mt-0 pt-16 md:pt-24 md:ml-28 md:pl-28 border-t-2 md:border-t-0 md:border-l-2 border-white'>
						<div className='w-full flex justify-end'>
							<PrevArrow />
							<NextArrow />
						</div>

						<div className='md:pt-16 pb-16 w-full affiliate'>
							<Slider {...sliderSettings} ref={sliderRef}>
								{reviews.map((review, index) => (
									<div key={index} className={`p-4 md:p-[30px] my-5`}>
										<p className='text-white text-2xl font-work-sans-regular leading-normal'>
											{review.details}
										</p>
										<h3 className='text-white text-3xl pt-12 font-work-sans-semibold leading-[53px]'>
											- {review.author}
										</h3>
									</div>
								))}
							</Slider>
						</div>
					</div>
				</div>
			</div>
			{shouldShowQualifyModal && <QualifyModal handleClose={toggleQualify} />}
			{shouldShowMeetingSchedular && (
				<ReinvestmentGenericModal handleClose={toggleMeetingScheduler}>
					<div className='h-[80vh] w-full'>
						<div className='h-[80vh] w-full'>
							<iframe
								className='meetings-iframe-container h-[80vh] w-full'
								title='HubSpot Meeting Scheduler'
								src={`https://meetings.hubspot.com/${MEETING_SCHEDULER_ID}?embed=true`}
							/>
						</div>
					</div>
				</ReinvestmentGenericModal>
			)}
			{shouldShowVideoModal && (
				<HowItWorksModal handleClose={handleCloseVideo} />
			)}
			<div>
				<div>
					<img
						className='w-full h-full min-h-[240px] object-cover md:object-fill'
						src={waysToGetStarted}
						alt='Ways to Get Started'
					/>
				</div>
				<div
					className='flex flex-col gap-y-8 md:gap-y-20 p-8 sm:p-20 md:px-36'
					ref={waysToGetStartedRef}
				>
					<div className='text-black text-center text-2xl md:text-6xl font-work-sans-light'>
						Ways to get started
					</div>
					<div className='grid md:grid-cols-3 grid-cols-1 w-full gap-8 md:gap-16'>
						{waysTogetStartedSteps.map((step, index) => (
							<div
								className=' border-[#68B6BF] border-t-4 justify-between items-center md:items-start flex flex-col w-full overflow-hidden gap-8'
								key={index}
							>
								<div className='flex flex-col w-full gap-4 pt-4'>
									<div
										className={`text-black font-work-sans-semibold uppercase`}
									>
										{step.title}
									</div>
									<div
										className={`w-full text-black font-work-sans-regular leading-loose`}
									>
										{step.description}
									</div>
								</div>
								<div
									onClick={step.onClick}
									className='text-black text-center font-work-sans-semibold w-full md:w-2/3 py-4 rounded-[30px] border border-black flex justify-center items-center hover:bg-black hover:text-white cursor-pointer transition duration-500 ease-in-out'
								>
									{step.buttonText}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default ReinvestmentAffiliate
