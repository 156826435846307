import React, { useRef } from 'react'
import { crossModalIcon, crossIconWithBorder } from '../../assets/icons'

const TaxSavingStepsGenericModal = ({ children, handleClose }) => {
	const backdropRef = useRef(null)
	return (
		<div className='modal-backdrop fixed inset-0 bg-slate-900/70 backdrop-blur-md z-[999] pt-16 sm:px-22 md:px-32 lg:px-52 sm:py-16 flex items-center'>
			<div
				ref={backdropRef}
				className='modal-content bg-white w-full h-auto mx-auto relative flex flex-col mt-auto sm:mt-0'
			>
				<img
					src={crossModalIcon}
					className='hidden md:flex absolute w-15 h-15 right-[-40px] top-[-30px] cursor-pointer z-10'
					alt='cross-icon'
					onClick={handleClose}
				/>
				<img
					src={crossIconWithBorder}
					className='flex md:hidden absolute w-15 h-15 right-[5px] top-[10px] cursor-pointer z-10'
					alt='cross-icon'
					onClick={handleClose}
				/>

				{children && (
					<div className='flex overflow-scroll no-scrollbar max-h-[85vh] sm:max-h-[80vh]'>
						{children}
					</div>
				)}
			</div>
		</div>
	)
}
export default TaxSavingStepsGenericModal
