import { createSlice } from '@reduxjs/toolkit'
import * as asyncActions from './moreDetails.asyncAction'

const initialState = {
	moreDetailsResponse: {},
	currentInvestment: {},
	newInvestments: [],
	moreDetails: {
		sellPlan: '',
		purchasePlan: '',
	},
}

const slice = createSlice({
	name: 'moreDetails',
	initialState,
	reducers: {
		setMoreDetailsState: (state, action) => {
			state.moreDetails = action.payload
		},
	},
	extraReducers: {
		[asyncActions.updateCurrentInvestmentSellAndPurchasePlan.fulfilled]: (
			state,
			action
		) => {
			state.moreDetailsResponse =
				action.payload || initialState.moreDetailsResponse
		},
		[asyncActions.getCurrentInvestmentDetails.fulfilled]: (
			state,
			{ payload }
		) => {
			state.currentInvestment = payload || initialState.currentInvestment
		},
		[asyncActions.getNewInvestmentsDetail.fulfilled]: (state, { payload }) => {
			state.newInvestments =
				[...payload.investmentResponseData] || initialState.newInvestments
		},
	},
})

export default slice
export const { name, actions, reducer } = slice
