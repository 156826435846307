import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { shouldActivate, selectMoreDetailsState } from '../../widgets/NavBar'
import { updateProgressPercentage } from '../../widgets/CircularProgressIndicator'
import { Modal } from '../../widgets/Modal'
import InvestmentsAccordion from '../../widgets/InvestmentsAccordion/InvestmentsAccordion'
import { errorNotification } from '../../widgets/ToastNotification'
import {
	redDeleteIcon,
	bellRingingIcon,
	poweredByPlenti,
} from '../../assets/icons'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import {
	NavRoutes,
	SessionKeys,
	scrollActions,
	EQUITY_FACTOR,
	TWO_DECIMAL_PLACE,
	ESTIMATED_TAX_FACTOR,
	PLENTI,
} from '../../utils/constants'
import { NavLinkStates } from '../../utils/constant.enum'
import {
	selectInvestments,
	selectAccordionCounter,
	selectEstimatedTaxes,
} from './findInvestment.selectors'
import { actions } from './findInvestment.slice'
import {
	convertToNumber,
	getCurrencyCode,
	numberCommasSeparator,
	removeSpecialCharacter,
	getNavigationRoute,
} from '../../utils/methods'
import { selectCurrentInvestment } from '../CurrentInvestmentWrapper/InvestmentForm'
import BusyIndicator from '../../widgets/busyIndicator'
import { createNewInvestment } from './findInvestment.asyncActions'
import { selectAffiliateSlug } from '../Affiliates/affiliates.selectors'

const USD = 'USD'
const ZERO = '0'
let counter = 0

const FindInvestment = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const isAffiliateSite = affiliateSlug !== PLENTI

	const investmentData = useSelector(selectCurrentInvestment)
	const {
		totalTaxWithoutExchange,
		purchasePrice,
		propertyValue,
		debtAmount,
		newInvestmentAmount,
		totalEquity,
	} = investmentData
	const { INVESTMENT_FORM, MORE_DETAILS } = NavRoutes
	const { USER_ID } = SessionKeys
	const isMoreDetailsVisited = useSelector(selectMoreDetailsState).isVisited
	const [shouldShowModal, setShouldShowModal] = useState(false)
	const calculatedEstimatedTaxes = useSelector(selectEstimatedTaxes)
	const [estimatedTaxes, setEstimatedTaxes] = useState(
		calculatedEstimatedTaxes || totalTaxWithoutExchange
	)
	const navigateToInvestmentForm = () =>
		navigate(getNavigationRoute(affiliateSlug, INVESTMENT_FORM))
	const navigateToMoreDetails = () => {
		dispatch(shouldActivate(NavLinkStates.MORE_DETAILS))
		if (!isMoreDetailsVisited) dispatch(updateProgressPercentage())
		navigate(getNavigationRoute(affiliateSlug, MORE_DETAILS))
	}
	const investments = useSelector(selectInvestments)
	const accordionCounter = useSelector(selectAccordionCounter)
	const [formData, setFormData] = useState(investments)
	const {
		addInvestment,
		addInvestmentData,
		incrementAccordionCounter,
		decrementAccordionCounter,
		removeInvestment,
		updateEstimatedTaxes,
	} = actions

	const handleValues = (values) => {
		const { id, index, field } = values
		const newFormData = formData.map((form) => {
			if (form.id === id) {
				return {
					...form,
					...field,
					index,
					isValidated: false,
				}
			} else {
				return form
			}
		})
		setFormData(newFormData)
	}
	const removeAccordionData = (values) => {
		const newFormData = formData.map((form) => {
			if (form.id === values.id) {
				return {
					id: values.id,
				}
			} else {
				return form
			}
		})
		setFormData(newFormData)
	}

	const handleRemoveAccordion = (id) =>
		setFormData(formData.filter((form) => form.id !== id))
	const showError = (msg) => errorNotification(msg)
	const validateFields = (form) => {
		// Added 'Boolean' to handle all edge case & random behaviour.
		if (
			Boolean(form.purchasePrice) &&
			Boolean(form.downPayment) &&
			Boolean(form.market) &&
			Boolean(form.rentalIncome)
		)
			return true
		else {
			if (!form.purchasePrice) showError('Please fill Purchase Price Field')
			if (!form.downPayment) showError('Please fill Down Payment Field')
			if (!form.market) showError('Please select Market option')
			if (!form.rentalIncome) showError('Please fill Rental Income Field')
			return false
		}
	}
	const numericConversion = (field) => {
		if (field) return convertToNumber(removeSpecialCharacter(field))
	}

	const fieldConversion = (form) => {
		return {
			...form,
			purchasePrice: numericConversion(form.purchasePrice),
			downPayment: numericConversion(form.downPayment),
			rentalIncome: numericConversion(form.rentalIncome),
		}
	}

	const requestedInvestmentObject = (findInvestmentData) =>
		findInvestmentData.map((form) => {
			const { index, purchasePrice, downPayment, market, rentalIncome } = form
			return {
				investmentType: index,
				estimatePurchasePrice: purchasePrice,
				estimateDownPayment: downPayment,
				market: market,
				expectedRentalIncome: rentalIncome,
			}
		})

	const onFormSubmit = (findInvestmentData) => {
		const userId = get(window.sessionStorage, USER_ID, null)
		findInvestmentData.forEach((form) => {
			dispatch(addInvestmentData({ ...form }))
		})
		const updatedInvestmentDetails = findInvestmentData.map((form) =>
			fieldConversion(form)
		)
		const formattedInvestmentDetails = requestedInvestmentObject(
			updatedInvestmentDetails
		)
		dispatch(
			createNewInvestment({
				userId,
				totalEstimatedTaxes: numericConversion(estimatedTaxes),
				investments: [...formattedInvestmentDetails],
			})
		)
		dispatch(updateEstimatedTaxes(numericConversion(estimatedTaxes)))
		setShouldShowModal(true)
	}

	const setForEvaluation = (finalForm) => {
		finalForm.forEach((form) => {
			if (validateFields(form)) {
				if (!form.isValidated) form.isValidated = true
				counter = counter + 1
			} else showError('Please complete investments info.')
		})
		if (counter > 0 && finalForm.length === counter) {
			onFormSubmit(finalForm)
		}
	}

	// Custom Validation for Accordian,
	const handleValidation = () => {
		// Check is first investment is filled
		if (
			formData.length !== 0 &&
			formData[0].index >= 0 &&
			formData.index !== null
		) {
			// Check is first investment filled & second investment empty
			if (formData.length === 1) {
				setForEvaluation(formData)
			}
			if (formData.length === 2) {
				if (formData[1].index !== null && formData[1].index >= 0) {
					setForEvaluation(formData)
				} else {
					const filterForm = formData.filter((form) => {
						if (form.index !== null && form.index >= 0) {
							return form
						}
					})
					setForEvaluation(filterForm)
				}
			}

			// Check if more than two investments filled,
			if (formData.length > 2) {
				// Edge Case: when middle investments are empty
				if (
					formData[formData.length - 1].index !== null &&
					formData[formData.length - 1].index >= 0
				) {
					const previousLength = formData.length
					const newFormData = formData.filter((form) => {
						if (form.index !== null && form.index >= 0) {
							return form
						}
					})
					if (previousLength !== newFormData.length) {
						showError(
							'! Incomplete Investments in between. Please fill all the investments'
						)
					} else {
						setForEvaluation(formData)
					}
				}
				// Case when last investment also empty
				else {
					let tempCounter = 0
					for (const element of [...formData].reverse()) {
						// Case handling when index will be null otherwise undefined
						if (element.index === null || !(element.index >= 0)) {
							tempCounter = tempCounter + 1
						} else {
							break
						}
					}
					const previousform = formData.slice(0, formData.length - tempCounter)
					const checkForm = formData.filter((form) => {
						if (form.index !== null && form.index >= 0) {
							return form
						}
					})
					if (previousform.length !== checkForm.length) {
						showError(
							'! Incomplete Investments in between. Please fill all the investments'
						)
					} else {
						setForEvaluation(checkForm)
					}
				}
			}
		} else {
			if (!formData[0].index) showError('Please fill the first investment.')
		}
	}
	useEffect(() => {
		const newForm = formData.filter((form) => {
			if (
				(Boolean(form.purchasePrice) &&
					convertToNumber(removeSpecialCharacter(form.purchasePrice)) >= 0) ||
				(Boolean(form.downPayment) &&
					convertToNumber(removeSpecialCharacter(form.downPayment)) >= 0)
			) {
				return form
			}
		})
		const newValues = newForm.map((form) => {
			return {
				purchasePrice: form.purchasePrice
					? convertToNumber(removeSpecialCharacter(form.purchasePrice))
					: 0,
				downPayment: form.downPayment
					? convertToNumber(removeSpecialCharacter(form.downPayment))
					: 0,
			}
		})
		const sumOfPurchasePrices = newValues.reduce(
			(total, currentValue) => total + currentValue.purchasePrice,
			0
		)
		const sumOfDownPayments = newValues.reduce(
			(total, currentValue) => total + currentValue.downPayment,
			0
		)
		if (sumOfPurchasePrices > 0 || sumOfDownPayments > 0) {
			const firstValue =
				propertyValue - EQUITY_FACTOR * propertyValue - sumOfPurchasePrices
			const secondValue = propertyValue - debtAmount - sumOfDownPayments
			const finalTaxes = (
				Math.max(firstValue, secondValue) * ESTIMATED_TAX_FACTOR
			).toFixed(TWO_DECIMAL_PLACE)
			const getEstimatedTax = () =>
				finalTaxes > 0 ? setEstimatedTaxes(finalTaxes) : setEstimatedTaxes(ZERO)
			finalTaxes < totalTaxWithoutExchange
				? getEstimatedTax()
				: setEstimatedTaxes(totalTaxWithoutExchange)
		} else setEstimatedTaxes(totalTaxWithoutExchange)
	}, [
		formData,
		investmentData,
		debtAmount,
		propertyValue,
		isMoreDetailsVisited,
		purchasePrice,
		totalTaxWithoutExchange,
	])

	const result = {
		icon: bellRingingIcon,
		iconBackground: 'bg-orange-300/50 ring-8 ring-orange-100',
	}
	const prevBtn = {
		title: 'Back',
		classes: 'btn-light',
	}
	const nextBtn = {
		title: 'Continue',
		click: navigateToMoreDetails,
	}
	useEffect(() => {
		const container = document.getElementById('main-container')
		container.scrollIntoView(scrollActions)
	}, [])
	return (
		<div
			id='main-container'
			className={`xl:px-22 xl:pb-22 px-12 pb-12 ${
				isAffiliateSite ? 'xl:pt-7 p-2' : 'xl:pt-22 pt-12'
			}`}
		>
			{isAffiliateSite && (
				<div className='flex justify-end xl:pb-7 pb-1'>
					<img src={poweredByPlenti} alt='Powered By Plenti Logo' />
				</div>
			)}
			<HelmetProvider>
				<Helmet>
					<title>Exchange It :: Find Investment</title>
				</Helmet>
			</HelmetProvider>
			<h1 className='text-3xl font-bold leading-7 text-gray-900 mb-3'>
				Find Investments
			</h1>
			<p className='text-gray-700 mb-6'>
				Select the property types you plan on investing in. Eliminate your total
				estimated taxes by re-investing the total amount of your equity in new
				purchases.
			</p>

			<div className='grid grid-cols-12 gap-4 mb-6'>
				<div className='sm:col-span-4 col-span-12 rounded-lg relative border border-gray-400 p-3 text-center'>
					<span className='text-xs font-semibold text-gray-500 absolute bottom-full left-1/2 -translate-x-1/2 translate-y-1/2 bg-white px-3 whitespace-nowrap'>
						Total Equity
					</span>
					<BusyIndicator>
						<h1 className='text-lg font-bold text-gray-700'>
							{getCurrencyCode(USD)}
							{totalEquity ? (
								<>{numberCommasSeparator(totalEquity)}</>
							) : (
								<>{ZERO}</>
							)}
						</h1>
					</BusyIndicator>
				</div>
				<div className='sm:col-span-4 col-span-12 rounded-lg relative border border-gray-400 p-3 text-center'>
					<span className='text-xs font-semibold text-gray-500 absolute bottom-full left-1/2 -translate-x-1/2 translate-y-1/2 bg-white px-3 whitespace-nowrap'>
						New Investment Amount
					</span>
					<BusyIndicator>
						<h1 className='text-lg font-bold text-gray-700'>
							{getCurrencyCode(USD)}
							{newInvestmentAmount ? (
								<>{numberCommasSeparator(newInvestmentAmount)}</>
							) : (
								<>{ZERO}</>
							)}
						</h1>
					</BusyIndicator>
				</div>
				<div className='sm:col-span-4 col-span-12 rounded-lg relative border border-gray-400 p-3 text-center'>
					<span className='text-xs font-semibold text-gray-500 absolute bottom-full left-1/2 -translate-x-1/2 translate-y-1/2 bg-white px-3 whitespace-nowrap'>
						Total Estimated Taxes
					</span>
					<BusyIndicator>
						<h1 className='text-lg font-bold text-red-700'>
							{getCurrencyCode(USD)}
							{totalTaxWithoutExchange ? (
								<>{numberCommasSeparator(estimatedTaxes)}</>
							) : (
								<>{ZERO}</>
							)}
						</h1>
					</BusyIndicator>
				</div>
			</div>
			{investments.map((investment) => (
				<div
					key={investment.id}
					className='bg-gray-50 border border-gray-200 p-6 rounded-lg mb-6'
				>
					<div className='mb-6 flex items-center'>
						<h1 className='text-lg font-bold text-gray-800 leading-7'>
							Investment {investment.id + 1}
						</h1>
						{investments.length - 1 === investment.id &&
							investments.length - 1 > 0 && (
								<button
									className='btn ml-auto hover:bg-red-100/50 px-2'
									onClick={() => {
										dispatch(decrementAccordionCounter())
										dispatch(removeInvestment())
										handleRemoveAccordion(investment.id)
									}}
								>
									<img src={redDeleteIcon} width='24' alt='delete-icon' />
								</button>
							)}
					</div>
					<p className='text-sm font-medium mb-3 text-gray-800'>
						Select your investment type:
					</p>
					<div className='accordion space-y-4'>
						<InvestmentsAccordion
							investmentId={investment.id}
							investment={investment}
							handleValues={handleValues}
							removeAccordionData={removeAccordionData}
						/>
					</div>
				</div>
			))}

			{accordionCounter < 4 && (
				<button
					className='btn btn-primary-extalight w-full mb-6'
					onClick={() => {
						dispatch(incrementAccordionCounter())
						setFormData([...formData, { id: formData.length }])
						dispatch(addInvestment({ id: investments.length }))
					}}
				>
					Add Another Investment
				</button>
			)}

			<div className='text-right space-x-2'>
				<button className='btn btn-light' onClick={navigateToInvestmentForm}>
					Previous
				</button>
				<button
					className='btn btn-primary'
					type='submit'
					onClick={() => {
						counter = 0
						handleValidation()
					}}
				>
					Calculate
				</button>
			</div>
			{shouldShowModal && (
				<Modal
					shouldShowModal={shouldShowModal}
					setShouldShowModal={setShouldShowModal}
					result={result}
					title={`You could be subject to an estimated tax of ${getCurrencyCode(
						USD
					)}${numberCommasSeparator(
						estimatedTaxes
					)} unless you buy as much real estate as you sold!`}
					subtitle={`Note: You can buy multiple properties in your exchange OR improve something you buy (Improvement Exchange) and get credit for the improvements. Call us for details 866-944-1031`}
					interactBtn={true}
					prevBtn={prevBtn}
					nextBtn={nextBtn}
				/>
			)}
		</div>
	)
}

export default FindInvestment
