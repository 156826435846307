import { createSlice } from '@reduxjs/toolkit'
import * as asyncAction from './investmentForm.asyncAction'

const initialState = {
	currentInvestment: {},
	investmentFormStates: {},
	investmentFormFields: {},
	typeOfProperty: '',
	maritalStatus: '',
}

const slice = createSlice({
	name: 'investmentForm',
	initialState,
	reducers: {
		setInvestmentFormStates(state, action) {
			state.investmentFormStates =
				action.payload || initialState.investmentFormStates
		},
		setInvestmentFormFields(state, action) {
			state.investmentFormFields =
				action.payload || initialState.investmentFormFields
		},
		setTypeOfProperty(state, action) {
			state.typeOfProperty = action.payload || initialState.typeOfProperty
		},
		setMaritalStatus(state, action) {
			state.maritalStatus = action.payload || initialState.maritalStatus
		},
	},
	extraReducers: {
		[asyncAction.saveCurrentInvestments.fulfilled]: (state, action) => {
			state.currentInvestment = action.payload || initialState.currentInvestment
		},
	},
})
export default slice
export const { name, actions, reducer } = slice
