import React from 'react'
import isEmpty from 'lodash/isEmpty'
import Accordion from '../../../widgets/Accordian'
import { avatarGroup } from '../../../assets/images'

const FAQS = [
	{
		question: 'How can I qualify for tax-free property transactions?',
		answer:
			'Recent updates to the IRS code allow for tax-free sales and purchases of various properties under section 1031. Simply follow specific rules for selling and buying within 180 days and utilize a qualified intermediary.',
	},
	{
		question: 'What is a qualified intermediary?',
		answer:
			'We serve as your qualified intermediary. The IRS mandates using a qualified intermediary to transfer funds from sold to purchased properties to potentially qualify for tax-free exchange.',
	},
	{
		question: 'Can I receive legal or tax advice from you?',
		answer:
			'No. As per IRS regulations, your attorney, accountant, or escrow company cannot serve as your qualified intermediary, and vice versa. We collaborate with your legal and tax advisors to ensure a tax-free transaction.',
	},
	{
		question: 'Can I withdraw funds tax-free during property transactions? ',
		answer:
			'Absolutely. You can withdraw funds from the property being sold or purchased tax-free. Reach out to us for crucial timing and contract details.',
	},
	{
		question: `I've already sold my property. Can I still make it tax-free?`,
		answer: `Yes, you can convert your taxable sale into a tax-free exchange with simple paperwork if the sale hasn't closed yet. Contact us anytime before the closing date.`,
	},
	{
		question: 'Can I conduct multiple tax-free property transactions?',
		answer: `Certainly. There's no limit to the number of properties you can sell or buy tax-free. Clients often consolidate properties for investment or diversify into multiple replacements.`,
	},
	{
		question:
			'How can I retire with a mortgage-free home through tax-free selling?',
		answer:
			'With proper planning, you can sell your investment property tax-free, acquire your retirement residence, and own it mortgage-free.',
	},
	{
		question: 'What are the costs associated with a 1031 tax-free exchange?',
		answer: `We offer the lowest fees among bonded and insured accommodators. Additionally, on Reverse Exchanges only, our "Guaranteed Tax-Free or Half Fee" policy ensures a risk-free exchange. If the tax-free exchange isn't completed for any reason, we refund half of the fee.`,
	},
	{
		question: 'Can I Withdraw Funds Tax Free during Property Transactions?',
		answer:
			'Only in some circumstances. Please contact us for specific information. The best way to withdraw tax free funds from your property is at least 6 months prior to a sale or immediately after a purchase when you complete your standard exchange.',
	},
]

const handleOpenChatBox = () => {
	const HUBSPOT_WIDGET = window?.HubSpotConversations?.widget
	if (!isEmpty(HUBSPOT_WIDGET)) {
		const status = HUBSPOT_WIDGET.status()
		if (status.loaded) HUBSPOT_WIDGET.open()
		else HUBSPOT_WIDGET.load({ widgetOpen: true })
	}
}

const RoadmapFaq = () => (
	<div className='p-8 sm:p-12 md:p-24 bg-gradient-to-bl from-primary-600 to-primary-200 text-white flex flex-col gap-6 sm:gap-12'>
		<div className='font-work-sans-light text-43'>
			Frequently asked Questions
		</div>
		<div className='flex flex-col sm:flex-row gap-12'>
			<div className='flex-1'>
				{FAQS.map(({ question, answer }) => (
					<div key={question} className='px-3 py-6 border-b'>
						<Accordion
							title={question}
							titleClasses='font-work-sans-medium text-base'
						>
							<div className='font-work-sans-regular text-sm mt-2 text-icyBlue pr-12'>
								{answer}
							</div>
						</Accordion>
					</div>
				))}
			</div>
			<div className='flex flex-col gap-8 items-center bg-athensGray/10 w-full sm:w-1/3 p-8 justify-center'>
				<img src={avatarGroup} width={120} height={56} />
				<div className='text-center'>
					<div className='font-work-sans-medium text-xl'>
						Still have questions?
					</div>
					<div className='font-work-sans-regular text-lg mt-2'>
						Can’t find the answer you’re looking for? Please chat to our
						friendly team.
					</div>
				</div>
				<button
					className='btn bg-white rounded-full text-primary-600 font-work-sans-semibold sm:text-lg px-3 py-2 md:px-5 md:py-4'
					type='button'
					onClick={handleOpenChatBox}
				>
					Get in touch
				</button>
			</div>
		</div>
	</div>
)

export default RoadmapFaq
