import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	chatWindow: {
		isVisible: false,
	},
}
const slice = createSlice({
	name: 'chatWindowStates',
	initialState,
	reducers: {
		toggleActive: (state) => {
			state.chatWindow.isVisible = true
		},
		hideChatWindow: (state, action) => {
			state.chatWindow.isVisible = false
		},
	},
})
export default slice
export const { name, actions, reducer } = slice
