// To map investment types
export const INVESTMENT_TYPE = {
	0: {
		title: 'Single Family Rental',
		content:
			'This is a Fast growing Asset Class that provides 6-10% return on your money with some risks to return for credit worthiness and maintenance/ unexpected repairs.',
	},
	1: {
		title: 'Vacation Rental or Second Home',
		content:
			'The right location/market and property can return more than single-family rentals even if not rented regularly. You also get to use it for a tropical vacation!.',
	},
	2: {
		title: 'Specialized 1031 Passive Investments ',
		content:
			'A great choice for dependable Bond Like income ( or think of it like a REIT). No headaches, strong Fortune 500 tenants and an investment of $50,000 to millions for dependable cash flow.',
	},
	3: {
		title:
			'Family Home with Home Office or Rented Units/Guest House (ADU/Granny Flat)',
		content:
			'This can let you reinvest in residential property some of which is used for personal use, and the rest is considered the exchange property provided used for business purposes. Please call us for details.',
	},
	4: {
		title: 'Land Office Mixed Use, Medical or Multi Family',
		content: 'Often best to Find this with a 1031 Approved broker. ',
	},
	5: {
		title: 'Co-Op Apartment',
		content:
			'So long the lease has more than 30 years it works.. Apartments, commercial leases, mobile parks, etc. Please call us for details.',
	},
	6: {
		title: 'Commercial Property / Other',
		content: 'Often best to Find this with a 1031 Approved broker. ',
	},
}
