import Model from './Model'
import * as getStartedStates from './model.selectors'
import slice from './model.slice'

export const {
	name,
	actions: { shouldActivateIntroScreen },
	reducer,
} = slice

export const { selectGetStartedState } = getStartedStates
export default Model
