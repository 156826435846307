import StartApplication from './StartApplication'
import * as selectors from './affiliates.selectors'
import slice from './affiliates.slice'

export const { name, actions, reducer } = slice
export const {
	selectIsCompanyNameValidated,
	selectIsSlugValidated,
	selectUploadedImage,
	selectImageData,
} = selectors
export default StartApplication
