import FindInvestment from './FindInvestment'
import * as findInvestmentStateSelector from './findInvestment.selectors'
import slice from './findInvestment.slice'

export const {
	name,
	actions: {
		incrementAccordionCounter,
		decrementAccordionCounter,
		addInvestment,
		addInvestmentData,
		removeInvestment,
		updateInvestmentData,
		updateEstimatedTaxes,
		resetInvestments,
	},
	reducer,
} = slice

export const {
	selectAccordionCounter,
	selectInvestments,
	selectEstimatedTaxes,
} = findInvestmentStateSelector

export default FindInvestment
