import {
	FILES_STORAGE_CONTAINER_NAME,
	STORAGE_ACCOUNT_NAME,
} from './getEnvVars'
import {
	reinvestmentChoice1,
	reinvestmentChoice2,
	reinvestmentChoice3,
	reinvestmentChoice4,
	reinvestmentChoice5,
	reinvestmentChoice6,
} from '../assets/images'

// Navigation URLs
export const NavRoutes = {
	HOME: '/',
	DEFAULT_PATH: '*',
	MODEL: '/model',
	CURRENT_INVESTMENT: '/current-investment',
	INVESTMENT_FORM: '/current-investment/investment-form',
	FIND_INVESTMENTS: '/find-investments',
	MORE_DETAILS: '/more-details',
	SUMMARY: '/summary',
	TAX_REPORT: '/tax-report',
	MEETING_SCHEDULER: '/meeting-scheduler',
	AUTHENTICATION: '/agents',
	REFERRAL_LINK: '/referral-link',
	AFFILIATE_INTRODUCTION: '/affiliate-signup',
	AFFILIATE_START_APPLICATION: '/affiliate-signup/start-application',
	AFFILIATE_REQUEST_APPROVAL: '/affiliate-signup/request-approval',
	AFFILIATE_TERMS: '/affiliate-signup/terms',
	SIGN_UP_FORM_SUBMITTED: '/affiliate-signup/submitted',
	REINVESTMENT_CHOICES: '/reinvestment-choices',
	TAXREPORT: '/taxreport',
	ROADMAP: '/roadmap',
	QUALIFY: '/qualify',
}

export const HIDE_LEFT_SIDEBAR_ROUTES = [
	NavRoutes.HOME,
	NavRoutes.REINVESTMENT_CHOICES,
	NavRoutes.SIGN_UP_FORM_SUBMITTED,
	NavRoutes.TAXREPORT,
	NavRoutes.ROADMAP,
	NavRoutes.HOME,
	NavRoutes.QUALIFY,
]

export const HIDE_CHAT_WINDOW_ICON_ROUTES = [
	NavRoutes.MODEL,
	NavRoutes.AUTHENTICATION,
	NavRoutes.REFERRAL_LINK,
]

export const SHOW_CHAT_WINDOW_ROUTES = [
	NavRoutes.CURRENT_INVESTMENT,
	NavRoutes.INVESTMENT_FORM,
	NavRoutes.FIND_INVESTMENTS,
	NavRoutes.MORE_DETAILS,
	NavRoutes.SUMMARY,
	NavRoutes.MEETING_SCHEDULER,
]

export const SHOW_ALERT_BEFORE_UNLOAD_ROUTES = [
	NavRoutes.CURRENT_INVESTMENT,
	NavRoutes.INVESTMENT_FORM,
	NavRoutes.FIND_INVESTMENTS,
	NavRoutes.MORE_DETAILS,
	NavRoutes.SUMMARY,
	NavRoutes.MEETING_SCHEDULER,
	NavRoutes.AFFILIATE_START_APPLICATION,
	NavRoutes.AFFILIATE_REQUEST_APPROVAL,
	NavRoutes.AFFILIATE_TERMS,
]

export const START_EXCHANGE_URL = 'https://startmyexchange.com'

export const PLENTI = 'plenti'

export const AFFILIATE_STATUS = {
	APPROVED: 'Approved',
	REJECTED: 'Rejected',
	PENDING: 'Pending',
}

export const tempSlang = '7a6a6f1e-142c-4684-a4d7-1a8fce3647ca'
export const USD = 'USD'
export const COMMA_SEPRATOR = ','
export const NOTIFICATION_TRIGGER = 'Process_Tax_Report'
export const PLAY_VIDEO = 'Play video'
export const PLAY = 'play'

export const BUSY_INDICATORS = {
	DELETE_LOGO: 'deleteLogo',
	VALIDATE_UNIQUE_FIELDS: 'validateUniqueFields',
	CREATE_AFFILIATE: 'createAffiliate',
}

export const COLORS = {
	PRIMARY: 'primary',
	CONTRAST: 'contrast',
}

export const UNIQUE_FIELDS = {
	SLUG: 'slug',
	COMPANY_SLUG: 'companySlug',
	COMPANY_NAME: 'companyName',
}

export const DEFAULT_COLORS = {
	primary: '#0B5058',
	contrast: '#111813',
}

export const DEFAULT_VALUES = {
	EMPTY_STRING: '',
	ZERO: 0,
	EMPTY_ARRAY: [],
	BOOLEAN: { TRUE: true, FALSE: false },
	FAV_ICON: '%PUBLIC_URL%/favicon.ico',
}

export const SliderConstants = {
	MIN_VALUE: 0,
	MAX_VALUE: 10000000,
	STEP_COUNT: 10000,
	ZERO: 0,
	ONE: 1,
	HUNDRED: 100,
	DEFAULT_RANGE_1M: 1000000,
	DEFAULT_RANGE_400K: 400000,
}

export const GrowthWithExchangeConstants = {
	SALE_DATA_PRIMARY: [75000, 105000, 147000, 250800],
	SALE_DATA_SECONDARY: [56063, 59250, 62016, 65116],
	PURCHASE_DATA_PRIMARY: [300000, 480000, 672000, 940800],
	PURCHASE_DATA_SECONDARY: [300000, 360000, 378000, 396900],
	ZERO: 0,
	EIGHT: 8,
	TEN: 10,
	TWELVE: 12,
	SIX_HUNDRED: 600,
	DOLLAR: '$',
	TOP: 'top',
	END: 'end',
}

export const taxRate = 0.3

export const FORM_FIELDS = [
	{
		label: 'Your First Name',
		id: 'firstName',
		type: 'text',
		placeholder: 'Your First Name',
	},
	{
		label: 'Your Last Name',
		id: 'lastName',
		type: 'text',
		placeholder: 'Your Last Name',
	},
	{
		label: 'Your Email',
		id: 'email',
		type: 'email',
		placeholder: 'name@company.com',
	},
	{
		label: 'Your Phone Number',
		id: 'phoneNumber',
		type: 'tel',
		placeholder: '555-555-5555',
	},
]

export const COLOR_FIELDS = [
	{
		id: 'primaryHexCode',
		label: 'Your Primary Color',
	},
	{
		id: 'contrastHexCode',
		label: 'Your Contrast Color',
	},
]

export const supportedFormats = ['image/jpeg', 'image/jpg', 'image/gif']

export const MAX_MONETARY_LIMIT = 999999999.99

// Scroll into view - action object (have following props)
// Behavior: auto, smooth (Default: auto)
// Block: start, center, end, nearest
// inline: start, center, end, nearest (Default: nearest)
export const scrollActions = {
	block: 'start',
}

export const SessionKeys = {
	AGENT: 'agent',
	USER_ID: 'user_id',
	REFERRAL_ID: 'referral_id',
	AGENT_DETAILS: 'agent_details',
}

// Classes for styling error messages
export const ERROR_MESSAGE_CLASSES =
	'-z-10 text-red-500 text-xs bg-error-500 mt-[-10px] pt-3 pl-2 rounded-b-md'

export const RADIO_ERROR_MESSAGE_CLASSES =
	'text-red-500 text-xs bg-error-500 p-1 rounded'

// Constants for find investment & summary calculation
export const EQUITY_FACTOR = 0.05
export const ESTIMATED_TAX_FACTOR = 0.35
export const PROPERTY_APPRECIATION_FACTOR = 0.03
export const NUMBER_OF_MONTHS = 12
export const TWO_DECIMAL_PLACE = 2

// Error messages
export const ERROR_MESSAGES = {
	FIRST_NAME_VALIDATE_SPACE:
		'Please enter valid first name: should not contain spaces',
	FIRST_NAME_VALIDATE_REGREX: 'Please enter valid first name',
	FIRST_NAME_REQUIRED: 'Please enter first name',
	LAST_NAME_VALIDATE_SPACE:
		'Please enter valid last name: should not contain spaces',
	LAST_NAME_VALIDATE_REGREX: 'Please enter valid last name',
	LAST_NAME_REQUIRED: 'Please enter last name',
	EMAIL_VALIDATE_SPACE: 'Please enter in the format: name@example.com',
	EMAIL_REQUIRED: 'Please enter your email',
	MOBILE_NUMBER: 'Please enter valid 10 digit phone number',
	CURRENT_POSITION: 'Please enter valid position',
	COMPANY_NAME: 'Please enter valid company name',
	MIN_LENGTH_3: 'Minimum 3 characters required',
	MIN_LENGTH_2: 'Minimum 2 characters required',
	MAX_LENGTH_255: 'Maximum 255 characters required',
	URL_ALREADY_IN_USE: 'This URL is already in use. Please use a different URL.',
	LOGO_TOO_LARGE: 'Logo size is too large, maximum 50 MB logo allowed.',
	INVALID_FORMAT: 'Invalid logo format, allowed file types are JPG or GIF.',
	INVALID_FILE_DIMENSIONS:
		'Logo dimensions are not valid, allowed max. 800 X 400px.',
	COMPANY_NAME_ALREADY_IN_USE:
		'This Company Name is already in use. Please use a different Company Name.',
}

export const RGBAToHexA = ({ r, g, b }) => {
	const red = r.toString(16).padStart(2, '0')
	const green = g.toString(16).padStart(2, '0')
	const blue = b.toString(16).padStart(2, '0')

	return `#${red}${green}${blue}`
}

export const getRequiredErrorMessage = (field) =>
	`Please ${field === 'logo' ? 'upload' : 'enter'} your ${field}`

export const getFileSize = (fileSize) => {
	const fileSizeInkb = (fileSize / 1024).toFixed(2)
	if (fileSizeInkb > 1024) {
		const fileSizeInMb = (fileSizeInkb / 1024).toFixed(2)
		return `${fileSizeInMb} MB`
	} else return `${fileSizeInkb} KB`
}

export const FREQUENTLY_ASKED_QUESTIONS = [
	{
		question: 'How can Plenti help my business retain clients?',
		answer:
			"Retention matters. If you have a bucket of clients who sell periodically, whether you are a mortgage rep, property manager or real estate agent, clients don’t always tell you. With the <span className='font-semibold'>Plenti Exchange™</span>, because you are involved in planning your clients' exchange with them and that is what triggers the need for your services.",
		type: 'html',
	},
	{
		question:
			'What does it cost to join the Plenti Exchange™ Marketing Program?',
		answer:
			"At the moment, it's free! You can join for free and use all the tools at no charge. Plenti will even refer you to clients that may want your services and not charge a transaction fee. In the future this may change depending on whether your account is active enough. You can choose to upload your contacts to Plenti for us to outreach your farm or find new clients, but if you choose not to do so, there may be a min usage fee charged monthly depending on the value you are receiving. Don’t worry… we’ll let you know!",
	},
	{
		question: 'How will Plenti help me find new clients for my business?',
		answer:
			'If you work with any real estate investors, they need to know about a 1031 exchange to better educate them on options to sell tax free! Even though 2 ml properties qualify for an exchange, only 250,000 actually exchange because they simply are not aware.',
	},
	{
		question: 'Who is liable for information on the 1031 exchange?',
		answer: 'Plenti is liable.',
	},
	{
		question:
			'Who does what? You mention Plenti and bring prospective clients to the platform.',
		answer:
			'Remind them before they buy or sell to use the 3 step process. Follow up with them once you receive a notification they have stated the process.',
	},
	{
		question: 'Is the referral fee you pay allowed under REPSA?',
		answer:
			'Yes, REPSA does not apply to commercial transactions which exchanges are considered.',
	},
	{
		question: "What if my client doesn't want to do an exchange?",
		answer:
			'No worries, they will thank you for asking and thinking about them, and they may buy a service from you or any other affiliated network provider.',
	},
	{
		question: 'When and how do I get paid?',
		answer:
			'Every quarter there will be a review and payment. See our sample partner agreement.',
	},
]

const STORAGE_BASE_URL = `https://${STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${FILES_STORAGE_CONTAINER_NAME}`

export const DOC_URL = {
	SAMPLE_DOC: {
		TAX_REPORT_URL: `${STORAGE_BASE_URL}/Tax Report.pdf`,
		PARTNER_AGREEMENT: `${STORAGE_BASE_URL}/Partner Agreement.pdf`,
		LEAD_ACTIVITY_REPORT: `${STORAGE_BASE_URL}/Lead Activity Report.pdf`,
	},
	PRIVACY_POLICY: `${STORAGE_BASE_URL}/Privacy Policy.pdf`,
	TERMS_OF_USE: `${STORAGE_BASE_URL}/Terms of Use.pdf`,
	HOME_PAGE_VDO: `${STORAGE_BASE_URL}/homepage-video.mp4`,
	SAMPLE_EXCHANGE_AGREEMENT: `${STORAGE_BASE_URL}/Sample Exchange Agreement - Plenti.pdf`,
	QUALIFIED_ESCROW_AGREEMENT: `${STORAGE_BASE_URL}/Qualified Escrow Agreement.pdf`,
	OUR_BONDING: `${STORAGE_BASE_URL}/Our Bonding.pdf`,
	AXOS_FDIC: `${STORAGE_BASE_URL}/Axos FDIC.pdf`,
	STANDARD_EXCHANGE: `${STORAGE_BASE_URL}/Standard Exchange.pdf`,
	REVERSE_EXCHANGE: `${STORAGE_BASE_URL}/Reverse Exchange.pdf`,
	IMPROVEMENT_EXCHANGE: `${STORAGE_BASE_URL}/Improvement Exchange.pdf`,
}

export const VIDEOS_URL = {
	WHAT_IS_1031_EXCHANGE: `${STORAGE_BASE_URL}/What is 1031 Exchange.mp4`,
	TAX_GUIDE_1031_EXCHANGE: `${STORAGE_BASE_URL}/Tax Guide 1031 Exchange.mp4`,
	HOW_IT_WORKS: `${STORAGE_BASE_URL}/How It Works.mp4`,
}

export const reviews = [
	{
		stars: 5,
		details:
			'I’ve been telling everyone I know about how great my first exchange experience was with you! I could not be more pleased at how everything was handled from the very beginning. Your support and professionalism are outstanding!',
		author: 'Gil S.',
	},
	{
		stars: 5,
		details: `You saved my client over $14,000 in less than 1 hour! The prompt attention I received was unbelievable! There is no doubt your team knows exactly what you're doing`,
		author: 'Jim K.',
	},
	{
		stars: 5,
		details: `The first exchange company I used was nothing like you – they actually lost $20,000 for 3 days!!! They had it floating around somewhere and just had no idea where it went! With you, I never once doubted the security of my funds. Thank you for an absolutely stress-free exchange.`,
		author: 'Tony D.',
	},
	{
		stars: 5,
		details: `Thanks for the expert guidance you gave me, David. Your staff was great. Even giving you less than 24 hours notice to wire out my funds was no problem. There is no doubt that I will be opening my next exchange with you.`,
		author: 'Gerald W.',
	},
	{
		stars: 5,
		details: `Your creative solutions for my clients’ complex exchange transaction saved her from having to pay over $50,000 in capital gains taxes! Thank you for making a complicated situation simple.`,
		author: 'Ben B.',
	},
	{
		stars: 5,
		details: `I love working with your team! I always receive prompt attention & your staff accommodates all my needs. I've just opened my 10th exchange with you and wouldn't think of using anyone else. I would refer my friends and fellow investors to you, but they all already use you!`,
		author: 'Judy G.',
	},
	{
		stars: 5,
		details:
			'I’ve been telling everyone I know about how great my first exchange experience was with you! I could not be more pleased at how everything was handled from the very beginning. Your support and professionalism are outstanding!',
		author: 'Gil S.',
	},
	{
		stars: 5,
		details: `You saved my client over $14,000 in less than 1 hour! The prompt attention I received was unbelievable! There is no doubt your team knows exactly what you're doing`,
		author: 'Jim K.',
	},
	{
		stars: 5,
		details: `The first exchange company I used was nothing like you – they actually lost $20,000 for 3 days!!! They had it floating around somewhere and just had no idea where it went! With you, I never once doubted the security of my funds. Thank you for an absolutely stress-free exchange.`,
		author: 'Tony D.',
	},
	{
		stars: 5,
		details: `Thanks for the expert guidance you gave me, David. Your staff was great. Even giving you less than 24 hours notice to wire out my funds was no problem. There is no doubt that I will be opening my next exchange with you.`,
		author: 'Gerald W.',
	},
	{
		stars: 5,
		details: `Your creative solutions for my clients’ complex exchange transaction saved her from having to pay over $50,000 in capital gains taxes! Thank you for making a complicated situation simple.`,
		author: 'Ben B.',
	},
	{
		stars: 5,
		details: `I love working with your team! I always receive prompt attention & your staff accommodates all my needs. I've just opened my 10th exchange with you and wouldn't think of using anyone else. I would refer my friends and fellow investors to you, but they all already use you!`,
		author: 'Judy G.',
	},
]

export const reinvestmentChoiceSide = [
	{
		title: 'Vacation home',
		description:
			'Many investors opt to buy a vacation home using the proceeds from their 1031 Exchange. Not only can this offer you your own personal spot in paradise, but the short-term rental market can also be a viable investment strategy, offering cashflow. 1031 Exchange rules allow owners to use the property part of the year, giving you a place to escape, cash-flow opportunity, and an appreciating investment. Win, win win!',
		image: reinvestmentChoice1,
	},
	{
		title: 'Management-free passive income',
		description:
			'Tired of the Three T’s (tenants, toilets, and trash)? We hear it all the time, and luckily there are lots of 1031-reinvestment options that do NOT require management! An investment called a Triple Net Lease allows you to be the sole-owner of a single-tenant building that’s rented to a Fortune 500 company, like Starbucks, or Dollar General (for example). These companies (your tenants) are responsible for the repairs, insurance and property tax payments, and will sometimes even offer rent guarantees for up to 15 years! \n	The second investment type is called a Delaware Statutory Trust which allows you to own a fractional interest in large commercial properties that are managed by a "Sponsor" who takes care of everything, and simply sends you a check every month.',
		image: reinvestmentChoice2,
	},
	{
		title: 'Diversify and move up',
		description:
			'Got a lot of equity? Sometimes, when a property has gone up considerably in value and a lot of equity has been built up, that equity is not generating as much monthly income as it could be if it were invested somewhere else. By using a 1031 Exchange, you can sell your property, and reinvest into new properties that offer more monthly cash-flow, like Turn-Key single-family rentals, short-term / vacation rentals, or commercial investments!',
		image: reinvestmentChoice3,
	},
	{
		title: 'Move into your investment in a few years',
		description:
			'Did you know you can move into your investment property after a few years without triggering a tax-bill? While you must purchase your property solely for investment purposes, knowing your options for the future use of the property is always best, and can give you a great opportunity to use an investment property you own in a location where you’d want to live! This is subject to specific IRS rules and requirements and needs to be reviewed before you make any purchases.',
		image: reinvestmentChoice4,
	},
	{
		title: 'Get cash without triggering a tax bill',
		description:
			'Get the cash you need without triggering a tax-bill. If you take money at closing (referred to as "Cash Boot"), you’ll pay capital gains taxes on it. However, if you reinvest it into new property, and pull it out using a Cash-Out Refinance Loan, or Line of Credit instead, you can avoid triggering those capital gains taxes, and still have access to your cash.',
		image: reinvestmentChoice5,
	},
	{
		title: 'More ways to make 1031 work for you',
		image: reinvestmentChoice6,
		type: 'list',
	},
]

export const exchangeInfo = [
	{
		title: '1031 Exchange: How it works',
		description:
			'A 1031 Exchange is a little-known tax law that helps investors like you reinvest into a better property without the huge tax bill. If this sounds exciting, but too good to be true, you’ve come to the right place. Plenti Financial is a 1031 Accommodator as required by the IRS to facilitate your exchange.',
		buttonText: PLAY_VIDEO,
	},
	{
		title: 'Does your property qualify?',
		description:
			'Almost every kind of real estate is considered “like-kind” and can be exchanged for any other real estate, including vacant land for apartments, a rental house for a shopping center, an office building for a leasehold interest with almost 30 years or more remaining, as long as you hold them for investment or business use.\n Check with us on the specific properties.',
		buttonText: 'See if you qualify',
		openModal: true,
	},
	{
		title: 'Options for reinvesting',
		description:
			'The options for reinvesting with a 1031 Exchange are vast and flexible. Whether you’re looking for a management-free option, a vacation rental, investments similar to shares in a REIT, or something to eventually move into, there’s a lot you can do.',
		buttonText: 'Reinvestment options',
		targetPage: NavRoutes.REINVESTMENT_CHOICES,
		tab: '_self',
	},
	{
		title: 'Model your exchange using our app',
		description:
			'We’ve taken our almost 50 years of experience and built a modeling tool that enables you to enter specifics around your property, your reinvestment goals, and see what your exchange could look like. ',
		buttonText: 'Start modeling',
		targetPage: NavRoutes.MODEL,
		tab: '_blank',
	},
	{
		title: 'How much does it cost?',
		component: true,
	},
]

export const userStoryData = [
	{
		text: 'Experienced Exchange Accommodator with 50+ years experience facilitating exchanges ',
	},
	{
		text: 'Tools and resources to help you make informed decisions and model out potential investment scenarios ',
	},
	{
		text: 'Dedicated team of experts available to answer questions and provide guidance throughout the process',
	},
	{
		text: 'Personalized referrals for experienced service providers in real estate and mortgage who can help you buy and sell',
	},
	{
		text: 'Affordable and accessible option for investors of all experience levels',
	},
]

export const ExchangeCostCards = [
	{
		price: '$1,195',
		exchangeType: 'Standard Exchange',
		bundleAndSave: 'Bundle & Save up to $900',
		detail: [
			'Sell first and buy second',
			'Save $10,000s in taxes',
			'Grow your real estate portfolio using your real estate equity.',
		],
	},
	{
		price: '$5,995',
		exchangeType: 'Reverse Exchange',
		bundleAndSave: 'Bundle & Save up to $3,500',
		detail: [
			'Buy first and sell second',
			'Take advantage of immediate investment opportunities',
			'Use new purchases to save 30% or more in taxes.',
		],
	},
	{
		price: '$5,995',
		exchangeType: 'Improvement Exchange',
		bundleAndSave: 'Bundle & Save up to $3,500',
		detail: [
			'Improve the property you purchase with exchange funds',
			'Skip the taxes and use all the proceeds to improve a new purchase.',
		],
	},
]

export const SECTION_IDS = {
	WAYS_TO_GET_STARTED_SECTION: 'waysToGetStarted',
	WHY_PLENTI_FINANCIAL_SECTION: 'whyPlentiFinancial',
}

export const PROPERTY_QUALIFY_STEPS = [
	{
		label: 'Property Type',
		name: 'propertyType',
		error: {
			heading: 'Select a Property Type',
			description: 'Please select a property type to continue',
		},
		subSteps: [
			{
				label: 'Primary Residence',
				name: 'primary',
				isQualified: false,
			},
			{
				label: 'Former Residence',
				name: 'former',
				description: 'Not currently living there',
				isQualified: true,
			},
			{
				label: 'Rental Property',
				name: 'rental',
				description:
					'Single Family, Condo, Mixed-Use, Commercial, Land, Co-op Apartments, Duplex, Triplex, etc.',
				isQualified: true,
			},
			{
				label: 'Other',
				name: 'other',
				isQualified: false,
			},
		],
	},
	{
		label: 'More Details',
		name: 'moreDetails',
		subSteps: {
			heading: 'Please answer the following questions...*',
			fields: {
				'Primary Residence': [
					{
						name: 'currentMaritalStatus',
						label: 'Current Marital Status',
						options: ['Currently Divorcing', 'Divorced', 'Single', 'Married'],
						error: 'Select Marital status',
					},
					{
						name: 'propertyValueIncrease250k',
						label:
							'Has your property gone up more than $250,000 since you purchased it?',
						options: ['No', 'Yes'],
						error: 'Select Property Value Increase status',
					},
					{
						name: 'propertyValueIncrease500k',
						label:
							'Has your property gone up more than $500,000 since you purchased it?',
						options: ['No', 'Yes'],
						error: 'Select Property Value Increase status',
					},
					{
						name: 'propertyUsage',
						label:
							'Does your property have a Home Office, Airbnb/VRBO, or Business use?',
						options: ['No', 'Yes'],
						error: 'Select Property Usage status',
					},
					{
						name: 'propertyRentalStatus',
						label:
							'Is the property, or any portion Of it, currently being used as a rental?',
						description:
							'Granny Flat/Garage Apartment, Apartment Duples, Triplex, etc.',
						options: ['No', 'Yes'],
						error: 'Select Property Rental status',
					},
					{
						name: 'propertyVacantOrUsedForAgriculature',
						label:
							'Is the property vacant land or being used for agricultural purposes?',
						options: ['No', 'Yes'],
						error: 'Select Property Type status',
					},
				],
				Other: [
					{
						label:
							'Is your property being used for Airbnb/VRBO, or Business Use?',
						options: ['No', 'Yes'],
						name: 'propertyUsage',
						error: 'Select Property Usage status',
					},
					{
						label:
							'Is the property, or any portion of it, currently being used as a Rental?',
						description:
							'(Granny Flat/Garage Apartment, Duplex, Triplex, etc.)',
						options: ['No', 'Yes'],
						name: 'propertyRentalStatus',
						error: 'Select Property Rental status',
					},
					{
						label:
							'Is the property Vacant Land, or used for Agricultural Purposes?',
						options: ['No', 'Yes'],
						name: 'propertyVacantOrUsedForAgriculature',
						error: 'Select Property Type status',
					},
					{
						label:
							'Is the property inherited and not currently being used as your primary residence?',
						options: ['No', 'Yes'],
						name: 'propertyInheritedStatus',
						error: 'Select Property Inherited status',
					},
					{
						label: 'Is the property currently sitting vacant?',
						options: ['No', 'Yes'],
						name: 'propertyVacantStatus',
						error: 'Select Property Vacant status',
					},
				],
			},
		},
	},
	{
		label: 'Done!',
		name: 'finalStep',
	},
]

export const DIQ_QUESTION_MAPPING = {
	propertyType: 'DIQPropertyType',
	currentMaritalStatus: 'DIQMaritalStatus',
	propertyValueIncrease250k: 'DIQPropertyGoneUp250kSincePurchased',
	propertyValueIncrease500k: 'DIQPropertyGoneUp500kSincePurchased',
	propertyUsage: 'DIQPropertyHasHomeOfficeAirBnbVrboBusinessUse',
	propertyRentalStatus: 'DIQPropertyOrAnyPortionBeingUsedAsRental',
	propertyVacantOrUsedForAgriculature:
		'DIQPropertyIsVacantLandOrAgriculturalUse',
	propertyInheritedStatus:
		'DIQPropertyInheritedAndNotCurrentlyUsedAsPrimaryResidence',
	propertyVacantStatus: 'DIQPropertyCurrentlyVacant',
}
