import React from 'react'

const RoadmapFooter = () => (
	<div className='bg-mischka text-gray-500 font-work-sans-regular text-xs p-4 text-center'>
		© 2024 Plenti Exchange.com, a division of SellTaxFree Replete Enterprises,
		Inc.
	</div>
)

export default RoadmapFooter
