import React from 'react'
import { isEmpty } from 'lodash'

// To display 'What kind of property is this?' question in 'Investment Form.js'
export const propertyTypes = [
	'My Residence',
	'Single Family Rental',
	'Vacation Rental or Second Home',
	'CO-OP Apartment',
	'Family Home with Home office',
	'Rented Units/Guest House (ADU/Granny Flat)',
	'Specialized 1031 Passive Investment',
	'Land, Office, Mixed Use, Medical or Multi- Family',
	'Commercial Property, Other NNN etc.',
]

// To display 'Marital Status' Dropdown in 'Investment-Form.js'
export const maritalStatusOptions = [
	'Married',
	'Single',
	'Divorced/Separated',
	'Widowed',
]

export const getMaritalStatus = maritalStatusOptions.map((state, id) => (
	<option key={id} value={state}>
		{state}
	</option>
))
export const getPropertyTypes = propertyTypes.map((eachProperty, id) => (
	<option key={id} value={eachProperty}>
		{eachProperty}
	</option>
))

// Formik field strings values
export const formerResidenceProp = ['isFormerResidence']
export const maritalStatusProp = ['maritalStatus']
export const propertyUseForProps = [
	'isPropertyCommercial',
	'isPropertyRental',
	'isPropertyInUse',
]
export const propertyWorthProps = [
	'hasPropertyGoneUpMT250K',
	'hasPropertyGoneUpMT500K',
]

export const resetAllValues = (
	setFieldValue,
	setFieldTouched,
	formerResidenceProp,
	maritalStatusProp,
	propertyWorthProps,
	propertyUseForProps
) => {
	const [isFormerResidence] = formerResidenceProp
	setFieldValue(isFormerResidence, '')
	setFieldTouched(isFormerResidence, false)
	resetMaritalStatus(
		setFieldValue,
		setFieldTouched,
		maritalStatusProp,
		propertyWorthProps,
		propertyUseForProps
	)
}
export const resetMaritalStatus = (
	setFieldValue,
	setFieldTouched,
	maritalStatusProp,
	propertyWorthProps,
	propertyUseForProps
) => {
	const [maritalStatus] = maritalStatusProp
	const [isPropertyMoreThanQuarterMil, isPropertyMoreThanHalfMil] =
		propertyWorthProps
	setFieldValue(maritalStatus, '')
	setFieldValue(isPropertyMoreThanQuarterMil, '')
	setFieldValue(isPropertyMoreThanHalfMil, '')
	setFieldTouched(maritalStatus, false)
	setFieldTouched(isPropertyMoreThanQuarterMil, false)
	setFieldTouched(isPropertyMoreThanHalfMil, false)
	resetPurchasedProperty(setFieldValue, setFieldTouched, propertyUseForProps)
}
export const resetPurchasedProperty = (
	setFieldValue,
	setFieldTouched,
	props
) => {
	const [isPropertyCommercial, isPropertyRental, isPropertyInUse] = props
	setFieldValue(isPropertyCommercial, '')
	setFieldValue(isPropertyRental, '')
	setFieldValue(isPropertyInUse, '')
	setFieldTouched(isPropertyCommercial, false)
	setFieldTouched(isPropertyRental, false)
	setFieldTouched(isPropertyInUse, false)
}
export const setInitialValues = (value) => (!isEmpty(value) ? value : '')
