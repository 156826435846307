import { createAsyncThunk } from '@reduxjs/toolkit'
import doAsync from '../../infrastructure/doAsync'
import { BUSY_INDICATORS } from '../../utils/constants'

const { VALIDATE_UNIQUE_FIELDS, CREATE_AFFILIATE, DELETE_LOGO } =
	BUSY_INDICATORS

export const validateAffiliateKeywords = createAsyncThunk(
	'validate',
	async (params, thunkArgs, { useCaching, noBusySpinner } = {}) =>
		await doAsync({
			url: 'affiliates/validate',
			httpConfig: { body: JSON.stringify(params) },
			useCaching,
			noBusySpinner,
			httpMethod: 'post',
			errorMessage: `Unable to validate ${params.key}. Please try again later.`,
			busyIndicatorName: VALIDATE_UNIQUE_FIELDS,
			...thunkArgs,
		})
)

export const deleteLogo = createAsyncThunk(
	'delete-logo',
	async (logoName, thunkArgs, { useCaching, noBusySpinner } = {}) => {
		await doAsync({
			url: `image?imageName=${logoName}`,
			httpMethod: 'delete',
			useCaching,
			noBusySpinner,
			busyIndicatorName: DELETE_LOGO,
			errorMessage: 'Unable to delete logo. Please try again later.',
			...thunkArgs,
		})
	}
)

export const createAffiliate = createAsyncThunk(
	'create-affiliate',
	async (params, thunkArgs, { useCaching, noBusySpinner } = {}) =>
		await doAsync({
			url: `affiliates`,
			httpMethod: 'post',
			httpConfig: { body: JSON.stringify(params) },
			useCaching,
			noBusySpinner,
			busyIndicatorName: CREATE_AFFILIATE,
			errorMessage: 'Unable to Create Affiliate. Please try again later.',
			...thunkArgs,
		})
)
