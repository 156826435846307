import React, { useState, useEffect } from 'react'
import { landingLeftBg, landingRightBg } from '../../assets/images'
import { landingBlub, landingHouse } from '../../assets/gifs'
import {
	greenTickMark,
	bellRingingIcon,
	poweredByPlenti,
} from '../../assets/icons'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	shouldActivate,
	selectCurrentInvestmentState,
} from '../../widgets/NavBar'
import { actions } from './model.slice'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { NavRoutes, PLENTI, SessionKeys } from '../../utils/constants'
import { updateProgressPercentage } from '../../widgets/CircularProgressIndicator'
import { IntroModal } from '../../widgets/Modal'
import AgentDetailsForm from './AgentDetailsForm'
import IntroQuestion from './IntroQuestion'
import CustomerDetailForm from './CustomerDetailsForm'
import { selectGetStartedState } from './model.selectors'
import {
	useQuery,
	isObjectEmpty,
	isSessionAvailable,
	getNavigationRoute,
} from '../../utils/methods'
import { errorNotification } from '../../widgets/ToastNotification'
import { selectCustomerInfo } from './CustomerDetailsForm/customerDetails.selectors'
import * as customerSlice from './CustomerDetailsForm/customerDetails.slice'
import { getAgentInfo } from './CustomerDetailsForm/customerDetails.asyncActions'
import { NavLinkStates } from '../../utils/constant.enum'
import { selectAffiliateSlug } from '../Affiliates/affiliates.selectors'

const { MODEL } = NavRoutes

export default function Model() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const isAffiliateSite = affiliateSlug !== PLENTI
	const queryParams = useQuery()
	const { shouldActivateIntroScreen } = actions
	const { resetToInititalState } = customerSlice.actions
	const { AGENT_DETAILS, USER_ID, REFERRAL_ID } = SessionKeys
	const customerResponseData = useSelector(selectCustomerInfo)
	const [modalsVisibility, setModalsVisibility] = useState({
		shouldShowGetStartedModal: false,
		shouldShowIntroQuestionModal: false,
		shouldShowAgentDetailsModal: false,
		shouldShowCustomerDetailsModal: false,
		errorMessage: '',
	})
	const { CURRENT_INVESTMENT } = NavRoutes
	const getStarted = useSelector(selectGetStartedState)
	const isCurrentInvestmentVisited = useSelector(
		selectCurrentInvestmentState
	).isVisited
	const navigateToCurrentInvestment = () => {
		const errorMessage = modalsVisibility.errorMessage
		if (errorMessage) errorNotification(errorMessage)
		else {
			dispatch(shouldActivate(NavLinkStates.CURRENT_INVESTMENT))
			if (!isCurrentInvestmentVisited) dispatch(updateProgressPercentage())
			navigate(getNavigationRoute(affiliateSlug, CURRENT_INVESTMENT))
			if (isSessionAvailable(AGENT_DETAILS))
				window.sessionStorage.removeItem(AGENT_DETAILS)
			if (window) {
				const HUBSPOT_WIDGET = window.HubSpotConversations.widget
				const status = HUBSPOT_WIDGET.status()
				if (status.loaded) {
					HUBSPOT_WIDGET.remove()
					HUBSPOT_WIDGET.refresh()
				} else HUBSPOT_WIDGET.load()
			}
		}
	}
	const result = {
		icon: bellRingingIcon,
		iconBackground: 'bg-orange-300/50 ring-8 ring-orange-100',
	}
	useEffect(() => {
		const agentID = queryParams.get('agents')
		const getAuthCode = queryParams.get('code')
		const isError = queryParams.get('error')
		const errorDescription = queryParams.get('error_description')
		const { id: userID } = customerResponseData
		if (getAuthCode && isObjectEmpty(customerResponseData)) {
			setModalsVisibility((prevState) => ({
				...prevState,
				shouldShowCustomerDetailsModal: true,
			}))
		} else if (agentID) {
			dispatch(getAgentInfo({ agentID })).then(async ({ payload }) => {
				if (payload) {
					const errorMessage = payload.replace(/\b\w/g, (char) =>
						char.toUpperCase()
					)
					if (errorMessage) {
						setModalsVisibility((prevState) => ({
							...prevState,
							errorMessage,
						}))
						errorNotification(errorMessage)
					}
				}
			})
			if (isSessionAvailable(REFERRAL_ID))
				window.sessionStorage.removeItem(REFERRAL_ID)
			window.sessionStorage.setItem(REFERRAL_ID, agentID)
			dispatch(shouldActivateIntroScreen(true))
		} else if (isError) {
			errorNotification(errorDescription)
		} else if (userID) {
			window.sessionStorage.setItem(USER_ID, userID)
		}
	}, [
		dispatch,
		shouldActivateIntroScreen,
		queryParams,
		customerResponseData,
		REFERRAL_ID,
		USER_ID,
	])

	return (
		<div
			className={`xl:px-22 xl:pb-22 px-12 pb-12 ${
				isAffiliateSite ? 'xl:pt-7 p-2' : 'xl:pt-22 pt-12'
			}`}
		>
			{isAffiliateSite && (
				<div className='flex justify-end xl:pb-7 pb-1'>
					<img src={poweredByPlenti} alt='Powered By Plenti Logo' />
				</div>
			)}
			<HelmetProvider>
				<Helmet>
					<title>Plenti - Exchange It :: Intro</title>
				</Helmet>
			</HelmetProvider>
			<>
				{!getStarted ? (
					<>
						<div className='grid grid-cols-12 border border-gray-300 rounded-lg mb-6 overflow-hidden'>
							<div className='col-span-12 lg:col-span-7 2xl:col-span-6 px-16 py-16 flex flex-col justify-center'>
								<h3 className='text-primary-600 text-3xl 2xl:text-4xl mb-4 font-bold'>
									The Plenti Exchange
									<sup className='text-base 2xl:text-xl'>TM</sup> App
								</h3>
								<h1 className='text-gray-900 text-4xl font-bold mb-4'>
									Real Estate Tax Free Investing Made Simple
								</h1>
								<p className='text-gray-600 text-base mb-8'>
									What is a 1031 Exchange? An exchange is really just a
									reinvestment. This app will show you:
								</p>
								<ul className='space-y-7'>
									<li className='flex'>
										<div className='icon w-7 h-7 grid place-content-center mr-3 rounded-full bg-teal-100/75'>
											<img
												src={greenTickMark}
												width='14'
												alt='green check mark'
											/>
										</div>
										<span className='text-gray-700'>
											How to qualify for bigger, better property
										</span>
									</li>
									<li className='flex'>
										<div className='icon w-7 h-7 grid place-content-center mr-3 rounded-full bg-teal-100/75'>
											<img
												src={greenTickMark}
												width='14'
												alt='green check mark'
											/>
										</div>
										<span className='text-gray-700'>
											Keep monthly mortgage costs down
										</span>
									</li>
									<li className='flex'>
										<div className='icon w-7 h-7 grid place-content-center mr-3 rounded-full bg-teal-100/75'>
											<img
												src={greenTickMark}
												width='14'
												alt='green check mark'
											/>
										</div>
										<span className='text-gray-700'>
											Generate more rental income
										</span>
									</li>
									<li className='flex'>
										<div className='icon w-7 h-7 grid place-content-center mr-3 rounded-full bg-teal-100/75'>
											<img
												src={greenTickMark}
												width='14'
												alt='green tick icon'
											/>
										</div>
										<span className='text-gray-700'>
											What to reinvest in to protect all your profits without
											paying tax
										</span>
									</li>
								</ul>
							</div>
							<div className='hidden lg:col-span-5 2xl:col-span-6 lg:flex justify-end items-end relative'>
								<img src={landingRightBg} alt='intro right background' />
								<img
									src={landingBlub}
									className='absolute p-16 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-10/12 2xl:w-7/12 rounded-full bg-white border-gray-200 border-2'
									alt='bulb image'
								/>
							</div>
						</div>

						<button
							className='btn btn-primary w-full'
							onClick={() => {
								if (window.sessionStorage.getItem('referral_id')) {
									setModalsVisibility({
										...modalsVisibility,
										shouldShowIntroQuestionModal: false,
									})
									dispatch(shouldActivateIntroScreen(true))
								} else {
									window.sessionStorage.clear()
									dispatch(resetToInititalState())
									setModalsVisibility({
										...modalsVisibility,
										shouldShowIntroQuestionModal: true,
									})
								}
							}}
						>
							Continue
						</button>
					</>
				) : (
					<>
						<div className='grid grid-cols-12 border border-gray-300 rounded-lg mb-6 overflow-hidden'>
							<div className='hidden lg:col-span-5 2xl:col-span-6 lg:flex relative'>
								<img src={landingLeftBg} alt='Get Started left background' />
								<img
									src={landingHouse}
									className='absolute p-16 right-1/2 top-1/2 translate-x-1/2 -translate-y-1/2 w-10/12 2xl:w-7/12 rounded-full bg-white border-gray-200 border-2'
									alt='house gif'
								/>
							</div>
							<div className='col-span-12 lg:col-span-7 2xl:col-span-6 px-16 py-16 flex flex-col justify-center'>
								<h1 className='text-gray-900 text-4xl font-bold mb-4'>
									How It Works
								</h1>
								<p className='text-gray-600 text-base mb-8'>
									The Plenti Exchange
									<sup className='font-features sups'>TM</sup> App walks you
									through the process step-by-step and includes all
									IRS-approved* tax rules built in!
								</p>
								<p className='text-gray-600 text-base mb-8'>
									Click your way to serious wealth building in minutes! Get
									started!
								</p>
								<p className='text-gray-600 text-sm'>
									*Using the IRS Section 1031 Tax Break
								</p>
							</div>
						</div>

						<div className='flex gap-4'>
							<button
								className='btn btn-light'
								onClick={() => {
									const url = getNavigationRoute(affiliateSlug, MODEL)
									window.history.replaceState({}, document.title, url)
									dispatch(shouldActivateIntroScreen(false))
								}}
							>
								Previous
							</button>
							<button
								className='btn btn-primary w-full'
								onClick={navigateToCurrentInvestment}
							>
								Get Started Now
							</button>
						</div>
					</>
				)}
			</>
			{modalsVisibility.shouldShowIntroQuestionModal && (
				<IntroModal
					shouldShowModal={modalsVisibility.shouldShowIntroQuestionModal}
					result={result}
					title={`Quick Question`}
				>
					<IntroQuestion
						modalsVisibility={modalsVisibility}
						setModalsVisibility={setModalsVisibility}
					/>
				</IntroModal>
			)}
			{modalsVisibility.shouldShowAgentDetailsModal && (
				<IntroModal
					shouldShowModal={modalsVisibility.shouldShowAgentDetailsModal}
					result={result}
					title={
						<div className='w-11/12'>
							Thanks You For Using The Plenti Exchange
							<sup className='font-features sups'>TM</sup> App
						</div>
					}
					subtitle={`Complete the following Information`}
				>
					<AgentDetailsForm
						modalsVisibility={modalsVisibility}
						setModalsVisibility={setModalsVisibility}
					/>
				</IntroModal>
			)}
			{modalsVisibility.shouldShowCustomerDetailsModal && (
				<IntroModal
					shouldShowModal={modalsVisibility.shouldShowCustomerDetailsModal}
					result={result}
					title={`Thanks! Please Enter Your Customer Information to Continue`}
					subtitle={`Complete the following Information`}
				>
					<CustomerDetailForm
						modalsVisibility={modalsVisibility}
						setModalsVisibility={setModalsVisibility}
					/>
				</IntroModal>
			)}
		</div>
	)
}
