import React, { useRef, useState } from 'react'
import { Header } from '../Reinvestment/Header'
import {
	About,
	BiggestMyth,
	Introduction,
	PropertyType,
	GrowthWithExchange,
	Glossary,
	RoadmapFaq,
} from './Screens'
import RoadmapLeftSidebar from './RoadmapLeftSidebar'
import { ReinvestmentGenericModal } from '../../widgets/Modal'
import { MEETING_SCHEDULER_ID } from '../../utils/getEnvVars'
import ExchangeTypes from './Screens/ExchangeTypes'
import RoadmapFooter from './RoadmapFooter'

const Roadmap = () => {
	const elementRef = useRef([])
	const [shouldShowMeetingSchedular, setShouldShowMeetingSchedular] =
		useState(false)

	const ROADMAP_MAPPING = [
		{
			id: 'introduction',
			element: (
				<Introduction
					setShouldShowMeetingSchedular={setShouldShowMeetingSchedular}
				/>
			),
		},
		{ id: 'aboutPlenti', element: <About /> },
		{
			id: 'exchangeTypes',
			element: <ExchangeTypes />,
		},
		{ id: 'howDoesMoneyGrowInExchange', element: <GrowthWithExchange /> },
		{ id: 'biggestMyth', element: <BiggestMyth /> },
		{ id: 'propertyTypes', element: <PropertyType /> },
		{ id: 'faq', element: <RoadmapFaq /> },
		{
			id: 'glossary',
			element: (
				<Glossary
					setShouldShowMeetingSchedular={setShouldShowMeetingSchedular}
				/>
			),
		},
	]

	const toggleMeetingScheduler = () =>
		setShouldShowMeetingSchedular((value) => !value)

	return (
		<div className='w-full flex flex-col h-screen'>
			<Header />
			<div className='flex-1 flex relative overflow-auto'>
				<RoadmapLeftSidebar elementRef={elementRef} />
				<div id='roadmap-content' className='flex-1 overflow-auto no-scrollbar'>
					{ROADMAP_MAPPING.map(({ id, element }, index) => (
						<div
							key={id}
							id={id}
							ref={(element) => (elementRef.current[index] = element)}
							className='w-full h-auto'
						>
							{element}
						</div>
					))}
					<RoadmapFooter />
				</div>
			</div>
			{shouldShowMeetingSchedular && (
				<ReinvestmentGenericModal handleClose={toggleMeetingScheduler}>
					<div className='h-[80vh] w-full'>
						<div className='h-[80vh] w-full'>
							<iframe
								className='meetings-iframe-container h-[80vh] w-full'
								title='HubSpot Meeting Scheduler'
								src={`https://meetings.hubspot.com/${MEETING_SCHEDULER_ID}?embed=true`}
							/>
						</div>
					</div>
				</ReinvestmentGenericModal>
			)}
		</div>
	)
}

export default Roadmap
