export const APP_BASE_URL = process.env.REACT_APP_BASE_URL

// HubSpot - Meeting Schedular ID

export const MEETING_SCHEDULER_ID =
	process.env.REACT_APP_HUBSPOT_MEETING_SCHEDULER_ID

// LinkedIn ID & callback URL's

export const LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID

export const LINKEDIN_CALLBACK_URL_INTRO =
	process.env.REACT_APP_LINKEDIN_CALLBACK_URL_INTRO

export const LINKEDIN_CALLBACK_URL_AGENTS_REFERRAL =
	process.env.REACT_APP_LINKEDIN_CALLBACK_URL_AGENTS_REFERRAL

export const STORAGE_ACCOUNT_NAME = process.env.REACT_APP_STORAGE_ACCOUNT_NAME

export const STORAGE_CONTAINER_NAME =
	process.env.REACT_APP_STORAGE_CONTAINER_NAME

export const FILES_STORAGE_CONTAINER_NAME =
	process.env.REACT_APP_FILES_STORAGE_CONTAINER_NAME
