import { Field, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import isString from 'lodash/isString'
import { arrowLeftIcon, arrowNarrowRight } from '../../assets/icons'
import { PROPERTY_QUALIFY_STEPS } from '../../utils/constants'
import { buyFirst, homeRepair, needCash } from '../../assets/images'
import { TaxSavingStepsGenericModal } from '../../widgets/Modal'
import ScheduleConsultationSection from './ScheduleConsultationSection'
import { MEETING_SCHEDULER_ID } from '../../utils/getEnvVars'

const OTHER_OPTIONS = [
	{
		id: 1,
		image: needCash,
		heading: 'Need Cash?',
		description: 'Complete your exchange, re-finance, and get cash tax free',
	},
	{
		id: 2,
		image: homeRepair,
		heading: 'Improvements?',
		description:
			'Pay for improvements with tax-free money in an Improvement Exchange',
	},
	{
		id: 3,
		image: buyFirst,
		heading: 'Buy First!',
		description:
			'Buy replacement property before you sell, in a Reverse Exchange',
	},
]

const PRIMARY = 'Primary Residence'
const OTHER = 'Other'
const MORE_DETAILS = 'moreDetails'
const SINGLE = 'Single'
const MARRIED = 'Married'
const DIVORCED = 'Divorced'
const CURRENTLY_DIVORCING = 'Currently Divorcing'
const CURRENT_MARITAL_STATUS = 'currentMaritalStatus'
const PROPERTY_VALUE_INCREASE_250K = 'propertyValueIncrease250k'
const PROPERTY_VALUE_INCREASE_500K = 'propertyValueIncrease500k'
const NO = 'No'

const isValidErrorMessage = (error) =>
	isString(error.heading) || isString(error.description)

const getQualificationStatus = (currentStepDetails, values, setIsQualified) => {
	if (currentStepDetails.name !== MORE_DETAILS) return
	const currentValues = values[currentStepDetails.name][values.propertyType]
	switch (values.propertyType) {
		case PRIMARY: {
			// Do Not Qualify Scenarios
			const maritialStatus = currentValues.currentMaritalStatus
			const isFirstQuestionNo = currentValues.propertyValueIncrease250k === NO
			if (
				isFirstQuestionNo &&
				[SINGLE, DIVORCED, CURRENTLY_DIVORCING].includes(maritialStatus)
			)
				// 1st question (propertyValueIncrease250k) is No with marital status Single, Divorced or Currently Divorcing
				return setIsQualified(false)

			if (maritialStatus === DIVORCED) {
				// 1st question is No, Rest All Yes, marital status Divorced
				const areRestAllNo = Object.entries(currentValues).every(
					([key, value]) =>
						[CURRENT_MARITAL_STATUS, PROPERTY_VALUE_INCREASE_250K].includes(
							key
						) || value === NO
				)
				if (areRestAllNo) return setIsQualified(false)
			} else if (maritialStatus === MARRIED) {
				if (currentValues.propertyValueIncrease500k === NO)
					// 2nd question (propertyValueIncrease500k) is No with marital status Married
					return setIsQualified(false)
				const isLastThreeNo = Object.entries(currentValues).every(
					([key, value]) =>
						[
							CURRENT_MARITAL_STATUS,
							PROPERTY_VALUE_INCREASE_250K,
							PROPERTY_VALUE_INCREASE_500K,
						].includes(key) || value === NO
				) // 3rd (propertyUsage), 4th (propertyRentalStatus) and 5th (propertyVacantOrUsedForAgriculature) question are No
				if (isLastThreeNo) return setIsQualified(false)
			}
			return setIsQualified(true)
		}
		case OTHER: {
			const areAllNoOptions = Object.values(currentValues).every(
				(value) => value === NO
			) // All questions are No
			if (areAllNoOptions) return setIsQualified(false)
			return setIsQualified(true)
		}
		default:
			setIsQualified(true)
			break
	}
}

const getErrorMessage = (errors, touched, currentStepDetails) => {
	const error = errors[currentStepDetails.name]
	const isTouched = touched[currentStepDetails.name]
	if (error && isTouched) {
		const currentError = isValidErrorMessage(error)
			? { heading: error.heading, description: error.description }
			: Object.values(error).find((error) => isValidErrorMessage(error))
		const { heading, description } = currentError || {}
		return { errorHeading: heading, errorDescription: description }
	}
	return {}
}

const QualifySteps = ({
	currentStepDetails,
	currentStep,
	setCurrentStep,
	setIsQualified,
	isQualified,
	isModal,
}) => {
	const { values, errors, touched, setFieldTouched, handleChange } =
		useFormikContext()
	const [showScheduleModal, setShowScheduleModal] = useState(false)

	const handleContinue = (errors, setFieldTouched) => {
		if (errors[currentStepDetails.name]) {
			setFieldTouched(currentStepDetails.name)
			setTimeout(() => {
				const errorElement = document.getElementById('error-element')
				if (errorElement) errorElement.scrollIntoView({ behavior: 'smooth' })
			}, 100)
		} else
			setCurrentStep(
				isQualified ? PROPERTY_QUALIFY_STEPS.length - 1 : currentStep + 1
			)
	}

	const { errorHeading, errorDescription } = getErrorMessage(
		errors,
		touched,
		currentStepDetails
	)

	useEffect(() => {
		!errors[currentStepDetails.name] &&
			getQualificationStatus(currentStepDetails, values, setIsQualified)
	}, [values, errors, currentStepDetails, setIsQualified])

	return (
		<>
			{(errorHeading || errorDescription) && (
				<div
					id='error-element'
					className='bg-amber-50 p-4 rounded-md border border-orange-400 text-sm'
				>
					<div className='text-peruTan font-work-sans-medium'>
						{errorHeading}
					</div>
					<div className='text-richGold font-work-sans-regular'>
						{errorDescription}
					</div>
				</div>
			)}
			{currentStepDetails.subSteps ? (
				<>
					<div className='form-group'>
						{Array.isArray(currentStepDetails.subSteps) ? (
							<div className='grid grid-cols-1 sm:grid-cols-2 gap-3'>
								{currentStepDetails.subSteps.map(
									({ name, label, description, isQualified }) => (
										<label
											key={name}
											className={classNames(
												'flex items-center gap-4 rounded-2xl border cursor-pointer',
												{
													'bg-primary-600 text-white':
														values[currentStepDetails.name] === label,
													'bg-white text-primary-600':
														values[currentStepDetails.name] !== label,
													'p-2 sm:p-4': isModal,
													'p-4 sm:p-8': !isModal,
												}
											)}
										>
											<Field
												name={currentStepDetails.name}
												type='radio'
												value={label}
												className='hover:accent-primary-600 accent-primary-600 w-4 h-4'
												onBlur={() => {}}
												onChange={(e) => {
													handleChange(e)
													setIsQualified(isQualified)
												}}
											/>
											<div className='flex flex-1 flex-col justify-center'>
												<div
													className={classNames(
														'uppercase font-work-sans-semibold',
														{
															'text-sm sm:text-base': isModal,
															'text-md sm:text-xl': !isModal,
														}
													)}
												>
													{label}
												</div>
												{description && (
													<div
														className={classNames(
															'font-work-sans-regular text-gray-500',
															{
																' text-white':
																	values[currentStepDetails.name] === label,
																'text-xm sm:text-sm': isModal,
																'text-sm sm:text-base': !isModal,
															}
														)}
													>
														{description}
													</div>
												)}
											</div>
										</label>
									)
								)}
							</div>
						) : (
							<div className='border border-gray-300/60 rounded-md overflow-hidden'>
								<div className='bg-white px-3 sm:px-5 py-4 font-work-sans-medium'>
									{currentStepDetails.subSteps.heading}
								</div>
								<div>
									{currentStepDetails.subSteps.fields[values.propertyType]?.map(
										({ name, label, options }) => (
											<div
												key={label}
												className='odd:bg-white even:bg-mistyGray odd:border-t odd:border-b border-gray-200 flex justify-between gap-4 text-sm items-center'
											>
												<div className='font-work-sans-regular border-r border-white px-3 sm:px-5 py-4 '>
													{label}
												</div>
												<div className='font-work-sans-medium flex flex-wrap pr-3 sm:pr-5 sm:flex-nowrap gap-3 sm:gap-7 border-white'>
													{options.map((option) => (
														<label
															key={option}
															className='flex items-center gap-2 sm:gap-3 min-w-[3rem] sm:min-w-[6rem]'
														>
															<Field
																name={`${currentStepDetails.name}.${values.propertyType}.${name}`}
																type='radio'
																value={option}
																className='hover:accent-primary-600 accent-primary-600 w-4 h-4'
																onBlur={() => {}}
															/>
															{option}
														</label>
													))}
												</div>
											</div>
										)
									)}
								</div>
							</div>
						)}
					</div>
					<div className='flex justify-between font-work-sans-semibold'>
						<button
							className={classNames(
								'btn btn-light rounded-full flex items-center gap-2 text-lg hover:bg-gray-100',
								{ invisible: !currentStep }
							)}
							onClick={() => setCurrentStep(currentStep - 1)}
						>
							<img src={arrowLeftIcon} />
							Previous
						</button>
						<button
							className={classNames(
								'btn flex items-center gap-1 sm:gap-2 rounded-full text-sm sm:text-lg py-2 sm:py-4',
								{
									'btn-light text-gray-400': errors[currentStepDetails.name],
									'btn-primary-light text-white hover:bg-primary-100':
										!errors[currentStepDetails.name],
								}
							)}
							onClick={() => handleContinue(errors, setFieldTouched)}
						>
							Continue
							<img
								src={arrowNarrowRight}
								className={classNames('', {
									'brightness-200': !errors[currentStepDetails.name],
								})}
							/>
						</button>
					</div>
				</>
			) : isQualified ? (
				<div className='flex flex-col gap-4'>
					<div className='h-[80vh] w-full'>
						<div className='h-[80vh] w-full'>
							<iframe
								className='meetings-iframe-container h-[80vh] w-full'
								title='HubSpot Meeting Scheduler'
								src={`https://meetings.hubspot.com/${MEETING_SCHEDULER_ID}?embed=true`}
							/>
						</div>
					</div>
				</div>
			) : (
				<div className='flex flex-col gap-8'>
					<div>
						<div className='text-lg'>Other Options You May Qualify For</div>
						<p className='text-sm text-gray-500 mt-2'>
							Even though you may not qualify for a 1031, you might still
							qualify for IRC Section 121 (the primary residence tax exclusion),
							in which the IRS grants US Citizens a tax-break if they have lived
							in the subject property for 2 of the 5 years prior to the sale. If
							you would like to ask us questions about the IRC Section 121 or
							your qualification status, please feel free to schedule a
							consultation below.
						</p>
					</div>
					<div className='flex justify-center'>
						<button
							className='btn btn-primary-light rounded-full text-white font-work-sans-semibold hover:bg-primary-100 py-3 text-lg'
							onClick={() => setShowScheduleModal(true)}
						>
							Schedule a Consultation
						</button>
					</div>
					<div className='shadow-md'>
						<div className='bg-gray-400 text-white font-work-sans-semibold text-center p-2.5'>
							Other Options
						</div>
						<div className='flex flex-wrap sm:flex-nowrap justify-center text-xs py-4 px-14 gap-x-12 gap-y-4 text-center bg-white'>
							{OTHER_OPTIONS.map(({ id, image, heading, description }) => (
								<div key={id} className='flex flex-col items-center gap-2'>
									<img src={image} width={50} height={50} />
									<div className='font-work-sans-semibold'>{heading}</div>
									<div className='font-work-sans-regular'>{description}</div>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
			{showScheduleModal && (
				<TaxSavingStepsGenericModal
					handleClose={() => setShowScheduleModal(false)}
				>
					<div className='w-full'>
						<ScheduleConsultationSection
							diqValues={values}
							handleClose={() => setShowScheduleModal(false)}
						/>
					</div>
				</TaxSavingStepsGenericModal>
			)}
		</>
	)
}

export default QualifySteps
