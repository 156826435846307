import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	greenWalletIcon,
	greenSummaryBuildingIcon,
	giftIcon,
	poweredByPlenti,
} from '../../assets/icons'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import classNames from 'classnames'
import { NavRoutes, PLENTI, SessionKeys } from '../../utils/constants'
import {
	getStartYou1031ExchangeURL,
	getCurrencyCode,
	numberCommasSeparator,
	isSessionAvailable,
	getNavigationRoute,
} from '../../utils/methods'
import { landingRightBg } from '../../assets/images'
import { walletSummary, houseSummary } from '../../assets/gifs'
import SummaryInvestments from './SummaryInvestments'
import { selectCurrentInvestment } from '../MoreDetails/moreDetails.selectors'
import { shouldShowNavbar } from '../../widgets/NavBar'
import BusyIndicator from '../../widgets/busyIndicator'
import { getUserDetails } from './summary.asyncActions'
import Loading from '../../widgets/Loading'
import { selectUsersResponseData } from './summary.selectors'
import { actions } from './summary.slice'
import { resetInvestments } from '../FindInvestment'
import { selectAffiliateSlug } from '../Affiliates/affiliates.selectors'

const USD = 'USD'

const Summary = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const isAffiliateSite = affiliateSlug !== PLENTI

	const { TAX_REPORT, SUMMARY, MEETING_SCHEDULER, FIND_INVESTMENTS } = NavRoutes
	const [loading, setLoading] = useState(false)
	const { resetToInititalState } = actions
	const usersResponseData = useSelector(selectUsersResponseData)
	const { USER_ID } = SessionKeys
	const navigateToTaxReport = () => {
		dispatch(resetToInititalState())
		dispatch(shouldShowNavbar(false))
		setLoading(true)
	}
	const getTaxReportData = () => {
		if (isSessionAvailable(USER_ID)) {
			const userId = window.sessionStorage.getItem(USER_ID)
			dispatch(getUserDetails({ userId: userId }))
		}
	}

	const startYou1031Exchange = () =>
		window.open(
			getStartYou1031ExchangeURL(affiliateSlug),
			'_blank',
			'noopener,noreferrer'
		)
	const currentInvestmentResponse = useSelector(selectCurrentInvestment)
	const {
		propertyValue,
		totalEstimatedPurchasePrice,
		totalDownPayment,
		totalTaxSaving,
		potentialTaxSaving,
		totalTaxOwed,
		totalEquity,
		totalTaxWithoutExchange,
	} = currentInvestmentResponse
	const [shouldShowTaxOwed, setShouldShowTaxOwed] = useState(false)
	const isTaxSaving = totalTaxSaving || potentialTaxSaving
	const isTaxOwed = totalTaxOwed || totalTaxWithoutExchange
	const getTaxSaving = () => {
		if (
			totalEstimatedPurchasePrice >= propertyValue &&
			totalDownPayment >= totalEquity
		)
			return Math.max(0, potentialTaxSaving)
		else return Math.max(0, totalTaxSaving)
	}
	const taxOwedValue =
		totalEstimatedPurchasePrice < propertyValue &&
		totalDownPayment < totalEquity
			? Math.min(totalTaxWithoutExchange, totalTaxOwed)
			: totalTaxOwed

	useEffect(() => {
		if (
			currentInvestmentResponse &&
			!(
				totalEstimatedPurchasePrice >= propertyValue &&
				totalDownPayment >= totalEquity
			)
		)
			setShouldShowTaxOwed(true)
		else setShouldShowTaxOwed(false)
	}, [
		currentInvestmentResponse,
		totalTaxSaving,
		propertyValue,
		totalEstimatedPurchasePrice,
		totalDownPayment,
		totalEquity,
	])

	const navigateToMeetingScheduler = () => {
		dispatch(shouldShowNavbar(false))
		navigate(getNavigationRoute(affiliateSlug, MEETING_SCHEDULER), {
			state: location.pathname,
		})
	}
	const navigateToFindInvestments = () => {
		dispatch(resetInvestments())
		navigate(getNavigationRoute(affiliateSlug, FIND_INVESTMENTS))
	}
	window.addEventListener('popstate', (event, state) => {
		if (location.pathname === SUMMARY) dispatch(shouldShowNavbar(true))
		if (location.pathname === TAX_REPORT) dispatch(shouldShowNavbar(false))
	})
	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>Exchange It :: Summary</title>
				</Helmet>
			</HelmetProvider>
			{loading ? (
				<Loading
					navigateTo={TAX_REPORT}
					navigationCondition={usersResponseData}
					setLoading={setLoading}
					loadingMessage='Loading Tax Report Details'
				/>
			) : (
				<div
					className={`xl:px-22 xl:pb-22 px-12 pb-12 ${
						isAffiliateSite ? 'xl:pt-7 p-2' : 'xl:pt-22 pt-12'
					}`}
				>
					{isAffiliateSite && (
						<div className='flex justify-end xl:pb-7 pb-1'>
							<img src={poweredByPlenti} alt='Powered By Plenti Logo' />
						</div>
					)}
					<h1 className='text-3xl font-bold leading-8 text-gray-900 mb-3'>
						Your 1031 Exchange Summary
					</h1>
					<p className='text-gray-700 mb-6'>
						Please call us at <a href='tel:+18669441031'>866-944-1031</a> or
						schedule a consultation to walk through all your options for a
						successful exchange!
					</p>
					<div className='grid grid-cols-12 border border-gray-300 rounded-lg mb-6 overflow-hidden'>
						<div className='animate-slide-in-top col-span-12 lg:col-span-7 2xl:col-span-6 px-6 py-16 flex flex-col justify-center'>
							<h1 className='text-gray-900 text-4xl font-bold mb-4'>
								You&apos;re selling a property worth&nbsp;
								{
									<BusyIndicator>
										{getCurrencyCode(USD)}
										{propertyValue && (
											<span>{numberCommasSeparator(propertyValue)}</span>
										)}
									</BusyIndicator>
								}
								&nbsp;and buying properties worth&nbsp;
								{
									<BusyIndicator>
										{getCurrencyCode(USD)}
										{totalEstimatedPurchasePrice && (
											<span>
												{numberCommasSeparator(totalEstimatedPurchasePrice)}
											</span>
										)}
									</BusyIndicator>
								}
							</h1>
							<p className='text-gray-600 text-base mb-8'>
								You are reinvesting funds from the sale of your property.
							</p>
							<div className='space-y-3'>
								<div className='p-3 flex items-center rounded-md space-x-4 border'>
									<div>
										<img src={greenWalletIcon} />
									</div>
									<div className='flex-1 text-gray-700'>
										<div className='text-sm 2xl:text-base'>
											{
												<BusyIndicator>
													{getCurrencyCode(USD)}
													{totalDownPayment && (
														<span>
															{numberCommasSeparator(totalDownPayment)}
														</span>
													)}
												</BusyIndicator>
											}
										</div>
										<p className='text-xs 2xl:text-sm'>Total Down Payment(s)</p>
									</div>
								</div>
								<div className='p-3 flex items-center rounded-md space-x-4 border'>
									<div>
										<img src={greenSummaryBuildingIcon} />
									</div>
									<div className='flex-1 text-gray-700'>
										<div className='text-sm 2xl:text-base'>
											{
												<BusyIndicator>
													{getCurrencyCode(USD)}
													{totalEstimatedPurchasePrice && (
														<span>
															{numberCommasSeparator(
																totalEstimatedPurchasePrice
															)}
														</span>
													)}
												</BusyIndicator>
											}
										</div>
										<p className='text-xs 2xl:text-sm'>
											Total Purchased Investments
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className='hidden lg:col-span-5 2xl:col-span-6 lg:flex justify-end items-end relative'>
							<img src={landingRightBg} alt='hash right background' />
							<img
								src={houseSummary}
								className='absolute p-0 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-10/12 2xl:w-7/12 rounded-full bg-white border-gray-200 border-2'
								alt='house gif'
							/>
						</div>
					</div>
					<SummaryInvestments />
					<div className='grid grid-cols-12 border border-gray-300 rounded-lg mb-6 overflow-hidden'>
						<div className='animate-slide-in-top col-span-12 lg:col-span-7 2xl:col-span-6 px-6 py-16 flex flex-col justify-center'>
							<h1 className='text-gray-900 text-4xl font-bold mb-4'>
								You&apos;ll save&nbsp;
								{
									<BusyIndicator>
										{getCurrencyCode(USD)}
										{isTaxSaving && (
											<span>{numberCommasSeparator(getTaxSaving())}</span>
										)}
									</BusyIndicator>
								}{' '}
								in capital gains taxes.
								<span
									className={classNames('align-top', {
										hidden: !shouldShowTaxOwed,
									})}
								>
									*
								</span>
							</h1>
							<p className='text-gray-600 text-base mb-8'>
								You will save a lot in taxes that can be reinvested in one or
								more properties and improvements.
							</p>
							<div className='space-y-3'>
								<div className='p-3 flex items-center rounded-lg space-x-4 border'>
									<div>
										<img src={giftIcon} />
									</div>
									<div className='flex-1 text-gray-700'>
										<div className='text-sm 2xl:text-base'>
											{
												<BusyIndicator>
													{getCurrencyCode(USD)}
													{isTaxSaving && (
														<span>{numberCommasSeparator(getTaxSaving())}</span>
													)}
												</BusyIndicator>
											}
										</div>
										<p className='text-xs 2xl:text-sm'>Total Tax Savings</p>
									</div>
								</div>

								{shouldShowTaxOwed && (
									<>
										<div className='p-3 flex items-center rounded-lg space-x-4 border'>
											<div>
												<img src={greenWalletIcon} />
											</div>
											<div className='flex-1 text-gray-700'>
												<div className='text-sm 2xl:text-base'>
													{
														<BusyIndicator>
															{getCurrencyCode(USD)}
															{isTaxOwed && (
																<span>
																	{numberCommasSeparator(
																		taxOwedValue > totalTaxWithoutExchange
																			? totalTaxWithoutExchange
																			: taxOwedValue
																	)}
																</span>
															)}
														</BusyIndicator>
													}
												</div>
												<p className='text-xs 2xl:text-sm'>
													Tax Owed Due to Partial Exchange
												</p>
											</div>
										</div>
										<div className='text-gray-600 text-base'>
											<div className='flex flex-row mb-8'>
												*
												<span className='px-1'>
													Tax Savings with a Full Exchange would be $
													{numberCommasSeparator(totalTaxWithoutExchange)}.
												</span>
											</div>
											<p>
												A full exchange is when you buy as much real estate as
												you sold and use all the cash equity from your sale
												property. A partial exchange is when you either
												don&apos;t buy as much real estate as you sold or
												don&apos;t use all the cash equity from the property you
												sold.
											</p>
										</div>
									</>
								)}
							</div>
						</div>
						<div className='hidden lg:col-span-5 2xl:col-span-6 lg:flex justify-end items-end relative'>
							<img src={landingRightBg} alt='hash right background' />
							<img
								src={walletSummary}
								className='absolute p-12 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-4/5 2xl:w-7/12 rounded-full bg-white border-gray-200 border-2'
								alt='wallet gif'
							/>
						</div>
					</div>
					<div className='space-y-2'>
						<button
							className='btn btn-primary w-full'
							onClick={() => {
								navigateToTaxReport()
								getTaxReportData()
							}}
						>
							Get Your Tax Report
						</button>
						<button
							className='btn btn-primary w-full'
							onClick={navigateToFindInvestments}
						>
							Change Your Investments
						</button>
						<button
							className='btn btn-primary w-full'
							onClick={startYou1031Exchange}
						>
							Start Your 1031 Exchange
						</button>
						<button
							className='btn btn-light w-full'
							onClick={navigateToMeetingScheduler}
						>
							Talk to an Attorney-Trained Mentor
						</button>
					</div>
				</div>
			)}
		</>
	)
}

export default Summary
