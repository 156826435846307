import { createSlice } from '@reduxjs/toolkit'
import * as asyncActions from './summary.asyncActions'

const initialState = {
	usersResponseData: {},
	taxReportInBase64: '',
}

const slice = createSlice({
	name: 'summary',
	initialState,
	reducers: {
		resetToInititalState: () => initialState,
	},
	extraReducers: {
		// asynchronous actions
		[asyncActions.getUserDetails.fulfilled]: (state, { payload }) => {
			state.usersResponseData = payload || initialState.usersResponseData
		},
		[asyncActions.updateCustomerAndAgentInfo.fulfilled]: (
			state,
			{ payload }
		) => {
			state.taxReportInBase64 = payload || initialState.taxReportInBase64
		},
	},
})

export default slice

export const { name, actions, reducer } = slice
