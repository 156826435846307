import { toast } from 'react-toastify'
import '../../../node_modules/react-toastify/dist/ReactToastify.css'

const TOAST_POSITION = {
	TOP_LEFT: 'top-left',
	TOP_RIGHT: 'top-right',
	TOP_CENTER: 'top-center',
	BOTTOM_LEFT: 'bottom-left',
	BOTTOM_RIGHT: 'bottom-right',
	BOTTOM_CENTER: 'bottom-center',
}
const TOAST_THEME = {
	LIGHT: 'light',
	DARK: 'dark',
	COLORED: 'colored',
}
const TOAST_PROGRESS = {
	ANIMATED_PROGRESS: undefined,
	WITH_PROGRESS_BAR: 1,
	WITHOUT_PROGRESS_BAR: 0,
}
// autoclose accepts boolean & timer, boolean if toast close by pressing close button (CTA)
const TOAST_DISMISS = 5000

export const successNotification = (message) => {
	toast.success(message, {
		position: TOAST_POSITION.TOP_RIGHT,
		autoClose: TOAST_DISMISS,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: TOAST_PROGRESS.ANIMATED_PROGRESS,
		theme: TOAST_THEME.LIGHT,
	})
}

export const warnNotification = (message) => {
	toast.warn(message, {
		position: TOAST_POSITION.TOP_RIGHT,
		autoClose: TOAST_DISMISS,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: TOAST_PROGRESS.ANIMATED_PROGRESS,
		theme: TOAST_THEME.LIGHT,
	})
}

export const errorNotification = (message) => {
	toast.error(message, {
		position: TOAST_POSITION.TOP_RIGHT,
		autoClose: TOAST_DISMISS,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: TOAST_PROGRESS.ANIMATED_PROGRESS,
		theme: TOAST_THEME.LIGHT,
	})
}

export const infoNotification = (message) => {
	toast.info(message, {
		position: TOAST_POSITION.TOP_RIGHT,
		autoClose: TOAST_DISMISS,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: TOAST_PROGRESS.ANIMATED_PROGRESS,
		theme: TOAST_THEME.LIGHT,
	})
}
