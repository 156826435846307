import InvestmentForm from './InvestmentForm'
import * as selectors from './investmentForm.selectors'
import slice from './investmentForm.slice'

export const { name, actions, reducer } = slice
export const {
	selectCurrentInvestment,
	selectInvestmentFormFields,
	selectInvestmentFormStates,
	selectTypeOfProperty,
	selectMaritalStatus,
} = selectors
export default InvestmentForm
