import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
	LINKEDIN_CLIENT_ID,
	LINKEDIN_CALLBACK_URL_INTRO,
} from '../../../utils/getEnvVars'
import {
	formatResponseObject,
	getLinkedInRedirectURL,
	isSessionAvailable,
	validateMobileNumber,
} from '../../../utils/methods'
import {
	SessionKeys,
	ERROR_MESSAGES,
	ERROR_MESSAGE_CLASSES,
} from '../../../utils/constants'
import { greenChevronDownIcon } from '../../../assets/icons'
import { NumericFormat } from 'react-number-format'
import { NameRegularExpression } from '../../../utils/constants.regex'
import { setInitialValues } from '../../CurrentInvestmentWrapper/InvestmentForm/investmentForm.constants'

const AgentDetailsForm = ({ modalsVisibility, setModalsVisibility }) => {
	const [isLinkedInButtonClicked, setIsLinkedInButtonClicked] = useState(false)
	const [shouldShowWithoutLinkedInButton, setShouldShowWithoutLinkedInButton] =
		useState(false)
	const { AGENT_DETAILS } = SessionKeys
	const linkedInButtonStyle =
		'inline-flex border-linkedin-200 border bg-linkedin-100 hover:bg-sky-700 text-white font-medium text-base items-center'
	const agentDetail = window.sessionStorage.agent_details
		? JSON.parse(window.sessionStorage.agent_details)
		: {}
	const initialValues = {
		firstName: setInitialValues(agentDetail.firstName),
		lastName: setInitialValues(agentDetail.lastName),
		email: setInitialValues(agentDetail.email),
		phoneNumber: setInitialValues(agentDetail.phoneNumber),
		currentPosition: setInitialValues(agentDetail.currentPosition),
		companyName: setInitialValues(agentDetail.companyName),
	}
	const validationSchema = Yup.object().shape({
		firstName: Yup.string()
			.min(3, ERROR_MESSAGES.MIN_LENGTH_3)
			.max(255, ERROR_MESSAGES.MAX_LENGTH_255)
			.test('firstName', ERROR_MESSAGES.FIRST_NAME_VALIDATE_REGREX, (val) =>
				NameRegularExpression.test(val)
			)
			.required(ERROR_MESSAGES.FIRST_NAME_REQUIRED),
		lastName: Yup.string()
			.min(3, ERROR_MESSAGES.MIN_LENGTH_3)
			.max(255, ERROR_MESSAGES.MAX_LENGTH_255)
			.test('lastName', ERROR_MESSAGES.LAST_NAME_VALIDATE_REGREX, (val) =>
				NameRegularExpression.test(val)
			)
			.required(ERROR_MESSAGES.LAST_NAME_REQUIRED),
		email: Yup.string()
			.email(ERROR_MESSAGES.EMAIL_VALIDATE_SPACE)
			.required(ERROR_MESSAGES.EMAIL_REQUIRED),
		phoneNumber: Yup.string().test(
			'phoneNumber',
			'Please enter valid 10 digit phone number',
			(val) => validateMobileNumber(val)
		),
		currentPosition: Yup.string()
			.min(3, ERROR_MESSAGES.MIN_LENGTH_3)
			.max(255, ERROR_MESSAGES.MAX_LENGTH_255),
		companyName: Yup.string()
			.min(3, ERROR_MESSAGES.MIN_LENGTH_3)
			.max(255, ERROR_MESSAGES.MAX_LENGTH_255),
	})
	const loginViaLinkedInHandler = () => {
		const url = getLinkedInRedirectURL(
			LINKEDIN_CLIENT_ID,
			LINKEDIN_CALLBACK_URL_INTRO
		)
		window.open(url, '_self', 'noopener,noreferrer')
	}
	const allowedMobileLength = (values) => {
		const MAX_TEN_DIGIT = 9999999999
		const { value } = values
		return value <= MAX_TEN_DIGIT
	}
	const onFormSubmit = (values) => {
		values = formatResponseObject(values)
		if (isSessionAvailable(AGENT_DETAILS))
			window.sessionStorage.removeItem(AGENT_DETAILS)
		window.sessionStorage.setItem(AGENT_DETAILS, JSON.stringify(values))
		if (isLinkedInButtonClicked) {
			loginViaLinkedInHandler()
			setModalsVisibility({
				...modalsVisibility,
				shouldShowAgentDetailsModal: false,
			})
		} else {
			setModalsVisibility({
				...modalsVisibility,
				shouldShowAgentDetailsModal: false,
				shouldShowCustomerDetailsModal: true,
			})
		}
	}
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => onFormSubmit(values)}
		>
			{({
				values,
				handleBlur,
				handleChange,
				touched,
				errors,
				handleSubmit,
			}) => (
				<form>
					<div className='form-group flex-col mb-6'>
						<div className='form-group mb-3'>
							<label
								className='mb-1 text-sm font-medium left-5 inline-block'
								htmlFor='fullName'
							>
								Your First Name
							</label>
							<input
								type='text'
								name='firstName'
								className='form-control'
								placeholder='Your First Name'
								onBlur={handleBlur}
								value={values.firstName}
								onChange={handleChange}
							/>
							{touched.firstName && errors.firstName && (
								<p className={ERROR_MESSAGE_CLASSES}>{errors.firstName}</p>
							)}
						</div>
						<div className='form-group mb-3'>
							<label
								className='mb-1 text-sm font-medium left-5 inline-block'
								htmlFor='fullName'
							>
								Your Last Name
							</label>
							<input
								type='text'
								name='lastName'
								className='form-control'
								placeholder='Your Last Name'
								onBlur={handleBlur}
								value={values.lastName}
								onChange={handleChange}
							/>
							{touched.lastName && errors.lastName && (
								<p className={ERROR_MESSAGE_CLASSES}>{errors.lastName}</p>
							)}
						</div>
						<div className='form-group mb-3'>
							<label
								className='mb-1 text-sm font-medium left-5 inline-block'
								htmlFor='email'
							>
								Your Email
							</label>
							<input
								type='text'
								name='email'
								className='form-control'
								placeholder='Your Email'
								onBlur={handleBlur}
								value={values.email}
								onChange={handleChange}
							/>
							{touched.email && errors.email && (
								<p className={ERROR_MESSAGE_CLASSES}>{errors.email}</p>
							)}
						</div>
						<div className='form-group mb-3'>
							<label
								className='mb-1 text-sm font-medium left-5 inline-block'
								htmlFor='streetAddress'
							>
								Your Phone Number
							</label>
							<NumericFormat
								type='text'
								name='phoneNumber'
								className='form-control'
								placeholder='Your Phone Number'
								allowNegative={false}
								decimalScale={0}
								isAllowed={(values) => allowedMobileLength(values)}
								onBlur={handleBlur}
								value={values.phoneNumber}
								onChange={handleChange}
							/>
							{touched.phoneNumber && errors.phoneNumber && (
								<p className={ERROR_MESSAGE_CLASSES}>{errors.phoneNumber}</p>
							)}
						</div>
						<div className='form-group mb-3'>
							<label
								className='mb-1 text-sm font-medium left-5 inline-block'
								htmlFor='streetAddress'
							>
								Your Current Position
							</label>
							<input
								type='text'
								name='currentPosition'
								className='form-control'
								placeholder='Your Current Position'
								onBlur={handleBlur}
								value={values.currentPosition}
								onChange={handleChange}
							/>
							{touched.currentPosition && errors.currentPosition && (
								<p className={ERROR_MESSAGE_CLASSES}>
									{errors.currentPosition}
								</p>
							)}
						</div>
						<div className='form-group mb-3'>
							<label
								className='mb-1 text-sm font-medium left-5 inline-block'
								htmlFor='companyName'
							>
								Your Company Name
							</label>
							<input
								type='text'
								name='companyName'
								className='form-control'
								placeholder='Your Company Name'
								onBlur={handleBlur}
								value={values.companyName}
								onChange={handleChange}
							/>
							{touched.companyName && errors.companyName && (
								<p className={ERROR_MESSAGE_CLASSES}>{errors.companyName}</p>
							)}
						</div>
					</div>
					<div className='flex-col mb-6 space-y-1'>
						<div className='flex relative'>
							<button
								type='button'
								className={`${linkedInButtonStyle} grow self-stretch rounded-l-lg border-r-0`}
								onClick={(e) => {
									setIsLinkedInButtonClicked(true)
									handleSubmit(e)
								}}
							>
								<span className='px-4 py-2'>Continue With LinkedIn</span>
							</button>
							<button
								type='button'
								className={`${linkedInButtonStyle} rounded-r-lg border-l-0`}
								onClick={() =>
									setShouldShowWithoutLinkedInButton(
										!shouldShowWithoutLinkedInButton
									)
								}
							>
								<div className='icon px-3 py-3 border-l border-sky-900 h-full'>
									<img src={greenChevronDownIcon} alt='down arrow icon' />
								</div>
							</button>
							{shouldShowWithoutLinkedInButton && (
								<div
									className='w-full bg-white font-medium text-gray-700 py-4 px-4 lg:w-80 border border-gray-300 text-left absolute top-full right-0 rounded-md mt-2 hover:bg-gray-50 cursor-pointer'
									onClick={(e) => {
										setIsLinkedInButtonClicked(false)
										handleSubmit(e)
									}}
								>
									<span>Continue Without LinkedIn</span>
								</div>
							)}
						</div>
						<div className='flex'>
							<button
								type='button'
								className={`btn btn-light block grow`}
								onClick={() =>
									setModalsVisibility({
										...modalsVisibility,
										shouldShowAgentDetailsModal: false,
										shouldShowIntroQuestionModal: true,
									})
								}
							>
								Back
							</button>
						</div>
					</div>
				</form>
			)}
		</Formik>
	)
}

export default AgentDetailsForm
