import { createAsyncThunk } from '@reduxjs/toolkit'
import doAsync from '../../infrastructure/doAsync'

export const getUserDetails = createAsyncThunk(
	'summary/getUsersDetails',
	async ({ userId, useCaching, noBusySpinner } = {}, thunkArgs) => {
		return await doAsync({
			url: `users/${userId}`,
			httpMethod: 'get',
			useCaching,
			noBusySpinner,
			...thunkArgs,
			errorMessage: 'Unable to get user details.',
		})
	}
)

export const updateCustomerAndAgentInfo = createAsyncThunk(
	'summary/updateCustomerAndAgentInfo',
	async (
		{ userId, ...rest },
		thunkArgs,
		{ useCaching, noBusySpinner } = {}
	) => {
		return await doAsync({
			url: `users/${userId}`,
			useCaching,
			noBusySpinner,
			httpMethod: 'put',
			httpConfig: {
				body: JSON.stringify({ ...rest }),
			},
			errorMessage:
				'Unable to update customer and agent details. Please try again later.',
			...thunkArgs,
		})
	}
)
