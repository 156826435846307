import React, { useMemo } from 'react'
import { PLENTI, COLORS, MAX_MONETARY_LIMIT, taxRate } from './constants'
import { stateCodeAndNames } from './stateCodes'
import { useLocation } from 'react-router-dom'

const { PRIMARY } = COLORS

export const refreshChatWindow = () => {
	if (window) {
		const HUBSPOT_WIDGET = window.HubSpotConversations.widget
		const status = HUBSPOT_WIDGET.status()
		if (status.loaded) {
			HUBSPOT_WIDGET.remove()
			HUBSPOT_WIDGET.refresh()
		}
	}
}

export const getLinkedInRedirectURL = (clientId, callbackURL) => {
	const redirectUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&scope=openid profile w_member_social email&redirect_uri=${callbackURL}`
	return redirectUrl
}

export const getStartYou1031ExchangeURL = (slug) =>
	slug === PLENTI
		? 'https://startmyexchange.com/'
		: `https://startmyexchange.com/${slug}`

export const isObjectEmpty = (obj) => {
	return Boolean(!Object.keys(obj).length)
}

export const getCurrencyCode = (currencyCode) => {
	switch (currencyCode) {
		case 'USD': {
			return '$'
		}
		default:
			return '$'
	}
}

export const stringToBooleanConversion = (value) => {
	if (value === 'Yes') return true
	else if (value === 'No') return false
}

export const removeSpecialCharacter = (number) => {
	return typeof number !== 'number' ? number.replace(/[^0-9.]/g, '') : number
}

export const convertToNumber = (number) => {
	return typeof number !== 'number' ? Number(number) : number
}

export const useQuery = () => {
	const { search } = useLocation()
	return useMemo(() => new URLSearchParams(search), [search])
}

export const copyTextToClipboard = async (text) => {
	if ('clipboard' in navigator) {
		return await navigator.clipboard.writeText(text)
	} else {
		return document.execCommand('copy', true, text)
	}
}

export const isSessionAvailable = (key) => {
	const isAvailable = key in window.sessionStorage
	return isAvailable
}

export const numberCommasSeparator = (value) => {
	return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const commaSeparatedToNumber = (value) =>
	value && parseInt(value.replace(/,/g, ''), 10)

export const convertToFixed = (number, demicalPlaces = 1) =>
	number?.toFixed(demicalPlaces)

export const getUserAgreementPDF = () => {
	const userAgreementURL =
		'https://plenti.blob.core.windows.net/termsandcondition/Plenti%20Exchange%20-%20User%20Terms%20-%2020221215.pdf'
	return userAgreementURL
}

export const getStates = stateCodeAndNames.map((state) => (
	<option key={state.state_code} value={state.state_code}>
		{state.state_name} ({state.state_code})
	</option>
))

export const validateMobileNumber = (mobileNumber) => {
	if (mobileNumber === undefined || mobileNumber === null) return false
	else return Boolean(mobileNumber.length === 10)
}
export const formatResponseObject = (values) => {
	for (const property in values) values[property] = values[property].trim()
	return values
}

const defaultColors = {
	primary: {
		100: '141, 215, 223',
		200: '104, 182, 191',
		600: '43, 129, 139',
		700: '11, 80, 88',
	},
	contrast: {
		200: '236 240 242',
		500: '107 114 128',
		600: '75 85 99',
		700: '55 65 81',
		800: '31 41 55',
		900: '17 24 39',
	},
}

export const getNavigationRoute = (affiliateSlug, route) => {
	const isPlentiSite = affiliateSlug === PLENTI
	return isPlentiSite ? route : `/${affiliateSlug}${route}`
}

const alphaValues = (type) =>
	type === PRIMARY
		? ['0.4', '0.6', '0.7', '1']
		: ['0.1', '0.4', '0.6', '0.7', '0.8', '1']

const getColorValues = (hexColor) => {
	const normalizedHex = hexColor.replace(/#/g, '')
	const color =
		normalizedHex.length === 3 ? normalizedHex.repeat(2) : normalizedHex
	const r = parseInt(color.substr(0, 2), 16)
	const g = parseInt(color.substr(2, 2), 16)
	const b = parseInt(color.substr(4, 2), 16)
	return `${r}, ${g}, ${b}`
}

export const generateColorVariables = (hex, type, affiliate) => {
	if (affiliate && affiliate !== PLENTI) {
		const rgbColor = getColorValues(hex)
		const variants = Object.keys(defaultColors[type])

		return alphaValues(type).map(
			(alpha, index) =>
				`--color-${type}-${variants[index]}: ${rgbColor}, ${alpha};`
		)
	} else {
		const defaultColorValues = Object.entries(defaultColors[type])
		return defaultColorValues.map(
			([key, colorValue]) => `--color-${type}-${key}: ${colorValue};`
		)
	}
}

export const openURLInNewTab = (URL) =>
	window.open(URL, '_blank', 'noopener,noreferrer')

export const openURLInNewTabDefault = (URL) => window.open(URL, '_blank')

export const openURLInCurrentTab = (URL) =>
	window.open(URL, '_self', 'noopener,noreferrer')

export const allowedInputValues = (values) => {
	const { value } = values
	if (value.charAt(0) === '0' && value.charAt(1) && value.charAt(1) !== '.') {
		return false
	}
	return value <= MAX_MONETARY_LIMIT
}
export const getPropertyTax = (sellingPriceNow, originalPrice) =>
	(sellingPriceNow - originalPrice) * taxRate

const cleanUpPath = (pathname) =>
	pathname === '/' ? pathname : pathname.replace(/\/+$/, '')

export const checkForCurrentRoute = (routes, affiliateSlug, location) => {
	let currentRoute = cleanUpPath(
		location?.pathname || window.location.pathname
	).toLowerCase()
	const formattedAffiliateSlug = affiliateSlug?.toLowerCase()
	if (currentRoute === `/${formattedAffiliateSlug}`)
		currentRoute = `${currentRoute}/`

	const isAffiliateUrl =
		affiliateSlug &&
		affiliateSlug !== PLENTI &&
		currentRoute.includes(formattedAffiliateSlug)

	const doesRouteMatch = routes.some(
		(route) =>
			(isAffiliateUrl
				? `/${formattedAffiliateSlug}${route}`
				: '' + route
			).toLowerCase() === currentRoute
	)
	return doesRouteMatch
}
