import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { loaderPngIcon } from '../../assets/images'
import { isObjectEmpty, getNavigationRoute } from '../../utils/methods'
import { useSelector } from 'react-redux'
import { selectAffiliateSlug } from '../../features/Affiliates/affiliates.selectors'

const Loading = ({
	setLoading,
	loadingMessage,
	navigateTo,
	navigateIn = false,
	navigationCondition = {},
	children,
	loading,
}) => {
	const navigate = useNavigate()
	const affiliateSlug = useSelector(selectAffiliateSlug)

	useEffect(() => {
		if (navigateIn) {
			setTimeout(() => {
				setLoading(false)
				navigate(getNavigationRoute(affiliateSlug, navigateTo))
			}, navigateIn)
		}
		if (!isObjectEmpty(navigationCondition)) {
			setLoading(false)
			navigate(getNavigationRoute(affiliateSlug, navigateTo))
		}
	})
	return children ? (
		<>
			{loading ? (
				<div className='flex-1 flex justify-center items-center'>
					<img
						src={loaderPngIcon}
						className='animate-spin inline'
						width='20'
						alt='spinner-icon'
					/>
				</div>
			) : (
				<span>{children}</span>
			)}
		</>
	) : (
		<div className='xl:p-22 p-12 flex items-center justify-center flex-col h-full xs:h-[90vh]'>
			<div className='icon loader mb-6'>
				<img
					src={loaderPngIcon}
					className='animate-spin'
					width='75'
					alt='spinner-icon'
				/>
			</div>
			<h1 className='text-3xl font-bold text-gray-900'>{loadingMessage}</h1>
		</div>
	)
}

export default Loading
