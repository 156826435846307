import React from 'react'
import { useSelector } from 'react-redux'
import { selectCircularProgressIndicatorState } from './circularProgressIndicator.selectors'

export default function CircularProgressIndicator() {
	const circumference = ((2 * 22) / 7) * 25
	const circularProgressIndicator = useSelector(
		selectCircularProgressIndicatorState
	)
	return (
		<div className='progress mb-4 flex justify-center relative'>
			<svg className='transform -rotate-90 w-16  h-16'>
				<circle
					cx='32'
					cy='32'
					r='25'
					stroke='currentColor'
					strokeWidth='8'
					fill='transparent'
					className='text-gray-200'
				/>

				<circle
					cx='32'
					cy='32'
					r='25'
					stroke='currentColor'
					strokeLinecap='round'
					strokeWidth='8'
					fill='transparent'
					strokeDasharray={circumference}
					strokeDashoffset={
						circumference -
						(circularProgressIndicator.percentage / 100) * circumference
					}
					className='text-primary-600 '
				/>
			</svg>
			<span className='absolute text-xs font-bold top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-gray-900'>
				{`${circularProgressIndicator.percentage}%`}
			</span>
		</div>
	)
}
