import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFormData, selectUploadedImage } from './affiliates.selectors'
import {
	NavRoutes,
	COLOR_FIELDS,
	FORM_FIELDS,
	DEFAULT_VALUES,
} from '../../utils/constants'
import { useNavigate } from 'react-router-dom'
import {
	STORAGE_CONTAINER_NAME,
	STORAGE_ACCOUNT_NAME,
} from '../../utils/getEnvVars'
import { shouldActivate } from '../../widgets/NavBar'
import { NavLinkStates } from '../../utils/constant.enum'

const { AFFILIATE_START_APPLICATION, AFFILIATE_TERMS } = NavRoutes
const { EMPTY_STRING } = DEFAULT_VALUES

const formFields = [
	{
		label: 'Your Company Name',
		id: 'companyName',
		type: 'text',
		placeholder: 'Your Company Name',
		className: 'col-span-2',
	},
	...FORM_FIELDS,
]

const RequestApproval = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const formData = useSelector(selectFormData)
	const uploadedImage = useSelector(selectUploadedImage)
	const isFormDataEmpty = Object.values(formData).some(
		(value) => value === DEFAULT_VALUES.EMPTY_STRING
	)

	useEffect(() => {
		if (isFormDataEmpty) navigate(AFFILIATE_START_APPLICATION)
	}, [isFormDataEmpty, navigate])

	return (
		<div className='flex flex-col min-h-screen sm:pt-22 pt-12'>
			<h1 className='sm:text-3xl text-2xl font-bold leading-7 text-gray-900 mb-6 sm:px-22 px-12'>
				Request Approval
			</h1>
			<div className='flex-grow grid'>
				<div className='sm:px-22 px-12 sm:pb-22 pb-12'>
					<div className='h-auto p-6 bg-gray-50 rounded-lg gap-6 sm:grid sm:grid-cols-2'>
						{formFields.map(({ label, id, className }) => (
							<div
								className={`sm:mt-0 mt-3 ${className || EMPTY_STRING}`}
								key={id}
							>
								<div className='Label text-slate-700 text-sm font-semibold leading-tight'>
									{label}
								</div>
								<div className='Text text-gray-500 text-base font-normal leading-normal mt-2'>
									{formData[id]}
								</div>
							</div>
						))}
						<div className='sm:mt-0 mt-3 col-span-2'>
							<div className='Label text-slate-700 text-sm font-semibold leading-tight'>
								Your Unique URL
							</div>
							<div className='flex sm:items-center sm:flex-row flex-col'>
								<p className='text-gray-900 mr-1 mt-2'>
									www.plentiexchange.com/
								</p>
								<div className='Text text-gray-500 text-base font-normal leading-normal sm:mt-2 mt-0'>
									{formData.companySlug}
								</div>
							</div>
						</div>
						{COLOR_FIELDS.map(({ label, id }) => (
							<div className='sm:mt-0 mt-3' key={id}>
								<div className='Label text-slate-700 text-sm font-semibold leading-tight'>
									{label}
								</div>
								<div className='flex items-center mt-4 w-full'>
									<div
										className='w-3 h-3 mr-2 rounded-full'
										style={{ backgroundColor: formData[id] }}
									/>
									<div className='Text text-gray-500 text-base font-normal leading-normal ml-2'>
										{formData[id]}
									</div>
								</div>
							</div>
						))}
						<div className='sm:mt-0 mt-3'>
							<div className='Label text-slate-700 text-sm font-semibold leading-tight'>
								Your Logo
							</div>
							<img
								src={`https://${STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${STORAGE_CONTAINER_NAME}/${uploadedImage}`}
								alt='logo'
								width={128}
								className='mt-4'
							/>
						</div>
					</div>
				</div>
				<div className='xs:text-right text-center space-x-2 self-end border-t-2 p-6'>
					<button
						className='btn btn-light'
						onClick={() => navigate(AFFILIATE_START_APPLICATION)}
					>
						Previous
					</button>
					<button
						className='btn btn-primary'
						type='submit'
						onClick={() => {
							dispatch(shouldActivate(NavLinkStates.TERMS))
							navigate(AFFILIATE_TERMS)
						}}
					>
						Continue
					</button>
				</div>
			</div>
		</div>
	)
}

export default RequestApproval
