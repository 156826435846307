import React, { useState, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { greenCheckIcon, poweredByPlenti } from '../../assets/icons'
import {
	NavRoutes,
	ERROR_MESSAGE_CLASSES,
	SessionKeys,
	ERROR_MESSAGES,
	PLENTI,
} from '../../utils/constants'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux'
import {
	selectUsersResponseData,
	selectTaxReportInBase64,
} from '../Summary/summary.selectors'
import {
	NameRegularExpression,
	EmailRegularExpression,
} from '../../utils/constants.regex'
import { NumericFormat } from 'react-number-format'
import { updateCustomerAndAgentInfo } from '../Summary/summary.asyncActions'
import {
	formatResponseObject,
	getNavigationRoute,
	validateMobileNumber,
} from '../../utils/methods'
import {
	infoNotification,
	successNotification,
} from '../../widgets/ToastNotification'
import { shouldShowNavbar } from '../../widgets/NavBar'
import { toast } from 'react-toastify'
import { Modal } from '../../widgets/Modal'
import { selectAffiliateSlug } from '../Affiliates/affiliates.selectors'

const FIRST_NAME_INDEX = 0

const DownloadTaxReport = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const isAffiliateSite = affiliateSlug !== PLENTI

	const { SUMMARY } = NavRoutes
	const taxReportInBase64 = useSelector(selectTaxReportInBase64)
	const [isDownloadingTaxReport, setIsDownloadingTaxReport] = useState(false)
	const [renderAgentDetails, setRenderAgentDetails] = useState(false)
	const [shouldDisableAgentDetails, setShouldDisableAgentDetails] =
		useState(false)
	const [isTaxReportEmailSent, setIsTaxReportEmailSent] = useState(false)
	const usersResponseData = useSelector(selectUsersResponseData)
	const { user, agent } = usersResponseData
	const navigateToSummary = () => {
		dispatch(shouldShowNavbar(true))
		navigate(getNavigationRoute(affiliateSlug, SUMMARY))
	}
	const { USER_ID } = SessionKeys
	const setInititalValues = (dataSet, key) => {
		const initValue = get(dataSet, key, '')
		if (typeof initValue === 'object') return ''
		return initValue
	}
	const qualifyIcon = {
		icon: greenCheckIcon,
		iconBackground: 'bg-green-100 ring-8 ring-green-50',
	}
	const nextBtn = {
		title: 'Close',
		click: navigateToSummary,
	}
	const initialValues = {
		firstName: setInititalValues(user, 'firstName'),
		lastName: setInititalValues(user, 'lastName'),
		email: setInititalValues(user, 'email'),
		phoneNumber: setInititalValues(user, 'phoneNumber'),
		agentFirstName: setInititalValues(agent, 'firstName'),
		agentLastName: setInititalValues(agent, 'lastName'),
		agentEmail: setInititalValues(agent, 'email'),
		agentPhoneNumber: setInititalValues(agent, 'phoneNumber'),
		agentCurrentPosition: setInititalValues(agent, 'currentPosition'),
		agentCompanyName: setInititalValues(agent, 'companyName'),
	}

	const validationSchema = Yup.object().shape({
		firstName: Yup.string()
			.min(3, ERROR_MESSAGES.MIN_LENGTH_3)
			.max(255, ERROR_MESSAGES.MAX_LENGTH_255)
			.test('firstName', ERROR_MESSAGES.FIRST_NAME_VALIDATE_REGREX, (val) =>
				NameRegularExpression.test(val)
			)
			.required(ERROR_MESSAGES.FIRST_NAME_REQUIRED),
		lastName: Yup.string()
			.min(3, ERROR_MESSAGES.MIN_LENGTH_3)
			.max(255, ERROR_MESSAGES.MAX_LENGTH_255)
			.test('lastName', ERROR_MESSAGES.LAST_NAME_VALIDATE_REGREX, (val) =>
				NameRegularExpression.test(val)
			)
			.required(ERROR_MESSAGES.LAST_NAME_REQUIRED),
		email: Yup.string()
			.email(ERROR_MESSAGES.EMAIL_VALIDATE_SPACE)
			.required(ERROR_MESSAGES.EMAIL_REQUIRED),
		phoneNumber: Yup.string().test(
			'phone number',
			ERROR_MESSAGES.MOBILE_NUMBER,
			(val) => validateMobileNumber(val)
		),
		agentFirstName: Yup.string()
			.test('isValid', ERROR_MESSAGES.FIRST_NAME_REQUIRED, (val) =>
				!renderAgentDetails ? true : val
			)
			.test('firstName', ERROR_MESSAGES.FIRST_NAME_VALIDATE_REGREX, (val) =>
				NameRegularExpression.test(val)
			),
		agentLastName: Yup.string()
			.test('isValid', ERROR_MESSAGES.LAST_NAME_REQUIRED, (val) =>
				!renderAgentDetails ? true : val
			)
			.test('lastName', ERROR_MESSAGES.LAST_NAME_VALIDATE_REGREX, (val) =>
				NameRegularExpression.test(val)
			),
		agentEmail: Yup.string()
			.test('isValid', ERROR_MESSAGES.EMAIL_REQUIRED, (val) =>
				!renderAgentDetails ? true : val
			)
			.test('email', ERROR_MESSAGES.EMAIL_VALIDATE_SPACE, (val) =>
				renderAgentDetails ? EmailRegularExpression.test(val) : true
			),
		agentPhoneNumber: Yup.string()
			.test('isValid', ERROR_MESSAGES.MOBILE_NUMBER, (val) =>
				!renderAgentDetails ? true : val
			)
			.test('agentphoneNumber', ERROR_MESSAGES.MOBILE_NUMBER, (val) =>
				renderAgentDetails ? validateMobileNumber(val) : true
			),
		agentCurrentPosition: Yup.string()
			.min(3, ERROR_MESSAGES.MIN_LENGTH_3)
			.max(255, ERROR_MESSAGES.MAX_LENGTH_255),
		agentCompanyName: Yup.string()
			.min(3, ERROR_MESSAGES.MIN_LENGTH_3)
			.max(255, ERROR_MESSAGES.MAX_LENGTH_255),
	})

	const shouldDisableCpaToggleAndAgentDetails = (userDetails) => {
		const { email, referralId } = userDetails
		if (!email && referralId) {
			setShouldDisableAgentDetails(true)
			return false
		} else {
			setShouldDisableAgentDetails(false)
			return true
		}
	}

	const toggleCpaOption = () => {
		if (shouldDisableCpaToggleAndAgentDetails(user))
			setRenderAgentDetails(!renderAgentDetails)
	}

	const allowedMobileLength = (values) => {
		const MAX_TEN_DIGIT = 9999999999
		const { value } = values
		return value <= MAX_TEN_DIGIT
	}
	const downloadTaxReport = (pdf) => {
		const linkSource = `data:application/pdf;base64,${pdf}`
		const downloadLink = document.createElement('a')
		const fileName = 'PlentiExchange.pdf'
		downloadLink.href = linkSource
		downloadLink.download = fileName
		downloadLink.click()
	}
	const dismissAll = () => toast.dismiss()
	useEffect(() => {
		if (taxReportInBase64 && isDownloadingTaxReport) {
			dismissAll()
			downloadTaxReport(taxReportInBase64)
			setIsTaxReportEmailSent(true)
			successNotification('Plenti Exchange Tax Report downloaded.')
		}
	}, [dispatch, taxReportInBase64, SUMMARY, navigate, isDownloadingTaxReport])

	const getCustomerDetails = ({ firstName, lastName, email, phoneNumber }) => ({
		firstName,
		lastName,
		email,
		phoneNumber,
	})

	const getAgentDetails = ({
		agentFirstName,
		agentLastName,
		agentEmail,
		agentPhoneNumber,
		agentCurrentPosition,
		agentCompanyName,
	}) => ({
		firstName: agentFirstName,
		lastName: agentLastName,
		email: agentEmail,
		phoneNumber: agentPhoneNumber,
		currentPosition: agentCurrentPosition,
		companyName: agentCompanyName,
	})
	const isAgentInfoValid = (agentInfo) =>
		Boolean(Object.values(agentInfo)[FIRST_NAME_INDEX].length)

	const onFormSubmit = (values) => {
		const userId = get(window.sessionStorage, USER_ID, null)
		values = formatResponseObject(values)
		const customerInfo = getCustomerDetails(values)
		const agentInfo = getAgentDetails(values)
		infoNotification('Downloading Tax Report')
		setIsDownloadingTaxReport(true)
		dispatch(
			updateCustomerAndAgentInfo({
				userId,
				...customerInfo,
				...(isAgentInfoValid(agentInfo) && {
					agent: { ...agentInfo },
				}),
			})
		)
	}

	useEffect(() => {
		if (agent) setRenderAgentDetails(true)
		setShouldDisableAgentDetails(!user.email && user.referralId)
	}, [agent, user])
	return (
		<div
			className={`xl:px-22 xl:pb-22 px-12 pb-12 ${
				isAffiliateSite ? 'xl:pt-7 p-2' : 'xl:pt-22 pt-12'
			}`}
		>
			{isAffiliateSite && (
				<div className='flex justify-end xl:pb-7 pb-1'>
					<img src={poweredByPlenti} alt='Powered By Plenti Logo' />
				</div>
			)}
			<HelmetProvider>
				<Helmet>
					<title>Exchange It :: Tax Report</title>
				</Helmet>
			</HelmetProvider>
			<h1 className='text-3xl font-bold leading-8 text-gray-900 mb-3'>
				Download Tax Report
			</h1>
			<p className='text-gray-700 mb-6'>
				We can send you a Personalized Seller Tax Report with all your options
				if you provide us your contact information below:
			</p>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values) => onFormSubmit(values)}
			>
				{({
					values,
					handleBlur,
					handleChange,
					touched,
					errors,
					handleSubmit,
				}) => (
					<form>
						<div className='mb-6'>
							<div className='flex space-x-2'>
								<div className='form-group mb-6 flex-1'>
									<label
										htmlFor='customerFirstName'
										className='mb-2 text-sm font-medium left-5 inline-block text-gray-900'
									>
										Your First Name
									</label>
									<input
										id='customerFirstName'
										type='text'
										name='firstName'
										className='form-control'
										placeholder='Your First Name'
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.firstName}
									/>
									{touched.firstName && errors.firstName && (
										<p className={ERROR_MESSAGE_CLASSES}>{errors.firstName}</p>
									)}
								</div>

								<div className='form-group mb-6 flex-1'>
									<label
										htmlFor='customerLastName'
										className='mb-2 text-sm font-medium left-5 inline-block text-gray-900'
									>
										Your Last Name
									</label>
									<input
										id='customerLastName'
										type='text'
										name='lastName'
										className='form-control'
										placeholder='Your Last Name'
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.lastName}
									/>
									{touched.lastName && errors.lastName && (
										<p className={ERROR_MESSAGE_CLASSES}>{errors.lastName}</p>
									)}
								</div>
							</div>
							<div className='form-group mb-6'>
								<label
									htmlFor='customerEmail'
									className='mb-2 text-sm font-medium left-5 inline-block text-gray-900'
								>
									Your Email
								</label>
								<input
									id='customerEmail'
									type='text'
									name='email'
									className='form-control'
									placeholder='Your Email'
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.email}
								/>
								{touched.email && errors.email && (
									<p className={ERROR_MESSAGE_CLASSES}>{errors.email}</p>
								)}
							</div>

							<div className='form-group mb-6'>
								<label
									htmlFor='customerPhoneNumber'
									className='mb-2 text-sm font-medium left-5 inline-block text-gray-900'
								>
									Your Phone Number
								</label>
								<NumericFormat
									id='customerPhoneNumber'
									type='text'
									name='phoneNumber'
									className='form-control'
									placeholder='Your Phone Number'
									allowNegative={false}
									decimalScale={0}
									isAllowed={(values) => allowedMobileLength(values)}
									onBlur={handleBlur}
									value={values.phoneNumber}
									onChange={handleChange}
								/>

								{touched.phoneNumber && errors.phoneNumber && (
									<p className={ERROR_MESSAGE_CLASSES}>{errors.phoneNumber}</p>
								)}
							</div>
							<div className='form-group mb-6'>
								<label
									htmlFor='toggle-form'
									className='inline-flex relative items-center mr-5 cursor-pointer text-gray-900'
								>
									<input
										id='toggle-form'
										type='checkbox'
										className='sr-only peer'
										checked={renderAgentDetails}
										onClick={() => toggleCpaOption()}
										readOnly
									/>
									<div className="w-11 h-6 bg-gray-300 rounded-full peer btn-primary peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:btn-primary"></div>
									<span className='ml-2 text-sm font-medium text-gray-700'>
										I am working with a CPA, real estate agent or tax consultant
									</span>
								</label>
							</div>
							{renderAgentDetails && (
								<div className='form-group md:flex-col mb-6'>
									<div className='flex space-x-2'>
										<div className='form-group mb-6 flex-1'>
											<label
												className='mb-2 text-sm font-medium left-5 inline-block text-gray-900'
												htmlFor='agentFullName'
											>
												Their First Name
											</label>
											<input
												type='text'
												name='agentFirstName'
												className='form-control'
												placeholder='Their First Name'
												onBlur={handleBlur}
												value={values.agentFirstName}
												onChange={handleChange}
												disabled={shouldDisableAgentDetails}
											/>
											{touched.agentFirstName && errors.agentFirstName && (
												<p className={ERROR_MESSAGE_CLASSES}>
													{errors.agentFirstName}
												</p>
											)}
										</div>
										<div className='form-group mb-6 flex-1'>
											<label
												className='mb-2 text-sm font-medium left-5 inline-block text-gray-900'
												htmlFor='agentLastName'
											>
												Their Last Name
											</label>
											<input
												id='agentLastName'
												type='text'
												name='agentLastName'
												className='form-control'
												placeholder='Their Last Name'
												onBlur={handleBlur}
												value={values.agentLastName}
												onChange={handleChange}
												disabled={shouldDisableAgentDetails}
											/>
											{touched.agentLastName && errors.agentLastName && (
												<p className={ERROR_MESSAGE_CLASSES}>
													{errors.agentLastName}
												</p>
											)}
										</div>
									</div>
									<div className='flex space-x-2'>
										<div className='form-group mb-6 flex-1'>
											<label
												className='mb-2 text-sm font-medium left-5 inline-block text-gray-900'
												htmlFor='agentEmail'
											>
												Their Email
											</label>
											<input
												id='agentEmail'
												type='text'
												name='agentEmail'
												className='form-control'
												placeholder='Their Email'
												onBlur={handleBlur}
												value={values.agentEmail}
												onChange={handleChange}
												disabled={shouldDisableAgentDetails}
											/>
											{touched.agentEmail && errors.agentEmail && (
												<p className={ERROR_MESSAGE_CLASSES}>
													{errors.agentEmail}
												</p>
											)}
										</div>
										<div className='form-group mb-6 flex-1'>
											<label
												className='mb-2 text-sm font-medium left-5 inline-block text-gray-900'
												htmlFor='agentPhoneNumber'
											>
												Their Phone Number
											</label>
											<NumericFormat
												id='agentPhoneNumber'
												type='text'
												name='agentPhoneNumber'
												className='form-control'
												placeholder='Their Phone Number'
												allowNegative={false}
												decimalScale={0}
												isAllowed={(values) => allowedMobileLength(values)}
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.agentPhoneNumber}
												disabled={shouldDisableAgentDetails}
											/>
											{touched.agentPhoneNumber && errors.agentPhoneNumber && (
												<p className={ERROR_MESSAGE_CLASSES}>
													{errors.agentPhoneNumber}
												</p>
											)}
										</div>
									</div>
									<div className='flex space-x-2'>
										<div className='form-group mb-6 flex-1'>
											<label
												className='mb-2 text-sm font-medium left-5 inline-block text-gray-900'
												htmlFor='agentCurrentPosition'
											>
												Their Current Position
											</label>
											<input
												id='agentCurrentPosition'
												type='text'
												name='agentCurrentPosition'
												className='form-control'
												placeholder='Their Current Position'
												onBlur={handleBlur}
												value={values.agentCurrentPosition}
												onChange={handleChange}
												disabled={shouldDisableAgentDetails}
											/>
											{touched.agentCurrentPosition &&
												errors.agentCurrentPosition && (
													<p className={ERROR_MESSAGE_CLASSES}>
														{errors.agentCurrentPosition}
													</p>
												)}
										</div>
										<div className='form-group mb-6 flex-1'>
											<label
												className='mb-2 text-sm font-medium left-5 inline-block text-gray-900'
												htmlFor='agentCompanyName'
											>
												Their Company Name
											</label>
											<input
												id='agentCompanyName'
												type='text'
												name='agentCompanyName'
												className='form-control'
												placeholder='Their Company Name'
												onBlur={handleBlur}
												value={values.agentCompanyName}
												onChange={handleChange}
												disabled={shouldDisableAgentDetails}
											/>
											{touched.agentCompanyName && errors.agentCompanyName && (
												<p className={ERROR_MESSAGE_CLASSES}>
													{errors.agentCompanyName}
												</p>
											)}
										</div>
									</div>
								</div>
							)}
						</div>

						<div className='text-right space-x-2'>
							<button
								type='button'
								className='btn btn-light'
								onClick={navigateToSummary}
							>
								Previous
							</button>
							<button
								type='submit'
								className='btn btn-primary'
								onClick={handleSubmit}
							>
								Submit
							</button>
						</div>
						{isTaxReportEmailSent && (
							<Modal
								shouldShowModal={isTaxReportEmailSent}
								setShouldShowModal={setIsTaxReportEmailSent}
								result={qualifyIcon}
								title={`Check Your email`}
								subtitle={`We've sent your tax report to your email.`}
								nextBtn={nextBtn}
							></Modal>
						)}
					</form>
				)}
			</Formik>
		</div>
	)
}

export default DownloadTaxReport
