import { createSlice } from '@reduxjs/toolkit'
import {
	DEFAULT_COLORS,
	UNIQUE_FIELDS,
	DEFAULT_VALUES,
} from '../../utils/constants'
import * as asyncActions from './affiliates.asyncActions'

const { SLUG } = UNIQUE_FIELDS
const { primary, contrast } = DEFAULT_COLORS
const { EMPTY_STRING } = DEFAULT_VALUES

const initialState = {
	formData: {
		companyName: EMPTY_STRING,
		firstName: EMPTY_STRING,
		lastName: EMPTY_STRING,
		email: EMPTY_STRING,
		phoneNumber: EMPTY_STRING,
		companySlug: EMPTY_STRING,
		logoURL: EMPTY_STRING,
		primaryHexCode: primary,
		contrastHexCode: contrast,
	},
	isSlugValid: null,
	isCompanyNameValid: null,
	isSlugFieldActive: null,
	uploadedImage: null,
	affiliateDetails: null,
	affiliateSlug: null,
	imageData: null,
}

const slice = createSlice({
	name: 'affiliateStartApplication',
	initialState,
	reducers: {
		setFormDataState: (state, action) => {
			state.formData = action.payload
		},
		setIsSlugFieldActive: (state, action) => {
			state.isSlugFieldActive = action.payload
		},
		setIsSlugValidated: (state, action) => {
			state.isSlugValid = action.payload
		},
		setIsCompanyNameValidated: (state, action) => {
			state.isCompanyNameValid = action.payload
		},
		setUploadedImage(state, action) {
			state.uploadedImage = action.payload
		},
		setAffiliateSlug(state, action) {
			state.affiliateSlug = action.payload
		},
		setAffiliateDetails(state, action) {
			state.affiliateDetails = action.payload
		},
		setImageData(state, action) {
			state.imageData = action.payload
		},
	},
	extraReducers: {
		[asyncActions.validateAffiliateKeywords.fulfilled]: (
			state,
			{ payload }
		) => {
			if (payload) {
				const { key, isValidated } = payload
				state[key === SLUG ? 'isSlugValid' : 'isCompanyNameValid'] = isValidated
			}
		},
		[asyncActions.deleteLogo.fulfilled]: (state) => {
			state.uploadedImage = null || initialState.uploadedImage
		},
	},
})

export default slice
export const { name, actions, reducer } = slice
