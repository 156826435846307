import slice from './investmentForm.slice'

export const selectSlice = (state) => state[slice.name]
export const selectCurrentInvestment = (state) =>
	selectSlice(state).currentInvestment
export const selectInvestmentFormFields = (state) =>
	selectSlice(state).investmentFormFields
export const selectInvestmentFormStates = (state) =>
	selectSlice(state).investmentFormStates
export const selectTypeOfProperty = (state) => selectSlice(state).typeOfProperty
export const selectMaritalStatus = (state) => selectSlice(state).maritalStatus
