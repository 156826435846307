import { createAsyncThunk } from '@reduxjs/toolkit'
import doAsync from '../../infrastructure/doAsync'

export const createNewInvestment = createAsyncThunk(
	'find-investment',
	async (details, thunkArgs, { useCaching, noBusySpinner } = {}) =>
		await doAsync({
			url: 'new-investments',
			useCaching,
			noBusySpinner,
			httpMethod: 'post',
			httpConfig: {
				body: JSON.stringify(details),
			},
			errorMessage: 'Unable to save new investment. Please try again later.',
			...thunkArgs,
		})
)
