import React from 'react'
import Accordion from '../../../widgets/Accordian'
import {
	propertyTypeResidential,
	propertyTypeHomeOffice,
	propertyTypeMultiFamily,
	propertyTypeNetLease,
	propertyTypeDST,
	propertyTypeVacationProperty,
	propertyTypeRawLand,
	propertyTypeOtherInvestments,
} from '../../../assets/images'

const propertyTypeData = [
	{
		src: propertyTypeResidential,
		title: 'Residential Properties',
		description:
			'With soaring prices, Single Family Rental (SFR) emerges as a recognized asset class for investors. Turn-key management via technology and third-party firms like Mynd, HomeRiver, Evernest, Marketplace, etc., offers nationwide accessibility. Reinvest in turnkey SFRs for hassle-free returns deposited monthly. ',
		subTitle: 'The Bottom Line',
		subDescription:
			'Upsides include appreciation, loan amortization, and rent growth. Downsides involve potential non-payment by tenants and repair needs. Many homeowners owning property for seven (7) years or more witness significant gains potentially uncovered by Section 121. Exchange options could mitigate or eliminate taxes owed, presenting advantageous opportunities.',
		footerNote:
			'Note: Married couples filing jointly can exclude up to $500,000 gain; for others, the limit is $250,000.',
	},
	{
		src: propertyTypeHomeOffice,
		title: 'Home Office',
		description:
			"Under Section 2005-14, taxpayers can exchange residential properties with a home office/work space. Section 1031 uses the appraised value of the business portion for the exchange property's value.",
		subTitle: 'The Bottom Line',
		subDescription:
			"In multi-family properties, one unit can qualify under Section 121 while the rented portion can be exchanged under Section 1031, allowing tax-free exchanges. Appraise the home office's square footage and exchange its value into the replacement property tax-free. ",
		subDescriptionSecondary:
			'Large appreciation makes residences valuable investments; strategic planning can minimize taxes. Consider a 1031 exchange for future residence or selling tax-free for a smaller or retirement home.',
	},
	{
		src: propertyTypeMultiFamily,
		title: 'Multi Family',
		description:
			'For the investor, early on in his investment career, a multi-family property is a great way to continue investing and exchanging real estate that can appreciate and throw off good cash flow. It is possible to combine a residence with a multi family property and use part of the property as your residence and the remainder as an investment/exchange property as in a 1-4 family unit.',
		subTitle: 'The Bottom Line',
		subDescription:
			'When owning a multi-family property, one unit can be your residence and still qualify under Section 121, while the portion of the property that is being rented (or held for investment—the code says either, so it need not be rented) can be exchanged under Section 1031 instead of selling, paying the tax and then reinvesting.',
	},
	{
		src: propertyTypeNetLease,
		title: 'Triple Net Leases (NNN)',
		description:
			'Real estate investors favor triple net properties for their high quality and security, often leased by credit-worthy tenants like Walmart, Office Depot, CVS, JiffyLube, Jack-in-the-Box, etc. Benefits of Triple Net Lease (NNN) include tenants covering operating expenses, taxes, utilities, insurance, maintenance, and repairs. Landlords receive monthly net income after mortgage payment directly to their online banking. Leases typically span 10 to 25 years due to financially robust corporate guarantees.',
		subTitle: 'The Bottom Line',
		subDescription:
			'A Triple Net Lease suits investors seeking low management involvement. Utilize a 1031 Exchange to transition into new investments, avoiding capital gains tax and enhancing returns with stable, high-grade triple net investments.',
	},
	{
		src: propertyTypeDST,
		title: 'Delaware Statutory Trusts (DST)',
		description:
			'Fractional ownership in strong, grade A properties mirrors REITs. Using Statutory Trust or DST structures, sponsor-issued shares fulfill 1031 requirements, safeguarding against legal issues like bankruptcy or divorce. DSTs cover various classes—multi-family, retail, offices, etc.—yielding stable income via long leases. Sponsors handle acquisition, structuring, finance, management, and share offerings',
		subTitle: 'The Bottom Line',
		subDescription:
			'DST properties are ideal for investors with immediate capital, offering minimal management involvement and steady returns. They cater to those interested in larger building investments without hefty upfront costs, typically starting at $25,000 or more. Loans may be accessible without personal qualifications after this investment threshold. Additionally, alternative structures like UPREITs and Self-Directed IRA Accounts are available alongside DSTs and TICs.',
		footerNote:
			'Disclaimer: DSTs are sold under prospectus; consult with professionals before investing due to limited liquidity.',
	},
	{
		src: propertyTypeVacationProperty,
		title: 'Vacation Properties & Condo Hotels',
		listTitle: 'Vacation properties offer exchange benefits:',
		list: [
			{
				value: 'Managed by third parties for rentals, cleaning, maintenance',
			},
			{
				value: 'Dual usage: vacation and investment',
			},
			{
				value: 'Potentially higher returns than regular SFR rentals',
			},
			{
				value: 'Appreciation potential in desirable areas like Condo Hotels',
			},
		],
		description:
			"Condo hotels, akin to vacation properties, may qualify. Note: Most timeshares don't qualify for 1031 treatment. Condo hotels are real estate investments allowing individual ownership within the hotel industry. Owners can rent out their units daily, with the option to engage in hotel management programs. Revenue sharing varies based on agreements.",
		footerNote: "Note: Most timeshares don't qualify for 1031 treatment.",
	},
	{
		src: propertyTypeRawLand,
		title: 'Raw Land',
		description:
			"Investing in undeveloped land offers high appreciation rates over time. Despite occasional downturns, land values historically rise long-term. It's not purely speculative but bought with personal or other use in mind, aiming for increased value. Prioritize research, assessing land quality, accessibility, seller's services, and utilities. Ensure suitability for your needs and lifestyle, considering location and costs. Some land may suit retirement projects or immediate building.",
		subTitle: 'The Bottom Line',
		subDescription:
			'Improve land but hold for investment before exchange; avoid rezoning pre-exchange. Best strategy: buy, improve, and locate for potential future use. Appreciation yields tax-free investment, possibly leading to mortgage-free second home or retirement residence. Exchange proceeds to enhance existing replacement property.',
	},
	{
		src: propertyTypeOtherInvestments,
		title: 'Other Investments',
		secondaryTitle: 'Oil and Gas, Cell Towers, and Mineral Rights',
		description:
			'Many 1031 exchange investors overlook the fact that oil and gas programs qualify as "like-kind" replacement property. Real estate ownership can involve mineral rights, enabling tax-free exchanges to oil and gas properties. You can swap working or royalty interests for similar ones or even non-energy assets like hotels or offices. Oil and gas investments offer steady demand and limited additional costs, with potential for significant returns. Ideal for mid to late-career investors seeking returns, minimal maintenance, and diversification opportunities.',
		subDescription:
			'Other qualifying investments include Co-Op Apartments, mobile homes on fixed foundations, and leases lasting 30 years or more, such as investments in cell towers.',
	},
]

const PropertyType = () => (
	<div className='p-8 sm:p-12 md:p-24 flex flex-col gap-4 sm:gap-12'>
		<div className='text-gray-500 font-work-sans-light text-43'>
			Property Types
		</div>
		<div className='flex flex-col gap-2'>
			{propertyTypeData.map(
				({
					title,
					description,
					subTitle,
					subDescription,
					subDescriptionSecondary,
					footerNote,
					secondaryTitle,
					listTitle,
					list,
					src,
				}) => (
					<div key={title} className='border-b'>
						<Accordion
							title={title}
							titleClasses='font-work-sans-medium text-lg text-gray-500'
							accordionClasses='aria-expanded:bg-gray-200 p-4 sm:p-6'
						>
							<div className='flex flex-col md:flex-row overflow-hidden gap-6 mt-6 md:gap-0'>
								<div className='w-full md:w-7/12 flex flex-col gap-4 justify-between px-3 sm:px-0 sm:pr-6'>
									{listTitle && (
										<div className='text-gray-500 text-sm font-work-sans-regular'>
											{listTitle}
										</div>
									)}
									{secondaryTitle && (
										<div className='text-gray-500 text-sm font-work-sans-semibold'>
											{secondaryTitle}
										</div>
									)}
									{list && list.length > 0 && (
										<ul className='list-disc p-4'>
											{list.map(({ value }) => (
												<li
													key={value}
													className='text-gray-500 text-sm font-work-sans-regular'
												>
													{value}
												</li>
											))}
										</ul>
									)}
									{description && (
										<div className='text-gray-500 text-sm font-work-sans-regular'>
											{description}
										</div>
									)}
									{subTitle && (
										<div className='text-gray-500 text-sm font-work-sans-semibold'>
											{subTitle}
										</div>
									)}
									{subDescription && (
										<div className='text-gray-500 text-sm font-work-sans-regular'>
											{subDescription}
										</div>
									)}
									{subDescriptionSecondary && (
										<div className='text-gray-500 text-sm font-work-sans-regular'>
											{subDescriptionSecondary}
										</div>
									)}
									{footerNote && (
										<div className='text-center text-gray-400 text-xs font-work-sans-regular'>
											{footerNote}
										</div>
									)}
								</div>
								<div className='w-full md:w-5/12 flex justify-center items-center'>
									<img
										src={src}
										className='w-full object-contain h-full xs:h-80 md:h-full md:object-cover'
									/>
								</div>
							</div>
						</Accordion>
					</div>
				)
			)}
		</div>
	</div>
)

export default PropertyType
