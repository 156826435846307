import React, { useCallback, useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import NavBar from './widgets/NavBar'
import ChatWindow from './widgets/ChatWindow'
import Routes from './Routes'
import './app.css'
import { ToastContainer } from 'react-toastify'
import {
	NavRoutes,
	PLENTI,
	COLORS,
	DEFAULT_VALUES,
	DEFAULT_COLORS,
	AFFILIATE_STATUS,
	SHOW_ALERT_BEFORE_UNLOAD_ROUTES,
} from './utils/constants'
import {
	checkForCurrentRoute,
	generateColorVariables,
	openURLInCurrentTab,
} from './utils/methods'
import { useDispatch, useSelector } from 'react-redux'
import { selectAffiliateSlug } from './features/Affiliates/affiliates.selectors'
import { actions } from './features/Affiliates/affiliates.slice'
import axios from 'axios'
import {
	API_BASE_URL,
	API_ENDPOINT_PREFIX,
} from './infrastructure/http/http.constants'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { get } from 'lodash'
import { loaderPngIcon } from './assets/images'

const { PRIMARY, CONTRAST } = COLORS
const { EMPTY_STRING, FAV_ICON } = DEFAULT_VALUES
const { PENDING, APPROVED, REJECTED } = AFFILIATE_STATUS
const { setAffiliateSlug, setAffiliateDetails } = actions
const { REINVESTMENT_CHOICES, MODEL, HOME } = NavRoutes

function App() {
	const dispatch = useDispatch()
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const [favIcon, setFavIcon] = useState(FAV_ICON)
	const [primaryHexCode, setPrimaryHexCode] = useState(DEFAULT_COLORS[PRIMARY])
	const [contrastHexCode, setContrastHexCode] = useState(
		DEFAULT_COLORS[CONTRAST]
	)
	const contrastColor = generateColorVariables(
		contrastHexCode,
		CONTRAST,
		affiliateSlug
	)
	const primaryColor = generateColorVariables(
		primaryHexCode,
		PRIMARY,
		affiliateSlug
	)

	const getAffiliateDetails = useCallback(
		async (affiliateSlug) => {
			try {
				await axios
					.get(
						`${API_BASE_URL}/${API_ENDPOINT_PREFIX}/affiliates?slug=${affiliateSlug}`
					)
					.then((res) => {
						const affiliateStatus = get(res, 'data.status', EMPTY_STRING)
						if (affiliateStatus === APPROVED) {
							dispatch(
								setAffiliateSlug(get(res, 'data.companySlug', EMPTY_STRING))
							)
							dispatch(setAffiliateDetails(res.data))
							get(res, 'data.primaryColor', EMPTY_STRING) &&
								setPrimaryHexCode(res.data.primaryColor)
							get(res, 'data.contrastColor', EMPTY_STRING) &&
								setContrastHexCode(res.data.contrastColor)
							get(res, 'data.logoURL', EMPTY_STRING) &&
								setFavIcon(res.data.logoURL)
						} else if (
							(affiliateStatus === PENDING || affiliateStatus === REJECTED) &&
							window.location.pathname.includes(MODEL)
						)
							openURLInCurrentTab(MODEL)
						else if (
							(affiliateStatus === PENDING || affiliateStatus === REJECTED) &&
							window.location.pathname.includes(REINVESTMENT_CHOICES)
						)
							openURLInCurrentTab(REINVESTMENT_CHOICES)
						else dispatch(setAffiliateSlug(PLENTI))
					})
			} catch (err) {
				if (window.location.pathname.includes(MODEL)) openURLInCurrentTab(MODEL)
				else if (window.location.pathname.includes(REINVESTMENT_CHOICES))
					openURLInCurrentTab(REINVESTMENT_CHOICES)
				else dispatch(setAffiliateSlug(PLENTI))
			}
		},
		[dispatch]
	)

	useEffect(() => {
		const slug = window.location.pathname.split('/')[1]
		const isPlentiSite = Object.values(NavRoutes).includes(`/${slug}`)
		isPlentiSite
			? dispatch(setAffiliateSlug(PLENTI))
			: getAffiliateDetails(slug)
	}, [getAffiliateDetails, dispatch])

	useEffect(() => {
		if (
			window.location.href.split('/').pop() === EMPTY_STRING &&
			window.location.pathname !== HOME
		)
			window.location.href = window.location.href.replace(/\/+$/, '')

		if (
			affiliateSlug &&
			window.performance
				.getEntriesByType('navigation')
				.map((nav) => nav.type)
				.includes('reload')
		)
			window.sessionStorage.clear()

		const handleBeforeUnload = (e) => {
			if (
				checkForCurrentRoute(SHOW_ALERT_BEFORE_UNLOAD_ROUTES, affiliateSlug)
			) {
				e.preventDefault()
				const message = 'Are you sure! Changes you made may not be saved.'
				e.returnValue = message
				return message
			}
		}

		window.addEventListener('beforeunload', handleBeforeUnload, false)
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}
	}, [affiliateSlug])

	const getCSSVariables = () => {
		if (primaryColor && contrastColor)
			return primaryColor.concat(contrastColor).join(EMPTY_STRING)
	}

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<style type='text/css'>{`:root {${getCSSVariables()} }`}</style>
					<link rel='icon' href={favIcon} />
				</Helmet>
			</HelmetProvider>
			<Router>
				<main className='flex flex-col lg:flex-row font-inter'>
					<ToastContainer newestOnTop />
					{affiliateSlug ? (
						<>
							<NavBar />
							<Routes />
							<ChatWindow />
						</>
					) : (
						<div className='flex flex-col justify-center w-screen h-screen items-center gap-2'>
							<img
								src={loaderPngIcon}
								className='animate-spin'
								width='75'
								height='75'
								alt='spinner-icon'
							/>
							<p className='text-2xl font-bold'>LOADING...</p>
						</div>
					)}
				</main>
			</Router>
		</>
	)
}

export default App
