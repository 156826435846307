import React, { useEffect, useMemo, useState } from 'react'
import { isEmpty } from 'lodash'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useDispatch, useSelector } from 'react-redux'
import { toggleActive } from '../ChatWindow'
import CircularProgressIndicator from '../CircularProgressIndicator'
import {
	menuIcon,
	logoIcon,
	chatIcon,
	arrowLeftIcon,
} from '../../assets/icons/index'
import {
	selectAuthState,
	selectCurrentInvestmentState,
	selectFindInvestmentState,
	selectIntroState,
	selectMoreDetailsState,
	selectNavbarState,
	selectReferralState,
	selectSummaryState,
	selectAffiliateStartApplicationState,
	selectRequestApprovalState,
	selectAffiliateIntro,
	selectTermsState,
} from './navbarStates.selectors'
import { actions } from './navbarStates.slice'
import {
	NavRoutes,
	PLENTI,
	DOC_URL,
	HIDE_LEFT_SIDEBAR_ROUTES,
	HIDE_CHAT_WINDOW_ICON_ROUTES,
} from '../../utils/constants'
import {
	getNavigationRoute,
	openURLInNewTab,
	checkForCurrentRoute,
} from '../../utils/methods'
import {
	selectAffiliateDetails,
	selectAffiliateSlug,
} from '../../features/Affiliates/affiliates.selectors'

const { PRIVACY_POLICY, TERMS_OF_USE } = DOC_URL
const {
	MODEL,
	MEETING_SCHEDULER,
	SUMMARY,
	MORE_DETAILS,
	FIND_INVESTMENTS,
	CURRENT_INVESTMENT,
	AUTHENTICATION,
	REFERRAL_LINK,
	AFFILIATE_START_APPLICATION,
	AFFILIATE_INTRODUCTION,
	AFFILIATE_REQUEST_APPROVAL,
	AFFILIATE_TERMS,
} = NavRoutes

const affiliateSignupFlowRoutes = [
	AFFILIATE_INTRODUCTION,
	AFFILIATE_START_APPLICATION,
	AFFILIATE_REQUEST_APPROVAL,
	AFFILIATE_TERMS,
]

const NavBar = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const isAffiliateSite = affiliateSlug !== PLENTI
	const affiliateDetails = useSelector(selectAffiliateDetails)

	const navbarState = useSelector(selectNavbarState)
	const [menu, setMenu] = useState(false)
	const [shouldShowAgentReferralPage, setShouldShowAgentReferralPage] =
		useState(false)
	const { shouldShowNavbar } = actions
	const isIntroVisited = useSelector(selectIntroState)
	const isCurrentInvestmentVisted = useSelector(selectCurrentInvestmentState)
	const isFindInvestmentVisited = useSelector(selectFindInvestmentState)
	const isMoreDetailsVisited = useSelector(selectMoreDetailsState)
	const isSummaryVisited = useSelector(selectSummaryState)
	const isAuthVisited = useSelector(selectAuthState)
	const isReferralVisited = useSelector(selectReferralState)
	const isAffiliateIntroductionVisited = useSelector(selectAffiliateIntro)
	const isAffiliateStartApplicationVisited = useSelector(
		selectAffiliateStartApplicationState
	)
	const isRequestApprovalVisited = useSelector(selectRequestApprovalState)
	const isTermsVisited = useSelector(selectTermsState)
	const toggleMenu = () => setMenu((menu) => !menu)
	const resetMenu = () => setMenu(false)
	const resetChat = () => window.HubSpotConversations.widget.close()

	const navigateToIntro = () =>
		navigate(getNavigationRoute(affiliateSlug, MODEL))

	const isAffiliateSignupFlow = useMemo(
		() =>
			checkForCurrentRoute(affiliateSignupFlowRoutes, affiliateSlug, location),
		[location, affiliateSlug]
	)

	const shouldShowLeftSideBar = !checkForCurrentRoute(
		HIDE_LEFT_SIDEBAR_ROUTES,
		affiliateSlug
	)

	const shouldShowProgressIndicator = !isAffiliateSignupFlow

	const shouldShowChatWindowIcon = useMemo(
		() =>
			!checkForCurrentRoute(
				HIDE_CHAT_WINDOW_ICON_ROUTES,
				affiliateSlug,
				location
			) && !isAffiliateSignupFlow,
		[location, affiliateSlug, isAffiliateSignupFlow]
	)

	const isScreenVisited = (screen) => Boolean(screen.isVisited)
	const toggleVisitedState = (screen) =>
		screen?.isVisited ? 'bg-green-600' : 'bg-amber-600'
	const handleChatWindow = () => dispatch(toggleActive())

	const navigateToMeetingScheduler = () => {
		dispatch(shouldShowNavbar(false))
		resetMenu()
		isAffiliateSite
			? navigate(`/${affiliateSlug}${MEETING_SCHEDULER}`, {
					state: location.pathname.split('/')[2],
			  })
			: navigate(MEETING_SCHEDULER, { state: location.pathname })
	}

	const getNavLinkStyle = (screen, isActive) => {
		const commonStyle =
			'text-md font-medium leading-6 rounded-md py-2 px-3 flex items-center'
		const activeStyle = 'text-white bg-primary-200'
		const inactiveStyle = 'text-gray-800 hover:text-primary-700'
		if (isScreenVisited(screen) && isActive)
			return `${commonStyle} ${activeStyle}`
		else return `${commonStyle} ${inactiveStyle}`
	}

	useEffect(() => {
		const HUBSPOT_WIDGET = window?.HubSpotConversations?.widget
		if (shouldShowChatWindowIcon && !isEmpty(HUBSPOT_WIDGET)) {
			const status = HUBSPOT_WIDGET.status()
			if (status.loaded) {
				HUBSPOT_WIDGET.remove()
				HUBSPOT_WIDGET.refresh()
			} else HUBSPOT_WIDGET.load()
		}
	}, [shouldShowChatWindowIcon])

	useEffect(() => {
		checkForCurrentRoute([AUTHENTICATION, REFERRAL_LINK], affiliateSlug)
			? setShouldShowAgentReferralPage(true)
			: setShouldShowAgentReferralPage(false)
	}, [affiliateSlug])

	const getLogo = isAffiliateSite ? affiliateDetails.logoURL : logoIcon

	return (
		shouldShowLeftSideBar && (
			<>
				<nav className='flex lg:hidden sticky top-0 z-20 px-6 py-6 w-100 bg-gray-50 border-b border-gray-200'>
					{navbarState.isVisible ? (
						<button
							className='w-10 h-10 grid place-content-center rounded-md hover:bg-slate-100'
							onClick={toggleMenu}
						>
							<img src={menuIcon} width='20' alt='menu-icon' />
						</button>
					) : null}
					<div className='logo mx-auto'>
						<img src={getLogo} width='110' alt='logo' />
					</div>
					{shouldShowChatWindowIcon && (
						<button
							className='w-10 h-10 grid place-content-center rounded-md hover:bg-slate-100'
							onClick={handleChatWindow}
						>
							<img src={chatIcon} width='20' alt='chat-icon' />
						</button>
					)}
				</nav>
				{!shouldShowAgentReferralPage && navbarState.isVisible ? (
					<aside
						className={`fixed transition-all duration-200  lg:static z-50 sidebar w-72 bg-gray-50 border-r border-gray-200 h-screen flex flex-col ${
							menu ? 'left-0' : '-left-72'
						}`}
					>
						<div className='sidebar-header mb-6 p-6'>
							<button
								className='flex items-center h-10 lg:hidden'
								onClick={toggleMenu}
							>
								<img
									src={arrowLeftIcon}
									className='mr-2'
									width='20'
									alt='left-arrow-icon'
								/>
								<span className='text-sm font-medium text-gray-500'>Close</span>
							</button>
							<img
								src={getLogo}
								onClick={navigateToIntro}
								className='mx-auto hidden lg:block'
								width='110'
								alt='logo'
							/>
						</div>
						{isAffiliateSignupFlow ? (
							<Scrollbars className='w-full h-full'>
								<div className='flex-1'>
									<ul className='px-6'>
										<li className='mb-2'>
											<NavLink
												to={AFFILIATE_INTRODUCTION}
												onClick={resetMenu}
												className={({ isActive }) =>
													getNavLinkStyle(
														isAffiliateIntroductionVisited,
														isActive
													)
												}
												end
											>
												<div className='w-2 h-2 rounded-full bg-green-600 mr-3'></div>
												Introduction
											</NavLink>
										</li>
										<li className='mb-2'>
											<NavLink
												to={
													isScreenVisited(isAffiliateStartApplicationVisited)
														? AFFILIATE_START_APPLICATION
														: null
												}
												onClick={resetMenu}
												className={({ isActive }) =>
													getNavLinkStyle(
														isAffiliateStartApplicationVisited,
														isActive
													)
												}
												end
											>
												<div
													className={`w-2 h-2 rounded-full mr-3 ${toggleVisitedState(
														isAffiliateStartApplicationVisited
													)}`}
												></div>
												Start Application
											</NavLink>
										</li>
										<li className='mb-2'>
											<NavLink
												to={
													isScreenVisited(isRequestApprovalVisited)
														? AFFILIATE_REQUEST_APPROVAL
														: null
												}
												className={({ isActive }) =>
													getNavLinkStyle(isRequestApprovalVisited, isActive)
												}
											>
												<div
													className={`w-2 h-2 rounded-full mr-3 ${toggleVisitedState(
														isRequestApprovalVisited
													)}`}
												></div>
												Request Approval
											</NavLink>
										</li>
										<li className='mb-2'>
											<NavLink
												to={
													isScreenVisited(isTermsVisited)
														? AFFILIATE_TERMS
														: null
												}
												className={({ isActive }) =>
													getNavLinkStyle(isTermsVisited, isActive)
												}
											>
												<div
													className={`w-2 h-2 rounded-full mr-3 ${toggleVisitedState(
														isTermsVisited
													)}`}
												></div>
												Terms
											</NavLink>
										</li>
									</ul>
								</div>
							</Scrollbars>
						) : (
							<Scrollbars className='w-full h-full'>
								<div className='flex-1'>
									<ul className='px-6'>
										<li className='mb-2'>
											<NavLink
												to={getNavigationRoute(affiliateSlug, MODEL)}
												onClick={(resetChat, resetMenu)}
												className={({ isActive }) =>
													getNavLinkStyle(isIntroVisited, isActive)
												}
												end
											>
												<div className='w-2 h-2 rounded-full bg-green-600 mr-3'></div>
												Intro
											</NavLink>
										</li>
										<li className='mb-2'>
											<NavLink
												to={
													isScreenVisited(isCurrentInvestmentVisted)
														? getNavigationRoute(
																affiliateSlug,
																CURRENT_INVESTMENT
														  )
														: null
												}
												onClick={resetMenu}
												className={({ isActive }) =>
													getNavLinkStyle(isCurrentInvestmentVisted, isActive)
												}
											>
												<div
													className={`w-2 h-2 rounded-full mr-3 ${toggleVisitedState(
														isCurrentInvestmentVisted
													)}`}
												></div>
												Your Current Investment
											</NavLink>
										</li>
										<li className='mb-2'>
											<NavLink
												to={
													isScreenVisited(isFindInvestmentVisited)
														? getNavigationRoute(
																affiliateSlug,
																FIND_INVESTMENTS
														  )
														: null
												}
												onClick={resetMenu}
												className={({ isActive }) =>
													getNavLinkStyle(isFindInvestmentVisited, isActive)
												}
											>
												<div
													className={`w-2 h-2 rounded-full ${toggleVisitedState(
														isFindInvestmentVisited
													)} mr-3`}
												></div>
												Find Investments
											</NavLink>
										</li>
										<li className='mb-2'>
											<NavLink
												to={
													isScreenVisited(isMoreDetailsVisited)
														? getNavigationRoute(affiliateSlug, MORE_DETAILS)
														: null
												}
												onClick={resetMenu}
												className={({ isActive }) =>
													getNavLinkStyle(isMoreDetailsVisited, isActive)
												}
											>
												<div
													className={`w-2 h-2 rounded-full mr-3 ${toggleVisitedState(
														isMoreDetailsVisited
													)}`}
												></div>
												More Details
											</NavLink>
										</li>
										<li className='mb-2'>
											<NavLink
												to={
													isScreenVisited(isSummaryVisited)
														? getNavigationRoute(affiliateSlug, SUMMARY)
														: null
												}
												onClick={resetMenu}
												className={({ isActive }) =>
													getNavLinkStyle(isSummaryVisited, isActive)
												}
											>
												<div
													className={`w-2 h-2 rounded-full mr-3 ${toggleVisitedState(
														isSummaryVisited
													)}`}
												></div>
												Summary
											</NavLink>
										</li>
									</ul>
								</div>
							</Scrollbars>
						)}
						<div className='menu-footer p-6'>
							{shouldShowProgressIndicator && <CircularProgressIndicator />}
							<button
								className='btn btn-light w-full'
								onClick={navigateToMeetingScheduler}
							>
								Schedule a Consultation
							</button>
							<div className='mt-2 text-gray-600 text-sm flex justify-around px-4 font-bold'>
								<div
									onClick={() => openURLInNewTab(PRIVACY_POLICY)}
									className='cursor-pointer'
								>
									Privacy Policy
								</div>
								|
								<div
									onClick={() => openURLInNewTab(TERMS_OF_USE)}
									className='cursor-pointer'
								>
									Terms of Use
								</div>
							</div>
						</div>
					</aside>
				) : null}
				{shouldShowAgentReferralPage && (
					<aside
						className={`fixed transition-all duration-200  lg:static z-50 sidebar w-72 bg-gray-50 border-r border-gray-200 h-screen flex flex-col ${
							menu ? 'left-0' : '-left-72'
						}`}
					>
						<div className='sidebar-header mb-6 p-6'>
							<button
								className='flex items-center h-10 lg:hidden'
								onClick={toggleMenu}
							>
								<img
									src={arrowLeftIcon}
									className='mr-2'
									width='20'
									alt='left-arrow-icon'
								/>
								<span className='text-sm font-medium text-gray-500'>Close</span>
							</button>
							<img
								src={getLogo}
								onClick={() => {
									navigate(getNavigationRoute(affiliateSlug, MODEL))
									setShouldShowAgentReferralPage(false)
								}}
								className='mx-auto hidden lg:block'
								width='110'
								alt='logo'
							/>
						</div>
						<Scrollbars className='w-full h-full'>
							<div className='flex-1'>
								<ul className='px-6'>
									<li className='mb-2'>
										<NavLink
											to={getNavigationRoute(affiliateSlug, AUTHENTICATION)}
											onClick={() => {
												resetMenu()
											}}
											className={({ isActive }) =>
												getNavLinkStyle(isAuthVisited, isActive)
											}
										>
											<div className='w-2 h-2 rounded-full bg-green-600 mr-3'></div>
											Authentication
										</NavLink>
									</li>
									<li className='mb-2'>
										<NavLink
											to={
												isScreenVisited(isReferralVisited)
													? getNavigationRoute(affiliateSlug, REFERRAL_LINK)
													: null
											}
											onClick={resetMenu}
											className={({ isActive }) =>
												getNavLinkStyle(isReferralVisited, isActive)
											}
										>
											<div
												className={`w-2 h-2 rounded-full mr-3 ${toggleVisitedState(
													isReferralVisited
												)}`}
											></div>
											Referral Link
										</NavLink>
									</li>
								</ul>
							</div>
						</Scrollbars>
					</aside>
				)}
				{menu && (
					<div className='sidebar-overlay z-40 w-full h-full fixed bg-gray-800/80 left-0 top-0 backdrop-blur-xl'></div>
				)}
			</>
		)
	)
}

export default NavBar
