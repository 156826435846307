import React, { useRef, useState } from 'react'
import {
	arrowRightWhite,
	email,
	phoneGrayScale,
	grayCirclePlaySolid,
} from '../../assets/icons'
import { whatIs1031Exchange } from '../../assets/images'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { validateMobileNumber } from '../../utils/methods'
import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'
import {
	ERROR_MESSAGES,
	getRequiredErrorMessage,
	VIDEOS_URL,
	PLENTI,
	NavRoutes,
	PLAY,
} from '../../utils/constants'
import { NameRegularExpression } from '../../utils/constants.regex'
import { useDispatch, useSelector } from 'react-redux'
import { saveContactOnHubspot } from '../../features/Reinvestment/ReinvestmentAffiliate.asynAction'
import Loading from '../../widgets/Loading/Loading'
import {
	selectAffiliateDetails,
	selectAffiliateSlug,
} from '../../features/Affiliates/affiliates.selectors'
import TaxSavingStepsGenericModal from './TaxSavingStepsGenericModal'

const {
	MIN_LENGTH_2,
	MAX_LENGTH_255,
	FIRST_NAME_VALIDATE_REGREX,
	MOBILE_NUMBER,
	LAST_NAME_VALIDATE_REGREX,
	EMAIL_VALIDATE_SPACE,
} = ERROR_MESSAGES

const { ROADMAP } = NavRoutes

const initialValues = {
	firstName: '',
	lastName: '',
	email: '',
	phoneNumber: '',
}

const nameSchema = (fieldName, regex, errorMessage) =>
	Yup.string()
		.min(2, MIN_LENGTH_2)
		.max(255, MAX_LENGTH_255)
		.matches(regex, errorMessage)
		.required(getRequiredErrorMessage(fieldName))

const validationSchema = Yup.object().shape({
	firstName: nameSchema(
		'First Name',
		NameRegularExpression,
		FIRST_NAME_VALIDATE_REGREX
	),
	lastName: nameSchema(
		'Last Name',
		NameRegularExpression,
		LAST_NAME_VALIDATE_REGREX
	),
	email: Yup.string()
		.email(EMAIL_VALIDATE_SPACE)
		.required(getRequiredErrorMessage('email')),
	phoneNumber: Yup.string()
		.test('phoneNumber', MOBILE_NUMBER, validateMobileNumber)
		.required(getRequiredErrorMessage('phone number')),
})

const schedulerFields = [
	{
		label: 'First Name',
		name: 'firstName',
		placeholder: 'First Name',
		className: 'w-1/2 pr-2',
	},
	{
		label: 'Last Name',
		name: 'lastName',
		placeholder: 'Last Name',
		className: 'w-1/2 pl-2',
	},
	{
		label: 'Email',
		name: 'email',
		placeholder: 'Enter Email',
		icon: email,
	},
	{
		label: 'Phone Number',
		name: 'phoneNumber',
		type: 'number',
		placeholder: '(___) ___ - ____',
		icon: phoneGrayScale,
	},
]

const ReinvestmentModal = ({ handleClose }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const affiliateDetails = useSelector(selectAffiliateDetails)
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const videoRef = useRef(null)
	const [isPlaying, setIsPlaying] = useState(false)
	const [isLoading, setIsLoading] = useState(true)

	const videoPlaying = (event) => setIsPlaying(event.type === PLAY)

	const handlePlay = () => videoRef.current && videoRef.current.play()

	const handleLoadedMetadata = () => setIsLoading(false)

	const handleFormSubmission = (values, { setSubmitting }) => {
		const affiliate = affiliateDetails?.companySlug || ''
		const request = { ...values, affiliate, urlReferrerRoadmap: '1' }
		dispatch(saveContactOnHubspot(request)).then(() => {
			setSubmitting(false)
			navigate(
				affiliateSlug && affiliateSlug !== PLENTI
					? `/${affiliateSlug}${ROADMAP}`
					: ROADMAP
			)
		})
	}

	return (
		<TaxSavingStepsGenericModal handleClose={handleClose}>
			<div className='sm:flex flex-1 overflow-auto no-scrollbar'>
				<div className='w-full sm:w-7/12 h-64 sm:h-auto bg-solitude relative'>
					<video
						ref={videoRef}
						preload='metadata'
						controls
						poster={whatIs1031Exchange}
						className='w-full relative h-full'
						onPlay={videoPlaying}
						onPause={videoPlaying}
						onLoadedMetadata={handleLoadedMetadata}
					>
						<source src={VIDEOS_URL.WHAT_IS_1031_EXCHANGE} type='video/mp4' />
					</video>
					{!isPlaying && !isLoading && (
						<img
							src={grayCirclePlaySolid}
							onClick={handlePlay}
							className='absolute top-1/2 left-1/2 opacity-50 hover:opacity-100 h-12 w-12 sm:h-16 sm:w-16 md:w-20 md:h-20 cursor-pointer transition duration-500 transform -translate-x-1/2 -translate-y-1/2'
						/>
					)}
				</div>
				<div className='w-full sm:w-5/12 no-scrollbar overflow-auto'>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={handleFormSubmission}
					>
						{({ isSubmitting }) => (
							<Form>
								<div className='flex flex-col p-6 w-full'>
									<div>
										<div>
											<div className='font-work-sans-semibold text-gray-900 text-base sm:text-xl'>
												Have More Questions?
											</div>
											<div className='font-work-sans-medium text-sm sm:text-lg text-gray-500 mt-2'>
												Request your free 1031 Exchange Roadmap from Plenti
												Financial. Please give us your information so we can
												send you your Roadmap.
											</div>
										</div>
										<div className='form-group flex flex-wrap gap-y-4 sm:gap-y-6 pt-6 font-work-sans-medium text-sm text-gray-700'>
											{schedulerFields.map(
												({
													label,
													name,
													icon,
													placeholder,
													className = 'w-full',
													...props
												}) => (
													<label key={name} className={className}>
														<span>
															{label}
															<span className='text-red-700 ml-0.5'>*</span>
														</span>
														<div className='flex items-center relative mt-2'>
															{icon && (
																<img
																	src={icon}
																	alt='icon'
																	className='absolute left-3'
																/>
															)}
															<Field
																name={name}
																className={classnames(
																	'form-control focus:border-gray-300 focus:ring-2 focus:ring-offset-gray-100 focus:ring-blue-200 remove-arrow placeholder-gray-500 border border-gray-400 font-work-sans-regular',
																	{
																		'pl-10': icon,
																	}
																)}
																placeholder={placeholder}
																{...props}
															/>
														</div>
														<ErrorMessage
															name={name}
															className='-z-10 text-red-500 text-xs bg-error-500 mt-[-10px] pt-3 pl-2 rounded-b-md'
															component='div'
														/>
													</label>
												)
											)}
										</div>
									</div>
									<div className='w-full pt-6 flex flex-col gap-2'>
										<button
											className='btn btn-primary-light rounded-full w-full text-white hover:bg-primary-100 font-work-sans-semibold flex justify-center items-center gap-3 duration-100 px-4 py-3'
											type='submit'
										>
											<Loading loading={isSubmitting}>
												<div className='flex justify-center items-center gap-2'>
													Send Me My Roadmap
													<img src={arrowRightWhite} />
												</div>
											</Loading>
										</button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</TaxSavingStepsGenericModal>
	)
}

export default ReinvestmentModal
