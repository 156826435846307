import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { Modal } from '../../../widgets/Modal'
import {
	alertCircleIcon,
	greenCheckIcon,
	poweredByPlenti,
	redCloseIcon,
} from '../../../assets/icons'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	shouldActivate,
	shouldShowNavbar,
	selectFindInvestmentState,
} from '../../../widgets/NavBar'
import { updateProgressPercentage } from '../../../widgets/CircularProgressIndicator'
import { NumericFormat } from 'react-number-format'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
	getCurrencyCode,
	isObjectEmpty,
	isSessionAvailable,
	convertToNumber,
	numberCommasSeparator,
	removeSpecialCharacter,
	stringToBooleanConversion,
	getNavigationRoute,
	allowedInputValues,
} from '../../../utils/methods'
import {
	propertyTypes,
	maritalStatusOptions,
	getMaritalStatus,
	getPropertyTypes,
	formerResidenceProp,
	maritalStatusProp,
	propertyUseForProps,
	propertyWorthProps,
	resetAllValues,
	resetMaritalStatus,
	resetPurchasedProperty,
	setInitialValues,
} from './investmentForm.constants'
import {
	NavRoutes,
	SessionKeys,
	ERROR_MESSAGE_CLASSES,
	DEFAULT_VALUES,
	PLENTI,
	USD,
	COMMA_SEPRATOR,
} from '../../../utils/constants'
import {
	NavLinkStates,
	PropertyTypes,
	MarriedTypes,
} from '../../../utils/constant.enum'
import { selectCurrentInvestmentDetails } from '../CurrentInvestment'
import { saveCurrentInvestments } from './investmentForm.asyncAction'
import {
	selectCurrentInvestment,
	selectInvestmentFormFields,
	selectInvestmentFormStates,
	selectTypeOfProperty,
	selectMaritalStatus,
} from './investmentForm.selectors'
import BusyIndicator, {
	getGlobalBusyIndicator,
} from '../../../widgets/busyIndicator'
import { actions } from './investmentForm.slice'
import classnames from 'classnames'
import {
	selectAffiliateDetails,
	selectAffiliateSlug,
} from '../../Affiliates/affiliates.selectors'

const YES = 'Yes'
const NO = 'No'
const MY_RESIDENCE = 'My Residence'
const NOT_MARRIED = ['Single', 'Divorced/Separated', 'Widowed']
const MARRIED = 'Married'
const ZERO = 0
const { EMPTY_STRING } = DEFAULT_VALUES

const InvestmentForm = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const location = useLocation()
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const typeOfProperty = useSelector(selectTypeOfProperty)
	const customerMaritalStatus = useSelector(selectMaritalStatus)
	const isAffiliateSite = affiliateSlug !== PLENTI
	const [isPropertySelected, setIsPropertySelected] = useState(typeOfProperty)
	const [isMaritalStatusSelected, setisMaritalStatusSelected] = useState(
		customerMaritalStatus
	)
	const streetAddressData = useSelector(selectCurrentInvestmentDetails)
	const investmentData = useSelector(selectCurrentInvestment)
	const { totalEquity } = investmentData
	const investmentFormStates = useSelector(selectInvestmentFormStates)
	const investmentFormFields = useSelector(selectInvestmentFormFields)
	const busyIndicatorState = useSelector(getGlobalBusyIndicator)
	const {
		setInvestmentFormStates,
		setInvestmentFormFields,
		setTypeOfProperty,
		setMaritalStatus,
	} = actions
	const {
		shouldShowFormerResidence,
		shouldShowMaritalStatusField,
		shouldShowPropertyMoreThanQuarterMillion,
		shouldShowPropertyMoreThanHalfMillion,
		shouldShowPropertyFieldsCategorizedIn,
	} = investmentFormStates
	const {
		purchasePrice,
		propertyValue,
		debtAmount,
		propertyType,
		isFormerResidence,
		maritalStatus,
		hasPropertyGoneUpMT250K,
		hasPropertyGoneUpMT500K,
		isPropertyCommercial,
		isPropertyRental,
		isPropertyInUse,
	} = investmentFormFields
	const { FIND_INVESTMENTS, CURRENT_INVESTMENT, MEETING_SCHEDULER } = NavRoutes
	const { USER_ID, REFERRAL_ID } = SessionKeys
	const isFindInvestmentVisited = useSelector(
		selectFindInvestmentState
	).isVisited
	const [shouldShow1031QualifiedModal, setShouldShow1031QualifiedModal] =
		useState(false)
	const [
		shouldShowExchangeQualifiedModal,
		setShouldShowExchangeQualifiedModal,
	] = useState(false)
	const [shouldShow1031NotQualifiedModal, setShouldShow1031NotQualifiedModal] =
		useState(false)
	const [isAmountToReinvestCalculated, setIsAmountToReinvestCalculated] =
		useState(false)
	const [fieldsVisibility, setFieldsVisibility] = useState({
		shouldShowFormerResidence: shouldShowFormerResidence || false,
		shouldShowMaritalStatusField: shouldShowMaritalStatusField || false,
		shouldShowPropertyMoreThanQuarterMillion:
			shouldShowPropertyMoreThanQuarterMillion || false,
		shouldShowPropertyMoreThanHalfMillion:
			shouldShowPropertyMoreThanHalfMillion || false,
		shouldShowPropertyFieldsCategorizedIn:
			shouldShowPropertyFieldsCategorizedIn || false,
	})
	const affiliateDetails = useSelector(selectAffiliateDetails)
	const affiliateOrganization = get(
		affiliateDetails,
		'companyName',
		EMPTY_STRING
	)

	const onChangePropertyType = (
		event,
		setFieldValue,
		setFieldTouched,
		formerResidenceProp,
		maritalStatusProp,
		propertyWorthProps,
		propertyUseForProps
	) => {
		setFieldsVisibility({
			shouldShowFormerResidence: false,
			shouldShowMaritalStatusField: false,
			shouldShowPropertyMoreThanQuarterMillion: false,
			shouldShowPropertyMoreThanHalfMillion: false,
			shouldShowPropertyFieldsCategorizedIn: false,
		})
		resetAllValues(
			setFieldValue,
			setFieldTouched,
			formerResidenceProp,
			maritalStatusProp,
			propertyWorthProps,
			propertyUseForProps
		)
		if (event.target.value === MY_RESIDENCE) {
			setFieldsVisibility({
				...fieldsVisibility,
				shouldShowFormerResidence: true,
			})
		}
	}
	const onChangeFormerResidence = (e) => {
		if (e.target.value === NO) {
			setFieldsVisibility({
				...fieldsVisibility,
				shouldShowMaritalStatusField: true,
			})
		} else {
			setFieldsVisibility({
				...fieldsVisibility,
				shouldShowPropertyFieldsCategorizedIn: false,
				shouldShowPropertyMoreThanQuarterMillion: false,
				shouldShowPropertyMoreThanHalfMillion: false,
				shouldShowMaritalStatusField: false,
			})
		}
	}
	const onChangeMaritalStatus = (
		e,
		setFieldValue,
		setFieldTouched,
		propertyWorthProps,
		propertyUseForProps
	) => {
		const [hasPropertyGoneUpMT250K, hasPropertyGoneUpMT500K] =
			propertyWorthProps
		setFieldValue(hasPropertyGoneUpMT250K, '')
		resetPurchasedProperty(setFieldValue, setFieldTouched, propertyUseForProps)
		if (NOT_MARRIED.includes(e.target.value)) {
			setFieldsVisibility({
				...fieldsVisibility,
				shouldShowPropertyMoreThanHalfMillion: false,
				shouldShowPropertyMoreThanQuarterMillion: true,
				shouldShowPropertyFieldsCategorizedIn: false,
			})
			setFieldValue(hasPropertyGoneUpMT500K, '')
			setFieldTouched(hasPropertyGoneUpMT500K, false)
		} else if (e.target.value === MARRIED) {
			setFieldsVisibility({
				...fieldsVisibility,
				shouldShowPropertyMoreThanQuarterMillion: false,
				shouldShowPropertyMoreThanHalfMillion: true,
				shouldShowPropertyFieldsCategorizedIn: false,
			})
			setFieldValue(hasPropertyGoneUpMT250K, '')
			setFieldTouched(hasPropertyGoneUpMT250K, false)
		} else {
			setFieldValue(hasPropertyGoneUpMT500K, '')
			setFieldTouched(hasPropertyGoneUpMT500K, false)
			setFieldValue(hasPropertyGoneUpMT250K, '')
			setFieldTouched(hasPropertyGoneUpMT250K, false)
			setFieldsVisibility({
				...fieldsVisibility,
				shouldShowPropertyMoreThanHalfMillion: false,
				shouldShowPropertyMoreThanQuarterMillion: false,
				shouldShowPropertyFieldsCategorizedIn: false,
			})
		}
	}
	const onChangePurchasedProperty = (e) => {
		const isSelected = Boolean(e.target.value === YES)
		setFieldsVisibility({
			...fieldsVisibility,
			shouldShowPropertyFieldsCategorizedIn: isSelected,
		})
	}
	const createCurrentInvestment = (requestObject, values) => {
		const userId = get(window.sessionStorage, USER_ID, null)
		const referralId = get(window.sessionStorage, REFERRAL_ID, null)
		dispatch(
			setInvestmentFormStates({
				...fieldsVisibility,
			})
		)
		dispatch(
			setInvestmentFormFields({
				...values,
			})
		)
		const {
			purchasePrice,
			propertyValue,
			debtAmount,
			propertyType,
			isFormerResidence,
			hasPropertyGoneUpMT250K,
			hasPropertyGoneUpMT500K,
			maritalStatus,
		} = requestObject
		dispatch(
			saveCurrentInvestments({
				...streetAddressData,
				...(userId && { userId }),
				...(referralId && { referralId }),
				...(affiliateOrganization && { affiliateOrganization }),
				...(maritalStatus && { maritalStatus }),
				purchasePrice,
				propertyValue,
				debtAmount,
				propertyType,
				isFormerResidence,
				hasPropertyGoneUpMT250K,
				hasPropertyGoneUpMT500K,
				isOfficeUse: requestObject.isPropertyCommercial,
				isRental: requestObject.isPropertyRental,
				isVacantOrAgricultural: requestObject.isPropertyInUse,
			})
		)
	}
	const initialValues = {
		purchasePrice: setInitialValues(purchasePrice),
		propertyValue: setInitialValues(propertyValue),
		debtAmount: setInitialValues(debtAmount),
		propertyType: setInitialValues(propertyType),
		isFormerResidence: setInitialValues(isFormerResidence),
		maritalStatus: setInitialValues(maritalStatus),
		hasPropertyGoneUpMT250K: setInitialValues(hasPropertyGoneUpMT250K),
		hasPropertyGoneUpMT500K: setInitialValues(hasPropertyGoneUpMT500K),
		isPropertyCommercial: setInitialValues(isPropertyCommercial),
		isPropertyRental: setInitialValues(isPropertyRental),
		isPropertyInUse: setInitialValues(isPropertyInUse),
	}
	const validationSchema = Yup.object().shape({
		purchasePrice: Yup.string('Please enter a valid price')
			.max(15, 'More then 14 characters')
			.required('Enter your Purchase Price'),
		propertyValue: Yup.string('Please enter a valid value')
			.max(15, 'More then 14 characters')
			.required('Enter your Property Value'),
		debtAmount: Yup.string('Please enter a valid amount')
			.max(15, 'More then 14 characters')
			.required('Enter your Debt Amount'),
		propertyType: Yup.string()
			.oneOf(propertyTypes, 'Please select one property type.')
			.required('Please select one property type.'),
		isFormerResidence: Yup.string().test(
			'isValid',
			'Please select a value',
			(val) => Boolean(!fieldsVisibility.shouldShowFormerResidence || val)
		),
		maritalStatus: Yup.string().test(
			'isValid',
			'Please select a value',
			(val) =>
				!fieldsVisibility.shouldShowMaritalStatusField
					? true
					: val && maritalStatusOptions.includes(val)
		),
		hasPropertyGoneUpMT250K: Yup.string().test(
			'isValid',
			'Please select a value',
			(val) =>
				Boolean(
					!fieldsVisibility.shouldShowPropertyMoreThanQuarterMillion || val
				)
		),
		hasPropertyGoneUpMT500K: Yup.string().test(
			'isValid',
			'Please select a value',
			(val) =>
				Boolean(!fieldsVisibility.shouldShowPropertyMoreThanHalfMillion || val)
		),
		isPropertyCommercial: Yup.string().test(
			'isValid',
			'Please select a value',
			(val) =>
				Boolean(!fieldsVisibility.shouldShowPropertyFieldsCategorizedIn || val)
		),
		isPropertyRental: Yup.string().test(
			'isValid',
			'Please select a value',
			(val) =>
				Boolean(!fieldsVisibility.shouldShowPropertyFieldsCategorizedIn || val)
		),
		isPropertyInUse: Yup.string().test(
			'isValid',
			'Please select a value',
			(val) =>
				Boolean(!fieldsVisibility.shouldShowPropertyFieldsCategorizedIn || val)
		),
	})
	const navigateToCurrentInvestment = () =>
		navigate(getNavigationRoute(affiliateSlug, CURRENT_INVESTMENT))
	const navigateToFindInvestment = () => {
		dispatch(shouldActivate(NavLinkStates.FIND_INVESTMENTS))
		if (!isFindInvestmentVisited) dispatch(updateProgressPercentage())
		navigate(getNavigationRoute(affiliateSlug, FIND_INVESTMENTS))
	}
	const navigateToMeetingScheduler = () => {
		dispatch(shouldShowNavbar(false))
		navigate(getNavigationRoute(affiliateSlug, MEETING_SCHEDULER), {
			state: location.pathname,
		})
	}
	const prevBtn = {
		title: 'Back',
		classes: 'btn-light',
	}
	const reInvestQualifyModal = {
		title: 'Continue',
		isDisabled: !isAmountToReinvestCalculated,
		click: navigateToFindInvestment,
	}
	const qualifyExchangeModal = {
		title: 'Continue Anyway',
		isDisabled: !isAmountToReinvestCalculated,
		click: navigateToFindInvestment,
	}
	const scheduleConsultationModal = {
		title: 'Schedule a Consultation',
		classes: 'text-xs',
		click: navigateToMeetingScheduler,
	}
	const reInvestQualifyIcon = {
		icon: greenCheckIcon,
		iconBackground: 'bg-green-100 ring-8 ring-green-50',
	}
	const qualifyExchangeIcon = {
		icon: alertCircleIcon,
		iconBackground: 'bg-orange-100 ring-8 ring-orange-50',
	}
	const scheduleConsultationIcon = {
		icon: redCloseIcon,
		iconBackground: 'bg-red-300/50 ring-8 ring-red-50',
	}
	const isQualified1031Exchange = (values) => {
		return !!(values.propertyType !== MY_RESIDENCE)
	}
	const isExchangeQualified = (values) => {
		return !!(
			values.isFormerResidence === YES ||
			values.isPropertyCommercial === YES ||
			values.isPropertyRental === YES ||
			values.isPropertyInUse === YES
		)
	}

	const numericConversion = (values) => {
		values.purchasePrice = convertToNumber(
			removeSpecialCharacter(values.purchasePrice)
		)
		values.debtAmount = convertToNumber(
			removeSpecialCharacter(values.debtAmount)
		)
		values.propertyValue = convertToNumber(
			removeSpecialCharacter(values.propertyValue)
		)
		return values
	}
	const radioFieldConversion = (values) => {
		values.isFormerResidence = stringToBooleanConversion(
			values.isFormerResidence
		)
		values.hasPropertyGoneUpMT500K = stringToBooleanConversion(
			values.hasPropertyGoneUpMT500K
		)
		values.hasPropertyGoneUpMT250K = stringToBooleanConversion(
			values.hasPropertyGoneUpMT250K
		)
		values.isPropertyCommercial = stringToBooleanConversion(
			values.isPropertyCommercial
		)
		values.isPropertyInUse = stringToBooleanConversion(values.isPropertyInUse)
		values.isPropertyRental = stringToBooleanConversion(values.isPropertyRental)
		return values
	}
	const propertyTypeConversion = (values) => {
		values.propertyType = PropertyTypes[values.propertyType]
		return values
	}
	const marriedTypeConversion = (values) => {
		if (values.maritalStatus !== '')
			values.maritalStatus = MarriedTypes[values.maritalStatus]
		else values.maritalStatus = null
		return values
	}
	const getAddress = () => {
		const { street, city, state, zipCode } = streetAddressData
		const completeAddress = `${street}, ${city} ${state} ${zipCode}`
		return completeAddress
	}
	const onFormSubmit = (values, { setSubmitting, resetForm }) => {
		let requestObject = { ...values }
		requestObject = numericConversion(requestObject)
		requestObject = propertyTypeConversion(requestObject)
		requestObject = radioFieldConversion(requestObject)
		requestObject = marriedTypeConversion(requestObject)
		if (isQualified1031Exchange(values)) {
			setShouldShow1031QualifiedModal(true)
			createCurrentInvestment(requestObject, values)
		} else if (isExchangeQualified(values)) {
			setShouldShowExchangeQualifiedModal(true)
			createCurrentInvestment(requestObject, values)
		} else {
			setShouldShow1031NotQualifiedModal(true)
		}
		setTimeout(() => {
			setSubmitting(false)
		}, 400)
	}
	const validateTypeOfProperty = ({ target }) => {
		dispatch(setTypeOfProperty(target?.value))
		setIsPropertySelected(!!target?.value?.length)
	}
	const validateMaritalStatus = ({ target }) => {
		dispatch(setMaritalStatus(target?.value))
		setisMaritalStatusSelected(!!target?.value?.length)
	}
	useEffect(() => {
		if (busyIndicatorState) setIsAmountToReinvestCalculated(false)
		else setIsAmountToReinvestCalculated(true)
		if (!isSessionAvailable(USER_ID) && !isObjectEmpty(investmentData)) {
			const { userId } = investmentData
			window.sessionStorage.setItem(USER_ID, userId)
		}
	}, [investmentData, USER_ID, totalEquity, busyIndicatorState])
	return (
		<>
			<div
				className={`xl:px-22 xl:pb-22 px-12 pb-12 ${
					isAffiliateSite ? 'xl:pt-7 p-2' : 'xl:pt-22 pt-12'
				}`}
			>
				{isAffiliateSite && (
					<div className='flex justify-end xl:pb-7 pb-1'>
						<img src={poweredByPlenti} alt='Powered By Plenti Logo' />
					</div>
				)}
				<h1 className='text-xl font-bold leading-7 text-gray-900 mb-3'>
					Your Current Investment
				</h1>
				<h2 className='text-3xl font-bold leading-7 text-gray-900 mb-3'>
					{!isObjectEmpty(streetAddressData) && getAddress()}
				</h2>
				<p className='text-gray-700 mb-6'>
					Enter your information to determine your estimated tax on sale and
					learn how to eliminate it with an exchange.
				</p>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					validateOnBlur={false}
					onSubmit={(values, { setSubmitting, resetForm }) =>
						onFormSubmit(values, { setSubmitting, resetForm })
					}
				>
					{({
						values,
						touched,
						errors,
						handleBlur,
						handleChange,
						handleSubmit,
						setFieldValue,
						setFieldTouched,
					}) => (
						<>
							<form onSubmit={handleSubmit}>
								<div className='form-group mb-6'>
									<label
										className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'
										htmlFor='purchasePrice'
									>
										How much did you originally purchase the property for?
									</label>
									<NumericFormat
										id='purchasePrice'
										name='purchasePrice'
										type='text'
										className='form-control'
										placeholder='Purchase Price'
										allowNegative={false}
										decimalScale={2}
										decimalSeparator={'.'}
										prefix={getCurrencyCode(USD)}
										thousandSeparator={COMMA_SEPRATOR}
										isAllowed={(purchasePrice) =>
											allowedInputValues(purchasePrice)
										}
										value={values.purchasePrice}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									{touched.purchasePrice && errors.purchasePrice && (
										<p className={ERROR_MESSAGE_CLASSES}>
											{errors.purchasePrice}
										</p>
									)}
									<p className='text-sm font-normal text-gray-500'>
										If you have exchanged into this investment, enter the
										original purchase price amount for your initial investment
										which started your exchange(s).
									</p>
								</div>

								<div className='form-group mb-6'>
									<label
										className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'
										htmlFor='propertyValue'
									>
										How much is your property worth?
									</label>
									<NumericFormat
										id='propertyValue'
										name='propertyValue'
										type='text'
										className='form-control'
										placeholder='Property Value'
										allowNegative={false}
										decimalScale={2}
										decimalSeparator={'.'}
										prefix={getCurrencyCode(USD)}
										thousandSeparator={COMMA_SEPRATOR}
										isAllowed={(propertyValue) =>
											allowedInputValues(propertyValue)
										}
										value={values.propertyValue}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									{touched.propertyValue && errors.propertyValue && (
										<p className={ERROR_MESSAGE_CLASSES}>
											{errors.propertyValue}
										</p>
									)}
								</div>

								<div className='form-group mb-6'>
									<label
										className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'
										htmlFor='debtAmount'
									>
										How much do you owe on your property?
									</label>
									<NumericFormat
										id='debtAmount'
										name='debtAmount'
										type='text'
										className='form-control'
										placeholder='Debt Amount'
										allowNegative={false}
										decimalScale={2}
										decimalSeparator={'.'}
										prefix={getCurrencyCode(USD)}
										thousandSeparator={COMMA_SEPRATOR}
										isAllowed={(debtAmount) => allowedInputValues(debtAmount)}
										value={values.debtAmount}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									{touched.debtAmount && errors.debtAmount && (
										<p className={ERROR_MESSAGE_CLASSES}>{errors.debtAmount}</p>
									)}
								</div>

								<div className='address-block'>
									<div className='form-group mb-6'>
										<label
											className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'
											htmlFor='propertyType'
										>
											What kind of property is this?
										</label>
										<select
											id='propertyType'
											name='propertyType'
											className={classnames('form-control custom-select', {
												'text-gray-400': !isPropertySelected,
											})}
											onChange={(e) => {
												onChangePropertyType(
													e,
													setFieldValue,
													setFieldTouched,
													formerResidenceProp,
													maritalStatusProp,
													propertyWorthProps,
													propertyUseForProps
												)
												handleChange(e)
												validateTypeOfProperty(e)
											}}
											onBlur={handleBlur}
											value={values.propertyType}
										>
											<option value=''>Select Property Type</option>
											{getPropertyTypes}
										</select>
										{touched.propertyType && errors.propertyType && (
											<p className={ERROR_MESSAGE_CLASSES}>
												{errors.propertyType}
											</p>
										)}
									</div>
									<div>
										{fieldsVisibility.shouldShowFormerResidence && (
											<div className='form-group mb-6'>
												<label className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'>
													Is this a former residence?
												</label>
												<div className='flex items-center gap-4'>
													<div className='checkbox '>
														<label className='flex items-center'>
															<input
																className='text-primary-700 mr-2  focus:ring-primary-200/75'
																type='radio'
																name='isFormerResidence'
																onChange={(e) => {
																	onChangeFormerResidence(e)
																	resetMaritalStatus(
																		setFieldValue,
																		setFieldTouched,
																		maritalStatusProp,
																		propertyWorthProps,
																		propertyUseForProps
																	)
																	handleChange(e)
																}}
																onBlur={handleBlur}
																value={YES}
																checked={values.isFormerResidence === YES}
															/>
															<span className='text-sm font-medium'>Yes</span>
														</label>
													</div>
													<div className='checkbox'>
														<label className='flex items-center'>
															<input
																className='text-primary-700 mr-2  focus:ring-primary-200/75'
																type='radio'
																name='isFormerResidence'
																onChange={(e) => {
																	onChangeFormerResidence(e)
																	handleChange(e)
																}}
																onBlur={handleBlur}
																value={NO}
																checked={values.isFormerResidence === NO}
															/>
															<span className='text-sm font-medium'>No</span>
														</label>
													</div>
												</div>
												{touched.isFormerResidence &&
													errors.isFormerResidence && (
														<p className='text-red-500 text-xs'>
															{errors.isFormerResidence}
														</p>
													)}
											</div>
										)}
										{fieldsVisibility.shouldShowMaritalStatusField && (
											<div className='form-group mb-6'>
												<label
													htmlFor='maritalStatus'
													className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'
												>
													What is your marital status?
												</label>
												<select
													id='maritalStatus'
													name='maritalStatus'
													className={classnames('form-control', {
														'text-gray-400': !isMaritalStatusSelected,
													})}
													onChange={(e) => {
														onChangeMaritalStatus(
															e,
															setFieldValue,
															setFieldTouched,
															propertyWorthProps,
															propertyUseForProps
														)
														handleChange(e)
														validateMaritalStatus(e)
													}}
													onBlur={handleBlur}
													value={values.maritalStatus}
												>
													<option value=''>Select</option>
													{getMaritalStatus}
												</select>
												{touched.maritalStatus && errors.maritalStatus && (
													<p className={ERROR_MESSAGE_CLASSES}>
														{errors.maritalStatus}
													</p>
												)}
											</div>
										)}
										{fieldsVisibility.shouldShowPropertyMoreThanQuarterMillion && (
											<div className='form-group mb-6'>
												<label className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'>
													Has your property gone up more than $250,000 since you
													purchased it?
												</label>
												<div className='flex items-center gap-4'>
													<div className='checkbox '>
														<label className='flex items-center'>
															<input
																className='text-primary-700 mr-2  focus:ring-primary-200/75'
																type='radio'
																name='hasPropertyGoneUpMT250K'
																onChange={(e) => {
																	onChangePurchasedProperty(e)
																	handleChange(e)
																}}
																onBlur={handleBlur}
																value={YES}
																checked={values.hasPropertyGoneUpMT250K === YES}
															/>
															<span className='text-sm font-medium'>Yes</span>
														</label>
													</div>
													<div className='checkbox'>
														<label className='flex items-center'>
															<input
																className='text-primary-700 mr-2  focus:ring-primary-200/75'
																type='radio'
																name='hasPropertyGoneUpMT250K'
																onChange={(e) => {
																	onChangePurchasedProperty(e)
																	resetPurchasedProperty(
																		setFieldValue,
																		setFieldTouched,
																		propertyUseForProps
																	)
																	handleChange(e)
																}}
																onBlur={handleBlur}
																value={NO}
																checked={values.hasPropertyGoneUpMT250K === NO}
															/>
															<span className='text-sm font-medium'>No</span>
														</label>
													</div>
												</div>
												{touched.hasPropertyGoneUpMT250K &&
													errors.hasPropertyGoneUpMT250K && (
														<p className='text-red-500 text-xs'>
															{errors.hasPropertyGoneUpMT250K}
														</p>
													)}
											</div>
										)}
										{fieldsVisibility.shouldShowPropertyMoreThanHalfMillion && (
											<div className='form-group mb-6'>
												<label className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'>
													Has your property gone up more than $500,000 since you
													purchased it?
												</label>
												<div className='flex items-center gap-4'>
													<div className='checkbox '>
														<label className='flex items-center'>
															<input
																className='text-primary-700 mr-2  focus:ring-primary-200/75'
																type='radio'
																name='hasPropertyGoneUpMT500K'
																onChange={(e) => {
																	onChangePurchasedProperty(e)
																	handleChange(e)
																}}
																onBlur={handleBlur}
																value={YES}
																checked={values.hasPropertyGoneUpMT500K === YES}
															/>
															<span className='text-sm font-medium'>Yes</span>
														</label>
													</div>
													<div className='checkbox'>
														<label className='flex items-center'>
															<input
																className='text-primary-700 mr-2  focus:ring-primary-200/75'
																type='radio'
																name='hasPropertyGoneUpMT500K'
																onChange={(e) => {
																	onChangePurchasedProperty(e)
																	resetPurchasedProperty(
																		setFieldValue,
																		setFieldTouched,
																		propertyUseForProps
																	)
																	handleChange(e)
																}}
																onBlur={handleBlur}
																value={NO}
																checked={values.hasPropertyGoneUpMT500K === NO}
															/>
															<span className='text-sm font-medium'>No</span>
														</label>
													</div>
												</div>
												{touched.hasPropertyGoneUpMT500K &&
													errors.hasPropertyGoneUpMT500K && (
														<p className='text-red-500 text-xs'>
															{errors.hasPropertyGoneUpMT500K}
														</p>
													)}
											</div>
										)}
										{fieldsVisibility.shouldShowPropertyFieldsCategorizedIn && (
											<>
												<div className='form-group mb-6'>
													<label className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'>
														Does your property have a Home Office, Airbnb/VRBO,
														or Business Use?
													</label>
													<div className='flex items-center gap-4'>
														<div className='checkbox '>
															<label className='flex items-center'>
																<input
																	className='text-primary-700 mr-2  focus:ring-primary-200/75'
																	type='radio'
																	name='isPropertyCommercial'
																	onChange={handleChange}
																	value={YES}
																	checked={values.isPropertyCommercial === YES}
																/>
																<span className='text-sm font-medium'>Yes</span>
															</label>
														</div>
														<div className='checkbox'>
															<label className='flex items-center'>
																<input
																	className='text-primary-700 mr-2  focus:ring-primary-200/75'
																	type='radio'
																	name='isPropertyCommercial'
																	onChange={handleChange}
																	value={NO}
																	checked={values.isPropertyCommercial === NO}
																/>
																<span className='text-sm font-medium'>No</span>
															</label>
														</div>
													</div>
													{touched.isPropertyCommercial &&
														errors.isPropertyCommercial && (
															<p className='text-red-500 text-xs'>
																{errors.isPropertyCommercial}
															</p>
														)}
												</div>
												<div className='form-group mb-6'>
													<label className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'>
														Is the property, or any portion of it, currently
														being used as a Rental?(Granny Flat/Garage
														Apartment, Duplex, Triplex, etc.)
													</label>
													<div className='flex items-center gap-4'>
														<div className='checkbox '>
															<label className='flex items-center'>
																<input
																	className='text-primary-700 mr-2  focus:ring-primary-200/75'
																	type='radio'
																	name='isPropertyRental'
																	onChange={handleChange}
																	value={YES}
																	checked={values.isPropertyRental === YES}
																/>
																<span className='text-sm font-medium'>Yes</span>
															</label>
														</div>
														<div className='checkbox'>
															<label className='flex items-center'>
																<input
																	className='text-primary-700 mr-2  focus:ring-primary-200/75'
																	type='radio'
																	name='isPropertyRental'
																	onChange={handleChange}
																	value={NO}
																	checked={values.isPropertyRental === NO}
																/>
																<span className='text-sm font-medium'>No</span>
															</label>
														</div>
													</div>
													{touched.isPropertyRental &&
														errors.isPropertyRental && (
															<p className='text-red-500 text-xs'>
																{errors.isPropertyRental}
															</p>
														)}
												</div>

												<div className='form-group mb-6'>
													<label className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'>
														Is part of the property Vacant Land, or used for
														Agricultural Purposes?
													</label>
													<div className='flex items-center gap-4'>
														<div className='checkbox '>
															<label className='flex items-center'>
																<input
																	className='text-primary-700 mr-2  focus:ring-primary-200/75'
																	type='radio'
																	name='isPropertyInUse'
																	onChange={handleChange}
																	value={YES}
																	checked={values.isPropertyInUse === YES}
																/>
																<span className='text-sm font-medium'>Yes</span>
															</label>
														</div>
														<div className='checkbox'>
															<label className='flex items-center'>
																<input
																	className='text-primary-700 mr-2  focus:ring-primary-200/75'
																	type='radio'
																	name='isPropertyInUse'
																	onChange={handleChange}
																	value={NO}
																	checked={values.isPropertyInUse === NO}
																/>
																<span className='text-sm font-medium'>No</span>
															</label>
														</div>
													</div>
													{touched.isPropertyInUse &&
														errors.isPropertyInUse && (
															<p className='text-red-500 text-xs'>
																{errors.isPropertyInUse}
															</p>
														)}
												</div>
											</>
										)}
									</div>
								</div>

								<div className='text-right space-x-2'>
									<button
										type='button'
										className='btn btn-light'
										onClick={navigateToCurrentInvestment}
									>
										Previous
									</button>
									<button
										className='btn btn-primary'
										type='submit'
										onClick={handleSubmit}
									>
										Calculate
									</button>
								</div>
								{shouldShow1031QualifiedModal && (
									<Modal
										shouldShowModal={shouldShow1031QualifiedModal}
										setShouldShowModal={setShouldShow1031QualifiedModal}
										result={reInvestQualifyIcon}
										title={
											<BusyIndicator>
												You have&nbsp;
												{getCurrencyCode(USD)}
												{totalEquity ? (
													<span>{numberCommasSeparator(totalEquity)}</span>
												) : (
													<span>{ZERO}</span>
												)}
												&nbsp;to re-invest
											</BusyIndicator>
										}
										subtitle={`This is an estimate based on your current investment answers. You will be able to reinvest the total amount tax-free via a 1031 exchange.`}
										prevBtn={prevBtn}
										nextBtn={reInvestQualifyModal}
									/>
								)}
								{shouldShowExchangeQualifiedModal && (
									<Modal
										shouldShowModal={shouldShowExchangeQualifiedModal}
										setShouldShowModal={setShouldShowExchangeQualifiedModal}
										result={qualifyExchangeIcon}
										title={
											'Your primary residence might qualify for an exchange.'
										}
										subtitle={`You may qualify for an exchange based on the use of your property. Please give us a call to discuss your situation.`}
										interactBtn={true}
										prevBtn={prevBtn}
										nextBtn={qualifyExchangeModal}
									/>
								)}
								{shouldShow1031NotQualifiedModal && (
									<Modal
										shouldShowModal={shouldShow1031NotQualifiedModal}
										setShouldShowModal={setShouldShow1031NotQualifiedModal}
										result={scheduleConsultationIcon}
										title={`Your primary residence would not qualify as a 1031 exchange`}
										subtitle={`You may still be able to use a primary residence tax break (Section 121). Reach out with any questions.`}
										prevBtn={prevBtn}
										nextBtn={scheduleConsultationModal}
									/>
								)}
							</form>
						</>
					)}
				</Formik>
			</div>
		</>
	)
}

export default InvestmentForm
