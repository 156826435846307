import React, { useState } from 'react'
import AccordionLayout from './AccordionLayout'
import {
	accordionTitles,
	accordionIndexes,
	accordionIcons,
	accordianSubTitle,
} from './invetmentsAccordion.constants'

const InvestmentsAccordion = ({
	investmentId,
	investment,
	handleValues,
	removeAccordionData,
}) => {
	const [activeIndex, setActiveIndex] = useState(null)

	return (
		<>
			{accordionIndexes.map((accordionKey) => (
				<AccordionLayout
					key={accordionKey}
					index={accordionKey}
					investmentId={investmentId}
					activeIndex={
						investment.index === 0
							? investment.index
							: investment.index || activeIndex
					}
					setActiveIndex={setActiveIndex}
					title={accordionTitles[accordionKey]}
					subtitle={accordianSubTitle[accordionKey]}
					icon={accordionIcons[accordionKey]}
					investment={investment}
					handleValues={handleValues}
					removeAccordionData={removeAccordionData}
				/>
			))}
		</>
	)
}

export default InvestmentsAccordion
