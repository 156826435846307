import React, { useEffect } from 'react'
import { landingRightBg } from '../../assets/images'
import { landingBlub } from '../../assets/gifs'
import { useSelector } from 'react-redux'
import { selectFormData } from './affiliates.selectors'
import { useNavigate } from 'react-router-dom'
import { DEFAULT_VALUES, NavRoutes } from '../../utils/constants'

const FormSubmitted = () => {
	const navigate = useNavigate()
	const formData = useSelector(selectFormData)

	useEffect(() => {
		const isFormDataEmpty = Object.values(formData).some(
			(value) => value === DEFAULT_VALUES.EMPTY_STRING
		)
		if (isFormDataEmpty) navigate(NavRoutes.AFFILIATE_START_APPLICATION)
	}, [formData, navigate])

	return (
		<div className='grid grid-cols-12 mb-6 overflow-hidden min-h-9/10 sm:p-[84px]'>
			<div className='col-span-12 xs:col-span-8 2xl:col-span-6 px-16 py-16 flex flex-col justify-center'>
				<h1 className='text-black text-xl xs:text-2xl md:text-3xl font-bold mb-6'>
					Application Submitted
				</h1>
				<p className='text-black text-xs'>
					Thank you for applying to be an Plenti Exchange Affiliate. We’ve
					successfully received your application and we’ll reach out to you
					within 48 business hours.
				</p>
			</div>
			<div className='hidden xs:col-span-3 lg:col-span-4 2xl:col-span-6 xs:flex justify-end items-end relative'>
				<img
					src={landingRightBg}
					alt='intro right background'
					className='absolute top-1/2 left-[40%] -translate-x-1/2 -translate-y-1/2 w-10/12 2xl:w-7/12'
				/>
				<img
					src={landingBlub}
					className='absolute md:p-2 lg:p-16 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-10/12 2xl:w-7/12 rounded-full bg-white border-gray-200 border-2'
					alt='bulb image'
				/>
			</div>
		</div>
	)
}

export default FormSubmitted
