import Authentication from './Authentication'
import * as authenticationStateSelector from './authentication.selectors'
import slice from './authentication.slice'

export const {
	name,
	actions: { resetToInititalState },
	reducer,
} = slice
export const { selectAgentResponseInfo } = authenticationStateSelector

export default Authentication
