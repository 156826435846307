import CircularProgressIndicator from './CircularProgressIndicator'
import * as circularProgressIndicatorStatesSelector from './circularProgressIndicator.selectors'
import slice from './circularProgressIndicator.slice'

export const {
	name,
	actions: { updateProgressPercentage },
	reducer,
} = slice

export const { selectCircularProgressIndicatorState } =
	circularProgressIndicatorStatesSelector

export default CircularProgressIndicator
