import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import throttle from 'lodash/throttle'

const ROADMAP_NAVIGATION = [
	{ label: 'Introduction', id: 'introduction' },
	{ label: 'About Plenti', id: 'aboutPlenti' },
	{ label: 'Exchange Types', id: 'exchangeTypes' },
	{
		label: 'How Does Your Money Grow with an Exchange?',
		id: 'howDoesMoneyGrowInExchange',
	},
	{ label: 'The 3 Biggest Tax Myths in Real Estate', id: 'biggestMyth' },
	{ label: 'Property Types', id: 'propertyTypes' },
	{ label: 'FAQ', id: 'faq' },
	{ label: 'Glossary', id: 'glossary' },
]
const INTRODUCTION = 'introduction'
const THROTTLE_TIME = 300
const ROADMAP_CONTENT = 'roadmap-content'

const RoadmapLeftSidebar = ({ elementRef }) => {
	const [currentIntersectedElement, setCurrentIntersectedElement] =
		useState(INTRODUCTION)

	const handleContentNavigation = (id) =>
		document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' })

	const updateIntersectedElement = useCallback(() => {
		const bufferHeight = window.innerHeight / 2
		let intersectedElementTop
		elementRef.current.forEach((element) => {
			const elementTop = element.getBoundingClientRect()?.top
			if (
				elementTop &&
				elementTop < bufferHeight &&
				(!intersectedElementTop || elementTop > intersectedElementTop)
			) {
				intersectedElementTop = elementTop
				setCurrentIntersectedElement(element.id)
			}
		})
	}, [elementRef])

	useEffect(() => {
		const scrollContainer = document.getElementById(ROADMAP_CONTENT)
		if (!scrollContainer) return
		scrollContainer.addEventListener(
			'scroll',
			throttle(updateIntersectedElement, THROTTLE_TIME)
		)

		updateIntersectedElement()

		return () =>
			scrollContainer.removeEventListener(
				'scroll',
				throttle(updateIntersectedElement, THROTTLE_TIME)
			)
	}, [updateIntersectedElement])

	return (
		<div className='bg-blueGray w-1/4 h-full hidden sm:flex gap-3 flex-col text-gray-300 font-work-sans-medium py-3 overflow-y-auto'>
			{ROADMAP_NAVIGATION.map(({ label, id }) => (
				<div
					key={id}
					onClick={() => handleContentNavigation(id)}
					className={classNames('px-4 py-3 duration-500 cursor-pointer', {
						'bg-primary-200': id === currentIntersectedElement,
					})}
				>
					{label}
				</div>
			))}
		</div>
	)
}

export default RoadmapLeftSidebar
