import React from 'react'
import { TaxSavingStepsGenericModal } from '../../widgets/Modal'
import { profitTaxedGraphPointer } from '../../assets/icons'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { numberCommasSeparator } from '../../utils/methods'
ChartJS.register(ArcElement, Tooltip, Legend)

const DOUGHNUT_BG = {
	TAXES: 'rgba(216, 78, 78, 1)',
	PROFIT: 'rgba(108, 233, 166, 1)',
	TRANSPARENT: 'transparent',
}

const TWO = 2
const HUNDRED = 100
const TAX_PERCENTAGE_MULTIPLIER = 3.6
const HALF_CIRCLE_DEGREE = 90

const SeeHowProfitTaxed = ({ profitDetails, toggleSeeHowProfitTaxed }) => {
	const {
		taxes,
		profit,
		federalCapitalGainsTax,
		federalNetInvIncTax,
		federalDepreciationRecaptureTax,
		stateCapitalGainsTax,
		stateWithHigherTax: { state_code: stateCode } = {},
	} = profitDetails
	const total = taxes + profit
	const taxPercentage = (taxes / total) * HUNDRED
	const datasets = [
		{
			data: [taxes, profit],
			backgroundColor: [DOUGHNUT_BG.TAXES, DOUGHNUT_BG.PROFIT],
			borderColor: DOUGHNUT_BG.TRANSPARENT,
		},
	]

	const options = {
		hover: false,
		plugins: {
			tooltip: {
				enabled: false,
			},
			datalabels: {
				display: false,
			},
		},
	}

	const rotateAngle =
		HALF_CIRCLE_DEGREE - (taxPercentage * TAX_PERCENTAGE_MULTIPLIER) / TWO

	return (
		<TaxSavingStepsGenericModal handleClose={toggleSeeHowProfitTaxed}>
			<div className='w-full custom-select flex flex-col gap-3 p-4 sm:p-6'>
				<div>
					<div className='text-start text-zinc-900 text-base sm:text-xl font-work-sans-semibold leading-[30px]'>
						How Your Profit is Taxed
					</div>
					<div className='text-start text-gray-500 text-sm sm:text-lg font-work-sans-medium leading-tight sm:leading-7 mb-4 sm:mb-6'>
						Based on your ${numberCommasSeparator(Math.round(profit))} in
						profit, we’ve estimated that your tax bill could be as high as $
						{numberCommasSeparator(Math.round(taxes))}! Take a look a the
						breakdown to see how we got there.
					</div>
					<div className='w-full p-4 sm:p-6 bg-neutral-100 border border-zinc-200 gap-4 flex flex-col sm:flex-row justify-between'>
						<div className='flex flex-col sm:w-2/4'>
							<div className='gap-2 flex sm:flex-col justify-center'>
								<div className='justify-start items-center gap-1 flex'>
									<div className='w-2 h-2 sm:w-4 sm:h-4 bg-emerald-300 rounded-full'></div>
									<div className='text-zinc-700 w-max text-xs sm:text-base font-work-sans-bold leading-[18px] sm:leading-normal'>
										Profit ${numberCommasSeparator(Math.round(profit))}
									</div>
								</div>
								<div className='justify-start items-center gap-1 flex'>
									<div className='w-2 h-2 sm:w-4 sm:h-4 bg-red-500 rounded-full'></div>
									<div className='text-zinc-700 w-max text-xs sm:text-base font-work-sans-bold leading-[18px] sm:leading-normal'>
										Taxes ${numberCommasSeparator(Math.round(taxes))}
									</div>
								</div>
							</div>
							<div className='flex items-center justify-center h-full pt-2 sm:p-8 sm:pr-11 sm:h-96'>
								<Doughnut
									data={{ datasets: datasets }}
									style={{ rotate: `${rotateAngle}deg` }}
									options={options}
								/>
							</div>
						</div>
						<div className='relative sm:w-2/4 gap-4 flex flex-col sm:border-l-4 sm:border-valencia sm:pl-4'>
							<img
								src={profitTaxedGraphPointer}
								className='w-[44px] absolute !top-2/4 left-[-45px] hidden sm:block'
							/>
							<div className='flex-col justify-center items-start gap-2 flex w-full'>
								<div className='text-zinc-700 text-sm sm:text-xl font-work-sans-bold leading-tight sm:leading-[30px] text-end'>
									Federal Taxes
								</div>
								<div className='self-stretch justify-between items-center flex'>
									<div className='text-neutral-500 text-xs sm:text-base font-work-sans-medium sm:font-work-sans-regular leading-[18px] sm:leading-normal'>
										Federal Capital Gains Tax
									</div>
									<div className='text-zinc-700 text-sm sm:text-xl font-work-sans-semibold sm:font-work-sans-bold leading-tight sm:leading-[30px] text-end'>
										${numberCommasSeparator(Math.round(federalCapitalGainsTax))}
									</div>
								</div>
								<div className='self-stretch justify-between items-center flex'>
									<div className='text-neutral-500 text-xs sm:text-base font-work-sans-medium sm:font-work-sans-regular leading-[18px] sm:leading-normal'>
										Federal Net Inv. Inc. Tax
									</div>
									<div className='text-zinc-700 text-sm sm:text-xl font-work-sans-semibold sm:font-work-sans-bold leading-tight sm:leading-[30px] text-end'>
										${numberCommasSeparator(Math.round(federalNetInvIncTax))}
									</div>
								</div>
								<div className='self-stretch justify-between items-center flex'>
									<div className='text-neutral-500 text-xs sm:text-base font-work-sans-medium sm:font-work-sans-regular leading-[18px] sm:leading-normal'>
										Federal Depreciation Recapture Tax
									</div>
									<div className='text-zinc-700 text-sm sm:text-xl font-work-sans-semibold sm:font-work-sans-bold leading-tight sm:leading-[30px] text-end'>
										$
										{numberCommasSeparator(
											Math.round(federalDepreciationRecaptureTax)
										)}
									</div>
								</div>
							</div>
							<div className='flex-col justify-center items-start gap-2 flex w-full'>
								<div className='text-zinc-700 text-sm sm:text-xl font-work-sans-bold leading-tight sm:leading-[30px] text-end'>
									State Taxes ({stateCode})
								</div>
								<div className='self-stretch justify-between items-center flex'>
									<div className='text-neutral-500 text-xs sm:text-base font-work-sans-medium sm:font-work-sans-regular leading-[18px] sm:leading-normal'>
										State Capital Gains Tax
									</div>
									<div className='text-zinc-700 text-sm sm:text-xl font-work-sans-semibold sm:font-work-sans-bold leading-tight sm:leading-[30px] text-end'>
										${numberCommasSeparator(Math.round(stateCapitalGainsTax))}
									</div>
								</div>
							</div>
							<div className='w-full border-2 border-zinc-200' />
							<div className='self-stretch justify-between items-center flex'>
								<div className='text-zinc-700 text-sm sm:text-xl font-work-sans-bold leading-tight sm:leading-[30px]'>
									Total Taxes
								</div>
								<div className='text-zinc-700 text-sm sm:text-xl font-work-sans-semibold sm:font-work-sans-bold leading-tight sm:leading-[30px]'>
									${numberCommasSeparator(Math.round(taxes))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</TaxSavingStepsGenericModal>
	)
}
export default SeeHowProfitTaxed
