export const stateCodes = [
	'AK',
	'AL',
	'AR',
	'AZ',
	'CA',
	'CO',
	'CT',
	'DC',
	'DE',
	'FL',
	'GA',
	'HI',
	'IA',
	'ID',
	'IL',
	'IN',
	'KS',
	'KY',
	'LA',
	'MA',
	'MD',
	'ME',
	'MI',
	'MN',
	'MO',
	'MS',
	'MT',
	'NC',
	'ND',
	'NE',
	'NH',
	'NJ',
	'NM',
	'NV',
	'NY',
	'OH',
	'OK',
	'OR',
	'PA',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VA',
	'VT',
	'WA',
	'WI',
	'WV',
	'WY',
]

export const stateCodeAndNames = [
	{
		state_code: 'AK',
		state_name: 'Alaska',
		state_value: 0,
	},
	{
		state_code: 'AL',
		state_name: 'Alabama',
		state_value: 0.05,
	},
	{
		state_code: 'AR',
		state_name: 'Arkansas',
		state_value: 0.044,
	},
	{
		state_code: 'AZ',
		state_name: 'Arizona',
		state_value: 0.025,
	},
	{
		state_code: 'CA',
		state_name: 'California',
		state_value: 0.133,
	},
	{
		state_code: 'CO',
		state_name: 'Colorado',
		state_value: 0.044,
	},
	{
		state_code: 'CT',
		state_name: 'Connecticut',
		state_value: 0.0669,
	},
	{
		state_code: 'DC',
		state_name: 'District of Columbia',
		state_value: 0.1075,
	},
	{
		state_code: 'DE',
		state_name: 'Delaware',
		state_value: 0.066,
	},
	{
		state_code: 'FL',
		state_name: 'Florida',
		state_value: 0,
	},
	{
		state_code: 'GA',
		state_name: 'Georgia',
		state_value: 0.0549,
	},
	{
		state_code: 'HI',
		state_name: 'Hawaii',
		state_value: 0.0725,
	},
	{
		state_code: 'IA',
		state_name: 'Iowa',
		state_value: 0.057,
	},
	{
		state_code: 'ID',
		state_name: 'Idaho',
		state_value: 0.058,
	},
	{
		state_code: 'IL',
		state_name: 'Illinois',
		state_value: 0.0495,
	},
	{
		state_code: 'IN',
		state_name: 'Indiana',
		state_value: 0.0305,
	},
	{
		state_code: 'KS',
		state_name: 'Kansas',
		state_value: 0.057,
	},
	{
		state_code: 'KY',
		state_name: 'Kentucky',
		state_value: 0.04,
	},
	{
		state_code: 'LA',
		state_name: 'Louisiana',
		state_value: 0.0425,
	},
	{
		state_code: 'MA',
		state_name: 'Massachusetts',
		state_value: 0.09,
	},
	{
		state_code: 'MD',
		state_name: 'Maryland',
		state_value: 0.0575,
	},
	{
		state_code: 'ME',
		state_name: 'Maine',
		state_value: 0.0715,
	},
	{
		state_code: 'MI',
		state_name: 'Michigan',
		state_value: 0.0425,
	},
	{
		state_code: 'MN',
		state_name: 'Minnesota',
		state_value: 0.0985,
	},
	{
		state_code: 'MO',
		state_name: 'Missouri',
		state_value: 0.048,
	},
	{
		state_code: 'MS',
		state_name: 'Mississippi',
		state_value: 0.047,
	},
	{
		state_code: 'MT',
		state_name: 'Montana',
		state_value: 0.059,
	},
	{
		state_code: 'NC',
		state_name: 'North Carolina',
		state_value: 0.045,
	},
	{
		state_code: 'ND',
		state_name: 'North Dakota',
		state_value: 0.025,
	},
	{
		state_code: 'NE',
		state_name: 'Nebraska',
		state_value: 0.0584,
	},
	{
		state_code: 'NH',
		state_name: 'New Hampshire',
		state_value: 0,
	},
	{
		state_code: 'NJ',
		state_name: 'New Jersey',
		state_value: 0.1075,
	},
	{
		state_code: 'NM',
		state_name: 'New Mexico',
		state_value: 0.059,
	},
	{
		state_code: 'NV',
		state_name: 'Nevada',
		state_value: 0,
	},
	{
		state_code: 'NY',
		state_name: 'New York',
		state_value: 0.0109,
	},
	{
		state_code: 'OH',
		state_name: 'Ohio',
		state_value: 0.035,
	},
	{
		state_code: 'OK',
		state_name: 'Oklahoma',
		state_value: 0.0475,
	},
	{
		state_code: 'OR',
		state_name: 'Oregon',
		state_value: 0.099,
	},
	{
		state_code: 'PA',
		state_name: 'Pennsylvania',
		state_value: 0.0307,
	},
	{
		state_code: 'RI',
		state_name: 'Rhode Island',
		state_value: 0.0599,
	},
	{
		state_code: 'SC',
		state_name: 'South Carolina',
		state_value: 0.064,
	},
	{
		state_code: 'SD',
		state_name: 'South Dakota',
		state_value: 0,
	},
	{
		state_code: 'TN',
		state_name: 'Tennessee',
		state_value: 0,
	},
	{
		state_code: 'TX',
		state_name: 'Texas',
		state_value: 0,
	},
	{
		state_code: 'UT',
		state_name: 'Utah',
		state_value: 0.0465,
	},
	{
		state_code: 'VA',
		state_name: 'Virginia',
		state_value: 0.0575,
	},
	{
		state_code: 'VT',
		state_name: 'Vermont',
		state_value: 0.0875,
	},
	{
		state_code: 'WA',
		state_name: 'Washington',
		state_value: 0.07,
	},
	{
		state_code: 'WI',
		state_name: 'Wisconsin',
		state_value: 0.0765,
	},
	{
		state_code: 'WV',
		state_name: 'West Virginia',
		state_value: 0.0512,
	},
	{
		state_code: 'WY',
		state_name: 'Wyoming',
		state_value: 0,
	},
]
