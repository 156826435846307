import React, { useRef } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Header } from './Header'
import Footer from './Footer'
import { getNavigationRoute, openURLInNewTabDefault } from '../../utils/methods'
import {
	reinvestmentChoicesIntro,
	modelYourExchange,
} from '../../assets/images'
import { NavRoutes, reinvestmentChoiceSide } from '../../utils/constants'
import { PreviousBtn, NextBtn } from '../../assets/icons'
import { useSelector } from 'react-redux'
import { selectAffiliateSlug } from '../Affiliates/affiliates.selectors'

const { MODEL } = NavRoutes

const ReinvestmentChoices = () => {
	const sliderRef = useRef(null)
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const PrevArrow = () => (
		<div className='mr-8' onClick={() => sliderRef.current.slickPrev()}>
			<PreviousBtn className='cursor-pointer slider-button' />
		</div>
	)

	const NextArrow = () => (
		<div onClick={() => sliderRef.current.slickNext()}>
			<NextBtn className='cursor-pointer slider-button' />
		</div>
	)
	const Description = () => {
		return (
			<div className='w-full'>
				<p className='text-white text-xl pb-8'>
					Improvement Exchanges, Reverse Exchanges, and more!
				</p>
				<ul className='text-white text-xl leading-relaxed list-disc pl-8'>
					<li>
						You can always find your own investment property to purchase in your
						1031 Exchange.
					</li>
					<li className='py-8'>
						Make Improvements to your replacement property with your 1031
						Exchange proceeds in an &quot;Improvement Exchange&quot;.
					</li>
					<li>
						Buy your replacement property first, and sell your existing property
						second, in a Reverse Exchange!
					</li>
				</ul>
			</div>
		)
	}

	const sliderSettings = {
		dots: true,
		autoplay: true,
		autoplaySpeed: 6000,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: false,
		infinite: true,
		adaptiveHeight: true,
		fade: true,
		cssEase: 'ease-in-out',
	}
	return (
		<div className='w-full'>
			<Header />
			<div className='pl-8 pr-4 sm:px-20 md:px-[140px] py-12 md:py-[140px] bg-white'>
				<div className='text-black flex flex-col gap-52 md:gap-[396px]'>
					<div className='flex justify-end gap-12 xl:gap-[135px] flex-wrap md:flex-nowrap'>
						<h2 className='font-work-sans-regular w-full text-[36px] md:text-[56px] md:w-2/3 md:font-work-sans-light leading-normal'>
							Reinvestment options for all types of scenarios
						</h2>
						<p className='text-xl leading-8 w-full font-work-sans-regular md:w-1/3 pt-4'>
							Saving on your tax bill empowers you to reinvest in something that
							offers more than just wealth-building. Here, we offer some options
							that may meet your goals. Your Plenti Financial 1031 Specialist
							can discuss in detail when you&apos;re ready.
						</p>
					</div>
				</div>
			</div>
			<div className='w-full max-[720px]:h-[320px]'>
				<img
					src={reinvestmentChoicesIntro}
					alt='Reinvest Intro'
					className='w-full h-full object-cover md:object-fill'
				/>
			</div>
			<div className='w-full px-8 sm:px-20 md:px-[141px] py-12 md:pt-28 md:pb-20 bg-black '>
				<div className='flex justify-between items-center'>
					<h1 className='text-white md:text-xl font-work-sans-regular uppercase'>
						What are you looking for?
					</h1>
					<div className=' flex justify-end'>
						<PrevArrow />
						<NextArrow />
					</div>
				</div>
				<Slider {...sliderSettings} ref={sliderRef}>
					{reinvestmentChoiceSide.map((choice, index) => (
						<div
							key={index}
							className='w-full !flex flex-col md:flex-row justify-between'
						>
							<div className='w-full md:w-[525px] md:h-[680px] py-8 md:px-0'>
								<img
									className='w-full sm:w-auto object-content md:w-[525px] md:h-[680px] md:mb-8'
									src={choice.image}
								/>
							</div>
							<div className='w-full md:ml-28 md:w-[680px]  py-4'>
								<div className='md:pt-28 w-full'>
									<div>
										<h3 className='text-white text-3xl md:text-5xl pb-8 font-work-sans-regular'>
											{choice.title}
										</h3>
										{choice.type === 'list' ? (
											<Description />
										) : (
											choice.description.split('\n').map((des, i) => (
												<p
													className='text-white text-xl leading-8 w-full font-work-sans-regular mb-8'
													key={i}
												>
													{des}
												</p>
											))
										)}
									</div>
								</div>
							</div>
						</div>
					))}
				</Slider>
			</div>
			<div
				className='px-8 sm:px-20 md:px-[140px] py-5 md:py-28 bg-cover'
				style={{ backgroundImage: `url(${modelYourExchange})` }}
			>
				<div className='text-white flex flex-col'>
					<h1 className=' font-work-sans-light text-[36px] md:text-[56px] leading-normal font-light'>
						Model your 1031 Exchange in our free app
					</h1>
					<div className='flex flex-col md:flex-row gap-12 xl:gap-72 justify-between items-end pt-10'>
						<div className='w-full md:w-[40%]'>
							<div className='h-0 rotate-180 border border-white'></div>
							<div className='text-white text-xl font-work-sans-regular leading-loose mb-8'>
								Get a tailored look at what your 1031 Exchange could look like
							</div>
							<div className='h-0 rotate-180 border border-white'></div>
							<div className=' text-white text-xl font-work-sans-regular leading-loose mb-8'>
								Ask questions along the way
							</div>
							<div className='h-0 rotate-180 border border-white'></div>
							<div className=' text-white text-xl font-work-sans-regular leading-loose'>
								Start your 1031 Exchange when you’re ready
							</div>
							<button
								onClick={() =>
									openURLInNewTabDefault(
										getNavigationRoute(affiliateSlug, MODEL)
									)
								}
								className='w-full sm:w-auto text-xl leading-[2px] font-work-sans-semibold rounded-[40px] border border-white py-[27px] px-3 md:px-[34px] hover:bg-white hover:text-black transition duration-1000 ease-in-out mt-20 mb-2'
							>
								Start modeling
							</button>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default ReinvestmentChoices
