import ReinvestmentAffiliate from './ReinvestmentAffiliate'
import * as selectors from './Reinvestment.selectors'
import slice from './Reinvestment.slice'

export const { name, actions, reducer } = slice
export const {
	selectIsStepOneCompleted,
	selectIsStepTwoCompleted,
	selectStepOneFormData,
} = selectors
export default ReinvestmentAffiliate
