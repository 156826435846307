import { React, useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import classNames from 'classnames'
import { logoIcon, navArrow, hamburger, crossIcon } from '../../assets/icons'
import { MEETING_SCHEDULER_ID } from '../../utils/getEnvVars'
import { ReinvestmentGenericModal } from '../../widgets/Modal'
import {
	openURLInNewTabDefault,
	openURLInNewTab,
	checkForCurrentRoute,
	getNavigationRoute,
} from '../../utils/methods'
import {
	selectAffiliateDetails,
	selectAffiliateSlug,
} from '../Affiliates/affiliates.selectors'
import {
	DEFAULT_VALUES,
	NavRoutes,
	PLENTI,
	SECTION_IDS,
	START_EXCHANGE_URL,
} from '../../utils/constants'

const { EMPTY_STRING } = DEFAULT_VALUES
const { HOME, MODEL } = NavRoutes
const { WAYS_TO_GET_STARTED_SECTION, WHY_PLENTI_FINANCIAL_SECTION } =
	SECTION_IDS

const EachItem = ({ text, onClick = () => {}, classes, showIcon = true }) => (
	<li
		className={`text-black font-metropolis-semibold flex justify-center gap-[5px] cursor-pointer text-sm p-3 md:text-[18px] ${classes}`}
		onClick={onClick}
	>
		<span>{text}</span>
		{showIcon && <img src={navArrow} alt='navArrow' />}
	</li>
)

const MenuItems = ({ items }) => (
	<ul className='flex flex-col md:flex-row md:gap-[30px] md:items-center font-metropolis-semibold text-[18px]'>
		{items.map((each, i) => (
			<EachItem
				key={i}
				text={each.text}
				showIcon={each.showIcon}
				classes={each.classes}
				onClick={each.onClick}
			/>
		))}
	</ul>
)

export const Header = (props) => {
	const affiliateDetails = useSelector(selectAffiliateDetails)
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const [isMenuOpen, setMenuOpen] = useState(false)
	const [shouldShowMeetingSchedular, setShouldShowMeetingSchedular] =
		useState(false)

	const toggleMenu = () => setMenuOpen((prevValue) => !prevValue)

	const toggleMeetingScheduler = () =>
		setShouldShowMeetingSchedular((prevValue) => !prevValue)

	const scrollToSection = useCallback(
		(ref, sectionId) => {
			if (!checkForCurrentRoute([HOME], affiliateSlug) && sectionId) {
				const newUrl =
					(affiliateSlug && affiliateSlug !== PLENTI
						? `/${affiliateSlug}`
						: '/') + `#${sectionId}`
				window.location.href = newUrl
			} else {
				const section = ref.current
				if (section) {
					const rect = section.getBoundingClientRect()
					const scrollTop = window.scrollY || document.documentElement.scrollTop
					const topPosition = rect.top + scrollTop - 50
					window.scrollTo({
						top: topPosition,
						behavior: 'smooth',
					})
				}
			}
		},
		[affiliateSlug]
	)

	useEffect(() => {
		const handleLoad = () => {
			const hash = window.location.hash
			if (hash) {
				const sectionId = hash.substring(1) // Remove the leading #
				setTimeout(() => {
					const targetRef =
						sectionId === WAYS_TO_GET_STARTED_SECTION
							? props.waysToGetStartedRef
							: props.aboutRef
					if (targetRef.current) scrollToSection(targetRef)
					history.replaceState(null, null, window.location.pathname)
				}, 500)
			}
		}

		handleLoad() // This works with affiliate site only.

		window.addEventListener('load', handleLoad) // This works with non-affiliate site only.
		return () => {
			window.removeEventListener('load', handleLoad)
		}
	}, [props, scrollToSection])

	const logoURL = get(affiliateDetails, 'logoURL', EMPTY_STRING)

	const menuItems = [
		{
			text: 'Learn',
			onClick: () => {
				scrollToSection(props.waysToGetStartedRef, WAYS_TO_GET_STARTED_SECTION)
			},
		},
		{
			text: 'Plan',
			onClick: () => openURLInNewTab(getNavigationRoute(affiliateSlug, MODEL)),
		},
		{
			text: 'Consult',
			onClick: () => toggleMeetingScheduler(),
		},
		{
			text: 'About',
			onClick: () =>
				scrollToSection(props.aboutRef, WHY_PLENTI_FINANCIAL_SECTION),
		},
		{
			text: 'Start Exchange',
			showIcon: false,
			classes: 'p-5 bg-black text-center text-white ',
			onClick: () => {
				const navigateTo =
					affiliateSlug && affiliateSlug !== PLENTI
						? `${START_EXCHANGE_URL}/${affiliateSlug}`
						: START_EXCHANGE_URL
				openURLInNewTabDefault(navigateTo)
			},
		},
	]

	return (
		<div className='w-full sticky top-0 z-[999]'>
			<div className='flex items-center justify-between !bg-white w-full shadow-md'>
				<div className='p-[15px] md:p-[25px] flex'>
					{logoURL && (
						<img
							src={logoURL}
							className='w-20 md:w-28 h-11 mr-7 object-contain'
							alt='logo'
						/>
					)}
					<img
						src={logoIcon}
						className='w-20 md:w-[112px] h-11'
						alt='logo-icon'
					/>
				</div>
				<div>
					<div
						className='md:hidden cursor-pointer p-[15px] md:p-[25px]'
						onClick={toggleMenu}
					>
						<img
							src={isMenuOpen ? crossIcon : hamburger}
							className='w-[30px] h-[30px]'
							alt={isMenuOpen ? 'Cross icon Menu' : 'Hamburger Menu'}
						/>
					</div>

					<div className={classNames('hidden md:block')}>
						<div className='flex items-center flex-1 justify-end py-[0.5rem] mr-[30px] font-work-sans-medium'>
							<div className='text-black text-sm'>
								<a href='tel:8669441031'>(866) 944-1031</a>
							</div>
							<div className='w-[49px] rotate-90 border border-black'></div>
							<div
								className='text-black text-sm cursor-pointer'
								onClick={() =>
									openURLInNewTabDefault('https://plentiPortal.com')
								}
							>
								Log In
							</div>
						</div>
						<div className='flex border-t border-black'>
							<MenuItems items={menuItems} />
						</div>
					</div>
				</div>
			</div>

			<div
				className={classNames(
					'text-sm text-slate-600 overflow-hidden transition-all duration-300 ease-in-out w-full bg-white h-0 md:hidden',
					{
						'h-[357px]': isMenuOpen,
					}
				)}
			>
				<div className='flex flex-col items-center justify-center font-work-sans-medium text-black text-sm gap-[5px] p-3 cursor-pointer'>
					<div className='text-black text-sm p-3'>
						<a href='tel:8669441031'> (866) 944-1031 </a>
					</div>
					<div
						className='text-black text-sm cursor-pointer border-b-4 border-black w-full p-3 text-center'
						onClick={() => openURLInNewTabDefault('plentiportal.com')}
					>
						Log In
					</div>
				</div>
				<div>
					<MenuItems items={menuItems} />
				</div>
			</div>
			{shouldShowMeetingSchedular && (
				<ReinvestmentGenericModal handleClose={toggleMeetingScheduler}>
					<div className='h-[80vh] w-full'>
						<div className='h-[80vh] w-full'>
							<iframe
								className='meetings-iframe-container h-[80vh] w-full'
								title='HubSpot Meeting Scheduler'
								src={`https://meetings.hubspot.com/${MEETING_SCHEDULER_ID}?embed=true`}
							/>
						</div>
					</div>
				</ReinvestmentGenericModal>
			)}
		</div>
	)
}
