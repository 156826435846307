import { createAsyncThunk } from '@reduxjs/toolkit'
import doAsync from '../../infrastructure/doAsync'

export const saveContactOnHubspot = createAsyncThunk(
	'reinvestment-affiliate/contacts',
	async (details, thunkArgs, { useCaching, noBusySpinner } = {}) =>
		await doAsync({
			url: 'contacts',
			useCaching,
			noBusySpinner,
			httpMethod: 'post',
			httpConfig: {
				body: JSON.stringify(details),
			},
			errorMessage: 'Unable to save your details. Please try again later.',
			...thunkArgs,
		})
)

export const fetchContactDetails = createAsyncThunk(
	'fetch-contact-details',
	async (query, thunkArgs) => {
		const queryParams = new URLSearchParams(query).toString()
		return await doAsync({
			url: `contacts?${queryParams}`,
			httpMethod: 'get',
			errorMessage: 'Unable to get contact details. Please try again later.',
			...thunkArgs,
		})
	}
)
