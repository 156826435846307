import React from 'react'
import { Routes as Switch, Route, Navigate } from 'react-router-dom'
import Page from './widgets/Page/Page'
import Model from './features/Model'
import {
	CurrentInvestment,
	InvestmentForm,
	CurrentInvestmentWrapper,
} from './features/CurrentInvestmentWrapper'
import { Scrollbars } from 'react-custom-scrollbars-2'
import useWindowSize from './utils/useWindowSize'
import FindInvestment from './features/FindInvestment'
import MoreDetails from './features/MoreDetails'
import Summary from './features/Summary'
import DownloadTaxReport from './features/DownloadTaxReport'
import MeetingScheduler from './features/MeetingScheduler'
import { Authentication, ReferralLink } from './features/AgentReferralGenerator'
import { NavRoutes, PLENTI } from './utils/constants'
import RequestApproval from './features/Affiliates/RequestApproval'
import StartApplication from './features/Affiliates/StartApplication'
import Terms from './features/Affiliates/Terms'
import AffiliateIntroduction from './features/Affiliates/AffiliateIntroduction'
import FormSubmitted from './features/Affiliates/FormSubmitted'
import { useSelector } from 'react-redux'
import { selectAffiliateSlug } from './features/Affiliates/affiliates.selectors'
import ReinvestmentAffiliate from './features/Reinvestment/ReinvestmentAffiliate'
import ReinvestmentChoices from './features/Reinvestment/ReinvestmentChoices'
import TaxReport from './features/Reinvestment/TaxReport'
import Roadmap from './features/Roadmap'
import Qualify from './features/Qualify/Qualify'

export default function Routes() {
	const affiliateSlug = useSelector(selectAffiliateSlug)

	const {
		HOME,
		DEFAULT_PATH,
		MODEL,
		CURRENT_INVESTMENT,
		INVESTMENT_FORM,
		FIND_INVESTMENTS,
		MORE_DETAILS,
		SUMMARY,
		TAX_REPORT,
		MEETING_SCHEDULER,
		AUTHENTICATION,
		REFERRAL_LINK,
		AFFILIATE_START_APPLICATION,
		SIGN_UP_FORM_SUBMITTED,
		AFFILIATE_INTRODUCTION,
		AFFILIATE_REQUEST_APPROVAL,
		AFFILIATE_TERMS,
		REINVESTMENT_CHOICES,
		TAXREPORT,
		ROADMAP,
		QUALIFY,
	} = NavRoutes

	const getRoutes = (Route) =>
		affiliateSlug && affiliateSlug !== PLENTI
			? `/${affiliateSlug}${Route}`
			: Route

	return (
		<Switch>
			<Route exact path={getRoutes(HOME)} element={<ReinvestmentAffiliate />} />
			<Route
				exact
				path={getRoutes(MODEL)}
				element={
					<PageRoute>
						<Model />
					</PageRoute>
				}
			/>
			<Route
				path={getRoutes(CURRENT_INVESTMENT)}
				element={
					<PageRoute>
						<CurrentInvestmentWrapper />
					</PageRoute>
				}
			>
				<Route
					index
					element={
						<PageRoute>
							<CurrentInvestment />
						</PageRoute>
					}
				/>
				<Route
					path={getRoutes(INVESTMENT_FORM)}
					element={
						<PageRoute>
							<InvestmentForm />
						</PageRoute>
					}
				/>
			</Route>
			<Route
				path={getRoutes(FIND_INVESTMENTS)}
				element={
					<PageRoute>
						<FindInvestment />
					</PageRoute>
				}
			/>
			<Route
				path={getRoutes(MORE_DETAILS)}
				element={
					<PageRoute>
						<MoreDetails />
					</PageRoute>
				}
			/>
			<Route
				path={getRoutes(SUMMARY)}
				element={
					<PageRoute>
						<Summary />
					</PageRoute>
				}
			/>
			<Route
				path={getRoutes(TAX_REPORT)}
				element={
					<PageRoute>
						<DownloadTaxReport />
					</PageRoute>
				}
			/>
			<Route
				path={getRoutes(MEETING_SCHEDULER)}
				element={
					<PageRoute>
						<MeetingScheduler />
					</PageRoute>
				}
			/>
			<Route
				path={getRoutes(AUTHENTICATION)}
				element={
					<PageRoute>
						<Authentication />
					</PageRoute>
				}
			/>
			<Route
				path={getRoutes(REFERRAL_LINK)}
				element={
					<PageRoute>
						<ReferralLink />
					</PageRoute>
				}
			/>
			<Route
				exact
				path={AFFILIATE_INTRODUCTION}
				element={
					<PageRoute>
						<AffiliateIntroduction />
					</PageRoute>
				}
			/>
			<Route
				path={SIGN_UP_FORM_SUBMITTED}
				element={
					<PageRoute>
						<FormSubmitted />
					</PageRoute>
				}
			/>
			<Route
				path={AFFILIATE_START_APPLICATION}
				element={
					<PageRoute>
						<StartApplication />
					</PageRoute>
				}
			/>
			<Route
				path={AFFILIATE_REQUEST_APPROVAL}
				element={
					<PageRoute>
						<RequestApproval />
					</PageRoute>
				}
			/>
			<Route
				path={AFFILIATE_TERMS}
				element={
					<Page>
						<div className='main-content lg:h-screen overflow-hidden flex-1'>
							<Terms />
						</div>
					</Page>
				}
			/>

			<Route
				path={getRoutes(REINVESTMENT_CHOICES)}
				element={<ReinvestmentChoices />}
			/>

			<Route path={getRoutes(TAXREPORT)} element={<TaxReport />} />

			<Route path={getRoutes(ROADMAP)} element={<Roadmap />} />

			<Route path={getRoutes(QUALIFY)} element={<Qualify />} />

			<Route
				exact
				path={DEFAULT_PATH}
				element={<Navigate to={getRoutes(HOME)} replace />}
			/>
		</Switch>
	)
}
function PageRoute({ children }) {
	const autoHideTimeout = 2000
	const autoHideDuration = 200
	const { width } = useWindowSize()
	const isDesktop = width > 1099
	return (
		<Page>
			<div className='main-content lg:h-screen overflow-hidden flex-1'>
				{isDesktop ? (
					<Scrollbars
						autoHide
						autoHideTimeout={autoHideTimeout}
						autoHideDuration={autoHideDuration}
						className='w-full h-full'
					>
						{children}
					</Scrollbars>
				) : (
					<>{children}</>
				)}
			</div>
		</Page>
	)
}
