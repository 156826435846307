import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	navbar: {
		isVisible: true,
	},
	intro: {
		isVisited: true,
	},
	currentInvestment: {
		isVisited: false,
	},
	findInvestment: {
		isVisited: false,
	},
	moreDetails: {
		isVisited: false,
	},
	summary: {
		isVisited: false,
	},
	auth: {
		isVisited: true,
	},
	referral: {
		isVisited: false,
	},
	affiliateIntroduction: {
		isVisited: true,
	},
	affiliateStartApplication: {
		isVisited: false,
	},
	affiliateRequestApproval: {
		isVisited: false,
	},
	affiliateTerms: {
		isVisited: false,
	},
}

const slice = createSlice({
	name: 'navbarStates',
	initialState,
	reducers: {
		shouldActivate: (state, action) => {
			state[action.payload].isVisited = true
		},
		shouldShowNavbar: (state, action) => {
			state.navbar.isVisible = action.payload
		},
	},
})

export default slice

export const { name, actions, reducer } = slice
