import CustomerDetailsForm from './CustomerDetailsForm'
import * as customerDetailsSelector from './customerDetails.selectors'
import slice from './customerDetails.slice'

export const {
	name,
	actions: { resetToInititalState },
	reducer,
} = slice
export const { selectAgentInfo, selectCustomerInfo, selectReferredAgentInfo } =
	customerDetailsSelector

export default CustomerDetailsForm
