import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import {
	copyIcon,
	greenCheckIcon,
	poweredByPlenti,
} from '../../../assets/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
	LINKEDIN_CALLBACK_URL_AGENTS_REFERRAL,
	APP_BASE_URL,
} from '../../../utils/getEnvVars'
import { createAgent } from '../../Model/CustomerDetailsForm/customerDetails.asyncActions'
import { shouldActivate } from '../../../widgets/NavBar'
import { selectAgentResponseInfo } from '../Authentication/authentication.selectors'
import BusyIndicator from '../../../widgets/busyIndicator'
import {
	isObjectEmpty,
	useQuery,
	copyTextToClipboard,
} from '../../../utils/methods'
import { NavLinkStates } from '../../../utils/constant.enum'
import { NavRoutes, PLENTI, SessionKeys } from '../../../utils/constants'
import { errorNotification } from '../../../widgets/ToastNotification'
import { selectAffiliateSlug } from '../../Affiliates/affiliates.selectors'

const { MODEL } = NavRoutes
const COPY = 'Copy'
const COPIED = 'Copied'
let isCopiedTimeoutID = ''
const ReferralLink = () => {
	const dispatch = useDispatch()
	const queryParams = useQuery()
	const { AGENT } = SessionKeys
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const isAffiliateSite = affiliateSlug !== PLENTI

	const [copyOptions, setCopyOptions] = useState({
		isCopied: false,
		copyStatus: COPY,
	})
	const agentInfo = JSON.parse(window.sessionStorage.getItem(AGENT))
	const agentResponseInfo = useSelector(selectAgentResponseInfo)
	const {
		id,
		firstName,
		lastName,
		email,
		profilePicture,
		phoneNumber,
		companyName,
		currentPosition,
	} = agentResponseInfo
	const REFERRAL_LINK = !isAffiliateSite
		? `${APP_BASE_URL}${MODEL}?agents=${id}`
		: `${APP_BASE_URL}/${affiliateSlug}${MODEL}?agents=${id}`
	const copyToClipboard = () => {
		if (REFERRAL_LINK) {
			copyTextToClipboard(REFERRAL_LINK)
				.then(() => {
					setCopyOptions({ isCopied: true, copyStatus: COPIED })
					isCopiedTimeoutID = setTimeout(() => {
						setCopyOptions({ ...copyOptions, isCopied: false })
					}, 1000)
				})
				.catch((err) => {
					errorNotification(err)
				})
		}
		clearTimeout(isCopiedTimeoutID)
	}
	useEffect(() => {
		const authCode = queryParams.get('code')
		if (isObjectEmpty(agentResponseInfo)) {
			if (authCode) dispatch(shouldActivate(NavLinkStates.REFERRAL))
			dispatch(
				createAgent({
					agent: { ...agentInfo },
					authCode: authCode || null,
					redirectURL: authCode ? LINKEDIN_CALLBACK_URL_AGENTS_REFERRAL : null,
				})
			)
		}
	}, [dispatch, agentInfo, agentResponseInfo, queryParams])
	return (
		<div
			className={`xl:px-22 xl:pb-22 px-12 pb-12 ${
				isAffiliateSite ? 'xl:pt-7 p-2' : 'xl:pt-22 pt-12'
			}`}
		>
			{isAffiliateSite && (
				<div className='flex justify-end xl:pb-7 pb-1'>
					<img src={poweredByPlenti} alt='Powered By Plenti Logo' />
				</div>
			)}
			<h1 className='text-3xl font-bold leading-7 text-gray-900 mb-3'>
				The Plenti Exchange<sup className='font-features sups'>TM</sup> App
				Referral Generator
			</h1>
			<p className='text-gray-700 mb-6'>
				This is how your information will be displayed on The Plenti Exchange
				<sup className='font-features sups'>TM</sup> App tax report. You can
				pass the below-listed referral link to your clients. This is your unique
				URL.
			</p>
			<div className='p-6 mb-6 rounded-lg bg-gray-50'>
				<p className='text-gray-500 mb-1 text-xs'>PREVIEW</p>
				<div className='flex flex-col space-y-4 md:space-y-0 md:space-x-6 md:flex-row'>
					{profilePicture && (
						<img
							src={profilePicture || ''}
							alt='Default image'
							className='self-center flex-shrink-0 w-24 h-24 border rounded-full md:justify-self-start dark:bg-gray-500 dark:border-gray-700'
						/>
					)}
					<div className='flex flex-col justify-center'>
						<span className='flex flex-col'>
							<h4 className='text-lg text-gray-900 font-semibold capitalize text-left'>
								<BusyIndicator>
									{firstName && <span>{firstName}</span>}
									{lastName && <span>&nbsp;{lastName}</span>}
								</BusyIndicator>
							</h4>
							<span className='text-sm text-gray-900'>
								<BusyIndicator>
									{currentPosition && <span>{currentPosition}</span>}
									{companyName && <span>,&nbsp;{companyName}</span>}
								</BusyIndicator>
							</span>
							<span className='text-sm text-gray-900'>
								<BusyIndicator>
									{email && <span>{email}</span>}
									{phoneNumber && (
										<>
											<span className='px-1'>|</span>
											<span>{phoneNumber}</span>
										</>
									)}
								</BusyIndicator>
							</span>
						</span>
					</div>
				</div>
			</div>
			<div className='form-group'>
				<label className='mb-2 text-sm text-gray-700 font-medium left-5 inline-block'>
					Your Referral Link
				</label>
				<div className='flex items-center mb-3 px-3'>
					<div className='text-gray-500 flex-1 border rounded-l-lg px-3 py-2'>
						<BusyIndicator customClass={'m-6-5-important'}>
							{id && <span>{REFERRAL_LINK}</span>}
						</BusyIndicator>
					</div>
					<button
						className='py-2 px-3 border rounded-r-lg border-l-0'
						onClick={copyToClipboard}
					>
						<span>
							<img
								id='copyToClipboardIcon'
								src={copyOptions.isCopied ? greenCheckIcon : copyIcon}
								alt='copy-icon'
								className={classnames('inline mr-2', {
									'animate-bounce': copyOptions.isCopied,
								})}
							/>
							{copyOptions.copyStatus}
						</span>
					</button>
				</div>
			</div>
		</div>
	)
}

export default ReferralLink
