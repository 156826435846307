import React, { useState } from 'react'
import { toast } from 'react-toastify'
import {
	arrowLongRight,
	closeIcon,
	downloadIcon,
	GreenStraightArrowUpIcon,
	HomeIcon,
	printIcon,
	RefreshIcon,
} from '../../../assets/icons'
import {
	standardExchangeRequirements,
	standardExchangeTimeline,
	improvementExchangeRequirements,
	improvementExchangeTimeline,
	reverseExchangeRequirements,
	reverseExchangeTimeline,
} from '../../../assets/images'
import { DOC_URL } from '../../../utils/constants'
import Loading from '../../../widgets/Loading'

const { STANDARD_EXCHANGE, IMPROVEMENT_EXCHANGE, REVERSE_EXCHANGE } = DOC_URL

const EXCHANGE_CARDS = [
	{
		name: 'standardExchange',
		heading: 'Standard 1031 Exchange',
		description:
			'Sell investment property & reinvest proceeds in similar one. Defer capital gains tax.',
		Icon: HomeIcon,
	},
	{
		name: 'reverseExchange',
		heading: 'Reverse 1031 Exchange',
		description:
			'Buy new property with loan. Sell old one & repay loan. Defer capital gains tax.',
		Icon: RefreshIcon,
	},
	{
		name: 'improvementExchange',
		heading: 'Improvement 1031 Exchange',
		description: 'Sell property, reinvest & upgrade. Defer capital gains tax.',
		Icon: GreenStraightArrowUpIcon,
	},
]

const MODAL_DETAILS = {
	standardExchange: {
		heading: 'Standard 1031 Exchange',
		description:
			'Allows a taxpayer to defer capital gains taxes on the sale of investment property by reinvesting the proceeds into a similar property within a specific timeframe.',
		subDescription: `To fully avoid paying taxes on your sale, the 1031 Exchange requires that you reinvest all of the cash you'll net from your sale ( after paying off mortgages, commissions, closing fees, etc.) into new investment property of equal or greater value.*`,
		footerText: `Your 1031 exchange must be set up BEFORE your sale closes. Then, from the closing date of your sale (Day 0),| you'll have 45 days to finalize and identify the properties you're planning to purchase, and 180 days in total to complete the purchase(s) of your new property.`,
		requirementImage: standardExchangeRequirements,
		timelineImage: standardExchangeTimeline,
		pdfLink: STANDARD_EXCHANGE,
	},
	improvementExchange: {
		heading: 'Improvement 1031 Exchange',
		description:
			'Allows you to count the dollar value of the improvements that you make towards the purchase price of your new replacement property in your 1031 Exchange. ',
		subDescription: `To fully avoid paying taxes on your sale, the 1031 Exchange requires that you reinvest all of the cash you'll net from your sale ( after paying off mortgages, commissions, closing fees, etc.) into new investment property of equal or greater value.* In an Improvement 1031 exchange, you can make improvements to the property you purchase and have the dollar value of the improvements you make added to the overall price of the new property.`,
		footerText: `An Improvement 1031 Exchange typically begins as a Standard 1031 Exchange (which must be set up BEFORE your sale closes). If you'll be doing an Improvement Exchange, you'll need to set it up BEFORE you close on your new replacement property purchase. From the closing date of your sale (Day 0), you'll have 45 days to finalize and identify the properties you're planning to purchase, and 180 days in total to (A) complete the purchase(s) of your new property and (B) complete your improvements to the property. The IRS requires your Accommodator (that's us) take title to, and be named as an 'additionally insured party' on the new property you'll be purchasing. The property will then be deeded back to you at Day 180 of your exchange at the new-and-improved value.`,
		footerSubText:
			'*If you want to take some cash at closing, or buy less property than what you sold, you can do so in a Partial exchange, allowing you to shelter a portion or your profits from taxes.',
		requirementImage: reverseExchangeRequirements,
		timelineImage: reverseExchangeTimeline,
		pdfLink: IMPROVEMENT_EXCHANGE,
	},
	reverseExchange: {
		heading: 'Reverse 1031 Exchange',
		description: `Just a standard 1031 Exchange, in reverse! As long as you're getting advice from an experienced 1031 Exchange Accommodator, they can be much easier that a standard exchange.`,
		subDescription: `To fully avoid paying taxes on your sale, the 1031 exchange requires that you reinvest all of the cash you'll net from your upcoming sale (after paying off mortgages, commissions, closing fees, etc.) into new investment property of equal or greater value.* The only thing that makes a Reverse 1031 exchange different than a regular 1031 exchange is that you'll purchase your new investment property first, then sell your existing property after.`,
		footerText: `Your Reverse 1031 exchange documents must be set up BEFORE your purchase closes. Additionally, the IRS requires that your Accommodator (that's us) be given a deed to the property you'll be selling (this deed will not be recorded), and be named an 'additionally insured party on that property prior to closing on your purchase. Then, from the closing date of your purchase (Day 0), you'll have 180 days to complete the sale of your existing property.`,
		footerSubText:
			'*If you want to take some cash at closing, or buy less property than what you sold, you can do so in a Partial exchange, allowing you to shelter a portion or your profits from taxes.',
		requirementImage: improvementExchangeRequirements,
		timelineImage: improvementExchangeTimeline,
		pdfLink: REVERSE_EXCHANGE,
	},
}

const ExchangeModal = ({ selectedModal, handleClose }) => {
	const [isFileLoading, setIsFileLoading] = useState(false)
	const {
		heading,
		description,
		subDescription,
		footerText,
		footerSubText,
		requirementImage,
		timelineImage,
		pdfLink,
	} = MODAL_DETAILS[selectedModal] || {}

	const handleFileDownload = async (e) => {
		setIsFileLoading(true)
		try {
			const response = await fetch(pdfLink)
			const blob = await response.blob()

			const url = window.URL.createObjectURL(new Blob([blob]))

			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', `${heading}.pdf`)

			document.body.appendChild(link)
			link.click()
			link.parentNode.removeChild(link)

			setIsFileLoading(false)
		} catch (error) {
			setIsFileLoading(false)
			toast.error('Failed to download the file')
		}
	}

	return (
		<div
			className='modal-backdrop fixed flex items-end sm:items-center inset-0 bg-slate-900/70 backdrop-blur-md z-[999] overflow-auto transition-opacity duration-300 custom-scrollbar'
			onClick={handleClose}
		>
			<div
				onClick={(e) => e.stopPropagation()}
				className='modal-content bg-white mx-auto relative flex flex-col inset-y-0 right-0 max-w-[1200px] w-full sm:w-[80vw] max-h-[90vh] sm:max-h-[80vh] sm:my-auto rounded-none md:rounded-lg print:max-w-full print:max-h-full print:w-full print:h-full'
			>
				<div className='flex px-3 print:hidden bg-solitude gap-4 items-center py-4'>
					<button
						className='btn btn-light hover:bg-gray-100 rounded-full font-work-sans-semibold text-gray-700 flex items-center justify-center gap-2'
						type='button'
						onClick={window.print}
					>
						Print <img src={printIcon} className='w-4 h-4' />
					</button>
					<button
						className='btn btn-primary-light hover:bg-primary-100 rounded-full font-work-sans-semibold text-white flex items-center justify-center gap-2'
						type='button'
						onClick={handleFileDownload}
						disabled={isFileLoading}
					>
						Download
						<Loading loading={isFileLoading}>
							<img src={downloadIcon} className='w-4 h-4' />
						</Loading>
					</button>
					<button
						className='p-3.5 rounded-full hover:bg-white duration-300 cursor-pointer ml-auto'
						type='button'
						onClick={handleClose}
					>
						<img src={closeIcon} className='w-5 h-5' />
					</button>
				</div>
				<div className='flex flex-col flex-1 overflow-x-auto no-scrollbar print:overflow-visible gap-6 p-6 font-work-sans-regular text-gray-700'>
					<div className='font-work-sans-semibold text-xl text-gray-900'>
						{heading}
					</div>
					<div>{description}</div>
					<div className='font-work-sans-medium text-lg text-primary-700'>
						Investment Requirements
					</div>
					<div>{subDescription}</div>
					<div>
						<img src={requirementImage} alt='requirements' />
					</div>
					<div className='font-work-sans-medium text-lg text-primary-700'>
						Timeline
					</div>
					<div>
						<img src={timelineImage} alt='timeline' />
					</div>
					<div>{footerText}</div>
					{footerSubText && (
						<div className='text-sm text-gray-600 text-center'>
							{footerSubText}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

const ExchangeTypes = () => {
	const [selectedModal, setSelectedModal] = useState()

	return (
		<>
			<div className='p-8 pt-0 sm:p-12 sm:pt-0 md:p-24 md:pt-0 flex flex-col gap-12'>
				<div>
					<div className='font-work-sans-light text-43 text-gray-500'>
						Exchange Types
					</div>
					<div className='font-work-sans-regular text-lg text-gray-500 mt-8'>
						1031 exchanges may seem confusing at first, but they’re really quite
						simple. Below, you’ll find an overview of the major requirements and
						timelines to be aware of in your upcoming 1031 exchange.
					</div>
				</div>
				<div className='flex gap-6 flex-col md:flex-row'>
					{EXCHANGE_CARDS.map(({ name, heading, description, Icon }) => (
						<div
							key={name}
							className='md:flex-1 gap-24 cursor-pointer bg-gradient-to-bl from-primary-600 to-primary-200 flex flex-col p-6'
							onClick={() => setSelectedModal(name)}
						>
							<div className='bg-gray-50 rounded-full w-fit p-1.5'>
								<div className='bg-gray-100 rounded-full w-fit p-1.5'>
									<Icon className='stroke-primary-600 w-6 h-6' />
								</div>
							</div>
							<div className='font-work-sans-regular flex-1 flex flex-col justify-between text-white'>
								<div>
									<div className='text-2xl'> {heading} </div>
									<div className='text-sm mt-2'> {description} </div>
								</div>
								<img src={arrowLongRight} className='mt-6 ml-auto' />
							</div>
						</div>
					))}
				</div>
				<div className='font-work-sans-regular text-sm text-gray-400 text-center'>
					Disclaimer: Improvement 1031 Exchanges are complex. Consult tax and
					legal advisors before proceeding
				</div>
			</div>
			{selectedModal && (
				<ExchangeModal
					selectedModal={selectedModal}
					handleClose={() => setSelectedModal()}
				/>
			)}
		</>
	)
}

export default ExchangeTypes
