// NavBar Links enum
export const NavLinkStates = {
	MODEL: 'model',
	CURRENT_INVESTMENT: 'currentInvestment',
	FIND_INVESTMENTS: 'findInvestment',
	MORE_DETAILS: 'moreDetails',
	SUMMARY: 'summary',
	AUTH: 'auth',
	REFERRAL: 'referral',
	START_APPLICATION: 'affiliateStartApplication',
	REQUEST_APPROVAL: 'affiliateRequestApproval',
	TERMS: 'affiliateTerms',
}
export const PropertyTypes = {
	'My Residence': 0,
	'Single Family Rental': 1,
	'Vacation Rental or Second Home': 2,
	'CO-OP Apartment': 3,
	'Family Home with Home office': 4,
	'Rented Units/Guest House (ADU/Granny Flat)': 5,
	'Specialized 1031 Passive Investment': 6,
	'Land, Office, Mixed Use, Medical or Multi-Family': 7,
	'Commercial Property, Other NNN etc.': 8,
}
export const MarriedTypes = {
	Married: 0,
	Single: 1,
	'Divorced/Separated': 2,
	Widowed: 3,
}
export const INVESTMENT_ENUMS = {
	SINGLE_FAMILY_RENTAL: 0,
	VACATION_RENTAL: 1,
	SPECIALIZED_1031_INVESTMENTS: 2,
	FAMILY_HOME_WITH_HOME_OFFICE: 3,
	LAND_OFFICE_MIXED_USE: 4,
	CO_OP_APARTMENT: 5,
	COMMERCIAL_PROPERTY: 6,
}
