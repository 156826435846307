import React from 'react'
import { ceoProfilePicture } from '../../../assets/images'
import { Ellipse } from '../../../assets/icons'

const About = () => (
	<div className='p-8 sm:p-12 md:p-24 lg:pr-0 lg:pl-24 lg:pt-14'>
		<div className='font-work-sans-light text-43 text-primary-600 mb-6 sm:mb-12'>
			Elevate your Tax Free Real Estate Strategy with Us!
		</div>
		<div className='flex flex-col sm:flex-row gap-5 w-full'>
			<div className='w-full lg:w-2/4 '>
				<div className='text-gray-500 text-43 font-work-sans-light pb-4 lg:pb-8'>
					About Plenti Financial
				</div>
				<div className='text-gray-500 text-lg font-work-sans-regular'>
					In business for almost 50 years, Plenti Financial is a nationwide
					provider of 1031 exchange accommodation services. There has never been
					a claim to recover funds against the firm. Your exchange funds, once
					sent to our bank, cannot be moved without your signature. This is our
					policy and is available on every account due to a special account
					agreement governing the exchange account, (Qualified Escrow Agreement)
					which requires your signature and instructions before funds can be
					moved. We work with some of the largest brokerages in the country and
					the banks we utilize have only the highest ratings in many categories
					and participate in a program to ensure 100% FULL balance FDIC
					coverage, no matter the amount, up to 200 ML.
				</div>
			</div>
			<div className='w-full sm:w-2/4 relative'>
				<div className='object-cover flex flex-col items-center justify-center w-full gap-2 relative lg:pl-20 xl:pl-32 2xl:pl-44 lg:pr-6'>
					<Ellipse
						preserveAspectRatio='none'
						className='absolute fill-primary-600 right-0 hidden lg:block w-full h-[calc(100%+7rem)] -top-28 2xl:h-[calc(100%+9rem)] 2xl:-top-32 z-[-10]'
					/>
					<img className='rounded-full z-10' src={ceoProfilePicture} />
					<div className=' text-gray-500 text-xs font-work-sans-semibold z-10  w-full'>
						A MESSAGE FROM OUR CEO
					</div>
					<div className='text-primary-700 text-lg font-work-sans-regular w-full z-10'>
						Hi, I’m David P. Greenberger,
					</div>
					<div className='text-gray-500 text-xs font-work-sans-regular w-full z-10 flex flex-col gap-2'>
						<div>
							Hi, I&apos;m David, CEO of Plenti Financial. I went to law school
							a long time ago for two reasons: I wanted to help people, and my
							mother told me too. But it wasn&apos;t until twenty years
							later—the day I stopped practicing law—that I really started to
							help people to improve their lives with Tax Free Real Estate
							Investing choices. It all began with a simple idea—help as many
							people as I can improve their lives through smarter real estate
							investment choices. Everyone&apos;s property & circumstances are
							unique.
						</div>
						<div>
							I invite you to call and learn about how some of your real estate
							life choices can be met with tax free exchanging to build wealth
							and help you achieve your goals.
						</div>
						<div>
							Remember death and taxes are certain, unless you exchange!
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
)

export default About
