import {
	singleBuildingIcon,
	markerIcon,
	starsIcon,
	homeIcon,
	globleIcon,
	doubleBuildingIcon,
	govBuildingIcon,
} from '../../assets/icons'

export const accordionTitles = [
	'Single Family Rental',
	'Vacation Rental',
	'Specialized 1031 Investments',
	'Family home with home office, rented units or guest house',
	'Land, Office, Mixed-Use, Medical or Multi-Family',
	'Co-Op Apartment',
	'Model my own property',
]
export const accordianSubTitle = [
	'Available nationwide, can be managed by 3rd party & turn-key',
	'Nationwide resort markets, high returns, and managed by professional hospitality firms',
	'Dependable passive income - no management, like a real estate investment trust (REIT)',
	'Real estate is expensive, work & make money at home',
	'Commercial investments nationwide, plenty of off-market properties',
	'Long term leases qualify for a 1031 exchange',
	'Plenty of  off-market properties from our clients',
]
export const accordionIcons = [
	singleBuildingIcon,
	markerIcon,
	starsIcon,
	homeIcon,
	globleIcon,
	doubleBuildingIcon,
	govBuildingIcon,
]
export const accordionIndexes = [0, 1, 2, 3, 4, 5, 6]
export const INVESTMENT_OPTIONS = [
	'purchasePrice',
	'downPayment',
	'rentalIncome',
	'market',
]
export const PURCHASE_PRICE = 'purchasePrice'
export const DOWN_PAYMENT = 'downPayment'
export const RENTAL_INCOME = 'rentalIncome'
export const MARKET = 'market'

export const CALC_RENTAL_INCOME = {
	SINGLE_FAMILY_RENTAL: 0.06,
	VACATION_RENTAL: 0.09,
	SPECIALIZED_1031: 0.0485,
}
