import React from 'react'

const IntroModal = ({
	shouldShowModal,
	setShouldShowModal,
	result,
	title,
	subtitle,
	children,
}) => {
	return (
		<>
			{shouldShowModal && (
				<div className='modal fixed inset-0 bg-slate-900/70 backdrop-blur-md z-[999]  overflow-auto px-4 flex items-center'>
					<div className='modal-dialog bg-white rounded-xl sm:w-[400px] my-6 mx-auto'>
						<div className='modal-header flex items-center p-6'>
							<div
								className={`icon w-10 h-10 grid place-items-center rounded-full ${result.iconBackground} `}
							>
								<img src={result.icon} width='24' alt='target-icon' />
							</div>
						</div>
						<div className='modal-body text-left px-6'>
							<h1 className='text-lg font-medium leading-7 mb-2 text-gray-900'>
								{title}
							</h1>
							<p className='text-sm leading-5 text-gray-500 mb-3'>{subtitle}</p>
							{children}
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default IntroModal
