import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { shouldShowNavbar } from '../../widgets/NavBar'
import { NavRoutes, PLENTI } from '../../utils/constants'
import { MEETING_SCHEDULER_ID } from '../../utils/getEnvVars'
import { refreshChatWindow, getNavigationRoute } from '../../utils/methods'
import { selectAffiliateSlug } from '../Affiliates/affiliates.selectors'
import { poweredByPlenti } from '../../assets/icons'

const MeetingScheduler = () => {
	const navigate = useNavigate()
	const { state } = useLocation()
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const isAffiliateSite = affiliateSlug !== PLENTI
	const dispatch = useDispatch()
	const { SUMMARY } = NavRoutes
	const navigateToSummary = () => {
		dispatch(shouldShowNavbar(true))
		state
			? navigate(isAffiliateSite ? `/${affiliateSlug}/${state}` : state)
			: navigate(getNavigationRoute(affiliateSlug, SUMMARY))
	}
	window.addEventListener('popstate', (event, state) => {
		if (event.state?.usr == null) {
			dispatch(shouldShowNavbar(true))
		} else {
			refreshChatWindow()
			dispatch(shouldShowNavbar(false))
		}
	})
	return (
		<div
			className={`xl:px-22 xl:pb-22 px-12 pb-12 flex flex-col h-full xs:p-[1.1rem] sm:p-[2.5rem] ${
				isAffiliateSite ? 'xl:pt-7 p-2' : 'xl:pt-22 pt-12'
			}`}
		>
			{isAffiliateSite && (
				<div className='flex justify-end xl:pb-7 pb-1'>
					<img src={poweredByPlenti} alt='Powered By Plenti Logo' />
				</div>
			)}
			<h1 className='text-3xl font-bold leading-8 text-gray-900 mb-3'>
				Schedule a Consultation
			</h1>
			<p className='text-gray-700 mb-6'>
				A consultation can get you on track and set your goals and objectives to
				choose the right reinvestment! Don&apos;t Lose Out, plan to win.
			</p>
			<div className='mb-6 flex-1'>
				<iframe
					className='meetings-iframe-container w-full lg:h-[100%] md:h-[675px] sm:h-[680px] xs:h-[700px]'
					title='HubSpot Meeting Scheduler'
					src={`https://meetings.hubspot.com/${MEETING_SCHEDULER_ID}?embed=true`}
				/>
			</div>
			<div className='text-right'>
				<button className='btn btn-light' onClick={navigateToSummary}>
					Previous
				</button>
			</div>
		</div>
	)
}

export default MeetingScheduler
