import classNames from 'classnames'
import React, { useState } from 'react'

const Accordion = ({
	title,
	active = false,
	children,
	accordionClasses = '',
	titleClasses = '',
}) => {
	const [accordionOpen, setAccordionOpen] = useState(active)

	return (
		<div
			className={`w-full h-full ${accordionClasses}`}
			aria-expanded={accordionOpen}
		>
			<button
				className={`flex items-center justify-between w-full group text-left ${titleClasses}`}
				onClick={(e) => {
					e.preventDefault()
					setAccordionOpen(!accordionOpen)
				}}
				aria-expanded={accordionOpen}
			>
				<span>{title}</span>
				<div className='border-2 border-current rounded-full p-1 ml-8 opacity-70'>
					<svg className='fill-current shrink-0' width='10' height='10'>
						<rect y='4.25' width='10' height='1.5' rx='1' />
						<rect
							y='4.25'
							width='10'
							height='1.5'
							rx='1'
							className='transform origin-center rotate-90 transition duration-300 ease-out group-aria-expanded:opacity-0'
						/>
					</svg>
				</div>
			</button>
			<div
				className={classNames(
					'grid overflow-hidden transition-all duration-500 ease-in-out',
					{
						'grid-rows-[1fr] opacity-100': accordionOpen,
						'grid-rows-[0fr] opacity-0': !accordionOpen,
					}
				)}
			>
				<div className='overflow-hidden'>{children}</div>
			</div>
		</div>
	)
}

export default Accordion
