import { createSlice } from '@reduxjs/toolkit'
import { createAgent } from '../../Model/CustomerDetailsForm/customerDetails.asyncActions'

const initialState = {
	agentResponseInfo: {},
}
const slice = createSlice({
	name: 'authentication',
	initialState,
	reducers: {
		resetToInititalState: () => initialState,
	},
	extraReducers: {
		[createAgent.fulfilled]: (state, action) => {
			state.agentResponseInfo = action.payload || initialState.agentResponseInfo
		},
	},
})

export default slice
export const { name, actions, reducer } = slice
