import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
	greenGovBuildingIcon,
	greenBuildingsIcon,
	greenCommercialBuildingIcon,
	greenHomeIcon,
	chartIcon,
	greenStarsIcon,
	greenGlobeIcon,
	markerPinIcon,
	greenTrendIcon,
} from '../../../assets/icons'
import { landingLeftBg } from '../../../assets/images'
import { towerSummary } from '../../../assets/gifs'
import {
	selectCurrentInvestment,
	selectNewInvestments,
} from '../../MoreDetails/moreDetails.selectors'
import { INVESTMENT_TYPE } from '../summary.constants'
import BusyIndicator from '../../../widgets/busyIndicator'
import { getCurrencyCode, numberCommasSeparator } from '../../../utils/methods'
import { INVESTMENT_ENUMS } from '../../../utils/constant.enum'
import {
	NUMBER_OF_MONTHS,
	TWO_DECIMAL_PLACE,
	PROPERTY_APPRECIATION_FACTOR,
} from '../../../utils/constants'
import { isEmpty, map } from 'lodash'
import classnames from 'classnames'
import '../../../app.css'

const USD = 'USD'
const ANNUALLY = ' annually'
const MONTH = '/month'
const ARRAY_INDEX_ZERO = 0

const SummaryInvestment = () => {
	const currentInvestment = useSelector(selectCurrentInvestment)
	const newInvestments = useSelector(selectNewInvestments)
	const [shouldShowMultipleInvestment, setShouldShowMultipleInvestment] =
		useState(false)
	const {
		SINGLE_FAMILY_RENTAL,
		VACATION_RENTAL,
		SPECIALIZED_1031_INVESTMENTS,
		FAMILY_HOME_WITH_HOME_OFFICE,
		LAND_OFFICE_MIXED_USE,
		CO_OP_APARTMENT,
		COMMERCIAL_PROPERTY,
	} = INVESTMENT_ENUMS
	const { totalEstimatedPurchasePrice, totalExpectedRentalIncome } =
		currentInvestment
	const [isAnnualROI, setIsAnnualROI] = useState(false)
	const [calculatedPurchasePrice, setCalculatedPurchasePrice] = useState()
	const [calculatedRentalIncome, setCalculatedRentalIncome] = useState()
	const [selectedInvestments, setSelectedInvestments] = useState([])
	const calculateROI = () => {
		if (!isAnnualROI) {
			setCalculatedRentalIncome(
				Math.round(
					(calculatedRentalIncome * NUMBER_OF_MONTHS).toFixed(TWO_DECIMAL_PLACE)
				)
			)
			setCalculatedPurchasePrice(
				Math.round(calculatedPurchasePrice * NUMBER_OF_MONTHS)
			)
		} else {
			setCalculatedRentalIncome(
				Math.round(
					(calculatedRentalIncome / NUMBER_OF_MONTHS).toFixed(TWO_DECIMAL_PLACE)
				)
			)
			setCalculatedPurchasePrice(
				Math.round(calculatedPurchasePrice / NUMBER_OF_MONTHS)
			)
		}
		setIsAnnualROI((prevState) => !prevState)
	}
	const shouldShowInvestment = (key) => selectedInvestments.includes(key)
	const isInvestmentsCountOdd = () => {
		const uniqueInvestments = [
			...new Set(map(newInvestments, 'investmentType')),
		]
		return newInvestments && uniqueInvestments.length % 2 !== 0
	}
	useEffect(() => {
		if (totalEstimatedPurchasePrice) {
			const purchasePrice = Math.round(
				(totalEstimatedPurchasePrice * PROPERTY_APPRECIATION_FACTOR) /
					NUMBER_OF_MONTHS
			)
			setCalculatedPurchasePrice(purchasePrice)
		}
		if (totalExpectedRentalIncome)
			setCalculatedRentalIncome(Math.round(totalExpectedRentalIncome))
		setShouldShowMultipleInvestment(newInvestments && newInvestments.length > 1)
		if (!isEmpty(newInvestments)) {
			const investments = newInvestments.map(
				(investment) => investment.investmentType
			)
			setSelectedInvestments(investments)
		}
	}, [newInvestments, totalEstimatedPurchasePrice, totalExpectedRentalIncome])

	return (
		<>
			{shouldShowMultipleInvestment ? (
				<div className='border border-gray-300 rounded-lg p-6 mb-6'>
					<h1 className='text-gray-900 text-2xl font-bold leading-10 mb-8'>
						You plan on reinvesting in multiple properties
					</h1>
					<div
						className={classnames('grid grid-cols-2 gap-6 lg:gap-8 mb-4', {
							'investments-container': isInvestmentsCountOdd(),
						})}
					>
						{shouldShowInvestment(FAMILY_HOME_WITH_HOME_OFFICE) && (
							<div className='col-span-2 lg:col-span-1 border border-gray-200 p-4 rounded-lg'>
								<div className='flex'>
									<div className='icon mr-4 shrink-0 mt-1.5'>
										<img src={greenHomeIcon} width='24' alt='home icon' />
									</div>
									<div>
										<h5 className='text-sm text-gray-800 mb-1 font-medium'>
											{INVESTMENT_TYPE[FAMILY_HOME_WITH_HOME_OFFICE].title}
										</h5>
										<p className='text-xs text-gray-800'>
											{INVESTMENT_TYPE[FAMILY_HOME_WITH_HOME_OFFICE].content}
										</p>
									</div>
								</div>
							</div>
						)}
						{shouldShowInvestment(CO_OP_APARTMENT) && (
							<div className='col-span-2 lg:col-span-1 border border-gray-200 p-4 rounded-lg'>
								<div className='flex'>
									<div className='icon mr-4 shrink-0 mt-1.5'>
										<img
											src={greenBuildingsIcon}
											width='24'
											alt='building icon'
										/>
									</div>
									<div>
										<h5 className='text-sm text-gray-800 mb-1 font-medium'>
											{INVESTMENT_TYPE[CO_OP_APARTMENT].title}
										</h5>
										<p className='text-xs text-gray-800'>
											{INVESTMENT_TYPE[CO_OP_APARTMENT].content}
										</p>
									</div>
								</div>
							</div>
						)}
						{shouldShowInvestment(SPECIALIZED_1031_INVESTMENTS) && (
							<div className='col-span-2 lg:col-span-1 border border-gray-200 p-4 rounded-lg'>
								<div className='flex'>
									<div className='icon mr-4 shrink-0 mt-1.5'>
										<img src={greenStarsIcon} width='24' alt='stars icon' />
									</div>
									<div>
										<h5 className='text-sm text-gray-800 mb-1 font-medium'>
											{INVESTMENT_TYPE[SPECIALIZED_1031_INVESTMENTS].title}
										</h5>
										<p className='text-xs text-gray-800'>
											{INVESTMENT_TYPE[SPECIALIZED_1031_INVESTMENTS].content}
										</p>
									</div>
								</div>
							</div>
						)}
						{shouldShowInvestment(LAND_OFFICE_MIXED_USE) && (
							<div className='col-span-2 lg:col-span-1 border border-gray-200 p-4 rounded-lg'>
								<div className='flex'>
									<div className='icon mr-4 shrink-0 mt-1.5'>
										<img src={greenGlobeIcon} width='24' alt='globe icon' />
									</div>
									<div>
										<h5 className='text-sm text-gray-800 mb-1 font-medium'>
											{INVESTMENT_TYPE[LAND_OFFICE_MIXED_USE].title}
										</h5>
										<p className='text-xs text-gray-800'>
											{INVESTMENT_TYPE[LAND_OFFICE_MIXED_USE].content}
										</p>
									</div>
								</div>
							</div>
						)}
						{shouldShowInvestment(COMMERCIAL_PROPERTY) && (
							<div className='col-span-2 lg:col-span-1 border border-gray-200 p-4 rounded-lg'>
								<div className='flex'>
									<div className='icon mr-4 shrink-0 mt-1.5'>
										<img
											src={greenGovBuildingIcon}
											width='24'
											alt='goverment building'
										/>
									</div>
									<div>
										<h5 className='text-sm text-gray-800 mb-1 font-medium'>
											{INVESTMENT_TYPE[COMMERCIAL_PROPERTY].title}
										</h5>
										<p className='text-xs text-gray-800'>
											{INVESTMENT_TYPE[COMMERCIAL_PROPERTY].content}
										</p>
									</div>
								</div>
							</div>
						)}
						{shouldShowInvestment(VACATION_RENTAL) && (
							<div className='col-span-2 lg:col-span-1 border border-gray-200 p-4 rounded-lg'>
								<div className='flex'>
									<div className='icon mr-4 shrink-0 mt-1.5'>
										<img src={markerPinIcon} width='24' alt='marker icon' />
									</div>
									<div>
										<h5 className='text-sm text-gray-800 mb-1 font-medium'>
											{INVESTMENT_TYPE[VACATION_RENTAL].title}
										</h5>
										<p className='text-xs text-gray-800'>
											{INVESTMENT_TYPE[VACATION_RENTAL].content}
										</p>
									</div>
								</div>
							</div>
						)}
						{shouldShowInvestment(SINGLE_FAMILY_RENTAL) && (
							<div className='col-span-2 lg:col-span-1 border border-gray-200 p-4 rounded-lg'>
								<div className='flex'>
									<div className='icon mr-4 shrink-0 mt-1.5'>
										<img
											src={greenCommercialBuildingIcon}
											width='24'
											alt='commercial building'
										/>
									</div>
									<div>
										<h5 className='text-sm text-gray-800 mb-1 font-medium'>
											{INVESTMENT_TYPE[SINGLE_FAMILY_RENTAL].title}
										</h5>
										<p className='text-xs text-gray-800'>
											{INVESTMENT_TYPE[SINGLE_FAMILY_RENTAL].content}
										</p>
									</div>
								</div>
							</div>
						)}
						<div className='col-span-2 border border-gray-200 p-4 rounded-lg'>
							<div className='flex'>
								<div className='icon mr-4 shrink-0 mt-1.5'>
									<img src={chartIcon} width='24' alt='chart icon' />
								</div>
								<div>
									<h5 className='text-sm text-gray-800 mb-1 font-medium 2xl:text-base'>
										{
											<BusyIndicator>
												{getCurrencyCode(USD)}
												{calculatedRentalIncome && (
													<span>
														{numberCommasSeparator(calculatedRentalIncome)}
													</span>
												)}
											</BusyIndicator>
										}
										{isAnnualROI ? ANNUALLY : MONTH}
									</h5>
									<p className='text-xs text-gray-800 2xl:text-sm'>
										New Rent Revenue
									</p>
								</div>
							</div>
						</div>
						<div className='col-span-2 border border-gray-200 p-4 rounded-lg'>
							<div className='flex'>
								<div className='icon mr-4 shrink-0 mt-1.5'>
									<img src={greenTrendIcon} width='24' alt='trend icon' />
								</div>
								<div>
									<h5 className='text-sm text-gray-800 mb-1 font-medium 2xl:text-base'>
										{
											<BusyIndicator>
												{getCurrencyCode(USD)}
												{calculatedPurchasePrice && (
													<span>
														{numberCommasSeparator(calculatedPurchasePrice)}
													</span>
												)}
											</BusyIndicator>
										}
										{isAnnualROI ? ANNUALLY : MONTH}
									</h5>
									<p className='text-xs text-gray-800 2xl:text-sm'>
										Property Appreciation
									</p>
								</div>
							</div>
						</div>
					</div>
					<label className='inline-flex relative items-center cursor-pointer mr-auto md:mr-0 ml-auto'>
						<input
							type='checkbox'
							value=''
							className='sr-only peer'
							onClick={calculateROI}
						/>
						<div className="w-11 h-6 bg-gray-300 peer-focus:outline-none rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-primary-700"></div>
						<span className='ml-3 text-sm font-medium text-gray-900'>
							Annual ROI
						</span>
					</label>
				</div>
			) : (
				<div className='grid grid-cols-12 border border-gray-300 rounded-lg mb-6 overflow-hidden'>
					<div className='hidden lg:col-span-5 2xl:col-span-6 lg:flex justify-start items-end relative'>
						<img src={landingLeftBg} alt='bulb image' />
						<img
							src={towerSummary}
							className='absolute py-8 right-1/2 top-1/2 translate-x-1/2 -translate-y-1/2 w-10/12 2xl:w-7/12 rounded-full bg-white border-gray-200 border-2'
							alt='house gif'
						/>
					</div>
					<div className='animate-slide-in-top col-span-12 lg:col-span-7 2xl:col-span-6 px-6 py-16 flex flex-col justify-center'>
						<h1 className='text-gray-900 text-4xl font-bold mb-4'>
							You plan on reinvesting in a&nbsp;
							{
								<BusyIndicator>
									{newInvestments[ARRAY_INDEX_ZERO] && (
										<span>
											{
												INVESTMENT_TYPE[
													newInvestments[ARRAY_INDEX_ZERO].investmentType
												].title
											}
										</span>
									)}
								</BusyIndicator>
							}
						</h1>
						<h3 className='text-gray-600 text-base mb-8'>
							<BusyIndicator>
								{newInvestments[ARRAY_INDEX_ZERO] && (
									<span>
										{
											INVESTMENT_TYPE[
												newInvestments[ARRAY_INDEX_ZERO].investmentType
											].content
										}
									</span>
								)}
							</BusyIndicator>
						</h3>
						<div className='space-y-3'>
							<div className='p-3 flex items-center rounded-md space-x-4 border'>
								<div>
									<img src={chartIcon} />
								</div>
								<div className='flex-1'>
									<div className='text-sm 2xl:text-base text-gray-700'>
										{
											<BusyIndicator>
												{getCurrencyCode(USD)}
												{calculatedRentalIncome && (
													<span>
														{numberCommasSeparator(calculatedRentalIncome)}
													</span>
												)}
											</BusyIndicator>
										}
										{isAnnualROI ? ANNUALLY : MONTH}
									</div>
									<p className='text-xs 2xl:text-sm text-gray-700'>
										New Rent Revenue
									</p>
								</div>
							</div>
							<div className='p-3 flex items-center rounded-md space-x-4 border'>
								<div>
									<img src={greenTrendIcon} />
								</div>
								<div className='flex-1'>
									<div className='text-sm 2xl:text-base text-gray-700'>
										{
											<BusyIndicator>
												{getCurrencyCode(USD)}
												{calculatedPurchasePrice && (
													<span>
														{numberCommasSeparator(calculatedPurchasePrice)}
													</span>
												)}
											</BusyIndicator>
										}
										{isAnnualROI ? ANNUALLY : MONTH}
									</div>
									<p className='text-xs 2xl:text-sm text-gray-700'>
										Property Appreciation
									</p>
								</div>
							</div>
							<label className='inline-flex relative items-center cursor-pointer mr-auto md:mr-0 ml-auto'>
								<input
									type='checkbox'
									value=''
									className='sr-only peer'
									onClick={calculateROI}
								/>
								<div className="w-11 h-6 bg-gray-300 peer-focus:outline-none rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-primary-700"></div>
								<span className='ml-3 text-sm font-medium text-gray-900'>
									Annual ROI
								</span>
							</label>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default SummaryInvestment
