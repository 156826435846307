import { createSlice } from '@reduxjs/toolkit'
import { createNewInvestment } from './findInvestment.asyncActions'

const initialState = {
	accordionCounter: 0,
	investments: [
		{
			id: 0,
		},
	],
	calculatedEstimatedTaxes: 0,
	findInvestmentResponse: {},
}

const slice = createSlice({
	name: 'findInvestment',
	initialState,
	reducers: {
		incrementAccordionCounter: (state, action) => {
			if (state.accordionCounter < 4) {
				state.accordionCounter = state.accordionCounter + 1
			}
		},
		decrementAccordionCounter: (state, action) => {
			if (state.accordionCounter > 0) {
				state.accordionCounter = state.accordionCounter - 1
			}
		},
		addInvestment: (state, action) => {
			state.investments = [...state.investments, { ...action.payload }]
		},
		removeInvestment: (state, action) => {
			state.investments.pop()
		},
		addInvestmentData: (state, action) => {
			const {
				id,
				index,
				purchasePrice,
				downPayment,
				market,
				rentalIncome,
				isValidated,
			} = action.payload
			state.investments[id] = {
				...state.investments[id],
				index: index,
				purchasePrice: purchasePrice,
				downPayment: downPayment,
				market: market,
				rentalIncome: rentalIncome,
				isValidated: isValidated,
			}
		},
		updateInvestmentData: (state, action) => {
			const { id } = action.payload
			state.investments[id] = {
				...state.investments[id],
				index: null,
				purchasePrice: '',
				downPayment: '',
				market: '',
				rentalIncome: '',
				isValidated: false,
			}
		},
		resetInvestments: (state) => {
			state.investments = initialState.investments
		},
		updateEstimatedTaxes: (state, action) => {
			state.calculatedEstimatedTaxes = action.payload
		},
	},
	extraReducers: {
		[createNewInvestment.fulfilled]: (state, action) => {
			state.findInvestmentResponse =
				action.payload || initialState.findInvestmentResponse
		},
	},
})

export default slice

export const { name, actions, reducer } = slice
