import React from 'react'

const RoadmapCta = ({ children }) => (
	<div className='relative overflow-hidden bg-gradient-to-bl from-primary-700 to-primary-100 p-6 sm:px-12 sm:py-16'>
		<div className='z-10 relative w-full h-auto'>{children}</div>
		<div className='absolute w-96 h-56 rounded-[60%_35%/50%] bg-primary-200 opacity-10 left-1/3 bottom-1/2' />
		<div className='absolute w-96 h-56 rounded-[60%_35%/50%] bg-primary-200 opacity-10 left-1/2 top-1/2' />
		<div className='absolute w-[45rem] h-[26rem] rounded-[60%_35%/50%] bg-primary-200 opacity-10 top-[10%]' />
	</div>
)

export default RoadmapCta
