import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	currentInvestmentDetails: {},
	propertyAddressState: '',
}

const slice = createSlice({
	name: 'currentInvestment',
	initialState,
	reducers: {
		setCurrentInvestmentAddress(state, action) {
			state.currentInvestmentDetails = action.payload
		},
		setPropertyAddressState(state, action) {
			state.propertyAddressState =
				action.payload || initialState.propertyAddressState
		},
	},
})

export default slice
export const { name, actions, reducer } = slice
