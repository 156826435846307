import React, { useState } from 'react'
import Loading from '../../../widgets/Loading'
import { useDispatch, useSelector } from 'react-redux'
import { NumericFormat } from 'react-number-format'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { stateCodeAndNames } from '../../../utils/stateCodes'
import { getStates } from '../../../utils/methods'
import {
	NavRoutes,
	ERROR_MESSAGE_CLASSES,
	PLENTI,
} from '../../../utils/constants'
import { actions } from './currentInvestment.slice'
import {
	selectCurrentInvestmentDetails,
	selectPropertyAddressState,
} from './currentInvestment.selectors'
import { setInitialValues } from '../InvestmentForm/investmentForm.constants'
import classnames from 'classnames'
import { selectAffiliateSlug } from '../../Affiliates/affiliates.selectors'
import { poweredByPlenti } from '../../../assets/icons'

const CurrentInvestment = () => {
	const [loading, setLoading] = useState(false)
	const propertyAddressState = useSelector(selectPropertyAddressState)
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const isAffiliateSite = affiliateSlug !== PLENTI
	const [isStateSelected, setIsStateSelected] = useState(propertyAddressState)
	const dispatch = useDispatch()
	const { setCurrentInvestmentAddress, setPropertyAddressState } = actions
	const { INVESTMENT_FORM } = NavRoutes
	const navigateToLoadingScreen = () => {
		setLoading(true)
	}
	const currentInvestmentDetails = useSelector(selectCurrentInvestmentDetails)
	const { street, city, state, zipCode } = currentInvestmentDetails
	const initialValues = {
		street: setInitialValues(street),
		city: setInitialValues(city),
		state: setInitialValues(state),
		zipCode: setInitialValues(zipCode),
	}
	const validationSchema = Yup.object().shape({
		street: Yup.string()
			.min(3, 'Street Address  cannot be less than 3 characters')
			.max(255, 'Street Address cannot be more than 255 characters')
			.required('Please enter your Street Address'),
		city: Yup.string()
			.min(3, 'City name cannot be less than 3 characters')
			.max(255, 'City name cannot be more than 255 characters')
			.required('Please enter your City'),
		state: Yup.string().test(
			'state',
			'Please select State Code',
			(val) =>
				val && stateCodeAndNames.find((state) => state.state_code === val)
		),
		zipCode: Yup.string()
			.min(5, 'ZipCode should have at least 5 digits')
			.test('isValid', 'Please enter a valid ZipCode', (val) => val),
	})
	const validatePropertyAddressState = ({ target }) => {
		dispatch(setPropertyAddressState(target?.value))
		setIsStateSelected(!!target?.value?.length)
	}
	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>Exchange It :: Current Investment</title>
				</Helmet>
			</HelmetProvider>
			{loading ? (
				<Loading
					navigateTo={INVESTMENT_FORM}
					navigateIn={1000}
					setLoading={setLoading}
					loadingMessage='Loading Property Details'
				/>
			) : (
				<div
					className={`xl:px-22 xl:pb-22 px-12 pb-12 ${
						isAffiliateSite ? 'xl:pt-7 p-2' : 'xl:pt-22 pt-12'
					}`}
				>
					{isAffiliateSite && (
						<div className='flex justify-end xl:pb-7 pb-1'>
							<img src={poweredByPlenti} alt='Powered By Plenti Logo' />
						</div>
					)}
					<h1 className='text-3xl font-bold leading-7 text-gray-900 mb-3'>
						What are you selling?
					</h1>
					<p className='text-gray-700 mb-6'>
						Any investment property qualifies, but under certain circumstances
						even your current residence can, so let&apos;s find out!
					</p>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values) => {
							dispatch(setCurrentInvestmentAddress(values))
							navigateToLoadingScreen()
						}}
					>
						{({
							values,
							touched,
							errors,
							handleBlur,
							handleChange,
							handleSubmit,
						}) => (
							<form>
								<div className='form-group mb-6'>
									<label
										className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'
										htmlFor='street'
									>
										What is your property address?
									</label>
									<input
										id='street'
										type='text'
										name='street'
										className='form-control'
										placeholder='Street Address'
										onBlur={handleBlur}
										value={values.street}
										onChange={handleChange}
									/>
									{touched.street && errors.street && (
										<p className={ERROR_MESSAGE_CLASSES}>{errors.street}</p>
									)}
									<div className='grid grid-cols-12 gap-4 mt-2' htmlFor='city'>
										<div className='col-span-4'>
											<input
												type='text'
												name='city'
												className='form-control'
												placeholder='City'
												onBlur={handleBlur}
												value={values.city}
												onChange={handleChange}
											/>
											{touched.city && errors.city && (
												<p className={ERROR_MESSAGE_CLASSES}>{errors.city}</p>
											)}
										</div>
										<div className='col-span-4'>
											<select
												name='state'
												className={classnames('form-control custom-select', {
													'text-gray-400': !isStateSelected,
												})}
												value={values.state}
												onBlur={handleBlur}
												onChange={(e) => {
													handleChange(e)
													validatePropertyAddressState(e)
												}}
											>
												<option value=''>Select State</option>
												{getStates}
											</select>
											{touched.state && errors.state && (
												<p className={ERROR_MESSAGE_CLASSES}>{errors.state}</p>
											)}
										</div>
										<div className='col-span-4'>
											<NumericFormat
												name='zipCode'
												type='text'
												className='form-control'
												placeholder='Zip Code'
												maxLength={5}
												allowLeadingZeros={true}
												allowNegative={false}
												value={values.zipCode}
												onBlur={handleBlur}
												onChange={handleChange}
											/>
											{touched.zipCode && errors.zipCode && (
												<p className={ERROR_MESSAGE_CLASSES}>
													{errors.zipCode}
												</p>
											)}
										</div>
									</div>
								</div>
								<div className='text-right space-x-2'>
									<button
										className='btn btn-primary'
										type='submit'
										onClick={handleSubmit}
									>
										Submit
									</button>
								</div>
							</form>
						)}
					</Formik>
				</div>
			)}
		</>
	)
}

export default CurrentInvestment
