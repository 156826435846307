import { combineReducers } from 'redux'
import * as pendingRequest from './infrastructure/pendingRequest'
import * as notificationPopup from './infrastructure/notificationPopup'
import * as httpCache from './infrastructure/httpCache'
import * as busyIndicator from './widgets/busyIndicator'
import * as navbarStates from './widgets/NavBar'
import * as chatWindowStates from './widgets/ChatWindow'
import * as circularProgressIndicatorStates from './widgets/CircularProgressIndicator'
import * as summary from './features/Summary'
import * as getStarted from './features/Model/index'
import * as authentication from './features/AgentReferralGenerator/Authentication'
import * as currentInvestment from './features/CurrentInvestmentWrapper/CurrentInvestment'
import * as investmentForm from './features/CurrentInvestmentWrapper/InvestmentForm'
import * as moreDetails from './features/MoreDetails'
import * as findInvestment from './features/FindInvestment'
import * as customer from './features/Model/CustomerDetailsForm'
import * as affiliateStartApplication from './features/Affiliates'
import * as reinvestmentAffiliate from './features/Reinvestment'

export default combineReducers({
	[pendingRequest.name]: pendingRequest.reducer,
	[notificationPopup.name]: notificationPopup.reducer,
	[httpCache.name]: httpCache.reducer,
	[busyIndicator.name]: busyIndicator.reducer,
	[navbarStates.name]: navbarStates.reducer,
	[chatWindowStates.name]: chatWindowStates.reducer,
	[circularProgressIndicatorStates.name]:
		circularProgressIndicatorStates.reducer,
	[summary.name]: summary.reducer,
	[getStarted.name]: getStarted.reducer,
	[authentication.name]: authentication.reducer,
	[currentInvestment.name]: currentInvestment.reducer,
	[investmentForm.name]: investmentForm.reducer,
	[moreDetails.name]: moreDetails.reducer,
	[findInvestment.name]: findInvestment.reducer,
	[customer.name]: customer.reducer,
	[moreDetails.name]: moreDetails.reducer,
	[affiliateStartApplication.name]: affiliateStartApplication.reducer,
	[reinvestmentAffiliate.name]: reinvestmentAffiliate.reducer,
})
