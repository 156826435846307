import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { shouldActivate, selectSummaryState } from '../../widgets/NavBar'
import { updateProgressPercentage } from '../../widgets/CircularProgressIndicator'
import {
	whiteCheckIcon,
	alertCircleIcon,
	poweredByPlenti,
} from '../../assets/icons'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import {
	NavRoutes,
	SessionKeys,
	scrollActions,
	RADIO_ERROR_MESSAGE_CLASSES,
	PLENTI,
} from '../../utils/constants'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { NavLinkStates } from '../../utils/constant.enum'
import {
	updateCurrentInvestmentSellAndPurchasePlan,
	getCurrentInvestmentDetails,
	getNewInvestmentsDetail,
} from './moreDetails.asyncAction'
import {
	convertToNumber,
	isObjectEmpty,
	getNavigationRoute,
} from '../../utils/methods'
import {
	moreDetailsSellOptions,
	moreDetailsPurchaseOptions,
	moreDetailsModalInfo,
} from './moreDetails.constants'
import { selectMoreDetails } from './moreDetails.selectors'
import { actions } from './moreDetails.slice'
import Loading from '../../widgets/Loading'
import { Modal } from '../../widgets/Modal'
import { selectAffiliateSlug } from '../Affiliates/affiliates.selectors'

const SELLING_PROPERTY = 'sellingProperty'

const MoreDetails = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const isAffiliateSite = affiliateSlug !== PLENTI

	const [loading, setLoading] = useState(false)
	const [showSetup1031ExchangeAlert, setShowSetup1031ExchangeAlert] =
		useState(false)
	const { FIND_INVESTMENTS, SUMMARY } = NavRoutes
	const { USER_ID } = SessionKeys
	const isSummaryVisited = useSelector(selectSummaryState).isVisited
	const [sellingProperty, setSellingProperty] = useState('')
	const [purchasingProperty, setPurchasingProperty] = useState('')
	const moreDetails = useSelector(selectMoreDetails)
	const { sellPlan, purchasePlan } = moreDetails
	const { setMoreDetailsState } = actions
	const navigateToFindInvestment = () => {
		navigate(getNavigationRoute(affiliateSlug, FIND_INVESTMENTS))
	}
	const navigateToSummary = () => {
		dispatch(shouldActivate(NavLinkStates.SUMMARY))
		if (!isSummaryVisited) dispatch(updateProgressPercentage())
		navigate(getNavigationRoute(affiliateSlug, SUMMARY))
	}
	const qualifyBtn = {
		title: 'Continue',
		click: navigateToSummary,
	}
	const prevBtn = {
		title: 'Back',
		classes: 'btn-light',
	}
	const moreDetailsAlertIcon = {
		icon: alertCircleIcon,
		iconBackground: 'bg-orange-100 ring-8 ring-orange-50',
	}
	const activeRadioOption = 'border-primary-600 bg-primary-200/5'
	const inactiveRadioOption = 'border-gray-200'
	const activeRadioRing = 'ring-primary-600 bg-primary-600'
	const inactiveRadioRing = 'ring-gray-300'

	const togglePropertyBorder = (name, key, value) => {
		if (name === SELLING_PROPERTY) {
			if (sellingProperty === key || sellPlan === value)
				return activeRadioOption
			else return inactiveRadioOption
		} else {
			if (purchasingProperty === key || purchasePlan === value)
				return activeRadioOption
			else return inactiveRadioOption
		}
	}
	const togglePropertyRing = (name, key, value) => {
		if (name === SELLING_PROPERTY) {
			if (sellingProperty === key || sellPlan === value) return activeRadioRing
			else return inactiveRadioRing
		} else {
			if (purchasingProperty === key || purchasePlan === value)
				return activeRadioRing
			else return inactiveRadioRing
		}
	}
	const initialValues = {
		sellingProperty: sellPlan !== undefined ? sellPlan : '',
		purchasingProperty: purchasePlan !== undefined ? purchasePlan : '',
	}
	const validationSchema = Yup.object().shape({
		sellingProperty: Yup.number().required('Please select a valid option'),
		purchasingProperty: Yup.number().required('Please select a valid option'),
	})
	const numericConversion = (values) => {
		values.sellingProperty = convertToNumber(values.sellingProperty)
		values.purchasingProperty = convertToNumber(values.purchasingProperty)
		return values
	}
	const onFormSubmit = (values, { setSubmitting, resetForm }) => {
		const userId = get(window.sessionStorage, USER_ID, null)
		dispatch(getCurrentInvestmentDetails({ userId }))
		dispatch(getNewInvestmentsDetail({ userId }))
		let requestObject = { ...values }
		requestObject = numericConversion(values)
		if (!isObjectEmpty(requestObject)) {
			const { sellingProperty, purchasingProperty } = requestObject
			const userId = get(window.sessionStorage, USER_ID, null)
			dispatch(
				setMoreDetailsState({
					sellPlan: sellingProperty,
					purchasePlan: purchasingProperty,
				})
			)
			dispatch(
				updateCurrentInvestmentSellAndPurchasePlan({
					userId,
					sellPlan: sellingProperty,
					purchasePlan: purchasingProperty,
				})
			)
			setShowSetup1031ExchangeAlert(true)
		}
		setTimeout(() => setSubmitting(false), 400)
	}
	useEffect(() => {
		const container = document.getElementById('main-container')
		container.scrollIntoView(scrollActions)
	}, [])

	return (
		<div
			id='main-container'
			className={`xl:px-22 xl:pb-22 px-12 pb-12 ${
				isAffiliateSite ? 'xl:pt-7 p-2' : 'xl:pt-22 pt-12'
			}`}
		>
			{isAffiliateSite && (
				<div className='flex justify-end pb-5'>
					<img src={poweredByPlenti} alt='Powered By Plenti Logo' />
				</div>
			)}
			<HelmetProvider>
				<Helmet>
					<title>Exchange It :: More Details</title>
				</Helmet>
			</HelmetProvider>
			{loading ? (
				<Loading
					navigateTo={SUMMARY}
					navigateIn={1000}
					setLoading={setLoading}
					loadingMessage='Loading Summary Details'
				/>
			) : (
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values, { setSubmitting, resetForm }) =>
						onFormSubmit(values, { setSubmitting, resetForm })
					}
				>
					{({ handleChange, handleBlur, handleSubmit, touched, errors }) => (
						<form onSubmit={handleSubmit}>
							<h1 className='text-3xl font-bold leading-8 text-gray-900 mb-3'>
								When do you plan on selling your property?
							</h1>
							<p className='text-gray-700 mb-6'>
								You can do an Exchange if you sell first and then buy an
								investment, but the clock starts running on your sale close
								date. After your close, you&apos;ll only have 6 months to
								reinvest.
							</p>
							<div className='space-y-4 mb-6'>
								{moreDetailsSellOptions.map(({ key, title, value, name }) => (
									<div
										key={key}
										className={`${togglePropertyBorder(
											name,
											key,
											value
										)} check-item rounded-lg border`}
									>
										<label className='flex items-center p-4 cursor-pointer'>
											<input
												type='radio'
												className='hidden'
												name={name}
												onClick={() => {
													dispatch(
														setMoreDetailsState({
															sellPlan: '',
															purchasePlan: purchasePlan,
														})
													)
													setSellingProperty(key)
												}}
												onBlur={handleBlur}
												onChange={handleChange}
												value={value}
												checked={value === sellPlan}
											/>
											<span className='text-sm font-medium text-gray-800'>
												{title}
											</span>
											<div
												className={`${togglePropertyRing(
													name,
													key,
													value
												)} h-4 w-4 grid place-items-center rounded-full ring-1  ml-auto`}
											>
												<img src={whiteCheckIcon} width='10' alt='check-icon' />
											</div>
										</label>
									</div>
								))}
								{touched.sellingProperty && errors.sellingProperty && (
									<p className={RADIO_ERROR_MESSAGE_CLASSES}>
										{errors.sellingProperty}
									</p>
								)}
							</div>
							<h1 className='text-3xl font-bold leading-8 text-gray-900 mt-2 mb-3'>
								When do you plan on purchasing a property?
							</h1>
							<p className='text-gray-700 mb-6'>
								You can do an Exchange if you buy first and sell second, but the
								clock starts running on the close date of your buy and you only
								have 6 months to sell your existing investment tax free.
							</p>
							<div className='space-y-4 mb-6'>
								{moreDetailsPurchaseOptions.map(
									({ key, title, value, name }) => (
										<div
											key={key}
											className={`${togglePropertyBorder(
												name,
												key,
												value
											)} check-item rounded-lg border`}
										>
											<label className='flex items-center p-4 cursor-pointer'>
												<input
													type='radio'
													className='hidden'
													name={name}
													onClick={() => {
														dispatch(
															setMoreDetailsState({
																sellPlan: sellPlan,
																purchasePlan: '',
															})
														)
														setPurchasingProperty(key)
													}}
													onChange={handleChange}
													onBlur={handleBlur}
													value={value}
													checked={value === purchasePlan}
												/>
												<span className='text-sm font-medium text-gray-800'>
													{title}
												</span>
												<div
													className={`${togglePropertyRing(
														name,
														key,
														value
													)} h-4 w-4 grid place-items-center rounded-full ring-1  ml-auto`}
												>
													<img
														src={whiteCheckIcon}
														width='10'
														alt='check-icon'
													/>
												</div>
											</label>
										</div>
									)
								)}
								{touched.purchasingProperty && errors.purchasingProperty && (
									<p className={RADIO_ERROR_MESSAGE_CLASSES}>
										{errors.purchasingProperty}
									</p>
								)}
							</div>
							<div className='text-right mt-3 space-x-2'>
								<button
									type='button'
									className='btn btn-light'
									onClick={navigateToFindInvestment}
								>
									Previous
								</button>
								<button
									type='button'
									className='btn btn-primary'
									onClick={handleSubmit}
								>
									Continue
								</button>
							</div>
							{showSetup1031ExchangeAlert && (
								<Modal
									shouldShowModal={showSetup1031ExchangeAlert}
									setShouldShowModal={setShowSetup1031ExchangeAlert}
									result={moreDetailsAlertIcon}
									title={moreDetailsModalInfo.title}
									subtitle={`${moreDetailsModalInfo.subTextFirst} \n\n ${moreDetailsModalInfo.subTextSecond}`}
									prevBtn={prevBtn}
									nextBtn={qualifyBtn}
								/>
							)}
						</form>
					)}
				</Formik>
			)}
		</div>
	)
}

export default MoreDetails
