import React, { useEffect, useState } from 'react'
import {
	blueTickIcon,
	imageIcon,
	whiteTrashIcon,
	uploadICon,
} from '../../assets/icons'
import {
	BUSY_INDICATORS,
	DEFAULT_VALUES,
	ERROR_MESSAGE_CLASSES,
	getFileSize,
	tempSlang,
} from '../../utils/constants'
import { actions } from './affiliates.slice'
import { deleteLogo } from './affiliates.asyncActions'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import {
	API_BASE_URL,
	API_ENDPOINT_PREFIX,
} from '../../infrastructure/http/http.constants'
import { selectUploadedImage } from './affiliates.selectors'
import { warnNotification } from '../../widgets/ToastNotification/ToastNotification'
import { getNamedBusyIndicator } from '../../widgets/busyIndicator'
import { spinner } from '../../assets/gifs'

const { EMPTY_STRING, ZERO, BOOLEAN } = DEFAULT_VALUES
const { DELETE_LOGO } = BUSY_INDICATORS
const { setUploadedImage, setImageData } = actions
const SUCCESS = 200
const { FALSE } = BOOLEAN

const Logo = ({ errorMessage, setFieldValue, value, slug, isSlugValid }) => {
	const dispatch = useDispatch()
	const [uploadProgress, setUploadProgress] = useState(ZERO)
	const uploadedImage = useSelector(selectUploadedImage)
	const [isFileUploadError, setIsFileUploadError] = useState(FALSE)
	const isDeleteLogoAPIActive = useSelector(getNamedBusyIndicator(DELETE_LOGO))

	const FileInformation = ({ value, uploadProgress, handleDelete }) => (
		<div className='flex flex-col w-full gap-2'>
			<div className='flex gap-1'>
				<div className='h-7 w-7'>
					<img src={imageIcon} alt='image icon' />
				</div>
				<span className='flex flex-col mt-0.5'>
					<p className='font-medium text-gray-700'>{value.name}</p>
					<p className='text-gray-500'>{getFileSize(value.size)}</p>
				</span>
				<div className='w-7 h-7 flex items-center justify-center ml-auto z-10'>
					{uploadProgress === 100 ? (
						<img src={blueTickIcon} alt='check icon' />
					) : (
						<img
							src={isDeleteLogoAPIActive ? spinner : whiteTrashIcon}
							alt='delete icon'
							onClick={handleDelete}
						/>
					)}
				</div>
			</div>
			{uploadProgress > ZERO && uploadProgress <= 100 && (
				<div className='flex items-center gap-1 justify-center'>
					<div className='w-11/12 h-2 bg-gray-200 opacity-50 rounded-full'>
						<div
							className='h-2 bg-primary-600 rounded-full'
							style={{ width: `${uploadProgress}%` }}
						></div>
					</div>
					<p className='text-gray-700 font-sm'>{uploadProgress}%</p>
				</div>
			)}
		</div>
	)

	const handleDelete = () =>
		value && uploadedImage && dispatch(deleteLogo(uploadedImage))

	useEffect(() => {
		!uploadedImage && setFieldValue('logoURL', EMPTY_STRING)
	}, [uploadedImage, setFieldValue])

	const handleFileChange = async (e) => {
		setIsFileUploadError(FALSE)
		const file = e.target.files[ZERO]

		if (file) {
			setFieldValue('logoURL', file)
			const formData = new FormData()
			file && formData.append('image', file)
			dispatch(setImageData(file))
			formData.append('slug', slug === EMPTY_STRING ? tempSlang : slug)

			try {
				const response = await axios.put(
					`${API_BASE_URL}/${API_ENDPOINT_PREFIX}/image`,
					formData,
					{
						onUploadProgress: (progressEvent) => {
							const progress =
								(progressEvent.loaded / progressEvent.total) * 100
							setUploadProgress(Math.round(progress - 1))
						},
					}
				)

				if (response.status === SUCCESS) {
					dispatch(setUploadedImage(response.data))
					setUploadProgress(100)
					setTimeout(() => setUploadProgress(ZERO), 1500)
				}
			} catch (error) {
				setIsFileUploadError(true)
				warnNotification('Logo upload failed. Please retry.')
				console.error('Error uploading image', error)
			}
		}
	}

	return (
		<div className='col-span-2 sm:mt-0 mt-3'>
			<label
				className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'
				htmlFor='logo'
			>
				Upload Your Logo
			</label>
			<div
				className={`relative form-control flex items-center text-sm justify-center h-auto p-4 ${
					errorMessage && 'border-red-400'
				}`}
			>
				{(value && isFileUploadError) || !value ? (
					<div className='flex flex-col items-center'>
						<img src={uploadICon} alt='upload icon' width={40} height={40} />
						<div className='flex'>
							<span className='font-medium text-gray-900 mr-1 whitespace-nowrap'>
								Click to upload
							</span>
							<span className='text-gray-500 whitespace-nowrap'>
								or drag and drop
							</span>
						</div>
						<span className='text-gray-500 text-xs'>
							JPG or GIF (max. 800x400px, max size 50mb)
						</span>
					</div>
				) : (
					<FileInformation
						value={value}
						uploadProgress={uploadProgress}
						handleDelete={handleDelete}
					/>
				)}
				<input
					className={`absolute opacity-0 left-0 w-full h-full ${
						slug !== EMPTY_STRING && !isSlugValid
							? 'cursor-not-allowed'
							: 'cursor-pointer'
					}`}
					type='file'
					id='logo'
					accept='.jpeg, .jpg, .gif'
					onChange={handleFileChange}
					key={value}
					disabled={slug !== EMPTY_STRING && !isSlugValid}
				/>
			</div>
			{errorMessage && (
				<p className={`${ERROR_MESSAGE_CLASSES} mt-[-1px]`}>{errorMessage}</p>
			)}
		</div>
	)
}

export default Logo
