import { useEffect } from 'react'

const useBodyScrollDisable = (open) => {
	useEffect(() => {
		document.body.style.overflow = open ? 'hidden' : 'unset'

		return () => (document.body.style.overflow = 'unset')
	}, [open])
}

export default useBodyScrollDisable
