import React, { useCallback, useRef, useState } from 'react'
import { SketchPicker } from 'react-color'
import useClickOutside from '../../utils/useClickOutside'
import { DEFAULT_VALUES, RGBAToHexA } from '../../utils/constants'

const { EMPTY_ARRAY } = DEFAULT_VALUES

const ColorPicker = ({ value, label, setFieldValue, id }) => {
	const popoverRef = useRef()
	const [isOpen, setIsOpen] = useState(false)

	const closePicker = useCallback(() => setIsOpen(false), [])
	useClickOutside(popoverRef, closePicker)

	const handleColorChange = (color) =>
		color && color.hex && setFieldValue(id, RGBAToHexA(color.rgb))

	return (
		<div className='sm:mt-0 mt-3'>
			<label
				className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'
				htmlFor={id}
			>
				{label}
			</label>
			<div
				className='relative form-control flex items-center cursor-pointer'
				onClick={() => setIsOpen(true)}
			>
				<div
					className='w-3 h-3 mr-2 rounded-full'
					style={{ backgroundColor: value }}
				/>
				<p>{value}</p>
				{isOpen && (
					<div ref={popoverRef} className='absolute z-10 left-0 top-10 mt-1'>
						<SketchPicker
							color={value}
							onChange={handleColorChange}
							presetColors={EMPTY_ARRAY}
							disableAlpha
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default ColorPicker
