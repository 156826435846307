import React from 'react'
import RoadmapCta from '../RoadmapCta'

const GLOSSARY_DATA = [
	{
		title: 'Forward Delayed Exchange ',
		description:
			'A type of exchange which occurs when a property is sold “Relinquished Property” and another property is purchased “Replacement Property” within 180 days following the sale of the Relinquished Property.',
	},
	{
		title: 'Relinquished Property ',
		description:
			'The original property being sold by the taxpayer when making a 1031 exchange.',
	},
	{
		title: 'Replacement Property',
		description:
			'This is the type of exchange in which the Replacement Property is purchased before the sale of the Relinquished Property.',
	},
	{
		title: 'Reverse Exchange',
		description:
			'This is the type of exchange in which the Replacement Property is purchased before the sale of the Relinquished Property.',
	},
	{
		title:
			'The Role of Qualified Intermediary (QI) in a 1031 “Like Kind” Exchange',
		description:
			'Section 1031 of the Internal Revenue Code (IRC) offers a great opportunity for real estate buyers to defer the capital gains tax liability associated with the sale of a business or investment asset. The 1031 exchange ensures the maximum return on investments to people of all financial backgrounds. To qualify for 1031 “like kind” property exchange the entire transaction has to be done in accordance to the detailed rules, regulations and compliance issues set forth in the US(IRS) tax code.The Qualified Intermediary (QI), also known as a facilitator or exchange accommodator serves a crucial function under the InternalRevenue (IRS) Code. Choosing an Intermediary to facilitate the 1031exchange is usually the first and one of the most important steps. QI should be a company that works on a full-time business of facilitating 1031 exchanges. The Internal Revenue (IRS) Code requires that the person or entity serving as QI cannot be someone with whom the exchanger has had a former business or family relationship prior to the transaction. The IRS code is clear in the fact that a QI has to be an independent organization whose only contact with the exchanger is to serve him as a QI.',
	},
]

const Glossary = ({ setShouldShowMeetingSchedular }) => (
	<div>
		<div className='text-gray-500 p-8 sm:p-12 md:p-24 gap-4 sm:gap-12 flex flex-col'>
			<div className='font-work-sans-light text-43'>Glossary</div>
			<div className='flex flex-col gap-4 sm:gap-8'>
				{GLOSSARY_DATA.map(({ title, description }) => (
					<div
						key={title}
						className='flex flex-col gap-2 border-t border-gray-200 pt-2 sm:pt-6'
					>
						<div className='text-gray-600 text-lg font-work-sans-medium'>
							{title}
						</div>
						<div className='text-base font-work-sans-regular'>
							{description}
						</div>
					</div>
				))}
			</div>
		</div>
		<RoadmapCta>
			<div className='flex flex-col sm:flex-row justify-between items-center gap-4 sm:gap-6'>
				<div className='text-white flex-1 font-work-sans-regular text-lg sm:text-2xl text-start'>
					Elevate your real estate strategy with us!
				</div>
				<button
					className='btn btn-light sm:w-auto cursor-pointer rounded-full font-work-sans-semibold text-sm sm:text-lg text-primary-700 px-3 py-2 sm:px-5 sm:py-4'
					onClick={() => setShouldShowMeetingSchedular((value) => !value)}
				>
					Schedule a Consultation
				</button>
			</div>
		</RoadmapCta>
	</div>
)

export default Glossary
