import React from 'react'
export const PreviousBtn = ({ className }) => (
	<svg
		className={className}
		width='60'
		height='60'
		viewBox='0 0 60 60'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M33.8457 18.4611L24.6149 29.9996L33.8457 41.538'
			stroke='#68B6BF'
			strokeWidth='1.5'
		/>
		<circle
			cx='30'
			cy='30'
			r='29.25'
			transform='rotate(-180 30 30)'
			stroke='#68B6BF'
			strokeWidth='1.5'
		/>
	</svg>
)

export const NextBtn = ({ className }) => (
	<svg
		className={className}
		width='60'
		height='60'
		viewBox='0 0 60 60'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M26.1543 41.5389L35.3851 30.0004L26.1543 18.462'
			stroke='#68B6BF'
			strokeWidth='1.5'
		/>
		<circle cx='30' cy='30' r='29.25' stroke='#68B6BF' strokeWidth='1.5' />
	</svg>
)

export const PlayBtn = ({ fill }) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 48 48'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g filter='url(#filter0_b_17_79)'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM20.25 32.8033L33.75 25.2576C34.75 24.6987 34.75 23.3013 33.75 22.7424L20.25 15.1967C19.25 14.6378 18 15.3365 18 16.4543V31.5457C18 32.6635 19.25 33.3622 20.25 32.8033Z'
				fill={fill}
			/>
		</g>
		<defs>
			<filter
				id='filter0_b_17_79'
				x='-16'
				y='-16'
				width='80'
				height='80'
				filterUnits='userSpaceOnUse'
				colorInterpolationFilters='sRGB'
			>
				<feFlood floodOpacity='0' result='BackgroundImageFix' />
				<feGaussianBlur in='BackgroundImageFix' stdDeviation='8' />
				<feComposite
					in2='SourceAlpha'
					operator='in'
					result='effect1_backgroundBlur_17_79'
				/>
				<feBlend
					mode='normal'
					in='SourceGraphic'
					in2='effect1_backgroundBlur_17_79'
					result='shape'
				/>
			</filter>
		</defs>
	</svg>
)
