import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	getStarted: false,
}

const slice = createSlice({
	name: 'getStartedStates',
	initialState,
	reducers: {
		shouldActivateIntroScreen: (state, action) => {
			state.getStarted = action.payload
		},
	},
})

export default slice
export const { name, actions, reducers } = slice
