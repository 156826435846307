import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	circularProgressIndicator: {
		percentage: 20,
	},
}
const slice = createSlice({
	name: 'circularProgressIndicatorStates',
	initialState,
	reducers: {
		updateProgressPercentage: (state) => {
			state.circularProgressIndicator.percentage =
				state.circularProgressIndicator.percentage + 20
		},
	},
})
export default slice
export const { name, actions, reducer } = slice
