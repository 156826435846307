import React from 'react'
import { useDispatch } from 'react-redux'
import { whiteCheckIcon, greenChevronDownIcon } from '../../assets/icons'
import { NumericFormat } from 'react-number-format'
import { actions } from '../../features/FindInvestment/findInvestment.slice'
import {
	PURCHASE_PRICE,
	MARKET,
	DOWN_PAYMENT,
	RENTAL_INCOME,
	INVESTMENT_OPTIONS,
	CALC_RENTAL_INCOME,
} from './invetmentsAccordion.constants'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
	getStates,
	convertToNumber,
	getCurrencyCode,
	removeSpecialCharacter,
} from '../../utils/methods'
import {
	MAX_MONETARY_LIMIT,
	ERROR_MESSAGE_CLASSES,
} from '../../utils/constants'
import { errorNotification } from '../ToastNotification'

const USD = 'USD'

const AccordionLayout = ({
	index,
	investmentId,
	activeIndex,
	setActiveIndex,
	title,
	subtitle,
	icon,
	investment,
	handleValues,
	removeAccordionData,
}) => {
	const dispatch = useDispatch()
	const { updateInvestmentData } = actions
	// condition applied here, since if redux has value all accordions were set with values for that investment
	const { purchasePrice, downPayment, rentalIncome, market } = investment
	const initialValues = {
		purchasePrice: investment.index === index ? purchasePrice || '' : '',
		downPayment: investment.index === index ? downPayment || '' : '',
		rentalIncome: investment.index === index ? rentalIncome || '' : '',
		market: investment.index === index ? market || '' : '',
	}
	const calculationHandler = (
		downPayment,
		conversionRate,
		setFieldTouched,
		setFieldValue
	) => {
		const updatedDownPayment = convertToNumber(
			removeSpecialCharacter(downPayment)
		)
		const rentalValue =
			Math.round(((updatedDownPayment * conversionRate) / 12) * 100) / 100
		setFieldValue('rentalIncome', rentalValue)
		handleValues({
			id: investmentId,
			index: index,
			field: {
				[DOWN_PAYMENT]: updatedDownPayment,
				[RENTAL_INCOME]: rentalValue,
			},
		})
		setFieldTouched('rentalIncome', true)
	}
	const calculateRentalIncome = (
		downPayment,
		index,
		setFieldTouched,
		setFieldValue
	) => {
		const { SINGLE_FAMILY_RENTAL, VACATION_RENTAL, SPECIALIZED_1031 } =
			CALC_RENTAL_INCOME
		if (index === 0) {
			calculationHandler(
				downPayment,
				SINGLE_FAMILY_RENTAL,
				setFieldTouched,
				setFieldValue
			)
		} else if (index === 1) {
			calculationHandler(
				downPayment,
				VACATION_RENTAL,
				setFieldTouched,
				setFieldValue
			)
		} else if (index === 2) {
			calculationHandler(
				downPayment,
				SPECIALIZED_1031,
				setFieldTouched,
				setFieldValue
			)
		} else {
			handleValues({
				id: investmentId,
				index: index,
				field: { [DOWN_PAYMENT]: downPayment },
			})
		}
	}
	const allowedInputs = (values) => {
		const { value } = values
		if (value.charAt(0) === '0') {
			if (value.charAt(1) && value.charAt(1) !== '.') {
				return false
			}
		}
		return value <= MAX_MONETARY_LIMIT
	}
	const validateDownPayment = (event, purchasePrice) => {
		const { value } = event
		purchasePrice = convertToNumber(removeSpecialCharacter(purchasePrice))
		if (!allowedInputs(event)) return false
		if (value > purchasePrice) {
			errorNotification(
				'Estimated down payment can not be greater than the Purchase Price.'
			)
			return false
		} else return value <= MAX_MONETARY_LIMIT
	}
	const onChangeRentalIncome = (event, handleChange) => {
		handleValues({
			id: investmentId,
			index: index,
			field: { [RENTAL_INCOME]: event.target.value },
			investmentId: investmentId,
		})
		handleChange(event)
	}
	const onChangeDownPayment = (
		event,
		handleChange,
		setFieldTouched,
		setFieldValue
	) => {
		calculateRentalIncome(
			event.target.value,
			index,
			setFieldTouched,
			setFieldValue
		)
		handleChange(event)
	}
	const onChangePurchasePrice = (event, handleChange) => {
		handleValues({
			id: investmentId,
			index: index,
			field: { [PURCHASE_PRICE]: event.target.value },
		})
		handleChange(event)
	}
	const onChangeMarketOption = (event, handleChange) => {
		handleValues({
			id: investmentId,
			index: index,
			field: { [MARKET]: event.target.value },
		})
		handleChange(event)
	}
	const validationSchema = Yup.object().shape({
		purchasePrice: Yup.string().required('Please select a value'),
		downPayment: Yup.string().required('Please select a value'),
		rentalIncome: Yup.string().required('Please select a value'),
		market: Yup.string().required('Please select a value'),
	})
	const resetFieldValue = (setFieldTouched, setFieldValue, fieldName) => {
		setFieldValue(fieldName, '')
		setFieldTouched(fieldName, false)
	}
	const clearFields = (setFieldTouched, setFieldValue, investmentOptions) => {
		const [purchasePrice, downPayment, rentalIncome, market] = investmentOptions
		setFieldValue(purchasePrice, '')
		setFieldTouched(purchasePrice, false)
		setFieldValue(downPayment, '')
		setFieldTouched(downPayment, false)
		setFieldValue(rentalIncome, '')
		setFieldTouched(rentalIncome, false)
		setFieldValue(market, '')
		setFieldTouched(market, false)
		removeAccordionData({ id: investmentId })
	}
	return (
		<div
			className={`${
				activeIndex === index ? 'border-primary-600' : 'border-gray-200'
			} rounded-lg border bg-white`}
		>
			<Formik initialValues={initialValues} validationSchema={validationSchema}>
				{({
					values,
					handleChange,
					handleBlur,
					handleSubmit,
					touched,
					errors,
					setFieldTouched,
					setFieldValue,
				}) => {
					return (
						<form onSubmit={handleSubmit}>
							<div
								onClick={() => {
									clearFields(
										setFieldTouched,
										setFieldValue,
										INVESTMENT_OPTIONS
									)
									dispatch(updateInvestmentData({ id: investmentId }))
									if (activeIndex !== index) {
										setActiveIndex(index)
									} else {
										setActiveIndex(null)
									}
								}}
								className={`flex items-center p-4 cursor-pointer rounded-lg ${
									activeIndex === index ? 'bg-[#F9FDFF]' : ''
								}`}
							>
								<div
									className={`${
										activeIndex === index
											? 'ring-primary-600 bg-primary-600'
											: 'ring-gray-300'
									} h-4 w-4 grid place-items-center rounded-full ring-1  mr-4`}
								>
									<img src={whiteCheckIcon} width='10' alt='check-icon' />
								</div>
								<div className='flex items-center'>
									<div className='icon mr-4'>
										<img src={icon} width='24' alt='accordians-icon' />
									</div>
									<span className='text-md font-medium text-gray-700'>
										{title}
									</span>
								</div>
								<div className='icon ml-auto'>
									<img
										src={greenChevronDownIcon}
										width='20'
										alt='down-arrow-icon'
									/>
								</div>
							</div>
							<div
								className={`${
									activeIndex === index ? 'max-h-full' : 'max-h-0 '
								}overflow-hidden transition-all duration-300`}
							>
								<div
									className={`${
										activeIndex === index
											? 'border-primary-600'
											: 'border-gray-200'
									} p-6 border-t`}
								>
									<p className='text-sm font-normal  text-gray-500 mb-5'>
										{subtitle}
									</p>
									<div className='form-group mb-6'>
										<label className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'>
											How much do you plan on purchasing this property for?
										</label>
										<NumericFormat
											name='purchasePrice'
											type='text'
											className='form-control'
											placeholder='Estimated Purchase Price'
											thousandSeparator={','}
											allowNegative={false}
											decimalScale={2}
											decimalSeparator={'.'}
											prefix={getCurrencyCode(USD)}
											isAllowed={(values) => allowedInputs(values)}
											onChange={(e) => {
												resetFieldValue(
													setFieldTouched,
													setFieldValue,
													DOWN_PAYMENT
												)
												onChangePurchasePrice(e, handleChange)
											}}
											onBlur={handleBlur}
											value={values.purchasePrice}
										/>
										{touched.purchasePrice && errors.purchasePrice && (
											<p className={ERROR_MESSAGE_CLASSES}>
												{errors.purchasePrice}
											</p>
										)}
									</div>
									<div className='form-group mb-6'>
										<label className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'>
											How much will you put down?
										</label>
										<NumericFormat
											name='downPayment'
											type='text'
											className='form-control'
											placeholder='Estimated Down Payment'
											thousandSeparator={','}
											allowNegative={false}
											decimalScale={2}
											decimalSeparator={'.'}
											prefix={getCurrencyCode(USD)}
											isAllowed={(inputValue) =>
												validateDownPayment(inputValue, values.purchasePrice)
											}
											onChange={(e) =>
												onChangeDownPayment(
													e,
													handleChange,
													setFieldTouched,
													setFieldValue
												)
											}
											onBlur={handleBlur}
											value={values.downPayment}
										/>
										{touched.downPayment && errors.downPayment && (
											<p className={ERROR_MESSAGE_CLASSES}>
												{errors.downPayment}
											</p>
										)}
									</div>
									<div className='form-group mb-6'>
										<label className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'>
											What market is this property in?
										</label>
										<select
											name='market'
											type='text'
											className='form-control custom-select'
											onChange={(e) => onChangeMarketOption(e, handleChange)}
											onBlur={handleBlur}
											value={values.market}
											required
										>
											<option value=''>Select Market</option>
											{getStates}
										</select>
										{touched.market && errors.market && (
											<p className={ERROR_MESSAGE_CLASSES}>{errors.market}</p>
										)}
									</div>
									<div className='form-group mb-6'>
										<label className='mb-2 text-sm font-medium left-5 inline-block text-gray-700'>
											What is your expected monthly rental income?
										</label>
										<NumericFormat
											name='rentalIncome'
											type='text'
											className='form-control'
											placeholder='Expected Rental Income'
											thousandSeparator={','}
											allowNegative={false}
											decimalScale={2}
											decimalSeparator={'.'}
											prefix={getCurrencyCode(USD)}
											isAllowed={(values) => allowedInputs(values)}
											onChange={(e) => onChangeRentalIncome(e, handleChange)}
											onBlur={handleBlur}
											value={values.rentalIncome}
										/>
										{touched.rentalIncome && errors.rentalIncome && (
											<p className={ERROR_MESSAGE_CLASSES}>
												{errors.rentalIncome}
											</p>
										)}
									</div>
								</div>
							</div>
						</form>
					)
				}}
			</Formik>
		</div>
	)
}

export default AccordionLayout
