import React, { useRef, useState } from 'react'
import ReinvestmentGenericModal from './ReinvestmentGenericModal'
import { VIDEOS_URL, PLAY } from '../../utils/constants'
import { plenti } from '../../assets/images'
import { grayCirclePlaySolid } from '../../assets/icons'

const VideoPlayer = ({ handleClose }) => {
	const videoRef = useRef(null)
	const [isPlaying, setIsPlaying] = useState(false)
	const [isLoading, setIsLoading] = useState(true)

	const videoPlaying = (event) => {
		setIsPlaying(event.type === PLAY)
	}

	const handlePlay = () => {
		if (videoRef.current) videoRef.current.play()
	}

	const handleLoadedMetadata = () => {
		setIsLoading(false)
	}

	return (
		<ReinvestmentGenericModal handleClose={handleClose}>
			<video
				ref={videoRef}
				preload='metadata'
				controls
				poster={plenti}
				className='w-full relative'
				onPlay={videoPlaying}
				onPause={videoPlaying}
				onLoadedMetadata={handleLoadedMetadata}
			>
				<source src={VIDEOS_URL.HOW_IT_WORKS} type='video/mp4' />
			</video>
			{!isPlaying && !isLoading && (
				<img
					src={grayCirclePlaySolid}
					onClick={handlePlay}
					className='absolute top-1/2 left-1/2 opacity-50 hover:opacity-100 h-6 w-6 xs:h-8 xs:w-8 sm:h-12 sm:w-12 md:w-16 md:h-16 cursor-pointer transition duration-500 transform -translate-x-1/2 -translate-y-1/2'
				/>
			)}
		</ReinvestmentGenericModal>
	)
}

export default VideoPlayer
