import React, { useMemo } from 'react'
import { closeIcon } from '../../assets/icons'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectChatWindowState } from './chatWindowStates.selectors'
import { actions } from './chatWindowStates.slice'
import './ChatWindow.css'
import { SHOW_CHAT_WINDOW_ROUTES } from '../../utils/constants'
import { selectAffiliateSlug } from '../../features/Affiliates/affiliates.selectors'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { checkForCurrentRoute } from '../../utils/methods'

const { hideChatWindow } = actions

const ChatWindow = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const chat = useSelector(selectChatWindowState)
	const affiliateSlug = useSelector(selectAffiliateSlug)

	const showChatWindow = () => dispatch(hideChatWindow())

	const shouldShowChatWindow = useMemo(
		() =>
			checkForCurrentRoute(SHOW_CHAT_WINDOW_ROUTES, affiliateSlug, location),
		[location, affiliateSlug]
	)

	return (
		<>
			{shouldShowChatWindow && (
				<aside
					className={`fixed transition-all duration-200  lg:static z-50 chat-sidebar w-72 h-screen border-l border border-gray-200 flex flex-col bg-white ${
						chat.isVisible ? 'right-0' : '-right-72'
					}`}
				>
					<HelmetProvider>
						<Helmet>
							<script>
								{`
		window.hsConversationsSettings = {
			loadImmediately: false,
			inlineEmbedSelector: '#chat-window',
			enableWidgetCookieBanner: true,
			disableAttachment: true
		};
	`}
							</script>
						</Helmet>
					</HelmetProvider>
					<div className='chat-header p-6 border-b border-gray-200 flex items-center'>
						<h4 className='text-lg font-medium leading-7 text-gray-900'>
							Chat With an Attorney-Trained Mentor
						</h4>
						<button
							className='w-10 h-10 grid place-content-center rounded-md ml-2 hover:bg-slate-100 lg:hidden flex-shrink-0'
							onClick={showChatWindow}
						>
							<img src={closeIcon} width='20' alt='close-icon' />
						</button>
					</div>
					<div id='chat-window' className='flex-1'></div>
				</aside>
			)}
			{chat.isVisible && (
				<div className='sidebar-overlay z-40 w-full h-full fixed bg-gray-800/80 left-0 top-0 backdrop-blur-xl'></div>
			)}
		</>
	)
}

export default ChatWindow
