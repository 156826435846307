import React, { useRef } from 'react'
import classNames from 'classnames'
import { modalCloseIcon } from '../../assets/icons'

const MD = 'medium'
const FULL = 'fullScreen'
const LG = 'largeScreen'
const CENTER = 'center'

const ReinvestmentGenericModal = ({
	children,
	handleClose,
	size = FULL,
	position = CENTER,
}) => {
	const backdropRef = useRef(null)
	return (
		<div
			className={classNames(
				'modal-backdrop flex fixed inset-0 bg-slate-900/70 backdrop-blur-md z-[999] overflow-auto transition-opacity duration-300 custom-scrollbar',
				{
					' px-4 py-16 lg:flex': size === MD || LG,
					' items-center': (size === MD || LG) && position === CENTER,
				}
			)}
			onClick={handleClose}
		>
			<div
				ref={backdropRef}
				onClick={(e) => e.stopPropagation()}
				className={classNames(
					`modal-content bg-white w-full mx-auto relative`,
					{
						' my-6 sm:w-[600px] h-fit': size === MD,
						'flex flex-col fixed inset-y-0 right-0 sm:w-[42vw] max-h-screen rounded-none md:rounded-lg':
							size === LG,
						' flex flex-col max-w-[884px]': size === FULL,
					}
				)}
			>
				<img
					src={modalCloseIcon}
					className='absolute w-10 h-10 right-[-15px] top-[-15px] cursor-pointer z-10'
					alt='cross-icon'
					onClick={handleClose}
				/>
				{children && <div>{children}</div>}
			</div>
		</div>
	)
}

export default ReinvestmentGenericModal
