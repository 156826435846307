import CurrentInvestment from './CurrentInvestment'
import * as selectors from './currentInvestment.selectors'
import slice from './currentInvestment.slice'
export const {
	name,
	actions: { setCurrentInvestmentAddress, selectPropertyAddressState },
	reducer,
} = slice
export const { selectCurrentInvestmentDetails } = selectors
export default CurrentInvestment
