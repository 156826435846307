import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	BUSY_INDICATORS,
	DEFAULT_VALUES,
	NavRoutes,
	tempSlang,
} from '../../utils/constants'
import { warnNotification } from '../../widgets/ToastNotification/ToastNotification'
import { createAffiliate, deleteLogo } from './affiliates.asyncActions'
import { getNamedBusyIndicator } from '../../widgets/busyIndicator'
import classNames from 'classnames'
import { get } from 'lodash'
import {
	STORAGE_CONTAINER_NAME,
	STORAGE_ACCOUNT_NAME,
} from '../../utils/getEnvVars'
import { useDispatch, useSelector } from 'react-redux'
import {
	selectFormData,
	selectUploadedImage,
	selectImageData,
} from './affiliates.selectors'
import axios from 'axios'
import {
	API_BASE_URL,
	API_ENDPOINT_PREFIX,
} from '../../infrastructure/http/http.constants'

const { EMPTY_STRING } = DEFAULT_VALUES
const { CREATE_AFFILIATE } = BUSY_INDICATORS
const { AFFILIATE_REQUEST_APPROVAL, SIGN_UP_FORM_SUBMITTED } = NavRoutes

const Terms = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const uploadedImage = useSelector(selectUploadedImage)
	const imageFileData = useSelector(selectImageData)
	const formData = useSelector(selectFormData)
	const companyName = get(formData, 'companyName', EMPTY_STRING)
	const email = get(formData, 'email', EMPTY_STRING)

	const isAffiliateCreating = useSelector(
		getNamedBusyIndicator(CREATE_AFFILIATE)
	)
	const [isUserAgreementAccepted, setIsUserAgreementAccepted] = useState(false)

	const toggleUserAgreementAcceptance = () =>
		setIsUserAgreementAccepted((preValue) => !preValue)

	const renderWarningToAcceptUserAgreement = (event) => {
		event.preventDefault()
		warnNotification('Please Accept User Agreement to continue.')
	}

	const handleSubmit = async (e) => {
		let uploadedImageURL = uploadedImage
		if (isUserAgreementAccepted) {
			const companySlug = get(formData, 'companySlug', EMPTY_STRING)
			const fileName = get(imageFileData, 'name', EMPTY_STRING)
			if (
				uploadedImage !== `${companySlug}_${fileName}` ||
				uploadedImage === `${tempSlang}_${fileName}`
			) {
				const uploadImageRequest = new FormData()
				uploadImageRequest.append('image', imageFileData)
				uploadImageRequest.append('slug', companySlug)

				dispatch(deleteLogo(uploadedImage))
				const uploadedImageResponse = await axios.put(
					`${API_BASE_URL}/${API_ENDPOINT_PREFIX}/image`,
					uploadImageRequest
				)
				uploadedImageURL = get(uploadedImageResponse, 'data', EMPTY_STRING)
			}
			dispatch(
				createAffiliate({
					...formData,
					logoURL: `https://${STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${STORAGE_CONTAINER_NAME}/${uploadedImageURL}`,
				})
			).then(
				(res) =>
					get(res, 'payload.hubSpotID', EMPTY_STRING) &&
					navigate(SIGN_UP_FORM_SUBMITTED)
			)
		} else renderWarningToAcceptUserAgreement(e)
	}

	return (
		<>
			<div className='flex flex-col h-full leading-[38px] sm:pt-22 p-[84px] !overflow-hidden'>
				<h1 className='text-3xl text-gray-900 font-bold leading-[38px] mb-2'>
					Terms
				</h1>
				<p className='font-normal text-lg leading-7 mb-6'>
					Please read and agree to the terms before submitting.
				</p>
				<div className='border border-gray-300 bg-[#FCFCFD] rounded-lg p-6 h-[80%] overflow-y-auto no-scrollbar'>
					<p className='text-lg leading-6 mb-6'>
						Referrer Name: [<u className='font-semibold'> {companyName} </u>] of
						[<u className='font-semibold'> {email} </u>]
						<br />
						<br />
						<span className='font-semibold'>
							Platform Provider/Lead Generator:
						</span>{' '}
						Fortune Cookie Consulting, Ltd. (“Plenti”), of: 5355 Avenida
						Encinas, Suite 203 Carlsbad, CA 92008, a software services company.
						<br />
						<span className='font-semibold'>Qualified Intermediary:</span> 1031
						Exchange Advantage®, a trade name of 1031 EA, LLC (“QI”), of 5355
						Avenida Encinas, Suite 203 Carlsbad, CA 92008, a qualified 1031
						exchange intermediary.
						<br />
						<br /> This Agreement among Referrer, Plenti and QI is entered into
						on [{new Date().toLocaleDateString('en-US')}] (the “Effective
						Date”). <br />
						<br />
						<span className='font-semibold flex justify-center'>RECITALS</span>
						<br /> WHEREAS, Company and QI have configured a business
						relationship to enable the referral and execution of 1031 qualified
						property exchanges (the “1031 Exchange Platform”);
						<br />
						<br /> WHEREAS Company owns all right, interest, and title in and to
						a mobile enabled and desktop compatible application for multi
						vertical real estate exchange businesses to utilize for the purpose
						of increasing efficiencies for their customer base (collectively
						referred to herein as the “Plenti Financial Technology”); and
						<br />
						<br />
						WHEREAS, Referrer desires to license and use the Plenti Financial
						Technology to utilize the 1031 Exchange Platform to enable
						Referrer&apos;s customers to execute 1031 qualified exchanges.
						<br />
						<br /> NOW, THEREFORE, in consideration of the mutual promises and.
						Covenants herein contained, the parties hereby agree as follows:
						<br />
						<br />
						<span className='font-semibold flex justify-center'>TERMS</span>
						<br />
						1. <u>Duties of Referrer</u>.
						<br />
						<br />
						&emsp;1.1 Market potential 1031 exchanges (an “Exchange”) to its
						customers and prospects (“Customers”) and refer them to QI
						(“Exchange Referrals”), in consideration of a gross revenue share to
						be paid by Plenti, based on completed resulting Exchanges (the
						“Revenue Share”). Referrer&apos;s marketing services will be
						provided through its customer and prospect-facing employees
						(collectively, “Referrer Staff”).
						<br />
						<br />
						&emsp;1.2 Make Referrer staff and real estate broker network aware
						of QI&apos;s services and develop a program to promote QI&apos;s
						exchange to their customers (the “Marketing Program”) and use best
						efforts to generate Exchange Referrals.
						<br />
						<br />
						&emsp;1.3 Utilize the Plenti Financial Technology, as described
						below, to initiate and track exchanges.
						<br />
						<br /> 2. <u>Duties of QI</u>.
						<br />
						<br />
						&emsp;2.1 Provide to Referrer a custom URL, Co-branded 1031 Planner
						App, and 1031 Exchange Portal (collectively, the “Plenti Software”)
						to access and use the Plenti Financial Technology to facilitate the
						Exchange Intake process on behalf of its Customers..
						<br />
						<br />
						&emsp;2.2 Facilitate Intake calls with each Customer to discuss
						exchange details including (but not limited to) property, “boot”
						(mortgage or cash out) implications and any cash needed at the close
						of the transaction
						<br />
						<br />
						&emsp;2.3 Provide each Customer with a formal Exchange document
						package for review and signature via Docusign.
						<br />
						<br />
						&emsp;2.4 Provide each Customer&apos;s identified Title/Escrow
						company or Closing Attorney (“Closing entity”) with all necessary
						documentation to facilitate the Exchange. This will include a list
						of all action items needed as well as Wiring Instructions for funds.
						<br />
						<br />
						&emsp;2.5 Facilitate an additional Intake call with Referrer&apos;s
						Customer to complete the Intake form for the upleg property
						(property to be purchased).
						<br />
						<br />
						&emsp;2.6 Provide an Intake Form document package for review and
						signature
						<br />
						<br />
						&emsp;2.7 Facilitate the following financial transactions:
						<br />
						<br />
						&emsp;&emsp;2.7.1 Inbound receipt of downleg (exchanged) property
						funds from Closing Entity b. <br />
						<br />
						&emsp;&emsp;2.7.2 Outbound sending of all funds to Closing Entity to
						facilitate purchase of upleg property (replacement property and/or
						improvement exchanges)
						<br />
						<br />
						&emsp;&emsp;2.7.3 Inbound receipt of downleg funds (reverse
						exchanges). <br />
						<br />
						3. <u>Duties of Plenti</u>. Provide the Plenti Software, and any
						updates thereto, together with instructions on how to deploy and use
						the Plenti Software. Plenti will provide reasonable support and
						assistance to Referrer, during normal business hours. <br />
						<br />
						4. <u>License</u>.
						<br />
						<br />
						&emsp;4.1 Plenti hereby grants to Referrer and Customers a limited,
						non-exclusive, non- transferable, non-sublicensable, license to use
						the Plenti Financial Technology within the United States to
						facilitate 1031 exchanges in accordance with the terms of this
						Agreement (the “License”). Neither Referrer nor its Customers may
						modify or alter the Plenti Software. <br />
						<br />
						&emsp;4.2 As an express condition of this License, Referrer
						represents and warrants that it will not associate or link the
						Plenti Software to any content, web page or site that contains any:
						(i) nudity, pornography, or other sexual or adult material; (ii)
						hate propaganda or material that encourages or promotes illegal
						activity or violence; (iii) content that violates or infringes
						applicable law or proprietary rights of others, including but not
						limited to copyrights, trademark rights, patents, or any other third
						party intellectual property, contract, privacy, or publicity rights;
						(iv) material that promotes or utilizes software or services
						designed to deliver unsolicited email; (v) viruses, Trojan horses,
						worms, time bombs, cancel bots or other similar harmful or
						deleterious programming routines; or (vi) misrepresentations or
						material that is threatening, abusive, harassing, defamatory,
						obscene, profane, indecent, or otherwise objectionable, offensive,
						or harmful, as determined in the other party&apos;s sole discretion.
						<br />
						<br /> 5. <u>Ownership</u>. Nothing in this Agreement shall be
						deemed or construed to limit or assign in any way Plenti&apos;s
						rights in and to the Plenti Financial Technology. Plenti expressly
						retains all right, title, and interest in and to the Plenti
						Financial Technology, inclusive of any improvements or modification
						made thereto and any data created, compiled, or derived from
						Referrer&apos;s use of the Plenti Software.
						<br />
						<br /> 6. <u>Trademarks</u>. <br />
						<br />
						&emsp;6.1 Plenti owns all right, title, and interest in and to the
						“Plenti Exchange” trademark as described in U.S. Trademark
						Application Number 97/652,433, the Plenti logo, and any and all
						goodwill associated therewith, including all goodwill accrued in
						connection with this the use of those trademarks pursuant to this
						Agreement (collectively, the “Plenti Marks&quot). Referrer may only
						use the Plenti Marks during the term of this Agreement in connection
						with Referrer&apos;s deployment of the Plenti Software, and must
						display the Plenti Marks in strict accordance with Plenti&apos;s
						brand guidelines, including by using either the ®, SM, or TM
						symbols, as is appropriate. <br />
						<br />
						&emsp;6.2 Plenti or QI, will provide Partner with electronic files
						containing the Plenti Marks to be used under this Agreement.
						<br />
						<br /> 7. <u>Referral Fee</u>: As full compensation for Exchange
						Referrals (exclusive of the Joint Business Development), Plenti
						shall pay Referrer: <br />
						<br />
						&emsp;7.1 Level 1 (Average Deposit Balances of Less than
						$10,000,000): 15% of client fees and interest (based on the
						published rate of 4.0% until changed after client interest, and any
						fee refunds) collected by QI for each Exchange to be paid on or
						before the 15th day of the first calendar month starting after the
						completion of the Exchange. <br />
						<br />
						&emsp;7.2 Level 2 (Average Deposit Balances of $10,000,001 to
						$20,000,000): 25% of client fees and interest (based on the
						published rate of 4.0% until changed after client interest, and any
						fee refunds) collected by QI for each Exchange to be paid on or
						before the 15th day of the first calendar month starting after the
						completion of the Exchange. <br />
						<br />
						&emsp;7.3 The foregoing assumes a Fed Funds rate of 5.00% or higher.
						Should the Fed Funds rate be lowered below 5.00% the Revenue Share
						shall be reduced in proportion to the reduction in the Fed Funds
						rate. <br />
						<br />
						&emsp;7.4 Nothing in this Agreement shall obligate QI to make
						deposits at any particular institution or to obtain any particular
						rate. Due to the critical banking service levels required by QI, QI
						shall have the sole and absolute discretion to choose the terms and
						provider of all deposit accounts and specifically shall have no duty
						to consider or maximize the payments to Partner. <br />
						<br />
						&emsp;7.5 Partner shall only be entitled to compensation for
						referred clients that use the Plenti Software unless written
						evidence reasonably suggests they contacted Plenti as a result of
						Referrer&apos;s efforts.
						<br />
						<br /> 8. <u>Warranty Disclaimer and Limitation of Liability</u>.
						THE PLENTI SOFTWARE, IS PROVIDED ON AN &quotAS IS&quot BASIS. THE
						COMPANY DISCLAIMS AS FAR AS LEGALLY PERMISSIBLE ALL WARRANTIES
						RELATING TO THE SOFTWARE, WHETHER EXPRESSED, IMPLIED OR STATUTORY,
						INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF SATISFACTORY
						QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
						COMPATIBILITY OR NON-INFRINGEMENT . THE COMPANY DOES NOT WARRANT
						THAT THE SOFTWARE WILL MEET CUSTOMER’S REQUIREMENTS OR BE
						UNINTERRUPTED, SECURE OR ERROR-FREE. NEITHER THE COMPANY NOR ANYONE
						ELSE WHO HAS BEEN INVOLVED IN THE CREATION, PRODUCTION, OR DELIVERY
						OF THE SOFTWARE (&quotSUPPLIERS&quot) SHALL BE LIABLE FOR ANY
						SPECIAL, INDIRECT, CONSEQUENTIAL, INCIDENTAL OR PUNITIVE DAMAGES
						(INCLUDING BUT NOT LIMITED TO LOST REVENUES, PROFITS AND/OR SAVINGS)
						ARISING OUT OF THE USE OR INABILITY TO USE SUCH SOFTWARE, EVEN IF
						THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR
						CLAIMS. THE PERSON USING THE SOFTWARE BEARS ALL RISK AS TO THE
						QUALITY AND PERFORMANCE OF THE SOFTWARE. THE ENTIRE LIABILITY OF THE
						COMPANY AND ITS SUPPLIERS, AND THE CUSTOMER’S EXCLUSIVE REMEDY, WITH
						RESPECT TO THE USE OF THE SOFTWARE OR ANY BREACH OF THIS AGREEMENT
						ARE LIMITED TO THE AMOUNT ACTUALLY PAID BY THE CUSTOMER, IF ANY, FOR
						USE OF THE SOFTWARE. CUSTOMER HEREBY RELEASES THE COMPANY AND SUCH
						OTHER PERSONS FROM ANY AND ALL OBLIGATIONS, LIABILITIES AND CLAIMS
						IN EXCESS OF THIS LIMITATION. CUSTOMER AGREES THAT ANY CLAIM OR
						CAUSE OF ACTION ARISING OUT OF OR RELATED TO USE OF THE SOFTWARE OR
						THIS AGREEMENT MUST BE FILED WITHIN ONE (1) YEAR AFTER SUCH CLAIM OR
						CAUSE OF ACTION AROSE OR BE FOREVER BARRED. CUSTOMER IS SOLELY
						RESPONSIBLE FOR SELECTION, INSTALLATION AND LAUNCH OF THE SOFTWARE
						AND FOR BACKING UP CUSTOMER’S DATA AND FILES, AND HEREBY RELEASES
						THE COMPANY AND ITS SUPPLIERS FROM ANY LIABILITY OR DAMAGES DUE TO
						THE LOSS OF ANY SUCH DATA OR FILES. THE COMPANY IS NOT LIABLE FOR
						ANY INAPPROPRIATE OR ILLEGAL USE OF THE SOFTWARE. CUSTOMER IS SOLELY
						RESPONSIBLE FOR SECURING ITS DATA AND COMPUTER SYSTEMS UPON WHICH
						THE SOFTWARE IS DEPLOYED. THE COMPANY IS NOT LIABLE FOR ANY DATA
						BREACH OR DISCLOSURE OF CUSTOMER DATA FROM CUSTOMER’S COMPUTER
						SYSTEMS OR NETWORK.
						<br />
						<br /> 9. <u>Miscellaneous</u>: <br />
						<br />
						&emsp;9.1 The parties represent warrant and covenant that its
						activities under this Agreement comply and will comply with all
						applicable laws, rules, regulations and licensing requirements, that
						this Agreement is and will be at all times binding upon it and
						enforceable in accordance with its terms and that this Agreement has
						been duly authorized, executed and entered into. Each party shall
						indemnify, hold harmless and defend the other party and any of their
						affiliates from and against any and all claims, damages,
						liabilities, losses, judgments, costs, and attorneys’ fees arising
						out of such party&apos;s performance or breach of this Agreement in
						any manner. Each party further represents that it carries insurance
						in amounts and with coverages sufficient by industry standards to
						adequately protect its business from insurable claims, including
						without limitation data security and cybersecurity coverage. <br />
						<br />
						&emsp;9.2 Referrer shall have no authority to bind 1031 EA, LLC or
						Plenti during this Agreement, and shall not purport to have such
						authority. The relationship between the parties is that of
						independent contractors, and no partnership, joint venture,
						fiduciary relationship or any other relationship other than a
						contractual one shall be deemed to exist. <br />
						<br />
						&emsp;9.3 Each party will comply with all state and federal
						regulations and provide the other party with a form W-9, as needed.
						<br />
						<br />
						The term of this Agreement shall be for five (5) years from the
						Effective Date, unless terminated by either of the parties with 90
						days written notice to the other party, amended by both in writing,
						or earlier terminated in accordance with the terms of this
						Agreement. Immediately upon termination of this Agreement, Referrer
						shall cease all use of the Plenti Marks and the Plenti Software.
						Compensation shall remain payable as to all clients referred in
						accordance with this Agreement prior to the date of termination,
						provided that no compensation shall be due for any referrals after
						the date of termination, and any otherwise payable amounts based on
						deposit balances or otherwise shall cease 180 days after the
						termination of the Agreement. <br />
						<br />
						&emsp;9.4 The disclosure and use of any confidential information
						exchanged by the parties is governed by a separate confidentiality
						agreement entered into by the parties. <br />
						<br />
						&emsp;9.5 This Agreement is to be construed in accordance with and
						governed by the internal laws of the State of California without
						giving effect to any choice of law rule that would cause the
						application of the laws of any jurisdiction other than the internal
						laws of the State of California to the rights and duties of the
						parties. Each party hereby irrevocably consents to the jurisdiction
						and venue of the state and federal courts located in San Diego
						County, California in connection with any claim, action, suit, or
						proceeding relating to this Agreement and agrees that all suits or
						proceedings relating to this Agreement shall be brought only in such
						courts, provided that either party may seek injunctive, equitable or
						similar relief from any court of competent jurisdiction. <br />
						<br />
						&emsp;9.6 This Agreement sets forth the entire understanding of the
						parties regarding the subject matter of this Agreement and it shall
						not be deemed amended except in a writing signed by the parties. No
						waiver of a party&apos;s rights shall be effective unless such
						waiver is in writing signed by the waiving party. If any provision
						of this Agreement or the application of such provision to any person
						or circumstance shall be held invalid, illegal, against public
						policy or is otherwise unenforceable, the remainder of this
						Agreement or the application of such provision to persons or
						circumstances other than those to which it is held invalid shall not
						be affected thereby. <br />
						<br />
						&emsp;9.7 Notices under this Agreement shall be provided to the
						persons on the signature page of this Agreement at the addresses set
						forth in the forepart of this Agreement unless modified by notice
						from one party to the other.
						<br />
						<br /> IN WITNESS WHEREOF, each of the parties has caused this
						License Agreement to be executed in duplicate originals by its duly
						authorized representatives as of the date first stated above.
						<br />
					</p>
					<label className='relative inline-flex items-center cursor-pointer flex-wrap'>
						<input
							type='checkbox'
							checked={isUserAgreementAccepted}
							className='sr-only peer'
							onClick={() => toggleUserAgreementAcceptance()}
							readOnly
						/>
						<div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:outline-none peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-700"></div>
						<span className='ml-3 text-sm font-medium text-gray-900'>
							By submitting your application you are agreeing to the Partnership
							Agreement, Terms of Service and Privacy Policy visible from the
							links below.
						</span>
					</label>
				</div>
			</div>
			<div className='sticky bottom-0 fixed'>
				<div className='border bg-white border-gray-200 p-6 flex align-center justify-end xs:text-right text-center space-x-2 self-end border-t-2 p-6'>
					<button
						className='btn btn-light'
						onClick={() => navigate(AFFILIATE_REQUEST_APPROVAL)}
					>
						Previous
					</button>{' '}
					<button
						className={classNames('btn btn-primary', {
							'cursor-not-allowed opacity-25': isAffiliateCreating,
						})}
						type='submit'
						onClick={handleSubmit}
						disabled={isAffiliateCreating}
					>
						Submit Request
					</button>
				</div>
			</div>
		</>
	)
}

export default Terms
