import NavBar from './NavBar'
import * as navbarStatesSelector from './navbarStates.selectors'
import slice from './navbarStates.slice'

export const {
	name,
	actions: { shouldActivate, shouldShowNavbar },
	reducer,
} = slice

export const {
	selectIntroState,
	selectCurrentInvestmentState,
	selectFindInvestmentState,
	selectMoreDetailsState,
	selectSummaryState,
	selectNavbarState,
} = navbarStatesSelector

export default NavBar
