import { React } from 'react'
import { openURLInNewTabDefault } from '../../utils/methods'
import { DOC_URL } from '../../utils/constants'

const {
	SAMPLE_EXCHANGE_AGREEMENT,
	QUALIFIED_ESCROW_AGREEMENT,
	OUR_BONDING,
	AXOS_FDIC,
} = DOC_URL

const Footer = () => {
	return (
		<div className='bg-[#E9ECF1] px-8 sm:px-20 md:px-36 py-6 text-[17px] font-axiforma-regular'>
			<p className='mb-6 font-bold'>
				<span
					className='cursor-pointer underline hover:no-underline'
					onClick={() => openURLInNewTabDefault(SAMPLE_EXCHANGE_AGREEMENT)}
				>
					Sample Exchange Agreement
				</span>
				<span> | </span>
				<span
					className='cursor-pointer underline hover:no-underline'
					onClick={() => openURLInNewTabDefault(QUALIFIED_ESCROW_AGREEMENT)}
				>
					Qualified Escrow Agreement
				</span>
				<span> | </span>
				<span
					className='cursor-pointer underline hover:no-underline'
					onClick={() => openURLInNewTabDefault(OUR_BONDING)}
				>
					Our Bonding
				</span>
				<span> | </span>
				<span
					className='cursor-pointer underline hover:no-underline'
					onClick={() => openURLInNewTabDefault(AXOS_FDIC)}
				>
					Axos FDIC
				</span>
			</p>
			<p className='mb-6'>
				NMLS #2149727 | DRE 02144466 | NMLS consumer access | Equal Housing
				Opportunity
			</p>
			<p className='mb-6'>
				Plenti Financial™ is a trade name of 1031 Exchange Advantage®. 1031
				Exchange Advantage™ is a trade name of 1031 EA, LLC and is not a
				licensed broker. 1031 EA, LLC is a division of Plenti Financial and is
				the accommodator for a 1031 Exchange as required by the IRS. 1031 EA,
				LLC does not provide, brokerage, legal or accounting services and is
				simply a Qualified Intermediary under the IRS code. It therefore is not
				providing any services other than accommodation services and clients and
				prospects are advised to confirm all tax, legal, accounting and real
				estate investment decisions with their professionals such as their
				accountant or attorney.
			</p>
			<p className='mb-6'>
				A 1031 exchange is a method of tax deferral upon the sale of investment
				real estate. However, the decision to pay or not pay accumulated and/or
				deferred taxes is up to the taxpayer, who has control over whether
				transactions are completed tax-deferred, or tax-free! If an exchanger
				should decide to continue exchanging into investment property of same or
				greater value for life, and never sell investment property without
				utilizing a 1031 exchange, no taxes would be due on any transactions,
				making them tax-free! Even in the event the taxpayer dies, the taxes die
				with him, again making the transactions tax-free! Because the choice is
				up to the taxpayer, we like to say taxes in an exchange are deferred,
				unless you choose to sell tax-free with Plenti Financial!
			</p>
			<p className='mb-4 text-center font-work-sans-regular'>
				Copyright 2024 @ All Rights Reserved
			</p>
		</div>
	)
}

export default Footer
