import React from 'react'
import RoadmapCta from '../RoadmapCta'
import { PhoneIcon } from '../../../assets/icons'

const MYTHS = [
	{
		heading: 'I can only buy similar property in a 1031 Exchange',
		description:
			'No! Almost all real estate is considered “like kind” and can be exchanged for any other real estate, as long as you own it for investment or business use.',
	},
	{
		heading: 'I’ll have to pay my taxes sooner or later',
		description:
			'No! You can avoid paying taxes on real estate profits forever. By following the 1031 rules when you sell/buy, your gains are exempt until you die. Your estate escapes the tax on profits forever! Call it the “Swap until you drop” plan.',
	},
	{
		heading: 'My personal residence disqualifies 1031 exchange',
		description:
			'No! You can still qualify! If you satisfy the requirements, you can actually combine both your primary residence exclusion and complete a tax free exchange when you sell.',
	},
]

const BiggestMyth = () => (
	<div>
		<div className='p-8 sm:p-12 md:p-24'>
			<div className='font-work-sans-light text-43 text-gray-500'>
				The 3 Biggest Tax Myths in Real Estate
			</div>
			<div className='flex flex-col gap-6 sm:gap-12 p-6 mt-6 sm:mt-12'>
				{MYTHS.map(({ heading, description }, index) => (
					<div
						key={heading}
						className='flex gap-4 sm:gap-8 items-center relative'
					>
						<div className='font-work-sans-light text-3xl sm:text-5xl text-primary-600 flex justify-center items-center rounded-full'>
							<div className='w-8 h-8 sm:w-12 sm:h-12 p-1 border-8 sm:border-[10px] box-content border-primary-600 opacity-10 rounded-full' />
							<div className='absolute'> {index + 1}</div>
						</div>
						<div className='flex-1'>
							<div className='font-work-sans-medium text-lg text-gray-600'>
								{heading}
							</div>
							<div className='font-work-sans-regular text-sm text-gray-500'>
								{description}
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
		<RoadmapCta>
			<div className='w-full flex flex-col items-center justify-center sm:flex-row gap-4 sm:gap-16'>
				<div className='flex-1 text-white sm:text-2xl font-work-sans-regular text-center sm:text-left'>
					Unlock hidden opportunities! Free consultation with a tax-free
					exchange expert. No cost, no obligation. Call now!
				</div>
				<a
					href='tel:8669441031'
					className='bg-white flex w-fit items-center justify-center gap-2 text-primary-600 font-work-sans-semibold sm:text-lg rounded-full px-3 py-2 sm:px-5 sm:py-4'
				>
					<PhoneIcon className='stroke-primary-600 w-4 h-4' /> (866) 944 - 1031
				</a>
			</div>
		</RoadmapCta>
	</div>
)

export default BiggestMyth
