import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	NavRoutes,
	SessionKeys,
	ERROR_MESSAGES,
	ERROR_MESSAGE_CLASSES,
	PLENTI,
} from '../../../utils/constants'
import { shouldActivate } from '../../../widgets/NavBar'
import { greenChevronDownIcon, poweredByPlenti } from '../../../assets/icons'
import {
	getLinkedInRedirectURL,
	isSessionAvailable,
	validateMobileNumber,
	formatResponseObject,
	getNavigationRoute,
} from '../../../utils/methods'
import { NumericFormat } from 'react-number-format'
import {
	LINKEDIN_CLIENT_ID,
	LINKEDIN_CALLBACK_URL_AGENTS_REFERRAL,
} from '../../../utils/getEnvVars'
import { NavLinkStates } from '../../../utils/constant.enum'
import { actions } from './authentication.slice'
import { NameRegularExpression } from '../../../utils/constants.regex'
import { setInitialValues } from '../../CurrentInvestmentWrapper/InvestmentForm/investmentForm.constants'
import { selectAffiliateSlug } from '../../Affiliates/affiliates.selectors'

const Authentication = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const affiliateSlug = useSelector(selectAffiliateSlug)
	const isAffiliateSite = affiliateSlug !== PLENTI

	const { resetToInititalState } = actions
	const { REFERRAL_LINK } = NavRoutes
	const { AGENT } = SessionKeys
	const [shouldShowWithoutLinkedInButton, setShouldShowWithoutLinkedInButton] =
		useState(false)
	const [isLinkedInButtonClicked, setIsLinkedInButtonClicked] = useState(false)
	const linkedInButtonStyle =
		'inline-flex border-linkedin-200 border bg-linkedin-100 hover:bg-sky-700 text-white font-medium text-base items-center'
	const agentDetail = window.sessionStorage.agent
		? JSON.parse(window.sessionStorage.agent)
		: {}
	const initialValues = {
		firstName: setInitialValues(agentDetail.firstName),
		lastName: setInitialValues(agentDetail.lastName),
		email: setInitialValues(agentDetail.email),
		phoneNumber: setInitialValues(agentDetail.phoneNumber),
		currentPosition: setInitialValues(agentDetail.currentPosition),
		companyName: setInitialValues(agentDetail.companyName),
	}
	const validationSchema = Yup.object().shape({
		firstName: Yup.string()
			.min(3, ERROR_MESSAGES.MIN_LENGTH_3)
			.max(255, ERROR_MESSAGES.MAX_LENGTH_255)
			.test('firstName', ERROR_MESSAGES.FIRST_NAME_VALIDATE_REGREX, (val) =>
				NameRegularExpression.test(val)
			)
			.required(ERROR_MESSAGES.FIRST_NAME_REQUIRED),
		lastName: Yup.string()
			.min(3, ERROR_MESSAGES.MIN_LENGTH_3)
			.max(255, ERROR_MESSAGES.MAX_LENGTH_255)
			.test('lastName', ERROR_MESSAGES.LAST_NAME_VALIDATE_REGREX, (val) =>
				NameRegularExpression.test(val)
			)
			.required(ERROR_MESSAGES.LAST_NAME_REQUIRED),
		email: Yup.string()
			.email(ERROR_MESSAGES.EMAIL_VALIDATE_SPACE)
			.required(ERROR_MESSAGES.EMAIL_REQUIRED),
		phoneNumber: Yup.string().test(
			'phoneNumber',
			ERROR_MESSAGES.MOBILE_NUMBER,
			(val) => validateMobileNumber(val)
		),
		currentPosition: Yup.string()
			.min(3, ERROR_MESSAGES.MIN_LENGTH_3)
			.max(255, ERROR_MESSAGES.MAX_LENGTH_255),
		companyName: Yup.string()
			.min(3, ERROR_MESSAGES.MIN_LENGTH_3)
			.max(255, ERROR_MESSAGES.MAX_LENGTH_255),
	})
	const loginViaLinkedInHandler = () => {
		const url = getLinkedInRedirectURL(
			LINKEDIN_CLIENT_ID,
			LINKEDIN_CALLBACK_URL_AGENTS_REFERRAL
		)
		window.open(url, '_self', 'noopener,noreferrer')
	}
	const allowedMobileLength = (values) => {
		const MAX_TEN_DIGIT = 9999999999
		const { value } = values
		return value <= MAX_TEN_DIGIT
	}
	const onFormSubmit = (values) => {
		values = formatResponseObject(values)
		dispatch(resetToInititalState())
		if (isSessionAvailable(AGENT)) {
			window.sessionStorage.removeItem(AGENT)
		}
		window.sessionStorage.setItem(AGENT, JSON.stringify(values))
		if (isLinkedInButtonClicked) {
			loginViaLinkedInHandler()
		} else {
			dispatch(shouldActivate(NavLinkStates.REFERRAL))
			navigate(getNavigationRoute(affiliateSlug, REFERRAL_LINK))
		}
	}
	return (
		<div
			className={`xl:px-22 xl:pb-22 px-12 pb-24 ${
				isAffiliateSite ? 'xl:pt-7 p-2' : 'xl:pt-22 pt-12'
			}`}
		>
			{isAffiliateSite && (
				<div className='flex justify-end xl:pb-7 pb-1'>
					<img src={poweredByPlenti} alt='Powered By Plenti Logo' />
				</div>
			)}
			<h1 className='text-3xl font-bold leading-7 text-gray-900 mb-3'>
				The Plenti Exchange<sup className='font-features sups'>TM</sup> App
				Referral Generator
			</h1>
			<p className='text-gray-700 mb-6'>
				Create a unique referral link you can pass to your clients that has all
				of your information and picture. Complete the following information and
				login to your LinkedIn account to include your LinkedIn profile photo on
				the The Plenti Exchange<sup className='font-features sups'>TM</sup> App
				tax report.
			</p>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values) => onFormSubmit(values)}
			>
				{({
					values,
					handleBlur,
					handleChange,
					touched,
					errors,
					handleSubmit,
				}) => (
					<form>
						<div className='form-group flex-col mb-6'>
							<div className='flex mb-4 space-x-4'>
								<div className='flex flex-col flex-1'>
									<label
										htmlFor='firstName'
										className='mb-2 text-sm font-medium left-5 inline-block text-gray-900'
									>
										Your First Name
									</label>
									<input
										id='firstName'
										type='text'
										name='firstName'
										className='form-control'
										placeholder='Your First Name'
										onBlur={handleBlur}
										value={values.firstName}
										onChange={handleChange}
									/>
									{touched.firstName && errors.firstName && (
										<p className={ERROR_MESSAGE_CLASSES}>{errors.firstName}</p>
									)}
								</div>
								<div className='flex flex-col flex-1'>
									<label
										htmlFor='lastName'
										className='mb-2 text-sm font-medium left-5 inline-block text-gray-900'
									>
										Your Last Name
									</label>
									<input
										id='lastName'
										type='text'
										name='lastName'
										className='form-control'
										placeholder='Your Last Name'
										onBlur={handleBlur}
										value={values.lastName}
										onChange={handleChange}
									/>
									{touched.lastName && errors.lastName && (
										<p className={ERROR_MESSAGE_CLASSES}>{errors.lastName}</p>
									)}
								</div>
							</div>
							<div className='flex mb-4 space-x-4'>
								<div className='flex-col flex-1'>
									<label
										htmlFor='email'
										className='mb-2 text-sm font-medium left-5 inline-block text-gray-900'
									>
										Your Email
									</label>
									<input
										id='email'
										type='text'
										name='email'
										className='form-control'
										placeholder='Your Email'
										onBlur={handleBlur}
										value={values.email}
										onChange={handleChange}
									/>
									{touched.email && errors.email && (
										<p className={ERROR_MESSAGE_CLASSES}>{errors.email}</p>
									)}
								</div>
								<div className='flex-col flex-1'>
									<label
										htmlFor='phoneNumber'
										className='mb-2 text-sm font-medium left-5 inline-block text-gray-900'
									>
										Your Phone Number
									</label>
									<NumericFormat
										id='phoneNumber'
										type='text'
										name='phoneNumber'
										className='form-control'
										placeholder='Your Phone Number'
										allowNegative={false}
										decimalScale={0}
										isAllowed={(values) => allowedMobileLength(values)}
										onBlur={handleBlur}
										value={values.phoneNumber}
										onChange={handleChange}
									/>
									{touched.phoneNumber && errors.phoneNumber && (
										<p className={ERROR_MESSAGE_CLASSES}>
											{errors.phoneNumber}
										</p>
									)}
								</div>
							</div>
							<div className='flex mb-4 space-x-4'>
								<div className='flex-col flex-1'>
									<label
										htmlFor='currentPosition'
										className='mb-2 text-sm font-medium left-5 inline-block text-gray-900'
									>
										Your Current Position
									</label>
									<input
										id='currentPosition'
										type='text'
										name='currentPosition'
										className='form-control'
										placeholder='Your Current Position'
										onBlur={handleBlur}
										value={values.currentPosition}
										onChange={handleChange}
									/>
									{touched.currentPosition && errors.currentPosition && (
										<p className={ERROR_MESSAGE_CLASSES}>
											{errors.currentPosition}
										</p>
									)}
								</div>
								<div className='flex-col flex-1'>
									<label
										htmlFor='companyName'
										className='mb-2 text-sm font-medium left-5 inline-block text-gray-900'
									>
										Your Company Name
									</label>
									<input
										id='companyName'
										type='text'
										name='companyName'
										className='form-control'
										placeholder='Your Company Name'
										onBlur={handleBlur}
										value={values.companyName}
										onChange={handleChange}
									/>
									{touched.companyName && errors.companyName && (
										<p className={ERROR_MESSAGE_CLASSES}>
											{errors.companyName}
										</p>
									)}
								</div>
							</div>
						</div>
						<div className='text-right'>
							<div className='inline-flex items-center relative'>
								<button
									type='button'
									className={`${linkedInButtonStyle} self-stretch rounded-l-lg border-r-0`}
									onClick={(e) => {
										setIsLinkedInButtonClicked(true)
										handleSubmit(e)
									}}
								>
									<span className='px-4 py-2'>Continue With LinkedIn</span>
								</button>
								<button
									type='button'
									className={`${linkedInButtonStyle} rounded-r-lg border-l-0`}
									onClick={() =>
										setShouldShowWithoutLinkedInButton(
											!shouldShowWithoutLinkedInButton
										)
									}
								>
									<div className='icon px-3 py-3 border-l border-sky-900 h-full'>
										<img src={greenChevronDownIcon} alt='down arrow icon' />
									</div>
								</button>
								{shouldShowWithoutLinkedInButton && (
									<div
										className='w-full font-medium text-gray-700 py-4 px-4 lg:w-72 border border-gray-300 text-left absolute top-full right-0 rounded-md mt-3 hover:bg-gray-50 cursor-pointer'
										onClick={(e) => {
											setIsLinkedInButtonClicked(false)
											handleSubmit(e)
										}}
									>
										<span>Continue Without LinkedIn</span>
									</div>
								)}
							</div>
						</div>
					</form>
				)}
			</Formik>
		</div>
	)
}

export default Authentication
