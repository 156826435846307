import { createAsyncThunk } from '@reduxjs/toolkit'
import doAsync from '../../../infrastructure/doAsync'

export const saveCurrentInvestments = createAsyncThunk(
	'investmentForm/current-investments',
	async (details, thunkArgs, { useCaching, noBusySpinner } = {}) =>
		await doAsync({
			url: 'current-investments',
			useCaching,
			noBusySpinner,
			httpMethod: 'post',
			httpConfig: {
				body: JSON.stringify(details),
			},
			errorMessage:
				'Unable to save current investment details. Please try again later.',
			...thunkArgs,
		})
)
