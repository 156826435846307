import React, { useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import { useDispatch, useSelector } from 'react-redux'
import {
	DIQ_QUESTION_MAPPING,
	ERROR_MESSAGES,
	getRequiredErrorMessage,
} from '../../utils/constants'
import { validateMobileNumber } from '../../utils/methods'
import { NameRegularExpression } from '../../utils/constants.regex'
import { arrowRightWhite } from '../../assets/icons'
import { saveContactOnHubspot } from '../Reinvestment/ReinvestmentAffiliate.asynAction'
import { MEETING_SCHEDULER_ID } from '../../utils/getEnvVars'
import Loading from '../../widgets/Loading/Loading'
import { selectAffiliateDetails } from '../Affiliates/affiliates.selectors'

const {
	MIN_LENGTH_2,
	MAX_LENGTH_255,
	FIRST_NAME_VALIDATE_REGREX,
	MOBILE_NUMBER,
	LAST_NAME_VALIDATE_REGREX,
	EMAIL_VALIDATE_SPACE,
} = ERROR_MESSAGES

const initialValues = {
	firstName: '',
	lastName: '',
	email: '',
	phoneNumber: '',
}

const nameSchema = (fieldName, regex, errorMessage) =>
	Yup.string()
		.min(2, MIN_LENGTH_2)
		.max(255, MAX_LENGTH_255)
		.matches(regex, errorMessage)
		.required(getRequiredErrorMessage(fieldName))

const validationSchema = Yup.object().shape({
	firstName: nameSchema(
		'first name',
		NameRegularExpression,
		FIRST_NAME_VALIDATE_REGREX
	),
	lastName: nameSchema(
		'last name',
		NameRegularExpression,
		LAST_NAME_VALIDATE_REGREX
	),
	email: Yup.string()
		.email(EMAIL_VALIDATE_SPACE)
		.required(getRequiredErrorMessage('email')),
	phoneNumber: Yup.string()
		.test('phoneNumber', MOBILE_NUMBER, validateMobileNumber)
		.required(getRequiredErrorMessage('phone number')),
})

const schedulerFields = [
	{
		label: 'First Name',
		name: 'firstName',
		placeholder: 'Enter First Name',
		className: 'w-1/2 pr-2',
	},
	{
		label: 'Last Name',
		name: 'lastName',
		placeholder: 'Enter Last Name',
		className: 'w-1/2 pl-2',
	},
	{
		label: 'Email',
		name: 'email',
		placeholder: 'Enter Email',
	},
	{
		label: 'Phone Number',
		name: 'phoneNumber',
		type: 'number',
		placeholder: '(___) ___ - ____',
	},
]

const ScheduleConsultationSection = ({
	diqValues = {},
	handleClose = () => {},
}) => {
	const dispatch = useDispatch()
	const affiliateDetails = useSelector(selectAffiliateDetails)
	const [areValuesSaved, setAreValuesSaved] = useState(false)

	const handleFormSubmission = (values, { setSubmitting }) => {
		const { propertyType, moreDetails } = diqValues
		const formattedDiqValues = {}
		formattedDiqValues[DIQ_QUESTION_MAPPING.propertyType] = propertyType
		if (!isEmpty(moreDetails[propertyType]))
			Object.entries(moreDetails[propertyType]).forEach(
				([key, value]) =>
					(formattedDiqValues[DIQ_QUESTION_MAPPING[key]] = value)
			)
		const affiliate = affiliateDetails?.companySlug || ''
		const request = {
			...values,
			...formattedDiqValues,
			affiliate,
			urlReferrerQualify: '1',
		}

		dispatch(saveContactOnHubspot(request)).then(() => {
			setSubmitting(false)
			setAreValuesSaved(true)
		})
	}

	return (
		<div className='bg-white'>
			{!areValuesSaved ? (
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={handleFormSubmission}
				>
					{({ isSubmitting }) => (
						<Form>
							<div className='flex flex-col p-6 w-full'>
								<div>
									<div>
										<div className='font-work-sans-semibold text-gray-900 text-xl'>
											Schedule a Consultation
										</div>
										<div className='font-work-sans-medium text-lg text-gray-500 mt-2'>
											Input your personal information to set up an appointment
											for a quick, no-obligation consultation with an attorney
											trained specialist.
										</div>
										<div className='font-work-sans-medium text-lg text-gray-600 mt-4'>
											Personal Information
										</div>
									</div>
									<div className='form-group flex flex-wrap gap-y-4 pt-4 font-work-sans-medium text-sm text-gray-700'>
										{schedulerFields.map(
											({ label, name, className = 'w-full', ...props }) => (
												<label key={name} className={className}>
													<span>{label}</span>
													<Field
														name={name}
														className='form-control focus:border-gray-300 focus:ring-2 focus:ring-offset-gray-100 focus:ring-blue-200 remove-arrow'
														{...props}
													/>
													<ErrorMessage
														name={name}
														className='-z-10 text-red-500 text-xs bg-error-500 mt-[-10px] pt-3 pl-2 rounded-b-md'
														component='div'
													/>
												</label>
											)
										)}
									</div>
								</div>
								<div className='w-full pt-4 flex flex-col gap-2'>
									<button
										className='btn btn-primary-light rounded-full w-full text-white hover:bg-primary-100 font-work-sans-semibold flex justify-center items-center gap-3 duration-100'
										type='submit'
										disabled={isSubmitting}
									>
										<Loading loading={isSubmitting}>
											<div className='flex justify-center items-center gap-3'>
												Schedule Consultation
												<img src={arrowRightWhite} />
											</div>
										</Loading>
									</button>
									<button
										className='btn btn-light rounded-full w-full font-work-sans-semibold flex justify-center items-center'
										type='button'
										onClick={handleClose}
									>
										Cancel
									</button>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			) : (
				<div className='h-[80vh] w-full'>
					<div className='h-[80vh] w-full'>
						<iframe
							className='meetings-iframe-container h-[80vh] w-full'
							title='HubSpot Meeting Scheduler'
							src={`https://meetings.hubspot.com/${MEETING_SCHEDULER_ID}?embed=true`}
						/>
					</div>
				</div>
			)}
		</div>
	)
}

export default ScheduleConsultationSection
