// sell & purchase Options
export const moreDetailsSellOptions = [
	{
		key: 'inSellTransationNow',
		value: 0,
		title: "I'm in a transaction now",
		name: 'sellingProperty',
	},
	{
		key: 'sellInThreeMonths',
		value: 1,
		title: 'I plan to sell in 3 months',
		name: 'sellingProperty',
	},
	{
		key: 'sellInSixMonths',
		value: 2,
		title: 'I plan to sell in 6 months',
		name: 'sellingProperty',
	},
	{
		key: 'sellInOneYear',
		value: 3,
		title: 'I plan to sell in 1 year',
		name: 'sellingProperty',
	},
]

export const moreDetailsPurchaseOptions = [
	{
		key: 'inPurchaseTransationNow',
		value: 0,
		title: "I'm in a transaction now",
		name: 'purchasingProperty',
	},
	{
		key: 'purchaseInThreeMonths',
		value: 1,
		title: 'I plan to purchase in 3 months',
		name: 'purchasingProperty',
	},
	{
		key: 'purchaseInSixMonths',
		value: 2,
		title: 'I plan to purchase in 6 months',
		name: 'purchasingProperty',
	},
	{
		key: 'purchaseInOneYear',
		value: 3,
		title: 'I plan to purchase in 1 year',
		name: 'purchasingProperty',
	},
]

export const moreDetailsModalInfo = {
	title:
		'You must set up your 1031 Exchange before you sell in a Standard Exchange or before you buy in a Reverse Exchange.',
	subTextFirst:
		'You can either start your 1031 Exchange when you sell your current property first (Standard Exchange) or when you buy a new property first (Reverse Exchange).',
	subTextSecond:
		'You must complete your 1031 Exchange within six months. Your 1031 Exchange starts when you either sell first or buy first.',
}
